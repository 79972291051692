import { AbstractUploaderComponent } from "./abstract-uploader-component";
export class ImageCropperComponent extends AbstractUploaderComponent {
    constructor() {
        super(...arguments);
        this.croppedImage = '';
        this.cropperReady = false;
    }
    imageCropped(event) {
        this.croppedImage = event.base64;
    }
    imageLoaded() {
        this.cropperReady = true;
    }
    loadImageFailed() {
        console.log('Load failed');
    }
    getPath() {
        return this.path;
    }
    getFile() {
        return this.changeImageDataToFile(this.croppedImage);
    }
    changeImageDataToFile(imageData) {
        const [mimeStr, base64Str] = imageData.split(','), mime = mimeStr.match(/:(.*?);/)[1], bStr = atob(base64Str);
        let n = bStr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bStr.charCodeAt(n);
        }
        // return new File([u8arr], 'logo', {type: mime});
        return new Blob([u8arr], { type: mime });
    }
}
