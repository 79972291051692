import { Component, OnInit } from '@angular/core';
import { UserService } from '@masterplanner2/users';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'masterplanner2-settings-menu-page',
  templateUrl: './settings-menu-page.component.html',
  styleUrls: ['./settings-menu-page.component.css']
})
export class SettingsMenuPageComponent implements OnInit {

  private isAdmin$: Observable<boolean>;
  private isAnyAdmin$: Observable<boolean>;
  private isAnyManager$: Observable<boolean>;
  private isGroupAdmin$: Observable<boolean>;

  constructor(userService: UserService) {
    this.isAdmin$ = userService.getCurrentUserHasAnyRole(['ADMIN']);
    this.isAnyAdmin$ = userService.getCurrentUserHasAnyRole(['ADMIN', 'GROUPADMIN']);
    this.isGroupAdmin$ = userService.getCurrentUserHasAnyRole(['GROUPADMIN']);
  }

  ngOnInit() {
  }

  isAdmin(): Observable<boolean> {
    return this.isAdmin$
  }

  isAnyAdmin(): Observable<boolean> {
    return this.isAnyAdmin$
  }

  isAnyManager(): Observable<boolean> {
    return this.isAnyManager$
  }

  isGroupManager(): Observable<boolean> {
    return this.isAnyManager$
  }
}
