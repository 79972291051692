<table [formGroup]="parentForm"
       id="devopps"
       class="table table-condensed developments col-sm-12">
  <thead>
    <th> {{ 'BUYINGBEHAVIOR_NAME' | translate }} </th>
    <th> {{ 'QUALIFICATION' | translate }} </th>
    <th> {{ 'REASON' | translate }} </th>
    <th>
      <button
        type="button"
        class="btn ng-scope no-btn success pull-right"
        (click)="addItem()"
        title="{{ 'NEW' | translate }}">
        <i class="glyphicon glyphicon-plus "></i>
      </button>
    </th>
  </thead>
  <tbody formArrayName="behaviors">
    <tr *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
      <td>
        <select class="form-control"
                formControlName="name">
          <option *ngFor="let bb of (buyingbehaviors$ | async)" value="{{ bb }}">{{ bb | translate }}</option>
        </select>
      </td>
      <td>
        <masterplanner2-swot-type formControlName="swotType"></masterplanner2-swot-type>
      </td>
      <td>
        <input class="form-control" formControlName="swotReason">
      </td>
      <td>
        <button type="button"
                class="btn ng-scope no-btn danger deletebutton "
                (click)="removeItem(i)"
                title="{{ 'DELETE' | translate }}">
          <i class="glyphicon glyphicon-trash "></i>
        </button>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <td colspan="4">
      <label>{{ 'EXPLANATION' | translate }}</label>
      <textarea
        class="form-control"
        id="cBuyingbehaviorExplanation"
        placeholder=""
        formControlName="explanation"
        rows="4">
      </textarea>
    </td>
  </tfoot>
</table>



