import { Component, Input } from '@angular/core';

@Component({
  selector: 'masterplanner2-activity-planning-flat',
  templateUrl: './activity-planning-flat.component.html',
  styleUrls: ['./activity-planning-flat.component.css']
})
export class ActivityPlanningFlatComponent {
  @Input()
  activities = [];

  constructor() { }

}
