
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { shareReplay, skip, takeUntil, tap, map } from 'rxjs/operators';
import { UserService } from "@masterplanner2/users";

export class FirebaseTransLoader implements TranslateLoader {

  private resetLanguage$ = new Subject<string>();
  private resetTranslation$ = new Subject<any>();

  constructor(private db: AngularFirestore) {}

  /**
   * 
   * @param lang locale string that might contain inheritence. nl-{something} will inherit from nl-NL, and en-{something}
   * will inherit from en-US locale.
   * @param prefix 
   */
  public getTranslation(lang: string, prefix: string = 'translations'): any {
    
    this.resetLanguage$.next(lang);

    const mainLanguage = lang.split('_')[0];
    const mainTranslations$ = mainLanguage ? this.db.collection(prefix).doc(mainLanguage).valueChanges() : of({});
    const customerTranslations$ = (mainLanguage === lang) ? of({}) : this.db.collection(prefix).doc(lang).valueChanges();

    const translations$ = combineLatest(mainTranslations$, customerTranslations$).pipe(
      map(([mainTranslation, customerTranslation]) => ({...mainTranslation, ...customerTranslation})),
      tap(translations => console.log('translation ' + lang, translations)),
      shareReplay(1),
    ) as Observable<any>;

    translations$.pipe(
      skip(1),
      takeUntil(this.resetLanguage$),
      tap(() => {
        console.log('update language');
        this.resetTranslation$.next();
      }),
    ).subscribe();
    return translations$;
  }

  onResetLanguage() {
    return this.resetLanguage$;
  }

  onResetTraslation() {
    return this.resetTranslation$;
  }
}

export function firebaseTransReloaderFactory(translateService: TranslateService) {
  return () => {
    (<FirebaseTransLoader>translateService.currentLoader).onResetTraslation().pipe(
      tap(() => {
        console.log('reloading language');
        translateService.reloadLang(translateService.currentLang);
        translateService.onTranslationChange.emit({lang: translateService.currentLang, translations: []});
      })
    ).subscribe();
    return Promise.resolve();
  }
}

export function FbTransLoaderFactory(db: AngularFirestore) {
  return new FirebaseTransLoader(db);
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: FbTransLoaderFactory, deps: [AngularFirestore]}
    })
  ],
  exports: [
    TranslateModule,
  ],
  providers: [
    FirebaseTransLoader,
    {provide: APP_INITIALIZER, useFactory: firebaseTransReloaderFactory, deps:[TranslateService], multi: true}
  ]
})
export class NgxFirebaseTranslateModule { }
