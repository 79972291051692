<div>
  <p>{{ 'IMAGE_UPLOAD_INSTRUCTIONS' | translate }}</p>
  <input type="file" (change)="setUploadFileChangeEvent($event)" accept="image/jpeg, image/png" />
  <button *ngIf="uploadFileChangedEvent$.getValue()" type="button" (click)="uploadImage()"
          [class]="'progress-button ' + (uploadState$ | async)" data-style="shrink" data-horizontal="">
    <span class="content">Upload</span>
    <span class="progress"><span class="progress-inner" [style.width]="(uploadProgress$ | async) + '%'" style="opacity: 1;"></span></span>
  </button>
  <a  *ngIf="getImagePreview() ? getImagePreview() : (downloadUrl$ | async) as url" [href]="url" target="_blank" title="">
    <img [src]="url" style="max-width: 400px; max-height: 300px"/>
  </a>
</div>
