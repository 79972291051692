<div class="form-group" [formGroup]="form">
  <div class="col-sm-12">
    <div class="row margin-top">
      <div class="col-sm-3">
        <label for="drive" class="control-label">{{ keyaccountName }} {{'IS' | translate }}: </label>
      </div>
      <div class="col-sm-5">
        <ng-select [items]="drives" id="drive" placeholder="Select drive" bindValue="id" bindLabel="label" clearAllText="Clear"
          formControlName="drive">
          <ng-template ng-label-tmp let-item="item">
            {{ item.label | translate }}
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            {{ item.label | translate }}
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="col-sm-12">
    <div class="row margin-bottom">
      <div class="col-sm-12">
        <label for="cMaincosts" class="control-label">{{ 'CMAINCOSTS' | translate }}: </label>
      </div>
      <div class="col-sm-12">
        <textarea class="form-control" formControlName="maincosts" name="cMaincosts">
        </textarea>
      </div>
    </div>
  </div>

  <div class="col-sm-12">
    <div class="row margin-bottom">
      <div class="col-sm-6 text-right">
        <label for="iYearFinancefigures" class="control-label">
          {{ 'IYEARFINANCEFIGURES' | translate }}
        </label>
      </div>
      <div class="col-sm-2">
        <input type="number" id="iYearFinancefigures" formControlName="yearFinancefigures" class="form-control">
      </div>
    </div>

    <div class="row margin-bottom">
      <div class="col-sm-6 text-right">
        <label for="dGrossoperatingincome" class="control-label">
          {{ 'DGROSSOPERATINGINCOME' | translate }}
        </label>
      </div>
      <div class="col-sm-3">
        <kendo-numerictextbox [autoCorrect]="false"
                              [spinners]="false"
                              [decimals]="0"
                              [format]="'c0'"
                              formControlName="grossoperatingincome"
        ></kendo-numerictextbox>
      </div>
    </div>

    <div class="row margin-bottom">
      <div class="col-sm-6 text-right">
        <label for="dNetoperatingincome" class="control-label">
          {{ 'DNETOPERATINGINCOME' | translate }}
        </label>
      </div>
      <div class="col-sm-3">
        <kendo-numerictextbox [autoCorrect]="false"
                              [spinners]="false"
                              [decimals]="0"
                              [format]="'c0'"
                              formControlName="netoperatingincome"
        ></kendo-numerictextbox>
      </div>
    </div>
    <div class="row margin-bottom">
      <div class="col-sm-6 text-right">
        <label for="dSolvencypercentage" class="control-label">
          {{ 'DSOLVENCYPERCENTAGE' | translate }}
        </label>
      </div>
      <div class="col-sm-2">
        <div class="input-group">
          <input type="text" name="dSolvencypercentage" formControlName="solvencypercentage" class="form-control">
          <div class="input-group-addon">%</div>
        </div>
      </div>
    </div>
  </div>
</div>