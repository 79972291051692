/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-cropper.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ngx-image-cropper/ngx-image-cropper.ngfactory";
import * as i3 from "ngx-image-cropper";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./image-cropper.component";
import * as i7 from "@angular/fire/storage";
var styles_ImageCropperComponent = [i0.styles];
var RenderType_ImageCropperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageCropperComponent, data: {} });
export { RenderType_ImageCropperComponent as RenderType_ImageCropperComponent };
function View_ImageCropperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "image-cropper", [["format", "png"], ["outputType", "both"], ["style", "max-height: 33vh"]], [[4, "display", null]], [[null, "imageCropped"], [null, "imageLoaded"], [null, "loadImageFailed"], ["window", "resize"], ["document", "mousemove"], ["document", "touchmove"], ["document", "mouseup"], ["document", "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).moveImg($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:touchmove" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).moveImg($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:mouseup" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).moveStop() !== false);
        ad = (pd_3 && ad);
    } if (("document:touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 1).moveStop() !== false);
        ad = (pd_4 && ad);
    } if (("imageCropped" === en)) {
        var pd_5 = (_co.imageCropped($event) !== false);
        ad = (pd_5 && ad);
    } if (("imageLoaded" === en)) {
        var pd_6 = (_co.imageLoaded() !== false);
        ad = (pd_6 && ad);
    } if (("loadImageFailed" === en)) {
        var pd_7 = (_co.loadImageFailed() !== false);
        ad = (pd_7 && ad);
    } return ad; }, i2.View_ImageCropperComponent_0, i2.RenderType_ImageCropperComponent)), i1.ɵdid(1, 573440, null, 0, i3.ImageCropperComponent, [i4.DomSanitizer, i1.ChangeDetectorRef, i1.NgZone], { imageChangedEvent: [0, "imageChangedEvent"], format: [1, "format"], outputType: [2, "outputType"], maintainAspectRatio: [3, "maintainAspectRatio"], resizeToWidth: [4, "resizeToWidth"] }, { imageCropped: "imageCropped", imageLoaded: "imageLoaded", loadImageFailed: "loadImageFailed" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.uploadFileChangedEvent$.getValue(); var currVal_2 = "png"; var currVal_3 = "both"; var currVal_4 = false; var currVal_5 = 128; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.cropperReady ? null : "none"); _ck(_v, 0, 0, currVal_0); }); }
function View_ImageCropperComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["data-horizontal", ""], ["data-style", "shrink"], ["type", "button"]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.uploadImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "progress"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "progress-inner"], ["style", "opacity: 1;"]], [[4, "width", null]], null, null, null, null)), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("progress-button " + i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.uploadState$))); _ck(_v, 0, 0, currVal_0); var currVal_1 = (i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.uploadProgress$)) + "%"); _ck(_v, 5, 0, currVal_1); }); }
function View_ImageCropperComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "upload-preview"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 0, 0, currVal_0); }); }
export function View_ImageCropperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "input", [["accept", "image/jpeg, image/png"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.setUploadFileChangeEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ImageCropperComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageCropperComponent_2)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ImageCropperComponent_3)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.canUpload$)); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.croppedImage; _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.croppedImage ? _co.croppedImage : i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.downloadUrl$))); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_ImageCropperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "masterplanner2-image-cropper", [], null, null, null, View_ImageCropperComponent_0, RenderType_ImageCropperComponent)), i1.ɵdid(1, 114688, null, 0, i6.ImageCropperComponent, [i7.AngularFireStorage], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageCropperComponentNgFactory = i1.ɵccf("masterplanner2-image-cropper", i6.ImageCropperComponent, View_ImageCropperComponent_Host_0, { path: "path" }, {}, []);
export { ImageCropperComponentNgFactory as ImageCropperComponentNgFactory };
