<tr >
  <th>{{ name.toUpperCase() | translate }}</th>
  <td class="text-center  border-top" *ngFor="let year of yearSpan" [ngClass]="{'target-cell': year === currentYear && highlightCurrentYear }">
    <ng-container *ngIf="mode === 'default'">
        {{ value[year] }}
    </ng-container>
    <ng-container *ngIf="mode === 'estimatedSow'">
      {{ 'SOW_' + value[year] | translate}}
    </ng-container>
    <ng-container *ngIf="mode === 'profitMargin'">
      {{ 'MAR_' + value[year] | translate}}
    </ng-container>
  </td>
</tr>

<tr *ngIf="showIndex">
  <th>
    <span class="row-header">{{ 'INDEX_LASTYEAR_' + name.toUpperCase() | translate }}</span>
  </th>
  <ng-container *ngIf="(index$ | async) as idx">
    <td class="text-center border-top"
         *ngFor="let year of (yearSpan$ | async)"
         [ngClass]="{'target-cell': year === currentYear && highlightCurrentYear,
                   'target-cell-last': lastRowHighlight && currentYear === year && highlightCurrentYear}"
    >
      {{ idx[year] }} %
    </td>
  </ng-container>
</tr>
