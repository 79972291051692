<div class="activityplanspro-table" [formGroup]="form">
  <div *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
    <div class="activityplans-tablerow clearfix" *ngIf="row.value.realised && !row.value.deleted">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-12">
            <button class="btn btn-danger pull-right" (click)="removeItem(i)">{{ 'DELETE' | translate }}</button>
          </div>
        </div>
        <div class="row margin-bottom">
          <div class="col-sm-9">
            <label class="control-label" for="action">{{ 'KEY_ACTION' | translate }}</label>
            <input type="text" formControlName="action" name="action" class="form-control tablecellinput">
          </div>
          <div class="col-sm-3">
            <label class="control-label">{{ 'BY_WHOM' | translate }}</label>
            <input type="text" formControlName="responsible" class="form-control">
          </div>
        </div>
        <div class="row margin-bottom">
          <div class="col-sm-2">
            <label class="control-label">{{ 'STARTDATE' | translate }}</label>
            <kendo-datepicker formControlName="startDate" format="dd-MM-yyyy" style="display:block;"></kendo-datepicker>
          </div>
          <div class="col-sm-2">
            <label class="control-label">{{ 'REALISEDDATE' | translate }}</label>
            <kendo-datepicker formControlName="endDate" format="dd-MM-yyyy" style="display:block;"></kendo-datepicker>
          </div>
          <div class="col-sm-8">
            <label class="control-label">{{ 'STATUSBAR' | translate }}</label>
            <ng5-slider formControlName="progress" [options]="Object.assign({}, sliderOptions, {readOnly: row.value.subActivities.length > 0})"></ng5-slider>
          </div>
        </div>
        <div class="row" style="margin-bottom:15px;">
          <div class="col-sm-12 ">
            <label for="result" class="control-label">{{ 'CREALISED_RESULT_LABEL' | translate }}</label>
            <textarea class="form-control" formControlName="result" name="result"></textarea>
          </div>
        </div>
        <div class="row" style="margin-bottom:15px;">
          <div class="col-sm-12 ">
            <label for="realisedResult" class="control-label">{{ 'TITLE_REALISED_RESULT' | translate }}</label>
            <textarea class="form-control" formControlName="realisedResult" name="realisedResult"></textarea>
          </div>
        </div>
        <div class="row" style="margin-bottom:15px;">
          <div class="col-sm-12 ">
            <label for="lessonsLearned" class="control-label">{{ 'TITLE_LESSONSLEARNED' | translate }}</label>
            <textarea class="form-control" formControlName="lessonsLearned" name="lessonsLearned"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <hr>
          </div>
        </div>
      </div>
    </div>  
  </div>
</div>
