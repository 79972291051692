<div class="col-sm-12" [formGroup]="parentForm">
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <label for="MainActivitydescription" class="control-label">{{ 'MAINACTIVITIES_DESC' | translate }}</label>
        <textarea
          class="form-control"
          name="MainActivitydescription"
          formControlName="description">
        </textarea>
      </div>
    </div>
  </div><!--row-->
  
  <div class="row">
    <div class="col-xs-1">
      <label class="control-label">#</label>
    </div>
    <div class="col-xs-4">
      <label class="control-label">{{ 'MAINACTIVITIES_ACTIVITY' | translate }}</label>
    </div>
    <div class="col-xs-2">
      <label class="control-label">{{ 'QUALIFICATION' | translate }}</label>
    </div>
    <div class="col-xs-5">
      <label class="control-label">{{ 'REASON' | translate }}</label>          
    </div>
  </div><!--row-->

  <div formArrayName="activities">
    <div class="row" *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
      <div class="col-xs-1">
          <input type="text" placeholder="{{ i + 1 }}" class="form-control" readonly>
      </div>
      <div class="col-xs-4">
        <div class="form-group">
          <input  type="text"
                  formControlName="activity"
                  class="form-control">
        </div>
      </div>
      <div class="col-xs-2">
        <div class="form-group">
          <masterplanner2-swot-type formControlName="swotType"></masterplanner2-swot-type>
        </div>
      </div>
      <div class="col-xs-5">
        <div class="form-group">
          <input class="form-control" formControlName="swotReason">
        </div>
      </div>
    </div><!--row-->
  </div><!--formarray-->
</div><!--col-xs-12-->
