<div class="col-sm-4">
  <div class="btn-group pull-right">
    <!--
      todo
    <button class="btn btn-default" type="button">
      <i class=" glyphicon glyphicon-tag"></i>
    </button> -->

    <button 
      class="btn btn-default" 
      type="button"
      title="{{ 'PREVIOUS_STEP' | translate }}"
      (click)="goToPreviousStep($event)"
      [disabled]="!canGoPrevious">
      <i class=" glyphicon glyphicon-chevron-left"></i>
    </button>

    <button 
      class="btn btn-default" 
      type="button" 
      title="{{ 'NEXT_STEP' | translate }}"
      (click)="goToNextStep($event)"
      [disabled]="!canGoNext">
      <i class=" glyphicon glyphicon-chevron-right"></i>
    </button>

    <button 
      class="btn btn-default"
      type="button"
      title="{{ 'SAVE' | translate }}"
      (click)="doSave($event)"
      [disabled]="!canSave">
      <i class=" glyphicon glyphicon-floppy-disk"></i>
    </button>

    <button 
      class="btn btn-default"
      type="button"
      title="{{ 'SAVE_QUIT' | translate }}"
      (click)="saveAndClose($event)">
      <i class=" glyphicon glyphicon-remove"></i>
    </button>
  </div>

  <form [formGroup]="stepsForm">
    <select class="form-control" formControlName="stepsControl" (change)="changeStepViaDropdown($event)">
      <option 
        *ngFor="let step of getRelevantSteps() let i = index"
        [ngValue]="step">
        {{i + 1}} - {{ step.displayName | translate }}
      </option>
    </select>
  </form>
</div>
