import { Component, Input, OnInit } from '@angular/core';
import { Observable, of, Subject } from "rxjs";
import { AngularFireStorage } from "@angular/fire/storage";
import { catchError, tap } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";
import { AbstractUploaderComponent } from "../image-cropper/abstract-uploader-component";

@Component({
  selector   : 'masterplanner2-image-upload-preview',
  templateUrl: './image-upload-preview.component.html',
  styleUrls  : ['./image-upload-preview.component.css']
})
export class ImageUploadPreviewComponent extends AbstractUploaderComponent {
  @Input()
  path: string;

  constructor(protected fbStorage: AngularFireStorage, protected sanitizer: DomSanitizer) {
    super(fbStorage);
  }

  getPath() {
    return this.path;
  }

  getFile() {
    const uploadFileChangedEvent = this.uploadFileChangedEvent$.getValue();
    if(uploadFileChangedEvent && uploadFileChangedEvent.target  && uploadFileChangedEvent.target.files) {
      return uploadFileChangedEvent.target.files[0];
    }
    return null;
  }

  getImagePreview() {
    const uploadFileChangedEvent = this.uploadFileChangedEvent$.getValue();
    if(uploadFileChangedEvent) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(uploadFileChangedEvent.target.files[0]))
    }
    return null;
  }

}
