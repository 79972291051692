import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subscription } from "rxjs";
import { FeatureToggleValues } from "../../keyaccount-plan.model";

@Component({
  selector: 'masterplanner2-feature-toggles',
  templateUrl: './feature-toggles.component.html',
  styleUrls: ['./feature-toggles.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: FeatureTogglesComponent, multi: true}
  ]
})
export class FeatureTogglesComponent implements ControlValueAccessor {

  features = Object.keys(new FeatureToggleValues());
  form: FormGroup;


  onChangeSubscription: Subscription;
  onTouchedSubscription: Subscription;

  protected onChangeCallback: (any) => void;

  constructor(formBuilder: FormBuilder) {
    this.form = formBuilder.group(
      this.features.reduce((acc, feature) => {acc[feature] = []; return acc;}, {})
    );
  }

  ngOnDestroy() {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe();
    }
    if (this.onTouchedSubscription) {
      this.onTouchedSubscription.unsubscribe();
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe()
    }
    this.onChangeSubscription = this.form.valueChanges.subscribe(value => fn(this.form.getRawValue()));
  }

  registerOnTouched(fn: any): void {
    if (this.onTouchedSubscription) {
      this.onTouchedSubscription.unsubscribe()
    }
  }

  setDisabledState(isDisabled: boolean): void {
    console.log('abstract set disabled state');
  }

  writeValue(items: any): void {
    if(!items) { // possibly a null / undefined value
      items = {};
    }

    this.form.patchValue(items, );
  }
}
