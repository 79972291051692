import { Component } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR, Validators, FormBuilder } from "@angular/forms";
import { AbstractFormArrayListComponent } from "../abstract-form-array-list/abstract-form-array-list.component";

@Component({
  selector   : 'masterplanner2-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls  : ['./opportunities.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: OpportunitiesComponent, multi: true}
  ]
})
export class OpportunitiesComponent extends AbstractFormArrayListComponent {

  constructor(protected formBuilder: FormBuilder) {
    super(formBuilder);
  }

  createItemControls(): FormGroup {
    return this.formBuilder.group({
      'uid'                 : [, Validators.required],
      'name'                : [, Validators.required],
      'businessunit'        : [, Validators.required],
      'expectedAwardDate'   : [, Validators.required],
      'executionStartDate'  : [, Validators.required],
      'totalDevelopmentCost': [, Validators.required],
      'swotTypeOT'          : [, Validators.required],
      'swotTypeSW'          : [],
    })
  }

}
