import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { AbstractTabComponent } from "../profile/abstract-tab.component";
import { KeyaccountPlan } from "../../keyaccount-plan.model";
import { toNumbers } from '../utils';

@Component({
  selector   : 'masterplanner2-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls  : ['./dashboard.component.css']
})
export class DashboardComponent extends AbstractTabComponent {

  orderIntakeTargetData: number[] = [];
  orderIntakeData: number[] = [];
  orderBacklogData: number[] = [];
  targetRevenueData: number[] = [];
  customerRevenueData: number[] = [];
  Math = Math;

  statuses = ['In process', 'Active', 'Archive'];

  generalGoal: Observable<boolean>;

  patchForm(keyaccountPlan: KeyaccountPlan, canEdit: boolean) {
    const yearSpan: number[] = this.keyaccountPlanService.getYearSpan(keyaccountPlan.targetYear);

    super.patchForm(keyaccountPlan, canEdit);
    this.orderIntakeTargetData = [];
    this.orderIntakeData = [];
    this.orderBacklogData = [];
    this.targetRevenueData = [];
    this.customerRevenueData = [];
    
    yearSpan.forEach(year => {
      this.orderIntakeTargetData.push(keyaccountPlan.orderIntakeTarget[year]);
      this.orderIntakeData.push(keyaccountPlan.orderIntake[year]);
      this.orderBacklogData.push(keyaccountPlan.orderBacklog[year]);
      this.targetRevenueData.push(keyaccountPlan.targetRevenue[year]);
      this.customerRevenueData.push(keyaccountPlan.customerRevenue[year]);
    });

    this.form.disable({emitEvent: false});
    if(canEdit) {
      this.form.get('generalGoal').enable();
      this.form.get('status').enable();
    }
  }

  buildForm(): FormGroup {
    return this.formBuilder.group({
      'uid'             : [],
      'orderIntakeTarget'   : [],
      'orderIntake'         : [],
      'orderBacklog'        : [],
      'targetRevenue'       : [],
      'customerRevenue'     : [],
      'accountGoals'    : this.formBuilder.group({
        'explanation1': [],
        'explanation2': [],
        'explanation3': [],
        'explanation4': [],
        'explanation5': [],
      }),
      'customerName'          : [],
      'version'               : [],
      'targetYear'            : [],
      'keyaccountName'        : [],
      'keyaccountManagerName' : [],
      'status'                : [],
      'strategicActions'      : [],
      'generalGoal'           : [],
    });
  }

  countBrickwall(brickwallCriteria: any[]) {
    return this.keyaccountPlanService.countBrickwall(brickwallCriteria);
  }

  mapFromDatabase(keyaccountPlan) {
    const newStrategicActions = (keyaccountPlan.strategicActions ? keyaccountPlan.strategicActions : []).map(action => {
      const newSubActivities = (action.subActivities ? action.subActivities : []).map(subAction => {
        return {
          ...subAction,
          startDate: (subAction.startDate ? subAction.startDate.toDate() : null),
          endDate  : (subAction.endDate ? subAction.endDate.toDate() : null),
        }
      });
      return {
        ...action,
        subActivities: newSubActivities,
        startDate    : (action.startDate ? action.startDate.toDate() : null),
        endDate      : (action.endDate ? action.endDate.toDate() : null),
      };
    });
    return {
      ...keyaccountPlan,
      accountGoals: keyaccountPlan.accountGoals ? keyaccountPlan.accountGoals : {explanation1: null, explanation2: null, explanation3: null, explanation4: null, explanation5: null},
      strategicActions: newStrategicActions,
      orderIntakeTarget: keyaccountPlan.orderIntakeTarget ? toNumbers(keyaccountPlan.orderIntakeTarget) : {},
      orderIntake: keyaccountPlan.orderIntake ? toNumbers(keyaccountPlan.orderIntake) : {},
      orderBacklog: keyaccountPlan.orderBacklog ? toNumbers(keyaccountPlan.orderBacklog) : {},
      targetRevenue: keyaccountPlan.targetRevenue ? toNumbers(keyaccountPlan.targetRevenue) : {},
      customerRevenue: keyaccountPlan.customerRevenue ? toNumbers(keyaccountPlan.customerRevenue) : {}
    };
  }

}
