/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./abstract-tab.component";
var styles_RefreshToasterComponent = [];
var RenderType_RefreshToasterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RefreshToasterComponent, data: {} });
export { RenderType_RefreshToasterComponent as RenderType_RefreshToasterComponent };
export function View_RefreshToasterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Content has changed. "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "button", [["style", "color:black"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doRefresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Refresh"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "button", [["style", "color:black"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"]))], null, null); }
export function View_RefreshToasterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "refresh-toaster-component", [], null, null, null, View_RefreshToasterComponent_0, RenderType_RefreshToasterComponent)), i0.ɵdid(1, 49152, null, 0, i1.RefreshToasterComponent, [], null, null)], null, null); }
var RefreshToasterComponentNgFactory = i0.ɵccf("refresh-toaster-component", i1.RefreshToasterComponent, View_RefreshToasterComponent_Host_0, {}, {}, []);
export { RefreshToasterComponentNgFactory as RefreshToasterComponentNgFactory };
