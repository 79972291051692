import { Component, Input, OnInit } from '@angular/core';
import { AbstractFormArrayListComponent } from '../../profile/abstract-form-array-list/abstract-form-array-list.component';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { delay, tap } from 'rxjs/operators';

@Component({
  selector   : 'masterplanner2-sw-rating',
  templateUrl: './sw-rating.component.html',
  styleUrls  : ['./sw-rating.component.css'],
  providers  : [
    { provide: NG_VALUE_ACCESSOR, useExisting: SwRatingComponent, multi: true }
  ]
})
export class SwRatingComponent extends AbstractFormArrayListComponent implements OnInit {
  toMatrix: number;
  totalScore: number;

  disabled: boolean = false;

  @Input()
  mode: 'strength' | 'weakness';

  distinctionOptions = [
    {value: 0, label: 'SW_NONE'},
    {value: 1, label: 'SW_LITTLE'},
    {value: 3, label: 'SW_AVERAGE'},
    {value: 5, label: 'SW_HIGH'},
  ];

  relevanceOptions = [
    {value: 0, label: 'SW_NONE'},
    {value: 1, label: 'SW_LITTLE'},
    {value: 3, label: 'SW_AVERAGE'},
    {value: 5, label: 'SW_HIGH'},
  ];

  ngOnInit() {
    this.form.valueChanges.pipe(
      delay(0),
      tap(values => {
        let total = 0;
        let toMatrix = 0;
        for(let i = 0; i < values.length; i++) {
          const c = this.form.get('' + i);
          if(!c) {
            continue;
          }
          const v = (<FormGroup>c).getRawValue();

          const score = v.relevance * v.distinction;
          if(score !== undefined && score !== v.score ) {
            c.get('score').setValue(score);
            if(score && v.inMatrix === null) {
              c.get('inMatrix').setValue(true);
            } else if(!score) {
              c.get('inMatrix').setValue(null);
            }
          }
          total += score;
          toMatrix += c.get('inMatrix').value ? 1 : 0;
        }
        this.totalScore = total;
        this.toMatrix = toMatrix;
      })
    ).subscribe()
  }

  createItemControls(): FormGroup {
    return this.formBuilder.group({
      'uid'        : [, Validators.required],
      'name'       : [, Validators.required],
      'score'      : [{value: 0, disabled: true}, Validators.required],
      'inMatrix'   : [],
      'distinction': [, Validators.required],
      'relevance'  : [, Validators.required],
    });
  }

  inMatrix(i): boolean {
    return this.form.getRawValue()[i].inMatrix;
  }

  hasScore(i) {
    return this.form.getRawValue()[i].score;
  }

  toggleInMatrix(i) {
    if(this.form.disabled) {
      console.log('Cannot toggle value on disabled control');
      return;
    }

    const control = this.form.get('' + i).get('inMatrix');
    control.setValue(!control.value);
    this.onChangeCallback(this.form.value);
  }
}
