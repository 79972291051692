import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DmuService {

  private attitudes = [
    { name: 'AMBASSADOR', score: 5 },
    { name: 'SPONSOR', score: 3 },
    { name: 'NEUTRAL', score: 1 },
    { name: 'ANTI_SPONSOR', score: 0 }
  ];

  private visitingIntensities = [
    { name: 'NO_CONTACT', score: 1 },
    { name: 'RARELY', score: 2 },
    { name: 'REGULARLY', score: 3 },
    { name: 'HIGH_FREQUENTLY', score: 4 }
  ];

  private dmuRoles = [
    { name: 'INFLUENCER', score: 3 },
    { name: 'DECISIONMAKER', score: 5 }
  ];

  constructor() { }

  calculateDmuScore(contacts: any[]): number {
    const score = this.dmuRoles.map(dmuRole => {
      const contactsWithRole = contacts.filter(c => c.role === dmuRole.name);
      let countContactsWithRole = 1;

      if (contactsWithRole.length > 1) {
        countContactsWithRole = contactsWithRole.length;
      }

      return contacts.map(contact => {
        if (contact.role === dmuRole.name) {
          return this.getRoleScore(contact.role) *
            this.getAttitudeScore(contact.attitude) *
            this.getVisitingIntensityScore(contact.visitingIntensity);
        }
        return 0;
      })
        .reduce((acc, score) => acc + score, 0) / countContactsWithRole;
    })
      //influencer avg plus descisionmaker avg
      .reduce((acc, score) => acc + score, 0);

    return Math.round(score / 160 * 100);
  }

  calculateDmuVisits(contacts: any[]): number {
    return contacts
      .map(contact => contact.visitingIntensity !== null && contact.visitingIntensity !== 'NO_CONTACT' ? 1 : 0)
      .reduce((acc, visits) => acc + visits, 0);
  }

  getRoleScore(roleName: string): number {
    const role = this.dmuRoles.find(dr => dr.name === roleName);
    return role ? role.score : 0;
  }

  getAttitudeScore(attitudeName: string): number {
    const attitude = this.attitudes.find(att => att.name === attitudeName);
    return attitude ? attitude.score : 0;
  }

  getVisitingIntensityScore(viName: string): number {
    const visiting = this.visitingIntensities.find(vi => vi.name === viName);
    return visiting ? visiting.score : 0;
  }

  getAttitudes() { return this.attitudes; }
  getVisitingIntensities() { return this.visitingIntensities; }
  getDmuRoles() { return this.dmuRoles; }
}
