import { AbstractFormArrayListComponent } from "../../profile/abstract-form-array-list/abstract-form-array-list.component";
import { FormGroup } from "@angular/forms";
export class StrategicActionsComponent extends AbstractFormArrayListComponent {
    constructor() {
        super(...arguments);
        this.templateValue = {
            gotik: { 'g': 0, 'o': 0, 't': 0, 'i': 0, 'k': 0 },
            subActivities: [],
            manual: true
        };
        this.activityTypes = [
            { id: 1, label: 'DEV_STRATEGY', group: 'DEV' },
            { id: 2, label: 'DEV_NEWBUSINESS', group: 'DEV' },
            { id: 3, label: 'DEV_INNOVATION', group: 'DEV' },
            { id: 4, label: 'PROD_SPECS', group: 'PROD' },
            { id: 5, label: 'PROD_SERVICE', group: 'PROD' },
            { id: 6, label: 'PROD_GARUANTEE', group: 'PROD' },
            { id: 7, label: 'PROD_COMPLAINTS', group: 'PROD' },
            { id: 8, label: 'TARIFF_TARIFF', group: 'TARIFF' },
            { id: 9, label: 'QUOTATION_TENDERNO', group: 'QUOTATION' },
            { id: 10, label: 'QUOTATION_TENDERYESNOTPUBLIC', group: 'QUOTATION' },
            { id: 11, label: 'QUOTATION_TENDERYESPUBLIC', group: 'QUOTATION' },
            { id: 12, label: 'SALES_CROSSSELLING', group: 'SALES' },
            { id: 13, label: 'SALES_DEEPSELLING', group: 'SALES' },
            { id: 14, label: 'SALES_UPSELLING', group: 'SALES' },
            { id: 15, label: 'RELATION_DMU', group: 'RELATION' },
            { id: 16, label: 'RELATION_BRICKWALLING', group: 'RELATION' },
            { id: 17, label: 'PROJECT_PROJECT', group: 'PROJECT' },
            { id: 18, label: 'MISCELLANEOUS_MISCELLANEOUS', group: 'MISCELLANEOUS' },
        ];
    }
    createItemControls() {
        return this.formBuilder.group({
            'uid': [],
            'question': [{ value: '', disabled: true }],
            'option': [{ value: '', disabled: true }],
            'action': [],
            'responsible': [],
            'result': [],
            'startDate': [],
            'endDate': [],
            'progress': [],
            'realised': [],
            'subActivities': [],
            'realisedResult': [],
            'lessonsLearned': [],
            'activityType': [],
            'gotik': [],
            'fromOldPlan': [],
            'manual': [],
            'turnoverCurrentYear': [],
            'turnoverNextYear': [],
            'activityComments': [],
            'deleted': [],
        });
    }
    writeValue(items) {
        if (!items) { // possibly a null / undefined value
            items = [];
        }
        items = items.map(item => {
            return Object.assign({}, item, { gotik: item.gotik ? item.gotik : Object.assign({}, this.templateValue.gotik) });
        });
        super.writeValue(items);
    }
}
