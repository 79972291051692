<div class="col-sm-12" [formGroup]="form">
  <div class="form-group col-sm-12">
    <b><p class="info-text"
          ng-bind-html="'STRATEGIC_QUESTIONS_EXPLANATION_PRO' | translate "></p></b>
  </div>
  <div class="strategicissue-light" *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
    <div class="separator col-sm-12">
      <span class="pull-left col-sm-1">{{ row.get('type').value }}</span>
      <div class="col-sm-10 text-right">
        <input id="cAlternativesentence"
               class="form-control pull-left cAlternativesentence"
               formControlName="question"
        />
      </div>
      <div class="col-sm-1 text-right">
        <button type="button"
                class="btn ng-scope no-btn danger deletebutton "
                (click)="removeItem(i)"
                title="{{ 'DELETE' | translate }}"
                *ngIf="!(hasExecutableOptions$ | async)[i]"
        >
          <i class="glyphicon glyphicon-trash "></i>
        </button>
      </div>
    </div>
    <masterplanner2-strategic-options formControlName="options"></masterplanner2-strategic-options>
  </div>
</div>
