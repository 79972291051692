<table class="subactivities" *ngIf="activities?.length">
  <tr >
    <th colspan="2">
      <label>{{ 'SUBACTIVITYPLANS' | translate }}</label>
    </th>
  </tr>
  <tr>
    <th style="text-align: left">{{ 'CACTIVITYPLAN' | translate }}</th>
    <th style="text-align: left">{{'BY_WHOM' | translate}}</th>
  </tr>
  <tr *ngFor="let row of activities; let i = index;">
    <td>
      {{ row.description }}
    </td>
    <td>
      {{ row.responsible }}
    </td>
  </tr>
</table>

