import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { UserGroup } from './user';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class UserGroupService {
  private readonly collectionName = 'customers';

  private userGroups$: Observable<UserGroup[]>;

  constructor(db: AngularFirestore, userService: UserService) {
    this.userGroups$ = userService.getCurrentUser().pipe(
      switchMap(user => {
          if (!user) {// No current user
            return of([]);
          }
          let queryFn = ref => ref.where('uid', '==', user.groupUid);
          if (user.roles.includes('ADMIN')) {
            queryFn = ref => ref;
          }
          return db.collection<UserGroup>(this.collectionName, queryFn).valueChanges().pipe(
            catchError(err => {
              console.error('Error retrieving user groups', err);
              return of([]);
            }),
          );
        }
      ),
      shareReplay(1),
    );
  }

  getUserGroup(uid: string): Observable<UserGroup> {
    return this.userGroups$.pipe(
      map(groups => groups.find(group => group.uid === uid)),
    );
  }

  getUserGroups() {
    return this.userGroups$;
  }
}
