<div class="col-sm-12">
  <div class="row">
    <div class="col-sm-3">
      <div class="row">
        <div class="col-sm-12 border-top header-column">
          <i  class="info glyphicon glyphicon-exclamation-sign icon-popover pull-left"
              *ngIf="popoverTitle"
              title="{{ 'CLICKFORREMARK' | translate }}"
              [ngbPopover]="popoverTitle | translate"
              popoverTitle="{{ 'INFO' | translate }}"
              style="top: 0"
          ></i>
          <span class="row-header">
            {{ label ? label : name.toUpperCase() | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-sm-7" [formGroup]="form">
      <div class="row">
        <div class="col-sm-2 text-center  border-top" *ngFor="let year of yearSpan" [ngClass]="{'target-cell': year === currentYear && highlightCurrentYear }">
          <ng-container *ngIf="mode === 'default'">
              <kendo-numerictextbox [autoCorrect]="false"
                                    [spinners]="false"
                                    [decimals]="0"
                                    [format]="'n0'"
                                    [formControlName]="year"
              ></kendo-numerictextbox>
          </ng-container>
          <ng-container *ngIf="mode === 'estimatedSow'">
            <select [formControlName]="year" class="form-control">
              <option *ngFor="let key of estimatedSowValues"[value]="key">
                {{ 'SOW_' + key | translate}}
              </option>
            </select>
          </ng-container>
          <ng-container *ngIf="mode === 'profitMargin'">
            <select [formControlName]="year" class="form-control">
              <option *ngFor="let key of profitMarginValues" [value]="key">
                  {{ 'MAR_' + key | translate }}
              </option>
            </select>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-sm-2" [formGroup]="form">
      <div class="row">
        <div class="col-sm-12 border-top">
          <masterplanner2-swot-type formControlName="swotType" [mode]="swotMode" *ngIf="showSwotType"></masterplanner2-swot-type>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="showIndex">
    <div class="col-sm-3">
      <div class="row">
        <div class="col-sm-12  border-top header-column">
          <span class="row-header">{{ 'INDEX_LASTYEAR_' + name.toUpperCase() | translate }}</span>
        </div>
      </div>
    </div>
    <div class="col-sm-7">
      <div class="row" *ngIf="(index$ | async) as index">
        <div class="col-sm-2 text-center border-top"
             *ngFor="let year of yearSpan"
             [ngClass]="{'target-cell': year === currentYear && highlightCurrentYear,
                       'target-cell-last': lastRowHighlight && currentYear === year && highlightCurrentYear}"
        >
          <span> {{ index[year] }} </span>
          <span class="percent">%</span>
        </div>
      </div>
    </div>
    <div class="col-sm-2">
      <div class="row">
        <div class="col-sm-12 border-top">
        </div>
      </div>
    </div>
  </div>
</div>
