import { ComponentFactoryResolver, Directive, HostListener, Injector, Input, NgZone } from '@angular/core';
import { PrintFlatComponent } from './print-flat.component';
import * as saveAs from 'file-saver';
import * as htmlDocx from 'html-docx-js/dist/html-docx';
import { debounceTime, first, tap } from 'rxjs/operators';

@Directive({
  selector: '[masterplanner2PrintClick]'
})
export class PrintClickDirective {

  @Input()
  masterplanner2PrintClick: number;

  constructor(private resolver: ComponentFactoryResolver, private injector: Injector, protected ngZone: NgZone,
  ) {

  }

  @HostListener('click', ['$event'])
  onClick(evt: any) {
    const factory = this.resolver.resolveComponentFactory(PrintFlatComponent);
    const component = factory.create(this.injector);

    component.instance.keyaccountplanId$.next(this.masterplanner2PrintClick);
    component.instance.testing = 3443;
    component.changeDetectorRef.detectChanges();
    this.ngZone.onStable.asObservable().pipe(tap(() => component.hostView.detectChanges()), debounceTime(5000), first()).subscribe(() => {
      console.log(this, 'is stable', new Date());
      this.export(component.location.nativeElement.innerHTML);
    });
    console.log(component.location.nativeElement)


  }

  export(html: string) {
    const styles = `
     /* Font Definitions */
     @font-face
      {font-family:"Cambria Math";
      panose-1:2 4 5 3 5 4 6 3 2 4;}
    @font-face
      {font-family:"Calibri Light";
      panose-1:2 15 3 2 2 2 4 3 2 4;}
    @font-face
      {font-family:Calibri;
      panose-1:2 15 5 2 2 2 4 3 2 4;}
      
     /* Style Definitions */
     p {
      margin-top:0cm;
      margin-right:0cm;
      margin-bottom:8.0pt;
      margin-left:0cm;
      line-height:107%;
      font-size:11.0pt;
      font-family:"Calibri",sans-serif;
    }
    h1 {
      mso-style-link:"Heading 1 Char";
      margin-top:12.0pt;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:0cm;
      margin-bottom:.0001pt;
      line-height:107%;
      page-break-after:avoid;
      font-size:16.0pt;
      font-family:"Calibri Light",sans-serif;
      color:#2E74B5;
      font-weight:normal;
    }
    h2 {
      mso-style-link:"Heading 2 Char";
      margin-top:2.0pt;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:0cm;
      margin-bottom:.0001pt;
      line-height:107%;
      page-break-after:avoid;
      font-size:13.0pt;
      font-family:"Calibri Light",sans-serif;
      color:#2E74B5;
      font-weight:normal;
    }
    h3 {
      mso-style-link:"Heading 3 Char";
      margin-top:2.0pt;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:0cm;
      margin-bottom:.0001pt;
      line-height:107%;
      page-break-after:avoid;
      font-size:12.0pt;
      font-family:"Calibri Light",sans-serif;
      color:#1F4D78;
      font-weight:normal;
    }
	    
    .vertical-text {
      transform: rotate(270deg);
      transform-origin: 42% 217% 0;
      vertical-align: bottom !important;
    }
    .text-strong {
      font-weight: bold;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      max-width: 100%;
      margin-bottom: 20px;
      border: 1px solid #ddd;
    }
    .table {
      width: 100%;
      max-width: 100%;
      margin-bottom: 20px;
    }
    td {
      border: 1px solid #ddd;
    }
    .scorecell {
        vertical-align: middle !important
        padding: 5px;
        text-align: center;
    }
    `;

    const content = '<!DOCTYPE html><html><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><style> ' + styles + '</style></head><body>' + html + '</body></html>';
    const converted = htmlDocx.asBlob(content);
    saveAs(converted, 'masterplanner_report.docx');
  }
}
