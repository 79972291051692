<div class="col-sm-12 swots-container">
  <div class="form-group col-sm-6">
    <table id="swotsopportunities" class="table table-condensed  swots col-sm-12">
      <tr class="tableheader">
      </tr>
      <tr>
        <th>{{ 'SWOTSOPPORTUNITIES_TABLE_HEADER' | translate }}</th>
      </tr>
      <tr *ngFor="let swotItem of swotOpportunities$ | async">
        <td>
          {{ swotItem.name }}
        </td>
      </tr>
    </table>
  </div>
  <div class="form-group col-sm-6">
    <table id="swotsthreats" class="table table-condensed  swots col-sm-12">
      <tr class="tableheader">
      </tr>
      <tr>
        <th>{{ 'SWOTSTHREATS_TABLE_HEADER' | translate }}</th>
      </tr>
      <tr *ngFor="let swotItem of swotThreats$ | async">
        <td>
          {{ swotItem.name }}
        </td>
      </tr>
    </table>
  </div>
</div>
<div class="col-sm-12 swots-container">
  <div class="form-group col-sm-6">
    <table id="swotsstrengths" class="table table-condensed  swots col-sm-12">
      <tr class="tableheader">
      </tr>
      <tr>
        <th>{{ 'SWOTSSTRENGTHS_TABLE_HEADER' | translate }}</th>
      </tr>
      <tr *ngFor="let swotItem of swotStrengths$ | async">
        <td>
          {{ swotItem.name }}
        </td>
      </tr>
    </table>
  </div>
  <div class="form-group col-sm-6">
    <table id="swotsweaknesses" class="table table-condensed swots col-sm-12">
      <tr class="tableheader">
      </tr>
      <tr>
        <th>{{ 'SWOTSWEAKNESSES_TABLE_HEADER' | translate }}</th>
      </tr>
      <tr *ngFor="let swotItem of swotWeaknesses$ | async">
        <td>
          {{ swotItem.name }}
        </td>
      </tr>
    </table>
  </div>
</div>
