import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "./auth.service";
export class UserService {
    constructor(db, authService) {
        this.db = db;
        this.authService = authService;
        this.collectionName = 'users';
        this.userCollection = this.db.collection(this.collectionName);
        this.user$ = authService.uid$.pipe(tap(uid => console.log({ uid })), switchMap(uid => uid ? this.userCollection.doc(uid).valueChanges() : of(null)), map(user => user ? this.mapFromDatabase(user) : user), switchMap(user => user ?
            db.collection('customers').doc(user.groupUid).valueChanges().pipe(map((group) => {
                return Object.assign({}, user, { group });
            })) : of(null)), tap(user => console.log({ user })), shareReplay(1));
        this.hasCurrentUser$ = this.user$.pipe(map(user => user !== null));
        this.users$ = this.user$.pipe(switchMap(user => {
            let queryFn = ref => ref.where('uid', '==', user.uid);
            if (user.roles.includes('ADMIN')) {
                queryFn = ref => ref;
            }
            else if (user.roles.includes('GROUPADMIN')) {
                queryFn = ref => ref.where('groupUid', '==', user.groupUid);
            }
            return this.db.collection(this.collectionName, queryFn).valueChanges()
                .pipe(map(users => users.map(this.mapFromDatabase)));
        }), shareReplay(1));
    }
    getCurrentUser() {
        return this.user$;
    }
    getCurrentUserMainLanguage() {
        return this.user$.pipe(// Default to english if there is no user yet
        map(user => user && user.language ? user.language.split('_')[0] : 'en-US'));
    }
    hasCurrentUser() {
        return this.hasCurrentUser$;
    }
    getCurrentUserHasAnyRole(roles) {
        return this.user$.pipe(map(user => user && user.roles && user.roles.filter(role => roles.includes(role)).length > 0));
    }
    getUsers() {
        return this.users$;
    }
    getUser(uid) {
        return this.userCollection.doc(uid).valueChanges()
            .pipe(map(user => this.mapFromDatabase(user)));
    }
    getUserEmail(uid) {
        return this.userCollection.doc(uid).valueChanges().pipe(map(user => user.email));
    }
    createUser(user) {
        const dbUser = this.mapToDatabase(user);
        return this.authService.register(dbUser.email)
            .then(userCredential => this.userCollection.doc(userCredential.user.uid).set(Object.assign({}, dbUser, { uid: userCredential.user.uid })));
    }
    updateUser(uid, formUser) {
        return this.userCollection.doc(uid).update(Object.assign({}, formUser));
    }
    editUser(uid, user) {
        return this.userCollection.doc(user.uid).update(Object.assign({}, this.mapToDatabase(user), { uid: uid }));
    }
    mapToDatabase(formUser) {
        const valueListToBoolObjReducer = (roleObj, role) => {
            roleObj[role] = true;
            return roleObj;
        };
        return Object.assign({}, formUser, { roles: formUser.roles.reduce(valueListToBoolObjReducer, {}), keyaccounts: formUser.roles.includes('ACCOUNTMANAGER') ? formUser.keyaccounts.reduce(valueListToBoolObjReducer, {}) : [], keyaccountsView: formUser.roles.includes('ACCOUNTVIEWER') ? formUser.keyaccountsView.reduce(valueListToBoolObjReducer, {}) : [] });
    }
    mapFromDatabase(dbUser) {
        return Object.assign({}, dbUser, { roles: Object.keys(dbUser.roles).filter(key => dbUser.roles[key]), keyaccounts: dbUser.keyaccounts ? Object.keys(dbUser.keyaccounts).filter(key => dbUser.keyaccounts[key]) : [], keyaccountsView: dbUser.keyaccountsView ? Object.keys(dbUser.keyaccountsView).filter(key => dbUser.keyaccountsView[key]) : [] });
    }
}
UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.AngularFirestore), i0.inject(i2.AuthService)); }, token: UserService, providedIn: "root" });
