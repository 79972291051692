import { map } from 'rxjs/operators';
import { KeyaccountService } from '@masterplanner2/users';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../libs/users/src/lib/keyaccount.service";
export class AbstractImporterService {
    constructor(keyaccountService) {
        this.keyaccountService = keyaccountService;
        this.keyaccountNameToUid$ = keyaccountService.getKeyAccountsForCurrentUsersGroup().pipe(map(keyaccounts => {
            const keyAccountNameMap = keyaccounts.reduce((accumulator, item) => {
                accumulator[item.name] = item.uid;
                return accumulator;
            }, {});
            return keyAccountNameMap;
        }));
    }
    formatWarnings(warnings) {
        const aggregatedWarnings = warnings.reduce((acc, e) => {
            if (acc[e.message]) {
                acc[e.message] = [...acc[e.message], ...e.elements];
            }
            else {
                acc[e.message] = e.elements;
            }
            return acc;
        }, {});
        return Object.keys(aggregatedWarnings)
            .map(message => `${message}: ${aggregatedWarnings[message]
            .filter((value, index, list) => list.indexOf(value) === index) // unique
            .join(", ")}`).join(". ");
    }
}
AbstractImporterService.ngInjectableDef = i0.defineInjectable({ factory: function AbstractImporterService_Factory() { return new AbstractImporterService(i0.inject(i1.KeyaccountService)); }, token: AbstractImporterService, providedIn: "root" });
