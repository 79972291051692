export const environment = {
  production: true,
  loginRedirectUrl: 'https://masterplanner-live.firebaseapp.com',
  firebase: {
    apiKey: "AIzaSyCzWMrxZm0cMkJ8cvFjI-sLX8fTC-otq_s",
    authDomain: "masterplanner-live.firebaseapp.com",
    databaseURL: "https://masterplanner-live.firebaseio.com",
    projectId: "masterplanner-live",
    storageBucket: "masterplanner-live.appspot.com",
    messagingSenderId: "59757536334"
  }
};
