import { TeamMember } from '@masterplanner2/users';


export type YearlyValues = {[year:string]: number};
type swotType = {swotType: string, swotReason: string};
export type SwotTypeValueType = 'OPPORTUNITY' | 'THREAT' | 'NEUTRAL' | 'STRENGTH' | 'WEAKNESS';

export class FeatureToggleValues {
  otherGoals: boolean = false;
  brancheRevenue: boolean = false;
  chracteristics: boolean = false;
  finances: boolean = false;
  profitMargin: boolean = false;
  stakeholders: boolean = false;
  activityType: boolean = false;
  activityComments: boolean = false;
  gotik: boolean = false;
  noMatrix: boolean = false;
  brickwall: boolean = false;
  activityTurnover: boolean = false;
  orderIntakeTarget: boolean = false;
  orderIntake: boolean = false;
  orderBacklog: boolean = false;
  generalGoal: boolean = false;
  showExport: boolean = false;
}

export interface LastUpdatedRevenues {
  orderIntakeTarget: any;
  orderBacklog: any;
  orderIntake: any;
  targetRevenue: any;
  customerRevenue: any;
  servicelines: any;
}

export type ScoreMatrix =  {
  uid?: string;
  userEmail?: string;
  userName?: string;
  rows: {id: number, value: number}[][];
}
export type ScoreMatrixList = {name: string, cells: {id: number, value: number}[]};
export type ScoreMatrixUserList = {userUid: string, name: string, cells: {id: number, value: number}[]}[];

export class Keyaccount {
  uid?: string;
  name: string;
  logo: string;
  groupUid: string;
  teamMembers: TeamMember[];
}

export class BuyingPower {
  uid: string;
  competitor1: number;
  competitor2: number;
  importance: number;
  score: number;
}

export class BuyingPowerScore extends BuyingPower {
  name?: string;
  restScore: number;
  weightedScore: number;
  importanceScore: number;
  competitor1Score: number;
  competitor2Score: number;
}

export class BuyingPowerTotals {
  scores: BuyingPowerScore[];

  companyTotalWeightedScore: number;
  companyTotalRestScore: number;
  competitor1Score: number;
  competitor2Score: number;
  companyIndex: number;
  competitor1Index: number;
  competitor2Index: number;

}

export class Activity {
  uid: string;
  description: string;
  responsible: string;
  startDate: Date;
  endDate: Date;
  progress: number;
  realised: boolean;
  activityType: number[];
  gotik: {
    'g': number,
    'o': number,
    't': number,
    'i': number,
    'k': number
  };
}

export class KeyaccountPlan {
  uid?: string;
  groupUid: string;
  targetYear: number;
  keyaccountManagerUid: string;
  keyaccountManagerName: string;
  keyaccountName: string;
  keyaccountUid: string;
  customerName: string;
  version: string;
  status: string;

  // Profile tab
  keyaccountRevenue: YearlyValues;
  brancheRevenue: YearlyValues;

  // Analysis tab
  orderIntakeTarget: YearlyValues;
  orderBacklog: YearlyValues;
  orderIntake: YearlyValues;
  targetRevenue: YearlyValues;
  customerRevenue: YearlyValues;
  sow: YearlyValues;
  profitMargin: YearlyValues;
  orderSwotReason: string;
  revenueSwotReason: string;
  orderIntakeImportedAt?: any;
  revenueImportedAt?: any;

  lastUpdatedRevenues?: LastUpdatedRevenues;

  profileRemarks: string;
  industry: string;
  companyType: string;
  strategy: string;
  mission: string;
  missionSwotType: SwotTypeValueType;
  mainGoals: string;
  mainActivities: {
    activities: {uid: string, activity: string, swotType: SwotTypeValueType}[],
    description: string;
  };
  employeesTotalCompany: number;
  departments: {
    uid: string,
    name: string,
    numEmployees: number
  }[];
  competitors: {
    uid: string,
    name: string,
    mainProducts: string,
    strengths: string,
    weaknesses: string
  }[];
  opportunities: {
    uid: string,
    name: string,
    businessunit: string,
    ultimateClient: string,
    expectedAwardDate: string,
    executionStartDate: string,
    totalDevelopmentCost: number;
    bokaValue: number;
    swotTypeOT: string;
    swotTypeSW: string;
  }[];
  developments: {
    uid: string;
    name: string;
    type: string;
    swotType: string;
  }[];
  strengthAndWeaknesses: {
    uid: string;
    name: string;
    type: string;
    swotType: string;
  }[];
  purchaseProcess: {
    specifyDecision: string;
    selectDecision: string;
    contractDecision: string;
    orderDecision: string;
    monitorDecision: string;
    evaluateDecision: string;

    specifyInvolved: string;
    selectInvolved: string;
    contractInvolved: string;
    orderInvolved: string;
    monitorInvolved: string;
    evaluateInvolved: string;

    explanation: string;
    swotType: string;
    swotReason: string;
  };
  buyingBehavior: {
    behaviors: {
      uid: string;
      name: string;
      type: string;
      swotType: string;
    }[];
    explanation: string;
  };
  buyingpowers: {
    [buyingpowerUid: string]: BuyingPower;
  } & {
    competitor1: string,
    competitor2: string,
    extra: {
      competitor1: number,
      competitor2: number,
      importance: number,
      name: string,
      positionCompetition1: any,
      positionCompetition2: any,
      score:number;
    },
    swotReason: string,
    swotType: string,
  };

  servicelines: {
    [servicelineUid: string]: {
      uid: string;
      description: string;
      competitor1: string;
      competitor2: string;
      positionCompetition1: SwotTypeValueType;
      positionCompetition2: SwotTypeValueType;
    } & YearlyValues & swotType
  };

  buyingpowerindex: any;
  buyingpowerindexSwotType: SwotTypeValueType;
  dmus: {
    contacts: {
      attitude: string;
      firstname: string;
      function: string;
      prefix: string;
      role: string
      surname: string
      uid: string
      visitingIntensity: string;
    }[];
    score: number;
    swotReasonOT: string;
    swotReasonSW: string;
    swotTypeOT: string;
    swotTypeSW: string;
    visits: number;
  };
  projects:{
    projects: {uid: string, opportunityName: string, status: string, statusReason: string, expiryDate: string}[]
    swotType: SwotTypeValueType;
    swotReason: string;
  };
  freeformOT: any[];
  freeformSW: any[];

  matrix: {
    uid: string;
    name: string;
    category: string;
    swotType: SwotTypeValueType;
    score: number;
    // SW
    inMatrix?: boolean;
    // TO
    distinction?: number;
    relevance?: number;
  }[];

  matrixCompleted = false;

  strategicQuestions: {
    cellId: number;
    question: string;
    type: string;
    options: {
      uid: string;
      description: string;
      execute: boolean;
      rating1: number;
      rating2: number;
      rating3: number;
      rating4: number;
      rating5: number;
      realised: boolean;
    }[];
  }[];

  // All strategic options from strategic questions that have execute=true
  strategicActions: (Activity & {
    question: string;
    option: string;
    fromOldPlan: boolean;
    deleted: boolean;
    subActivities: Activity[];
  })[];
  accountGoals: {
    explanation1: string;
    explanation2: string;
    explanation3: string;
    explanation4: string;
    explanation5: string;
  };

  swotOpportunities: {
    name: string;
    score: number;
    uid: string;
  }[] = [];

  swotThreats: {
    name: string;
    score: number;
    uid: string;
  }[] = [];

  swotStrengths: {
    distinction: string;
    inMatrix: boolean;
    name: string;
    relevance: string;
    score: number;
    uid: string;
  }[] = [];

  swotWeaknesses: {
    distinction: string;
    inMatrix: boolean;
    name: string;
    relevance: string;
    score: number;
    uid: string;
  }[] = [];


  featureToggles: FeatureToggleValues;

  culture: number;
  proposition: number;
  characteristics: {uid: number, name: number, top3: boolean}[];
  finances: {
    drive: number;
    maincosts: string;
    yearFinancefigures: number;
    grossoperatingincome: number;
    netoperatingincome: number;
    solvencypercentage: number;
  };

  stakeholders: {

  }[];
  brickwall: {
    description: string;
    option0: string;
    option1: string;
    option2: string;
    score: number;
    target: number;
    uid: string;
  }[];
}
