import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureToggleValues, KeyaccountPlan } from '../../../keyaccount-plan.model';

@Component({
  selector: 'masterplanner2-turnover-dashboard',
  templateUrl: './turnover-dashboard.component.html',
  styleUrls: ['./turnover-dashboard.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TurnoverDashboardComponent {

  @Input()
  public keyaccountPlans$: Observable<KeyaccountPlan[]>;

  @Input()
  featureToggles: FeatureToggleValues;

  @Input()
  yearSpan: number[];

  constructor() {
  }

}
