<div class="subactivities" [formGroup]="form">
  <div class="col-sm-12">
    <div class="col-sm-12">
      <label class="pull-left">{{ 'SUBACTIVITYPLANS' | translate }}</label>
      <button type="button"
              class="btn btn-newsub pull-left success no-btn"
              (click)="addItem()"
      >
        <i class="glyphicon glyphicon-plus"></i>
      </button>
    </div>
  </div>
  <div class="col-sm-12 clearfix subactivity" *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
    <div class="col-sm-12 subsep"></div>
    <div class="col-sm-7 table-cell">
      <input type="text" formControlName="description"
             class="form-control tablecellinput"
             placeholder="{{ 'CACTIVITYPLAN' | translate }}"
      >
    </div>
    <div class="col-sm-5 table-cell" style="min-height: 50px">
      <ng5-slider formControlName="progress" [options]="sliderOptions"></ng5-slider>
    </div>
    <div class="col-sm-4  table-cell">
      <input type="text" formControlName="responsible"
             class="form-control tablecellinput"
             placeholder="{{'BY_WHOM' | translate}}"
      >
    </div>
    <div class="col-sm-2 table-cell">
      <kendo-datepicker formControlName="startDate" format="dd-MM-yyyy"></kendo-datepicker>
    </div>
    <div class="col-sm-2 table-cell">
      <kendo-datepicker formControlName="endDate" format="dd-MM-yyyy"></kendo-datepicker>
    </div>
    <div class="cols-sm-1 table-cell">
      <button type="button"
              class="btn btn-remsub pull-right danger no-btn"
              (click)="removeItem(i)"
              style="padding-top: 3px; padding-left: 0px; position: relative;"
              *ngIf="row.value.progress < 100"
      >
        <i class="glyphicon glyphicon-trash"></i>
      </button>
    </div>
  </div>
</div>

