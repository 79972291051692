import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'masterplanner2-sub-activities-flat',
  templateUrl: './sub-activities-flat.component.html',
  styleUrls: ['./sub-activities-flat.component.css']
})
export class SubActivitiesFlatComponent {

  @Input()
  activities = [];

  constructor() { }

}
