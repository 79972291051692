import { Component, OnInit } from '@angular/core';
import { User } from '../user';
import { combineLatest, Observable } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { UserService } from '../user.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'masterplanner2-users-grid',
  templateUrl: './users-grid.component.html',
  styleUrls: ['./users-grid.component.css']
})
export class UsersGridComponent implements OnInit {
  displayedColumns = ['name', 'group', 'keyaccounts', 'actionsColumn'];

  form: FormGroup;
  users$: Observable<User[]>;
  filteredUsers$: Observable<User[]>;

  constructor(private userService: UserService, formBuilder: FormBuilder) {
    this.form = formBuilder.group({
      'name'            : [],
      'email'           : [],
      'groupName'       : [],
      'keyaccountNames' : [],
    });

  }

  ngOnInit() {
    this.users$ = this.userService.getUsers();

    this.filteredUsers$ = combineLatest(this.users$, this.form.valueChanges.pipe(startWith(this.form.value))).pipe(
      map(([keyaccountPlans, filtersForm]) => {
        const filterKeys = Object.keys(filtersForm);
        const filterMap = filterKeys
          .map(field =>({key: field, value: filtersForm[field] ? filtersForm[field].toLowerCase(): null}))
          .filter(filterField => !!filterField.value)
        ;
        const filterFn = (keyaccountPlan) => {
          return filterMap.reduce((acc, filter) => {
            return acc && keyaccountPlan[filter.key] && keyaccountPlan[filter.key].toString().toLowerCase().includes(filter.value);
          }, true)
        };
        return filterMap.length ? keyaccountPlans.filter(filterFn) : keyaccountPlans;
      })
    );
  }

  trackByUId(index, item) {
    return item.uid;
  }

  resetFiltersForm(): void {
    this.form.reset();
  }

}
