<p><strong >{{ 'EXPLANATION_GOAL1' | translate }}</strong></p>
<p>{{ accountGoals?.explanation1 }}</p>
<p><strong >{{ 'EXPLANATION_GOAL2' | translate }}</strong></p>
<p>{{ accountGoals?.explanation2 }}</p>
<p><strong >{{ 'EXPLANATION_GOAL3' | translate }}</strong></p>
<p>{{ accountGoals?.explanation3 }}</p>
<p><strong >{{ 'EXPLANATION_GOAL4' | translate }}</strong></p>
<p>{{ accountGoals?.explanation4 }}</p>
<ng-container *ngIf="showOtherGoals">
  <p><strong >{{ 'EXPLANATION_GOAL5' | translate }}</strong></p>
  <p>{{ accountGoals?.explanation5 }}</p>
</ng-container>