<div [formGroup]="form">
  <div class="col-sm-1 pull-left leftheader">
    <div class="vertical-text text-center" id="leftheader1">
      <span>{{ 'STRENGTHS' | translate }}</span>
    </div>
    <div class="vertical-text text-center" id="leftheader2">
      <span> {{ 'WEAKNESSES' | translate }}</span>
    </div>
  </div>

  <table
    id="matrix"
    class="table table-condensed  matrix col-sm-11  pull-left">
    <tr class="tableheader">
      <th class="empty"></th>
      <th class="topheader text-center" colspan="3">
        {{ 'OPPORTUNITIES' | translate }}
      </th>
      <th class="topheader text-center" colspan="3">
        {{ 'THREATS' | translate }}
      </th>
    </tr>
    <tr>
      <td class="empty" width="12.5%"></td>
      <td *ngFor="let swotName of OTs" class="descriptionY" width="12.5%"><span>{{ swotName | translate }}</span></td>
    </tr>
    <tr *ngFor="let row of form.controls; let rowIndex = index" [formArrayName]="rowIndex">
      <td class="descriptionX" width="12.5%"><span>{{ getSwName(rowIndex) | translate }}</span></td>
      <td *ngFor="let cell of row.controls; let cellIndex = index" [formGroupName]="cellIndex"
          width="12.5%"
          class="text-center scorecell"
          [ngClass]="{
                                    'score-1' : cell.get('value').value == '1',
                                    'score-3' : cell.get('value').value == '3',
                                    'score-5' : cell.get('value').value == '5'
                                }"
      >
        <div class="btn-group">
          <select
            id="{{cell.get('id')}}"
            formControlName="value"
          >
            <option [value]="0">0</option>
            <option *ngIf="isScoreAvailable(cellIndex, rowIndex, 1)" [value]="1">1</option>
            <option *ngIf="isScoreAvailable(cellIndex, rowIndex, 3)" [value]="3">3</option>
            <option *ngIf="isScoreAvailable(cellIndex, rowIndex, 5)" [value]="5">5</option>      
          </select>
        </div>
      </td>
    </tr>
  </table>
  <button class="btn btn-primary" type="button" (click)="saveScore()">{{ 'SAVE_SCORE' | translate}}</button>
  <button class="btn btn-default" type="button" (click)="resetScore()">{{ 'RESET_SCORE' | translate}}</button>
</div>
