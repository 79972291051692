import { Component, Input, OnInit } from '@angular/core';
import { ScoreMatrix, ScoreMatrixUserList, SwotTypeValueType } from '../../../keyaccount-plan.model';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { KeyaccountPlanService } from '../../keyaccount-plan.service';

@Component({
  selector: 'masterplanner2-matrix-flat',
  templateUrl: './matrix-flat.component.html',
  styleUrls: ['./matrix-flat.component.css']
})
export class MatrixFlatComponent implements OnInit {
  @Input()
  matrix: {uid: string, name: string, swotType: SwotTypeValueType}[];

  @Input()
  scoreMatrix: ScoreMatrix;
  @Input() // Y-axis titles
  SWs: string[];
  @Input() // X-axis titles
  OTs: string[];

  constructor(keyaccountPlanService: KeyaccountPlanService) {

  }

  ngOnInit() {
  }

  getOtNames(): string[] {
    if(!this.matrix) {
      return [];
    }
    return [
      ...this.matrix.filter(matrixRow => matrixRow.swotType === 'OPPORTUNITY'),
      ...this.matrix.filter(matrixRow => matrixRow.swotType === 'THREAT'),
    ].map(swot => swot.name);
  }

  getSwName(row: number): string {
    if(!this.matrix) {
      return '';
    }
    const combined = [
      ...this.matrix.filter(matrixRow => matrixRow.swotType === 'STRENGTH'),
      ...this.matrix.filter(matrixRow => matrixRow.swotType === 'WEAKNESS'),
    ];
    return combined[row] ? combined[row].name : '-';
  }



}
