<div class="panel panel-primary">
  <div class="sw-heading col-sm-12 panel-heading">
    <h2>{{ 'customers' | translate }}</h2>
  </div>
  <table cdk-table [dataSource]="dataSource" class="table table-bordered table-striped table-hover">
    <!-- Name Column -->
    <ng-container cdkColumnDef="name">
      <th cdk-header-cell *cdkHeaderCellDef> Klantnaam </th>
      <td cdk-cell *cdkCellDef="let element"> {{ element.name }}</td>
    </ng-container>

    <!-- Email Column -->
    <ng-container cdkColumnDef="contact">
      <th cdk-header-cell *cdkHeaderCellDef> Contactpersoon </th>
      <td cdk-cell *cdkCellDef="let element"> {{ element.contact }}</td>
    </ng-container>

    <!-- group Column -->
    <ng-container cdkColumnDef="address">
      <th cdk-header-cell *cdkHeaderCellDef> Adres </th>
      <td cdk-cell *cdkCellDef="let element"> {{ element.address }}</td>
    </ng-container>

    <!-- group Column -->
    <ng-container cdkColumnDef="zipcode">
      <th cdk-header-cell *cdkHeaderCellDef> Postcode </th>
      <td cdk-cell *cdkCellDef="let element"> {{ element.zipcode }}</td>
    </ng-container>

    <!-- group Column -->
    <ng-container cdkColumnDef="city">
      <th cdk-header-cell *cdkHeaderCellDef> Stad </th>
      <td cdk-cell *cdkCellDef="let element"> {{ element.city }}</td>
    </ng-container>

    <!-- group Column -->
    <ng-container cdkColumnDef="country">
      <th cdk-header-cell *cdkHeaderCellDef> Land </th>
      <td cdk-cell *cdkCellDef="let element"> {{ element.country }}</td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container cdkColumnDef="actionsColumn">
      <th cdk-header-cell *cdkHeaderCellDef>
        <a class="btn btn-success btn-xs" routerLink="/settings/new-customer"
           title=" {{ 'NEW' | translate }}"
        >
          <i class="glyphicon glyphicon-plus "></i>
        </a>

      </th>
      <td cdk-cell *cdkCellDef="let element">
        <a routerLink="/settings/customer/{{element.uid}}" class="btn btn-default btn-xs btn-primary" title="{{ 'EDIT' | translate }}">
          <i class="glyphicon glyphicon-pencil"></i>
        </a>
      </td>
    </ng-container>

    <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
