import { toNumber as _toNumber } from 'lodash'

export function toNumbers(object: any): any {
    Object.keys(object).forEach(key => {
        if (object[key] && !isNaN(Number(object[key]))) {
            object[key] = _toNumber(object[key])
        }
    });
    return object;
}

export function toNumber(value: any): number {
    return _toNumber(value);
}