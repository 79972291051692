import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConnectableObservable, Subscription } from 'rxjs';
import { map, publishReplay, tap } from 'rxjs/operators';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DomainValue } from '../../../customer.model';
import { IntlService } from '@progress/kendo-angular-intl';
import { tag } from 'rxjs-spy/operators';
import * as firebase from 'firebase/app';
import Timestamp = firebase.firestore.Timestamp;
import { LastUpdatedRevenues } from '../../../keyaccount-plan.model';

@Component({
  selector: 'masterplanner2-servicelines',
  templateUrl: './servicelines.component.html',
  styleUrls: ['./servicelines.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: ServicelinesComponent, multi: true}
  ]
})
export class ServicelinesComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  targetYear: number;
  @Input()
  yearSpan: number[];
  @Input()
  serviceLines: DomainValue[];

  @Input()
  revenueImportedAt: any;

  @Input()
  lastUpdatedRevenues: LastUpdatedRevenues;

  form: FormGroup;

  yearlySum$: ConnectableObservable<{[key: string]: number}>;

  private onChangeSubscription: Subscription;
  private onTouchedSubscription: Subscription;

  constructor(private formBuilder: FormBuilder, private intlService: IntlService) {
    this.form = formBuilder.group({
    });


    this.yearlySum$ = this.form.valueChanges.pipe(
      tag('Servicelines'),
      map(servicelines => this.yearSpan.map(year => {
        const yearlySum = Object.keys(servicelines).reduce((acc, newValue) => {
          return acc + (servicelines[newValue] && servicelines[newValue][year] ? parseInt(servicelines[newValue][year], 10) : 0)
        }, 0);
        return {year: year, sum: yearlySum}
      })),
      map(yearlySumArr => yearlySumArr.reduce((acc, newValue) => {acc[newValue.year] = newValue.sum; return acc}, {})),
      tag('Servicelines yearly sum'),
      publishReplay(1),
    ) as ConnectableObservable<{[key: string]: number}>;
    // This is an hot observable, otherwise the initial patched value is not seen by the time we subscribe in the template
    this.yearlySum$.connect();

  }

  ngOnInit() {
    console.log(this.lastUpdatedRevenues);
    const formGroup = this.form;
    this.serviceLines.forEach(serviceLine => {
      formGroup.addControl(serviceLine.uid, this.formBuilder.group(this.createServicelineControls()));
      formGroup.get(serviceLine.uid).get('description').setValue(serviceLine.description);
    });
  }

  ngOnDestroy() {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe();
    }
    if (this.onTouchedSubscription) {
      this.onTouchedSubscription.unsubscribe();
    }
  }

  createServicelineControls(): {[key: string]: FormControl} {
    const controlMap = {};
    this.yearSpan.forEach(year => {
      controlMap['' + year] = this.formBuilder.control(0, null);
    });
    controlMap['description'] = this.formBuilder.control('', null);
    controlMap['competitor1'] = this.formBuilder.control('', null);
    controlMap['competitor2'] = this.formBuilder.control('', null);
    controlMap['positionCompetition1'] = this.formBuilder.control('', null);
    controlMap['positionCompetition2'] = this.formBuilder.control('', null);
    controlMap['swotType'] = this.formBuilder.control('', null);
    controlMap['swotReason'] = this.formBuilder.control('', null);
    return controlMap;
  }

  registerOnChange(fn: any): void {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe()
    }
    this.onChangeSubscription = this.form.valueChanges.subscribe(value => fn(value));
  }

  registerOnTouched(fn: any): void {
    if (this.onTouchedSubscription) {
      this.onTouchedSubscription.unsubscribe()
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.form[isDisabled ? 'disable' : 'enable']({emitEvent: false});
  }
  writeValue(items: any): void {
    if(!items) {
      items = {};
    }
    this.form.patchValue(items);
  }

  public formatNumber(value: number, format: string): string {
    return this.intlService.formatNumber(value, format);
  }
}
