<table
  *ngIf="scores$ | async as scores"
  id="buyingpowerindices"
  class="table table-condensed buyingpowerindices col-sm-12">
  <tr>
    <th style="width: 30%; text-align: left">
      {{ 'BUYINGPOWERINDICES_TABLE_HEADER' | translate }}
    </th>
    <th style="width: 10%; text-align: left">
      {{ 'BUYINGPOWERINDICES_IMPORTANCE' | translate }}
    </th>
    <th style="width: 10%; text-align: left">
      {{ 'BUYINGPOWERINDICES_SCORE' | translate }} {{ customerName }}
    </th>
    <th style="width: 30%; text-align: left">
      {{ 'BUYINGPOWERINDICES_WEIGHTSCORE' | translate }} {{ customerName }}
    </th>
    <th style="width: 10%; text-align: left">
      {{ buyingpowers.competitor1 }}
    </th>
    <th style="width: 10%; text-align: left">
      {{ buyingpowers.competitor2 }}
    </th>
  </tr>
  <tr *ngFor="let buyingcriterion of (allBuyingcriteria$ | async)" >
    <ng-container *ngIf="buyingpowers[buyingcriterion.uid]?.name">
      <td class="col-sm-4">
        {{ buyingpowers[buyingcriterion.uid]?.name }}
      </td>
      <td class="rating">
        {{ buyingpowers[buyingcriterion.uid]?.importance }}
      </td>
      <td class="rating">
        {{ buyingpowers[buyingcriterion.uid]?.score }}
      </td>
      <td class="progressbar">
        <div class="bar-container" *ngIf="scores[buyingcriterion.uid] as score">
          {{ Math.round(score.weightedScore) }}
        </div>
      </td>
      <td class="rating">
        {{  Math.round(buyingpowers[buyingcriterion.uid]?.competitor1 * buyingpowers[buyingcriterion.uid]?.importance) }}
      </td>
      <td class="rating">
        {{  Math.round(buyingpowers[buyingcriterion.uid]?.competitor2 * buyingpowers[buyingcriterion.uid]?.importance) }}
      </td>
    </ng-container>
  </tr>
  <tfoot *ngIf="(sum$ | async) as score">
  <tr>
    <td></td>
    <th colspan="2" class="text-right">{{ 'TOTAL' | translate }}</th>
    <td class="progressbar">
      <div class="bar-container">
        {{ Math.round(score.companyTotalWeightedScore) }}
      </div>
    </td>
    <td class="text-left">
      <span class="text-center">{{ score.competitor1Score }}</span>
    </td>
    <td class="text-left">
      <span class="text-center">{{ score.competitor2Score }}</span>
    </td>
  </tr>
  <tr class="totalline">
    <th colspan="3" class="text-right"> {{ 'BUYINGPOWERINDEX' | translate }}</th>
    <th style="text-align: left">
      {{ Math.round(score.companyIndex) }}
    </th>
    <th style="text-align: left">
      {{ Math.round(score.competitor1Index) }}
    </th>
    <th style="text-align: left">
      {{ Math.round(score.competitor2Index) }}
    </th>
  </tr>
  </tfoot>
</table>
