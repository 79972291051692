<div class="row" [formGroup]="form">
  <div class="col-sm-12">
    <div class="row table-row">
      <div class="col-sm-7">
        <label class="control-label">{{ title }}</label>
      </div>
      <div class="col-sm-4">
        <label class="control-label">{{ 'QUALIFICATION' | translate }}</label>
      </div>
      <div class="col-sm-1">
        <button type="button" 
                class="btn ng-scope no-btn success pull-right" 
                (click)="addItem()" 
                title="{{ 'NEW' | translate }}"
        >
          <i class="glyphicon glyphicon-plus "></i>
        </button>
      </div>
    </div>
    <div class="row table-row" *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
      <div class="col-sm-7 table-column">
        <input type="text" class="form-control" formControlName="name">
      </div>
      <div class="col-sm-4 table-column">
        <masterplanner2-swot-type formControlName="swotType"></masterplanner2-swot-type>
      </div>
      <div class="col-sm-1 table-column">
        <button type="button" 
                class="btn ng-scope no-btn danger deletebutton pull-right" 
                (click)="removeItem(i)" 
                title="{{ 'DELETE' | translate }}"
        >
          <i class="glyphicon glyphicon-trash "></i>
        </button>
      </div>
    </div>
  </div>
</div>