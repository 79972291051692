/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-preview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./image-preview.component";
import * as i5 from "@angular/fire/storage";
var styles_ImagePreviewComponent = [i0.styles];
var RenderType_ImagePreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImagePreviewComponent, data: {} });
export { RenderType_ImagePreviewComponent as RenderType_ImagePreviewComponent };
function View_ImagePreviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "media-object img-responsive"]], [[8, "src", 4], [4, "max-height", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf; var currVal_1 = _co.maxHeight; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ImagePreviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "glyphicon glyphicon-picture pull-right"]], [[8, "title", 0]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("NO_LOGO_YET")), ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_ImagePreviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ImagePreviewComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["noImage", 2]], null, 0, null, View_ImagePreviewComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.downloadUrl$)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ImagePreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "masterplanner2-image-preview", [], null, null, null, View_ImagePreviewComponent_0, RenderType_ImagePreviewComponent)), i1.ɵdid(1, 114688, null, 0, i4.ImagePreviewComponent, [i5.AngularFireStorage], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImagePreviewComponentNgFactory = i1.ɵccf("masterplanner2-image-preview", i4.ImagePreviewComponent, View_ImagePreviewComponent_Host_0, { path: "path", maxHeight: "maxHeight" }, {}, []);
export { ImagePreviewComponentNgFactory as ImagePreviewComponentNgFactory };
