import { Pipe, PipeTransform } from '@angular/core';
import { UserGroupService } from './user-group.service';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'userGroup'
})
export class UserGroupPipe implements PipeTransform {

  constructor(private userGroupService: UserGroupService) {}
  transform(value: any, args?: any): any {
    return this.userGroupService.getUserGroup(value).pipe(map(group => group ? group.name : null));
  }

}
