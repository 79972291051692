import { Component, Input, OnInit } from '@angular/core';
import { addQuarters, differenceInCalendarDays, format, startOfQuarter } from "date-fns";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Activity } from "../../../keyaccount-plan.model";


@Component({
  selector   : 'masterplanner2-activity-calendar',
  templateUrl: './activity-calendar.component.html',
  styleUrls  : ['./activity-calendar.component.css']
})
export class ActivityCalendarComponent implements OnInit {

  @Input()
  activities: Observable<Activity[]>;
  activitiesPlanning$: Observable<(Activity & {duration:number, startAt: number, endAt: number, isMaster?: boolean})[]>;

  quarters: Date[];
  quarterNames: string[];

  startCalendar: Date;
  endCalendar: Date;

  constructor() {
    // we go from the previous quarter and render 4 quarters;
    const now = new Date();
    const startQ = startOfQuarter(now);

    this.quarters = [-1, 0, 1, 2].map(nr => addQuarters(startQ, nr));
    this.quarterNames = this.quarters.map(d => format(d, 'yyyy - Q'));

    this.startCalendar = this.quarters[0];
    this.endCalendar = addQuarters(this.quarters[3], 1);

    console.log('CALENDAR', this.quarters, this.quarterNames);

  }

  ngOnInit() {
    const totalDays = differenceInCalendarDays(this.endCalendar, this.startCalendar);
    if (this.activities) {
      this.activitiesPlanning$ = this.activities.pipe(
        map(activities => {
          return activities
            // Don't show activities with no date or description and only when not realised yet
            .filter(activity => activity.startDate
              && activity.endDate
              && activity.description
              && (activity.startDate < activity.endDate)
              && !activity.realised
            )
            .map(activity => {
              const startDiff = differenceInCalendarDays(activity.startDate, this.startCalendar);
              const endDiff = differenceInCalendarDays(activity.endDate, this.startCalendar);
              const startAt = startDiff > 0 ? (startDiff / totalDays * 100) : 0;
              const endAt = Math.min(endDiff > 0 ? (endDiff / totalDays * 100) : 0, 100);
              const duration = endAt - startAt;
              return {
                ...activity,
                startAt,
                endAt,
                duration,
              }
            })
        })
      );
    }
  }

  trackByFn(index, item) {
    return item.uid;
  }

}
