import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'masterplanner2-total-matrix',
  templateUrl: './total-matrix.component.html',
  styleUrls: ['./total-matrix.component.css']
})
export class TotalMatrixComponent implements OnInit {
  @Input()
  matrix: {id: number, value: number}[][];
  @Input() // Y-axis titles
  SWs: string[];
  @Input() // X-axis titles
  OTs: string[];

  @Input()
  collapsed = false;

  @Input()
  totalMatrix = true;

  @Input()
  userName: string;

  @Output()
  addStrategicQuestion = new EventEmitter();

  @Input()
  canAddStrategicQuestion: boolean;

  constructor(private translateService: TranslateService) {

  }

  ngOnInit() {
    console.log('totalMatrix', this.matrix);
  }

  addStrategicIssue(cellId) {
    // One-based column/row index
    const x = (cellId - 1) % 6;
    const y = Math.floor((cellId - 1) / 6);    

    const xAxisTitle = this.getOtName(x);
    const yAxisTitle = this.getSwName(y);

    const xType = (x <= 2) ? 'O' : 'T';
    const yType = (y <= 2) ? 'S' : 'W';
    const type = xType + yType;
    const title = this.translateService.instant('STRATEGIC_QUESTION_' + type, {ot: xAxisTitle, sw: yAxisTitle, type});

    console.log({x, y, xAxisTitle, yAxisTitle, xType, yType, type, title});
    this.addStrategicQuestion.emit({cellId, title, type});
  }

  getSwName(i) {
    return this.SWs? this.SWs[i] : '';
  }
  getOtName(i) {
    return this.OTs? this.OTs[i] : '';
  }
}
