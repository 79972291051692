<div [formGroup]="form" class="form-group lpts">
  <table
    id="lpts"
    class="table table-condensed lpts col-sm-12">
    <tr>
      <th colspan="9">
        <span> {{'LPTS_STEP3' | translate }}</span>
        <span ng-bind-html="vm.oEndpoint.cBrandcode"></span>
      </th>
    </tr>
    <tr>
      <th>{{ 'COPPORTUNITYNAME' | translate }}</th>
      <th>{{ 'CBUSINESSUNIT' | translate }}</th>
      <th>{{ 'CEXPECTEDAWARDDATE' | translate }}</th>
      <th>{{ 'CEXECUTIONSTARTDATE' | translate }}</th>
      <th>{{ 'CTOTALDEVELOPMENTCOST' | translate }}</th>
      <th>{{ 'BOKAVALUE' | translate }}</th>
      <th style="width: 5%">{{ 'GO%' | translate }}</th>
      <th style="min-width: 135px;">{{ 'QUALIFICATION' | translate }}</th>
    </tr>
    <tr *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
      <td>
        <input
          type="text"
          class="form-control"
          formControlName="name"
        >
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          formControlName="businessunit"
        >
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          formControlName="expectedAwardDate"
        >
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          formControlName="executionStartDate"
        >
      </td>
      <td>
        <kendo-numerictextbox [autoCorrect]="false"
                              [spinners]="false"
                              [decimals]="0"
                              [format]="'n0'"
                              formControlName="totalDevelopmentCost"
        ></kendo-numerictextbox>
      </td>
      <td>
        <kendo-numerictextbox [autoCorrect]="false"
                              [spinners]="false"
                              [decimals]="0"
                              [format]="'n0'"
                              formControlName="bokaValue"
        ></kendo-numerictextbox>
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          formControlName="goPercentage"
        >
      </td>
      <td>
        <masterplanner2-swot-type formControlName="swotTypeSW" mode="SW"></masterplanner2-swot-type>
      </td>
    </tr>
  </table>
</div>
