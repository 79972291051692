import { ErrorHandler } from '@angular/core';
import { AuthGuard, AuthTokenGuard, EmailLoginComponent, UserCreateComponentComponent, UserModuleConfig, UsersGridComponent } from '@masterplanner2/users';
import * as Sentry from "@sentry/browser";
import 'hammerjs';
import { environment } from '../environments/environment';
import { CustomerEditComponent } from './customer-edit/customer-edit.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { KeyaccountEditComponent } from './keyaccount-edit/keyaccount-edit.component';
import { KeyaccountPlanCreateComponent } from './keyaccount-plan-create/keyaccount-plan-create.component';
import { KeyaccountPlansListComponent } from './keyaccount-plans-list/keyaccount-plans-list.component';
import { ActivityComponent } from './keyaccountplans/activity/activity.component';
import { AnalysisComponent } from './keyaccountplans/analysis/analysis.component';
import { DashboardComponent } from './keyaccountplans/dashboard/dashboard.component';
import { LessonsComponent } from './keyaccountplans/lessons/lessons.component';
import { MatrixComponent } from "./keyaccountplans/matrix/matrix.component";
import { PrintFlatComponent } from './keyaccountplans/print-flat/print-flat.component';
import { ProfileComponent } from './keyaccountplans/profile/profile.component';
import { StrategyComponent } from './keyaccountplans/strategy/strategy.component';
import { SwotComponent } from './keyaccountplans/swot/swot.component';
import { KeyaccountsListComponent } from './keyaccounts-list/keyaccounts-list.component';
import { MySettingsComponent } from './my-settings/my-settings.component';
import { SettingsMenuPageComponent } from './settings-menu-page/settings-menu-page.component';
import { TranslationComponent } from './translation/translation.component';
import '@progress/kendo-angular-intl/locales/nl/all';
import localeNl from '@angular/common/locales/nl';
import { registerLocaleData } from '@angular/common';
import { BundleListComponent } from './bundles/bundle-list/bundle-list.component';
import { BundleCreateComponent } from './bundles/bundle-create/bundle-create.component';
import { DashboardComponent as BundleDashboardComponent } from './bundles/dashboard/dashboard.component';
import { ImportComponent } from './import/import.component';
import { CanDeactivateTabGuard } from './keyaccountplans/can-deactivate-tab.guard';
registerLocaleData(localeNl, 'nl-NL');
Sentry.init({
    defaultIntegrations: false,
    dsn: "https://b13fee07fd73451c967a2fd3bca7e98b@sentry.io/1361351"
});
export class SentryErrorHandler {
    constructor() { }
    handleError(error) {
        Sentry.captureException(error.originalError || error);
        console.error(error);
        throw error;
    }
}
const routes = [
    // user settings & login
    { path: '', pathMatch: 'full', redirectTo: 'login' },
    { path: 'login', component: EmailLoginComponent },
    { path: 'settings', component: SettingsMenuPageComponent, canActivate: [AuthGuard] },
    { path: 'settings/users', component: UsersGridComponent, canActivate: [AuthGuard] },
    { path: 'settings/user/:id', component: UserCreateComponentComponent, canActivate: [AuthGuard] },
    { path: 'settings/new-user', component: UserCreateComponentComponent, canActivate: [AuthGuard] },
    { path: 'settings/my-settings', component: MySettingsComponent, canActivate: [AuthGuard] },
    // customers
    { path: 'settings/customers', component: CustomerListComponent, canActivate: [AuthGuard] },
    { path: 'settings/new-customer', component: CustomerEditComponent, canActivate: [AuthGuard] },
    { path: 'settings/customer/:id', component: CustomerEditComponent, canActivate: [AuthGuard] },
    // keyaccounts
    { path: 'settings/keyaccounts', component: KeyaccountsListComponent, canActivate: [AuthGuard] },
    { path: 'settings/new-keyaccount', component: KeyaccountEditComponent, canActivate: [AuthGuard] },
    { path: 'settings/keyaccount/:id', component: KeyaccountEditComponent, canActivate: [AuthGuard] },
    // bundles
    { path: 'settings/bundles', component: BundleListComponent, canActivate: [AuthGuard] },
    { path: 'settings/new-bundle', component: BundleCreateComponent, canActivate: [AuthGuard] },
    { path: 'settings/new-bundle/:id', component: BundleCreateComponent, canActivate: [AuthGuard] },
    { path: 'settings/bundle/:id', component: BundleCreateComponent, canActivate: [AuthGuard] },
    { path: 'settings/dashboard/:id', component: BundleDashboardComponent, canActivate: [AuthGuard] },
    // keyaccountplans
    { path: 'keyaccountplans', component: KeyaccountPlansListComponent, canActivate: [AuthGuard] },
    { path: 'keyaccountplan/:id/dashboard', component: DashboardComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateTabGuard] },
    { path: 'keyaccountplan/:id/profile', component: ProfileComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateTabGuard] },
    { path: 'keyaccountplan/:id/analysis', component: AnalysisComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateTabGuard] },
    { path: 'keyaccountplan/:id/swot', component: SwotComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateTabGuard] },
    { path: 'keyaccountplan/:id/matrix', component: MatrixComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateTabGuard] },
    { path: 'keyaccountplan/:id/strategy', component: StrategyComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateTabGuard] },
    { path: 'keyaccountplan/:id/activity', component: ActivityComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateTabGuard] },
    { path: 'keyaccountplan/:id/lessons', component: LessonsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateTabGuard] },
    { path: 'keyaccountplan/:id/print', component: PrintFlatComponent, canActivate: [AuthTokenGuard], canDeactivate: [CanDeactivateTabGuard] },
    { path: 'keyaccountplan/new-plan', component: KeyaccountPlanCreateComponent, canActivate: [AuthGuard] },
    { path: 'keyaccountplan/new-plan/:id', component: KeyaccountPlanCreateComponent, canActivate: [AuthGuard] },
    // import
    { path: 'settings/import', component: ImportComponent, canActivate: [AuthGuard] },
    // translation
    { path: 'settings/translation', component: TranslationComponent, canActivate: [AuthGuard] },
];
const userModuleConfig = {
    loginRedirectUrl: environment.loginRedirectUrl,
    redirectAfterLogin: '/keyaccountplans',
    firebaseCfg: environment.firebase
};
const ɵ0 = userModuleConfig;
export class AppModule {
}
export { ɵ0 };
