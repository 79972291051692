<div name="editForm" id="editForm" class="form-horizontal col-sm-12" [formGroup]="form" (submit)="doSave()" role="form">
  <div class="panel panel-primary" *ngIf="(keyaccountPlan$ | async) as keyaccountPlan">
    <div class="sw-heading col-sm-12 panel-heading">
      <div class="pull-left col-sm-8">
        <h2>
          <masterplanner2-header-logos [groupUid]="keyaccountPlan.groupUid" 
                                       [keyaccountUid]="keyaccountPlan.keyaccountUid"
          ></masterplanner2-header-logos>
          <div class="col-sm-10">{{ 'ACCOUNTANALYSIS_PRO' | uppercase | translate }}</div>
        </h2>
        <div class="col-sm-12 version">{{ 'VERSION' | translate }}:{{ keyaccountPlan.version }}
          {{ 'ITARGETYEAR' | translate }}: {{ keyaccountPlan.targetYear }}
        </div>
      </div>
      <masterplanner2-nav-bar (save)="doSave()" [canSave]="canEdit$ | async"></masterplanner2-nav-bar>
    </div>
    <div class="form-group">
      <div class="separator col-sm-12">
        {{ keyaccountPlan.customerName }}
        {{ 'POSITION_AT' | translate }}
        {{ keyaccountPlan.keyaccountName }}
        <span class="info-text col-sm-6  text-right pull-right">{{ 'ALL_AMOUNTS_THOUSENDS' | translate }}</span>
      </div>
      <masterplanner2-account-revenues formControlName="accountRevenues" [yearSpan]="yearSpan$ | async"
                                       [featureToggles]="keyaccountPlan.featureToggles"
                                       [revenueImportedAt]="keyaccountPlan.revenueImportedAt"
                                       [orderIntakeImportedAt]="keyaccountPlan.orderIntakeImportedAt"
                                       [lastUpdatedRevenues]="keyaccountPlan.lastUpdatedRevenues"></masterplanner2-account-revenues>

      <masterplanner2-servicelines *ngIf="servicelines$ | async as servicelines" formControlName="servicelines"
                                   [yearSpan]="yearSpan$ | async" [targetYear]="keyaccountPlan.targetYear"
                                   [serviceLines]="servicelines"
                                   [revenueImportedAt]="keyaccountPlan.revenueImportedAt"
                                   [lastUpdatedRevenues]="keyaccountPlan.lastUpdatedRevenues"></masterplanner2-servicelines>

      <div class="col-sm-12 separator">
        <span>{{ keyaccountPlan.customerName }} </span>
        <span>{{ 'PROJECTS_TENDERS' | translate }}</span>
      </div>
      <masterplanner2-projects formControlName="projects"></masterplanner2-projects>
      <masterplanner2-oppertunities-analysis formControlName="opportunities"></masterplanner2-oppertunities-analysis>

      <div class="separator col-sm-12">
        {{ keyaccountPlan.keyaccountName }}
        {{ 'TITLE_BUYING_POWER_INDEX_PRO' | translate }}
      </div>

      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-8">
            <b>
              <p class="info-text" [innerHTML]="'BUYING_POWER_INDEX_TABLE_EXPLANATION' | translate "></p>
            </b>
          </div>
          <div class="col-sm-4 text-right" style="padding-right:70px;">
            <label [innerHTML]="'COMPETITION' | translate"></label>
          </div>
        </div>      
      </div>
      <masterplanner2-buyingpower
        *ngIf="buyingcriteria$ | async as buyingcriteria"
        formControlName="buyingpowers"
        [buyingcriteria]="buyingcriteria"
        [customerName]="keyaccountPlan.customerName"
      >
      </masterplanner2-buyingpower>

      <div class="separator col-sm-12">
        {{ keyaccountPlan.customerName }} {{ 'TITLE_DMU_PRO_STEP3' | translate }} {{ keyaccountPlan.keyaccountName }}
      </div>
      <div class="col-sm-12">
        <div class="form-group col-sm-8">
          <b>
            <p class="info-text" [innerHTML]="'DMU_STEP3PRO_EXPLANATION' | translate"></p>
          </b>
      </div>
      </div>
      <masterplanner2-critical-contacts formControlName="dmus"></masterplanner2-critical-contacts>

      <div *ngIf="keyaccountPlan.featureToggles.brickwall" id="brickwalling" class="separator col-sm-12">
        {{ keyaccountPlan.customerName }} {{ 'BRICKWALLCRITERIUMS' | translate }} {{ keyaccountPlan.keyaccountName }}
      </div>
      <div class="col-sm-12" *ngIf="keyaccountPlan.featureToggles.brickwall">
        <div class="form-group col-sm-12">
          <b><p class="info-text" [innerHTML]="'BRICKWALLCRITERIUMS_TABLE_EXPLANATION' | translate "></p></b>
        </div>
      </div>
      <masterplanner2-brickwall-score *ngIf="keyaccountPlan.featureToggles.brickwall" formControlName="brickwall"></masterplanner2-brickwall-score>
    </div>
  </div>
</div>
























