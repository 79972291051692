import { AbstractFormArrayListComponent } from "../../../profile/abstract-form-array-list/abstract-form-array-list.component";
import { FormGroup } from "@angular/forms";
import { sliderOptions } from '../slider-optionts';
export class SubActivitiesComponent extends AbstractFormArrayListComponent {
    constructor() {
        super(...arguments);
        this.sliderOptions = sliderOptions;
    }
    createItemControls() {
        return this.formBuilder.group({
            'uid': [],
            'description': [],
            'responsible': [],
            'result': [],
            'startDate': [],
            'endDate': [],
            'progress': [],
            'realised': [],
        });
    }
}
