import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UserService } from '@masterplanner2/users';

export interface Locale {
  code: string;
  name: string;
  isMainLocale: boolean;
  groupUid?: string;
}
 
@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private readonly locales$: Observable<Locale[]>;

  translationCollection: AngularFirestoreCollection<any>;
  localesCollection: AngularFirestoreCollection<Locale>;

  constructor(private db: AngularFirestore, userService: UserService) {
    this.translationCollection = db.collection<any>('translations');
    this.localesCollection = db.collection<Locale>('locales');

    const user$ = userService.getCurrentUser();

    this.locales$ = combineLatest(this.localesCollection.valueChanges(), user$).pipe(
      map(([locales, user]) => {
        const isAdmin = user && user.roles.includes('ADMIN');
        // Admin users can see all the locales.
        // Return main locales, locales without a groupUid or locales that match the groupUid of the user
        return locales.filter(locale => isAdmin || locale.isMainLocale || !locale.groupUid ||  (user && user.groupUid === locale.groupUid));
      }),
      shareReplay(1),
    )
  }

  getLocales() { 
    return this.locales$;
  }

  getMainLocales() {
    return this.locales$.pipe(
      map(locales => locales.filter(locale => locale.code.split('_').length === 1))
    );
  }

  createLocale(code: string, name: string) {
    return this.localesCollection.doc(code).set({code, name});
  }

  getTranslations(language: string) {
    return this.translationCollection.doc(language).valueChanges();
  }

  editTranslation(locale: string, translations) {
    return this.translationCollection.doc(locale).set({...translations}, {merge: true});
  }
}
