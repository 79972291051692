import { AngularFireStorage } from "@angular/fire/storage";
import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { OnInit } from '@angular/core';

export abstract class AbstractUploaderComponent implements OnInit {
  downloadUrl$: Observable<string>;
  uploadProgress$ = new Subject();
  uploadState$: Subject<'state-loading' | 'state-success' | 'state-error' | ''> = new Subject();
  canUpload$: Observable<boolean>;

  uploadFileChangedEvent$ = new BehaviorSubject<any>(null);

  constructor(protected fbStorage: AngularFireStorage) {
    this.canUpload$ = combineLatest(this.uploadFileChangedEvent$, this.uploadState$).pipe(
      map(([event, state]) => {
        return event && state === ''
      })
    )
  }

  ngOnInit(): void {
    if(this.getPath()) {
      const metadata$ = this.fbStorage.ref(this.getPath()).getMetadata();
      const url$ = this.fbStorage.ref(this.getPath()).getDownloadURL();
      this.downloadUrl$ = combineLatest(url$, metadata$).pipe(
        tap(data => console.log(data)),
        map(([downloadUrl, metadata]) => downloadUrl),
        catchError(err => {
          console.log('Error retrieving download url', err);
          return this.fbStorage.ref(this.getPath()).getDownloadURL(); // retry
        })
      )
    }
  }

  uploadImage() {
    if (this.getPath()) {
      const uploadTask = this.fbStorage.upload(this.getPath(), this.getFile());
      this.uploadState$.next('state-loading');
      uploadTask.percentageChanges().pipe(tap(progress => {
        console.log('progress', progress);
        this.uploadProgress$.next(progress)
      })).subscribe();
      uploadTask.catch((err) => {
        this.uploadState$.next('state-error');
        console.error(err);
        this.uploadProgress$.next(0)
      });
      uploadTask.then().then(() => {
        this.uploadState$.next('state-success');
        this.uploadProgress$.next(0);
        this.uploadDone(uploadTask);
        console.log('done!')
      });
    } else {
      console.log('No path to upload to');
    }
  }

  /** Overridable method to get notified */
  uploadDone(uploadTask) {

  }

  deleteImage(): Promise<any> {
    if(this.getPath()) {
      return this.fbStorage.ref(this.getPath()).delete().toPromise();
    } else {
      return Promise.reject('No path to file');
    }
  }


  setUploadFileChangeEvent(event: any): void {
    this.uploadState$.next('');
    this.uploadFileChangedEvent$.next(event);
  }

  abstract getFile();
  abstract getPath();
}
