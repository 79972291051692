import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'masterplanner2-strategic-actions-flat',
  templateUrl: './strategic-actions-flat.component.html',
  styleUrls: ['./strategic-actions-flat.component.css']
})
export class StrategicActionsFlatComponent implements OnInit {

  @Input()
  actions = [];

  constructor() { }

  ngOnInit() {
  }

}
