<div class="form-group col-sm-12 lpts">
  <table [formGroup]="form"
    id="competitors"
    class="table table-condensed lpts col-sm-12">
    <tr>
      <th>{{ 'COPPORTUNITYNAME' | translate }}</th>
      <th>{{ 'CBUSINESSUNIT' | translate }}</th>
      <th>{{ 'CEXPECTEDAWARDDATE' | translate }}</th>
      <th>{{ 'CEXECUTIONSTARTDATE' | translate }}</th>
      <th>{{ 'CTOTALDEVELOPMENTCOST' | translate }}</th>
      <th>{{ 'QUALIFICATION' | translate }}</th>
      <th>
        <button
          type="button"
          class="btn ng-scope no-btn success pull-right"
          (click)="addItem()"
          title="{{ 'NEW' | translate }}"
        >
          <i class="glyphicon glyphicon-plus "></i>
        </button>
      </th>
    </tr>
    <tr *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
      <td>
        <input
          type="text"
          class="form-control"
          formControlName="name"
        >
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          formControlName="businessunit"
        >
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          formControlName="expectedAwardDate"
        >
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          formControlName="executionStartDate"
        >
      </td>
      <td>
        <kendo-numerictextbox [autoCorrect]="false"
                              [spinners]="false"
                              [decimals]="0"
                              [format]="'n0'"
                              formControlName="totalDevelopmentCost"
        ></kendo-numerictextbox>
      </td>
      <td>
        <masterplanner2-swot-type formControlName="swotTypeOT" mode="OT"></masterplanner2-swot-type>
      </td>
      <td>
        <button type="button"
                class="btn ng-scope no-btn danger deletebutton "
                (click)="removeItem(i)"
                title="{{ 'DELETE' | translate }}"
        >
          <i class="glyphicon glyphicon-trash "></i>
        </button>
      </td>
    </tr>
  </table>
</div>
