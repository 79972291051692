/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sub-activities-flat.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./sub-activities-flat.component";
var styles_SubActivitiesFlatComponent = [i0.styles];
var RenderType_SubActivitiesFlatComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubActivitiesFlatComponent, data: {} });
export { RenderType_SubActivitiesFlatComponent as RenderType_SubActivitiesFlatComponent };
function View_SubActivitiesFlatComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.description; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.responsible; _ck(_v, 4, 0, currVal_1); }); }
function View_SubActivitiesFlatComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "table", [["class", "subactivities"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "th", [["colspan", "2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "th", [["style", "text-align: left"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 2, "th", [["style", "text-align: left"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubActivitiesFlatComponent_2)), i1.ɵdid(15, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.activities; _ck(_v, 15, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("SUBACTIVITYPLANS")); _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("CACTIVITYPLAN")); _ck(_v, 9, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("BY_WHOM")); _ck(_v, 12, 0, currVal_2); }); }
export function View_SubActivitiesFlatComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubActivitiesFlatComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.activities == null) ? null : _co.activities.length); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SubActivitiesFlatComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "masterplanner2-sub-activities-flat", [], null, null, null, View_SubActivitiesFlatComponent_0, RenderType_SubActivitiesFlatComponent)), i1.ɵdid(1, 49152, null, 0, i4.SubActivitiesFlatComponent, [], null, null)], null, null); }
var SubActivitiesFlatComponentNgFactory = i1.ɵccf("masterplanner2-sub-activities-flat", i4.SubActivitiesFlatComponent, View_SubActivitiesFlatComponent_Host_0, { activities: "activities" }, {}, []);
export { SubActivitiesFlatComponentNgFactory as SubActivitiesFlatComponentNgFactory };
