import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Bundle, BundleService } from '../bundle.service';
import { filter, first, map, pluck, publishReplay, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { Customer } from '../../customer.model';
import { BehaviorSubject, combineLatest, ConnectableObservable, Subject } from 'rxjs';
import { KeyaccountPlanService } from '../../keyaccountplans/keyaccount-plan.service';
import { KeyaccountPlan } from '../../keyaccount-plan.model';
import { Keyaccount, KeyaccountService } from '@masterplanner2/users';
import { CustomerService } from '../../customer.service';
import { ToasterService } from 'angular2-toaster';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'masterplanner2-bundle-create',
  templateUrl: './bundle-create.component.html',
  styleUrls: ['./bundle-create.component.css']
})
export class BundleCreateComponent implements OnInit {

  searchForm: FormGroup;
  bundleForm: FormGroup;

  keyaccountPlans$: Observable<KeyaccountPlan[]>;
  selectedKeyaccountPlans$: BehaviorSubject<KeyaccountPlan[]> = new BehaviorSubject([]);
  filterOptions$: Observable<{ [filterKey: string]: string[]}>;
  filteredKeyaccountPlans$: Observable<KeyaccountPlan[]>;
  multiplePlansForKeyaccount$: Observable<boolean>;

  groups$: Observable<Customer[]>;
  keyaccounts$: Observable<Keyaccount[]>;

  customer$: Observable<Customer | null>;

  selectedLeft = [];
  selectedRight = [];

  constructor(
    formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private bundleService: BundleService,
    private keyaccountPlanService: KeyaccountPlanService,
    private customerService: CustomerService,
    private keyaccountService: KeyaccountService,
    protected toasterService: ToasterService,
    protected router: Router,
  ) {
    this.selectedKeyaccountPlans$.next([]);
    this.searchForm = formBuilder.group({
      keyaccountUid: [],
      status: [[]],
      industry: [],
      companyType: [],
      keyaccountManagerName: [],
   });
    this.bundleForm = formBuilder.group({
      uid: [],
      groupUid: [],
      name: [],
      description: [],
      keyaccountPlans: []
    });

    this.groups$ = this.customerService.getCustomers();
    this.keyaccounts$ = keyaccountService.getKeyaccounts();
    this.keyaccountPlans$ = this.keyaccountPlanService.getKeyaccountPlans();

    this.filteredKeyaccountPlans$ = combineLatest(
      this.keyaccountPlans$,
      this.searchForm.valueChanges.pipe(startWith(this.searchForm.value)),
      this.selectedKeyaccountPlans$,
      this.bundleForm.get('groupUid').valueChanges.pipe(startWith(this.bundleForm.get('groupUid').value)),
    ).pipe(
      map(([keyaccountPlans, filtersForm, selectedKeyaccountPlans, groupUid]) => {
        filtersForm = {...filtersForm, groupUid};
        const filterKeys = Object.keys(filtersForm);
        const filterMap = filterKeys
          .map(field => {
            const v = filtersForm[field];
            if(!v) {
              return {key: field, value: null};
            }
            const normalisedValue = Array.isArray(v) ? v.map(item => item.toString().toLowerCase()) : v.toString().toLowerCase();
            return {key: field, value: normalisedValue}
          })
          .filter(filterField => !!filterField.value)
        ;

        const filterFn = (keyaccountPlan) => {
          return filterMap.reduce((acc, filter) => {
            const planFilterValue = keyaccountPlan[filter.key];
            return acc && planFilterValue &&
              // If it is an array, the value must be includes in the filter options.
              (Array.isArray(filter.value) ?
                (!filter.value.length || filter.value.includes(planFilterValue.toString().toLowerCase()))
                :
                planFilterValue.toString().toLowerCase().includes(filter.value)
              );
          }, true)
        };

        const alreadySelectedUids = selectedKeyaccountPlans.map(plan => plan.uid);
        return (filterMap.length ? keyaccountPlans.filter(filterFn) : keyaccountPlans).filter(plan => !alreadySelectedUids.includes(plan.uid));
      }),
      shareReplay(1),
    );

    this.filterOptions$ = this.filteredKeyaccountPlans$.pipe(
      map(keyaccountPlans => this.createFilterOptionsFromKeyaccountPlans(keyaccountPlans)),
    );

    this.customer$ = combineLatest(this.groups$, this.bundleForm.valueChanges).pipe(
      map(([groups, plan]) => groups.find(group => group.uid === plan.groupUid)),
      // tap(group => console.log('customer', group)),
      publishReplay(1),
    ) as ConnectableObservable<Customer>;

    this.multiplePlansForKeyaccount$ = this.selectedKeyaccountPlans$.pipe(
      map(keyaccountPlans => {
        const keyaccountUids = keyaccountPlans.map(plan => plan.keyaccountUid);
        const distinctKeyaccountUids = Array.from(new Set(keyaccountUids));
        return keyaccountUids.length !== distinctKeyaccountUids.length;
      })
    );
  }

  ngOnInit() {
    (this.customer$ as ConnectableObservable<Customer>).connect();
    this.customerService.getCustomers().pipe(
      first(),
      tap(customer => {
        this.bundleForm.patchValue({groupUid: customer[0].uid}, {emitEvent: true});
        console.log('Initial customer', customer[0]);
      }),
    ).toPromise();

    this.activatedRoute.params.pipe(
      pluck('id'),
      filter(id=> !!id),
      first(),
      switchMap(bundleId => this.bundleService.getBundle(<string>bundleId)),
      tap(bundle => {
        this.bundleForm.patchValue(bundle);


        this.keyaccountPlans$.pipe(
          first(),
          map(keyaccountPlans => keyaccountPlans.filter(keyaccountPlan => bundle.keyaccountPlans.includes(keyaccountPlan.uid))),
          tap(keyacountPlans => {
            this.selectedKeyaccountPlans$.next(keyacountPlans);
          })
        ).toPromise();


      }),
    ).toPromise();

  }


  createFilterOptionsFromKeyaccountPlans(bundles: KeyaccountPlan[]): {[filterKey:string]: string[]} {
    const fields = ['name', 'customerName', 'description', 'industry', 'companyType', 'keyaccountManagerName'];
    // Create the inital options {someField: Set<string>, someOtherField: Set<string>}
    const initialFilterOptions = fields.reduce((acc, field) => ({...acc, [field]: new Set()}), {});

    const filterOptionSets = bundles.reduce((acc, keyaccountPlan) => {
      // Add all the fields to the filterOptions
      fields.forEach(field => {
        if(keyaccountPlan[field]) {
          acc[field].add(keyaccountPlan[field]);
        }
      });
      return acc;
    }, initialFilterOptions);

    // Change Sets to arrays
    return fields.reduce((acc, field) => ({...acc, [field]: Array.from(filterOptionSets[field])}), {});
  }


  toggleStatus(status: string) {
    const statusControl = this.searchForm.get('status');
    const currentStatus = statusControl.value;
    if(!Array.isArray(currentStatus)) {
      statusControl.setValue([status]);
      return;
    }
    if(currentStatus.includes(status)) {
      statusControl.setValue(currentStatus.filter(v => v !== status));
      return;
    } else {
      statusControl.setValue([...currentStatus, status]);
    }
  }

  toggleRow(side: 'left' | 'right', uid: string) {
    const currentValue = side === 'left' ? this.selectedLeft : this.selectedRight;
    const newValue = currentValue.includes(uid) ? currentValue.filter(v => v !== uid) : [...currentValue, uid];
    side === 'left' ? this.selectedLeft = newValue : this.selectedRight = newValue;
  }

  moveToRight(plan?) {
    if(plan) {
      this.selectedKeyaccountPlans$.next([...this.selectedKeyaccountPlans$.value, plan]);
    } else {
      // move the left selected items
      this.keyaccountPlans$.pipe(
        first(),
        map(keyaccountPlans => keyaccountPlans.filter(keyaccountPlan => this.selectedLeft.includes(keyaccountPlan.uid))),
        tap(keyacountPlans => {
          this.selectedKeyaccountPlans$.next([...this.selectedKeyaccountPlans$.value, ...keyacountPlans]);
        })
      ).toPromise();
    }
    this.selectedLeft = [];
  }

  moveAllToRight() {
    this.filteredKeyaccountPlans$.pipe(
      first(),
      tap(keyacountPlans => {
        console.log('moving to right', keyacountPlans);
        this.selectedKeyaccountPlans$.next([...this.selectedKeyaccountPlans$.value, ...keyacountPlans]);
      })
    ).toPromise();
    this.selectedLeft = [];
  }

  /**
   * More a 'removeFromRight'
   * @param plan
   */
  moveToLeft(plan?) {
    if(plan) {
      this.selectedKeyaccountPlans$.next(this.selectedKeyaccountPlans$.value.filter(p => p.uid !== plan.uid));
    } else {
      this.selectedKeyaccountPlans$.next(this.selectedKeyaccountPlans$.value.filter(p => !this.selectedRight.includes(p.uid)));
    }
    this.selectedRight = [];
  }

  moveAllToLeft() {
    this.selectedKeyaccountPlans$.next([]);
    this.selectedRight = [];
  }

  createDashboard() {
    if(this.bundleForm.valid && this.selectedKeyaccountPlans$.value.length) {
      const selectedPlanUids = this.selectedKeyaccountPlans$.value.map(plan => plan.uid);
      const formValue = this.bundleForm.value;
      const bundleUid = formValue.uid;

      const dbPromise = (bundleUid ?
        this.bundleService.editBundle(bundleUid, {...formValue, keyaccountPlans: selectedPlanUids})
        :
        this.bundleService.createBundle(formValue.groupUid, selectedPlanUids, formValue.name, formValue.description)
      );


      dbPromise.then(docRef => {
          this.toasterService.pop('success',  'Saved successfull');
          return this.router.navigateByUrl('/settings/dashboard/' + docRef.id);
        })
        .catch(err => {
          console.error(err);
          this.toasterService.pop('error', 'Error saving: ' + err.message);
        });
    } else {
      this.toasterService.pop('error', 'Form incomplete');
    }

  }
}
