import { Component } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { map, publishReplay } from "rxjs/operators";
import { ConnectableObservable, Subscription } from "rxjs";
import { Activity } from "../../keyaccount-plan.model";
import { AbstractTabComponent } from "../profile/abstract-tab.component";

@Component({
  selector   : 'masterplanner2-activity',
  templateUrl: './activity.component.html',
  styleUrls  : ['./activity.component.css']
})
export class ActivityComponent extends AbstractTabComponent {
  activities$: ConnectableObservable<(Activity & { isMaster?: boolean })[]>;
  activitiesSubscription: Subscription;


  setupObservables() {
    super.setupObservables();
    this.activities$ = this.form.valueChanges.pipe(
      map(keyaccountPlan => keyaccountPlan.strategicActions ? keyaccountPlan.strategicActions : []),
      map(mainActivities => {
        // flatten array with activities and sub-activities
        return [].concat.apply([], mainActivities.map(mainActivity => ([
          {...mainActivity, isMaster: true, description: mainActivity.action},
          ...(mainActivity.subActivities ? mainActivity.subActivities : [])
            .map(subActivity => ({...subActivity, realised: mainActivity.realised}))
        ])));
      }),
      publishReplay(1)
    ) as ConnectableObservable<any>;
  }

  buildForm(): FormGroup {
    return this.formBuilder.group({
      'uid'              : [],
      'strategicActions' : [],
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.activitiesSubscription = this.activities$.connect();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.activitiesSubscription) {
      this.activitiesSubscription.unsubscribe();
    }
  }

  mapFromDatabase(keyaccountPlan) {
    const newStrategicActions = (keyaccountPlan.strategicActions ? keyaccountPlan.strategicActions : []).map(action => {
      const newSubActivities = (action.subActivities ? action.subActivities : []).map(subAction => {
        return {
          ...subAction,
          startDate: (subAction.startDate ? subAction.startDate.toDate() : null),
          endDate  : (subAction.endDate ? subAction.endDate.toDate() : null),
        }
      });
      return {
        ...action,
        subActivities: newSubActivities,
        startDate    : (action.startDate ? action.startDate.toDate() : null),
        endDate      : (action.endDate ? action.endDate.toDate() : null),
      };
    });
    return {
      ...keyaccountPlan,
      strategicActions: newStrategicActions,
    };
  }

}
