<div [formGroup]="form">
  <table class="table">
    <thead *ngIf="featureToggles.orderIntakeTarget || featureToggles.orderIntake ||featureToggles.orderBacklog">
      <tr>
        <td></td>
        <td *ngFor="let year of yearSpan" [ngClass]="{'purple-year-header': year === currentYear }"></td>
        <td></td>
      </tr>
      <tr>
        <th width="21%"></th>
        <th width="8%" *ngFor="let year of yearSpan" class="year" [ngClass]="{'current-year': year === currentYear }">{{ year }}</th>
        <th width="*" class="year">{{ 'QUALIFICATION' | translate }}</th>
      </tr>
    </thead>
    <tbody *ngIf="featureToggles.orderIntakeTarget || featureToggles.orderIntake ||featureToggles.orderBacklog">
      <tr formGroupName="orderIntakeTarget" *ngIf="featureToggles.orderIntakeTarget">
        <td class="name">{{ 'ORDERINTAKETARGET' | translate }}
          <span *ngIf="lastUpdatedRevenues?.orderIntakeTarget" style="color:gray;font-size: small"><br/>Last updated: {{ lastUpdatedRevenues.orderIntakeTarget.toDate()  | date: 'dd-MM-yyyy' }}</span>
        </td>
        <td *ngFor="let year of yearSpan" [ngClass]="{'current-year': year === currentYear }">
            <kendo-numerictextbox [autoCorrect]="false"
                                  [spinners]="false"
                                  [decimals]="0"
                                  [format]="'n0'"
                                  [formControlName]="year"
            ></kendo-numerictextbox>
        </td>
        <td></td>
      </tr>
      <tr formGroupName="orderIntake" *ngIf="featureToggles.orderIntake">
        <td class="name">{{ 'ORDERINTAKE' | translate }}
          <span *ngIf="orderIntakeImportedAt" style="color:gray;font-size: small"><br/>Last import: {{ orderIntakeImportedAt.toDate()  | date: 'dd-MM-yyyy' }}</span>
          <span *ngIf="lastUpdatedRevenues?.orderIntake && !orderIntakeImportedAt" style="color:gray;font-size: small"><br/>Last updated: {{ lastUpdatedRevenues.orderIntake.toDate()  | date: 'dd-MM-yyyy' }}</span>
        </td>
        <td *ngFor="let year of yearSpan" [ngClass]="{'current-year': year === currentYear }">
            <kendo-numerictextbox [autoCorrect]="false"
                                  [spinners]="false"
                                  [decimals]="0"
                                  [format]="'n0'"
                                  [readonly]="!!orderIntakeImportedAt"
                                  [formControlName]="year"
            ></kendo-numerictextbox>
        </td>
        <td><masterplanner2-swot-type formControlName="swotType" [mode]="'SW'"></masterplanner2-swot-type></td>
      </tr>
      <tr formGroupName="orderBacklog" *ngIf="featureToggles.orderBacklog">
        <td class="name">{{ 'ORDERBACKLOG' | translate }}
          <span *ngIf="lastUpdatedRevenues?.orderBacklog" style="color:gray;font-size: small"><br/>Last updated: {{ lastUpdatedRevenues.orderBacklog.toDate()  | date: 'dd-MM-yyyy' }}</span>
        </td>
        <td *ngFor="let year of yearSpan" [ngClass]="{'current-year': year === currentYear }">
            <kendo-numerictextbox [autoCorrect]="false"
                                  [spinners]="false"
                                  [decimals]="0"
                                  [format]="'n0'"
                                  [formControlName]="year"
            ></kendo-numerictextbox>
        </td>
        <td><masterplanner2-swot-type formControlName="swotType" [mode]="'SW'"></masterplanner2-swot-type></td>
      </tr>
      <tr>
        <td class="name">{{ 'REASON' | translate }}</td>
        <td colspan="7"><input type="text" formControlName="orderSwotReason" class="form-control swotReason"></td>
      </tr>
    </tbody>
    <thead>
      <tr>
        <td></td>
        <td *ngFor="let year of yearSpan" [ngClass]="{'purple-year-header': year === currentYear }"></td>
        <td></td>
      </tr>
      <tr>
        <th width="21%"></th>
        <th width="8%"*ngFor="let year of yearSpan" class="year" [ngClass]="{'current-year': year === currentYear }">{{ year }}</th>
        <th width="*" class="year">{{ 'QUALIFICATION' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr formGroupName="targetRevenue">
        <td class="name">{{ 'TARGETREVENUE' | translate }}
          <span *ngIf="lastUpdatedRevenues?.targetRevenue" style="color:gray;font-size: small"><br/>Last updated: {{ lastUpdatedRevenues.targetRevenue.toDate()  | date: 'dd-MM-yyyy' }}</span>
        </td>
        <td *ngFor="let year of yearSpan" [ngClass]="{'current-year': year === currentYear }">
            <kendo-numerictextbox [autoCorrect]="false"
                                  [spinners]="false"
                                  [decimals]="0"
                                  [format]="'n0'"
                                  [formControlName]="year"
            ></kendo-numerictextbox>
        </td>
        <td></td>
      </tr>
      <tr formGroupName="customerRevenue">
        <td class="name">{{ 'CUSTOMERREVENUE' | translate }}
          <span *ngIf="revenueImportedAt" style="color:gray;font-size: small"><br/>Last import: {{ revenueImportedAt.toDate()  | date: 'dd-MM-yyyy' }}</span>
          <span *ngIf="lastUpdatedRevenues?.customerRevenue && !revenueImportedAt" style="color:gray;font-size: small"><br/>Last updated: {{ lastUpdatedRevenues.customerRevenue.toDate()  | date: 'dd-MM-yyyy' }}</span>

        </td>
        <td *ngFor="let year of yearSpan" [ngClass]="{'current-year': year === currentYear }">
            <kendo-numerictextbox [autoCorrect]="false"
                                  [spinners]="false"
                                  [decimals]="0"
                                  [format]="'n0'"
                                  [formControlName]="year"
                                  [readonly]="!!revenueImportedAt"
            ></kendo-numerictextbox>
        </td>
        <td><masterplanner2-swot-type formControlName="swotType" [mode]="'SW'"></masterplanner2-swot-type></td>
      </tr>
      <tr *ngIf="(customerRevenueIndex$ | async) as index">
        <td class="name">{{ 'INDEX_LASTYEAR_CUSTOMERREVENUE' | translate }}</td>
        <td *ngFor="let year of yearSpan" [ngClass]="{'current-year': year === currentYear }">
            <span> {{ index[year] }} </span>
            <span class="percent">%</span>
        </td>
        <td></td>
      </tr>
      <tr formGroupName="sow">
        <td class="name">
          <i  class="info glyphicon glyphicon-exclamation-sign icon-popover pull-left"
              title="{{ 'CLICKFORREMARK' | translate }}"
              [ngbPopover]="'ESTIMATED_SOW_EXPLANATION' | translate"
              popoverTitle="{{ 'INFO' | translate }}"
              style="top: 0"
              placement="right"
          ></i>
          {{ 'ESTIMATEDSOW' | translate }}</td>
        <td *ngFor="let year of yearSpan" [ngClass]="{'current-year': year === currentYear }">
          <select [formControlName]="year" class="form-control">
            <option *ngFor="let key of estimatedSowValues" [value]="key">
              {{ 'SOW_' + key | translate}}
            </option>
          </select>
        </td>
        <td><masterplanner2-swot-type formControlName="swotType" [mode]="'SW'"></masterplanner2-swot-type></td>
      </tr>
      <tr formGroupName="profitMargin" *ngIf="featureToggles.profitMargin">
        <td class="name">
          <i  class="info glyphicon glyphicon-exclamation-sign icon-popover pull-left"
              title="{{ 'CLICKFORREMARK' | translate }}"
              [ngbPopover]="'PROFITMARGIN_EXPLANATION' | translate"
              popoverTitle="{{ 'INFO' | translate }}"
              style="top: 0"
              placement="right"
          ></i>
          {{ 'PROFITMARGIN' | translate }}</td>
        <td *ngFor="let year of yearSpan" [ngClass]="{'current-year': year === currentYear }">
          <select [formControlName]="year" class="form-control">
            <option *ngFor="let key of profitMarginValues" [value]="key">
                {{ 'MAR_' + key | translate }}
            </option>
          </select>
        </td>
        <td></td>
      </tr>
      <tr>
        <td class="name">{{ 'REASON' | translate }}</td>
        <td colspan="7"><input type="text" formControlName="revenueSwotReason" class="form-control swotReason"></td>
      </tr>
    </tbody>
  </table>
</div>
