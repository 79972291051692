import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, first, map, pluck, switchMap, tap, startWith } from 'rxjs/operators';
import { Keyaccount, KeyaccountService } from '../keyaccount.service';
import { UserGroupService } from '../user-group.service';
import { UserService } from '../user.service';
import { User, UserGroup } from '../user';
import { ToasterService } from 'angular2-toaster';
import { TranslationService, Locale } from 'apps/masterplanner-client/src/app/translation/translation.service';

@Component({
  selector: 'masterplanner2-user-create-component',
  templateUrl: './user-create-component.component.html',
  styleUrls: ['./user-create-component.component.css']
})
export class UserCreateComponentComponent implements OnInit, OnDestroy {
  form: FormGroup;
  roles$:  Observable<{value:string, label:string}[]>;
  languages$: Observable<Locale[]>;
  keyaccounts$: Observable<Keyaccount[]>;
  groups$: Observable<UserGroup[]>;
  user$: Observable<User>;
  isAccountManager$: Observable<boolean>;
  isAccountViewer$: Observable<boolean>;
  isEditing$ = new BehaviorSubject(false);
  private editUserSubscription: Subscription;

  constructor(
    formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private keyaccountService: KeyaccountService,
    groupService: UserGroupService,
    private userService: UserService,
    private toasterService: ToasterService,
    private translationService: TranslationService
  ) {
    this.form = formBuilder.group({
      uid: [''],
      name: ['', Validators.required],
      phonenumber: [''],
      language: ['', Validators.required],
      groupUid: [''],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      roles: [[], Validators.required],
      keyaccounts:[[]],
      keyaccountsView:[[]],
      enabled:[false],
    });

    this.roles$ = of(['ADMIN', 'GROUPADMIN', 'ACCOUNTMANAGER', 'ACCOUNTVIEWER']
      .map(role => ({value: role, label: role}))
    );

    this.groups$ = groupService.getUserGroups();

    this.isAccountManager$ = this.hasRole('ACCOUNTMANAGER');

    this.isAccountViewer$ = this.hasRole('ACCOUNTVIEWER');

    this.languages$ = this.translationService.getLocales();

  }

  ngOnInit() {

    const user$ = this.activatedRoute.params.pipe(
      pluck('id'),
      switchMap(id => id ? this.userService.getUser(<string>id): of(null)),
    );

    this.editUserSubscription = user$.pipe(
      filter(user=> !!user),
      first(),
      tap(user => {
        this.form.patchValue(user);
        this.isEditing$.next(true);
      })
    ).subscribe();

    this.keyaccounts$ = user$.pipe(
      switchMap(user => {
        return this.form.get('groupUid').valueChanges.pipe(
          startWith(user ? user.groupUid : this.form.get('groupUid').value),
          switchMap(groupUid => this.keyaccountService.getKeyaccountForGroupUid(groupUid))
        )
      })      
    )
  }

  ngOnDestroy(): void {
    if(this.editUserSubscription) {
      this.editUserSubscription.unsubscribe();
    }
  }

  isAccountManager(): Observable<boolean> {
    return this.isAccountManager$;
  }

  isAccountViewer(): Observable<boolean> {
    return this.isAccountViewer$;
  }

  doSave(){
    const uid = this.form.get('uid').value;
    const user = this.form.value;
    console.log(user);
    return (uid ? this.userService.editUser(uid, user) : this.userService.createUser(user))
      .then(() => {
        this.toasterService.pop('success', uid ? 'Edited user successfull' : 'Created user successfull');
        this.router.navigateByUrl('/settings/users');
      })
      .catch(err => {
        console.error(err);
        switch(err.code) {
          case "auth/email-already-in-use":
            this.toasterService.pop('error', 'This emailaddress is already in use');
            break;
          case "auth/invalid-email":
            this.toasterService.pop('error', 'Invalid emailaddress');
            break;
          case "auth/weak-password":
            this.toasterService.pop('error', 'Password is too weak');
            break;
          case "auth/operation-not-allowed":
            this.toasterService.pop('error', 'Operation not allowed');
            break;
          default:
            this.toasterService.pop('error', 'Error creating account: ' + err.message);
            break;
        }
      })
      ;

  }

  doCancel() {
    return this.router.navigateByUrl('/settings/users');
  }



  private hasRole(role:string): Observable<boolean> {
    return this.form.get('roles').valueChanges
      .pipe(
        map( (roles: string[]) =>roles.includes(role))
      );
  }

}
