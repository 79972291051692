<div class="container">
  <div id="appheader" >
    <div id="logo" class="col-lg-5 toprow">
      <a routerLink="/keyaccountplans">
        <img src="assets/images/logo_masterplanner.png" class="notLight ng-scope" style="margin-left: -1px; margin-top: 1px">
      </a>
    </div>
    <div id="userinfo" class="brandtab pull-right col-lg-7 toprow" *ngIf="hasCurrentUser$ | async">
      <masterplanner2-main-menu></masterplanner2-main-menu>
    </div>
  </div>

  <div id="viewarea" >
      <router-outlet></router-outlet>
  </div>
  <toaster-container></toaster-container>
  <masterplanner2-scroll-top></masterplanner2-scroll-top>
</div>
