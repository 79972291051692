<div class="form-group strategicoption-pro">
  <div class="separator col-sm-12">
    {{ 'TITLE_CALENDAR' | translate }}
  </div>
  <div class="col-sm-12">
    <table class="table table-striped table-condensed calendar">
      <tr>
        <td width="50%"></td>
        <td width="50%">
          <div class="danger" style="float:left; width: 25%"> {{ quarterNames[0] }}</div>
          <div class="warning" style="float:left; width: 25%"> {{ quarterNames[1] }}</div>
          <div class="info" style="float:left; width: 25%"> {{ quarterNames[2] }}</div>
          <div class="success" style="float:left; width: 25%"> {{ quarterNames[3] }}</div>
        </td>
      </tr>
      <tr *ngFor="let activity of (activitiesPlanning$ | async); trackBy: trackByFn" [class.subactivity]="!activity.isMaster">
        <td>
          <label> {{ activity.description}}</label>
        </td>
        <td>
          <div class="activity" [style.width]="activity.duration + '%'" [style.margin-left]="activity.startAt + '%'">&nbsp;</div>
        </td>
      </tr>
    </table>
  </div>
</div>
