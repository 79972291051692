import { Component, Input, OnInit } from '@angular/core';
import { KeyaccountPlan } from '../../../keyaccount-plan.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tbody[masterplanner2-bundle-revenue]',
  templateUrl: './bundle-revenue.component.html',
  styleUrls: ['./bundle-revenue.component.css']
})
export class BundleRevenueComponent implements OnInit {
  @Input()
  keyaccountPlans$: Observable<KeyaccountPlan[]>;

  @Input()
  property: keyof KeyaccountPlan;

  @Input()
  yearSpan: number[];

  sum$: Observable<number[]>;

  expandKeyaccountPlans = false;

  constructor() {
  }

  ngOnInit() {
    this.sum$ = this.keyaccountPlans$.pipe(
      map(keyaccountPlans => {
        return this.yearSpan.map(year => {
          return keyaccountPlans
            // get the year value for each keyaccountPlan.
            .map(plan => plan[this.property][year])
            // sum the year values
            .reduce((acc, v) => acc + this.getValueAsNumber(v), 0);
        });
      })
    );
  }

  public getValueAsNumber(v: any): number {
    if (!v)
      return 0;
    if (Array.isArray(v))
      return 0;
    if (typeof v === 'string')
      return parseInt(v, 10);
    return v;
  }
}
