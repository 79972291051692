import { Component, Input, OnInit } from '@angular/core';
import { Observable, of, Subject } from "rxjs";
import { AngularFireStorage } from "@angular/fire/storage";
import { catchError } from "rxjs/operators";

@Component({
  selector: 'masterplanner2-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.css']
})
export class ImagePreviewComponent implements OnInit {
  @Input()
  path: string;

  @Input()
  maxHeight = "35px";

  downloadUrl$: Observable<string>;

  constructor(protected fbStorage: AngularFireStorage) {
  }

  ngOnInit(): void {
    if(this.path) {
      this.downloadUrl$ = this.fbStorage.ref(this.path).getDownloadURL().pipe(
        catchError(err => {
          // console.log('Error retrieving download url', err);
          return of(null)
        })
      )
    }
  }

}
