<div class="col-sm-12" [formGroup]="form">
  <table id="brickwallcriteriums" class="table table-striped table-bordered brickwallcriteriums">
    <tr>
      <th class="col-sm-2"> {{ 'CDESCRIPTION' | translate }} </th>
      <th class="col-sm-7" colspan="3"> {{ 'COPTIONS' | translate }}</th>
      <th class="col-sm-2"> {{ 'BRICKWALL_ITARGET' | translate }}</th>
      <th class="col-sm-1">
        <button
          type="button"
          class="btn no-btn success"
          (click)="addItem()"
          title="{{ 'NEW' | translate }}"
        >
          <i class="glyphicon glyphicon-plus "></i>
        </button>
      </th>
    </tr>
    <tr *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
      <td class="form-group">
        <input type="text" class="form-control" formControlName="description" >
      </td>
      <td class="form-group">
        <input type="text" class="form-control" formControlName="option0" >
      </td>
      <td class="form-group">
        <input type="text" class="form-control" formControlName="option1" >
      </td>
      <td class="form-group">
        <input type="text" class="form-control" formControlName="option2" >
      </td>
      <td class="form-group">
        <select class="form-control" name="iTarget" formControlName="target" >
          <option *ngFor="let option of [0, 1, 2]" value="{{option}}">
            {{ row.value['option' + option] | translate }}
          </option>
        </select>
      </td>
      <td class="form-group">
        <button type="button"
                class="btn no-btn danger deletebutton "
                (click)="removeItem(i)"
                title="{{ 'DELETE' | translate }}"
        >
          <i class="glyphicon glyphicon-trash "></i>
        </button>
      </td>
    </tr>
  </table>
</div>
