import { Component, Input, OnInit } from '@angular/core';
import { AbstractFormArrayListComponent } from "../abstract-form-array-list/abstract-form-array-list.component";
import { FormGroup, NG_VALUE_ACCESSOR, Validators, FormBuilder } from "@angular/forms";

@Component({
  selector   : 'masterplanner2-developments',
  templateUrl: './developments.component.html',
  styleUrls  : ['./developments.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: DevelopmentsComponent, multi: true}
  ]
})
export class DevelopmentsComponent extends AbstractFormArrayListComponent {
  @Input()
  title: string;  
  
  @Input()
  type: string;

  constructor(protected formBuilder: FormBuilder) {
    super(formBuilder);
  }

  createItemControls(): FormGroup {
    return this.formBuilder.group({
      'uid'       : [, Validators.required],
      'name'      : [, Validators.required],
      'type'      : [this.type, Validators.required],
      'swotType'  : [, Validators.required],
    })
  }

}
