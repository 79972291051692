import { Component, Input } from '@angular/core';

@Component({
  selector: 'masterplanner2-header-logos',
  templateUrl: './header-logos.component.html',
  styleUrls: ['./header-logos.component.css']
})
export class HeaderLogosComponent {

  @Input()
  groupUid: string;

  @Input()
  keyaccountUid: string;

  constructor() {}
}
