import { Component, ViewChild } from '@angular/core';
import { combineLatest, Observable } from "rxjs";
import { KeyaccountPlan, ScoreMatrix, ScoreMatrixUserList } from "../../keyaccount-plan.model";
import { FormGroup } from "@angular/forms";
import { first, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { StrategicQuestionsComponent } from "./strategic-questions/strategic-questions.component";
import * as pushid from "pushid";
import { AbstractTabComponent } from "../profile/abstract-tab.component";

@Component({
  selector   : 'masterplanner2-matrix',
  templateUrl: './matrix.component.html',
  styleUrls  : ['./matrix.component.css']
})
export class MatrixComponent extends AbstractTabComponent {
  totalMatrix$: Observable<ScoreMatrix>;
  myScoreMatrix$: Observable<ScoreMatrix>;
  scoreMatrixes$: Observable<ScoreMatrix[]>;

  SWs$: Observable<string[]>;
  OTs$: Observable<string[]>;

  @ViewChild(StrategicQuestionsComponent)
  questionsComponent: StrategicQuestionsComponent;

  setupObservables() {
    const scoreMatrixLists$: Observable<ScoreMatrixUserList> = this.keyaccountPlan$.pipe(
      switchMap(keyaccountPlan => this.keyaccountPlanService.getScoreMatrixUserList(keyaccountPlan.uid)),
      shareReplay(1),
    );

    this.scoreMatrixes$ = scoreMatrixLists$.pipe(
      map(matrixes => {
        return matrixes.map(matrix => {
          return ({...this.listToMatrix(matrix.cells), name: matrix.name})
        })
      })
    );

    this.myScoreMatrix$ = combineLatest(this.keyaccountPlan$, this.user$).pipe(
      switchMap(([keyaccountPlan, user]) => this.keyaccountPlanService.getScoreMatrix(keyaccountPlan.uid, user.uid)),
      map(scoreMatrixList => this.listToMatrix(this.matrixListSum(this.createEmptyMatrixList(), scoreMatrixList.cells ? scoreMatrixList.cells : []))),
    );

    this.totalMatrix$ = scoreMatrixLists$.pipe(
      map(matrixes => {
        const sumList = matrixes
                        .reduce((totalMatrix, userMatrix) => this.matrixListSum(totalMatrix, userMatrix.cells), this.createEmptyMatrixList());
        return this.listToMatrix(sumList);

      }),
      tap(totalMatrix => console.log('total matrix:', totalMatrix)),
    );

    this.OTs$ = this.keyaccountPlan$.pipe(
      map(keyaccountPlan => {
        return [
          ...keyaccountPlan.matrix.filter(matrixRow => matrixRow.swotType === 'OPPORTUNITY'),
          ...keyaccountPlan.matrix.filter(matrixRow => matrixRow.swotType === 'THREAT'),
        ].map(matrixRow => matrixRow.name)
      })
    );

    this.SWs$ = this.keyaccountPlan$.pipe(
      map(keyaccountPlan => {
        return [
          ...keyaccountPlan.matrix.filter(matrixRow => matrixRow.swotType === 'STRENGTH'),
          ...keyaccountPlan.matrix.filter(matrixRow => matrixRow.swotType === 'WEAKNESS'),
        ].map(matrixRow => matrixRow.name)
      })
    );
  }

  buildForm(): FormGroup {
    return this.formBuilder.group({
      'uid'               : [],
      'matrixCompleted'   : [],
      'strategicQuestions': [],
    });
  }

  doSave(completedMatrix = false) {
    if(this.form.disabled) {
      console.log('Cannot save a disabled form');
      return;
    }
    const uid = this.form.get('uid').value;
    const formValue = this.form.value;
    const patch: Partial<KeyaccountPlan> = {
      strategicQuestions: formValue.strategicQuestions
    };

    if(completedMatrix) {
      patch['matrixCompleted'] = true;
    }
    this.form.markAsPristine();
    return (this.keyaccountPlanService.editKeyaccountPlan(uid, patch))
      .then(() => {
        this.toasterService.pop('success', 'Saved matrix successfull');
      })
      .catch(err => {
        console.error(err);
        this.toasterService.pop('error', 'Error saving matrix: ' + err.message);
      });
  }

  saveScores(scores: {id: number, value: number}[][]) {
    const keyaccountPlanUid = this.form.get('uid').value;
    const scoreList = this.matrixToList(scores);
    return this.user$
      .pipe(
        first(),
        switchMap(user => {
          console.log('saving matrix scores for', user.uid, scoreList);
          return this.keyaccountPlanService.setScoreMatrix(keyaccountPlanUid, user.uid, {name: user.name, cells: scoreList});
        }),
      ).toPromise();

  }

  eraseMyScores() {
    if(!this.form.get('matrixCompleted').value) {
      this.saveScores(this.createEmptyMatrix().rows);
    } else {
      console.log('Cannot erase value if the matrix has already been completed');
    }
  }

  lockMatrix() {
    if (!this.form.get('matrixCompleted').value) {
      this.form.get('matrixCompleted').setValue(true);
      return this.doSave(true).then(() => this.toasterService.pop('success', 'Matrix locked'));
    } else {
      this.toasterService.pop('error', 'Matrix already locked');
    }
  }

  createEmptyMatrixList(matrixSize = 6): {id: number, value: number}[] {
    return Array.from({length: matrixSize * matrixSize}, () => ({})).map((v, i) => ({id: i + 1, value: 0}));
  }

  createEmptyMatrix(matrixSize = 6): ScoreMatrix {
    return this.listToMatrix(this.createEmptyMatrixList(matrixSize));
  }

  addStrategicQuestion(evt) {
    if (!this.questionsComponent) {
      this.toasterService.pop('error', 'Could not locate questionsComponent. Is the matrix locked?');
      return;
    }

    const success = this.questionsComponent.addStrategicQuestion({
      question: evt.title, matrixCell: evt.cellId, type: evt.type,
      options : [{uid: pushid(), description: '', rating1: 0, rating2: 0, rating3: 0, rating4: 0, rating5: 0}]
    });
    if (!success) {
      this.translationService.get('STRATEGICISSUE_ALREADY_EXISTS').pipe(first()).toPromise()
        .then(alertMessage => this.toasterService.pop('error', alertMessage));
    }
  }

  listToMatrix(scoreMatrixList: {id: number, value: number}[], matrixSize = 6): ScoreMatrix {
    const splitArrayInChunks = (arr, size) => {
      const resultArr = [];
      for(var i = 0; i < arr.length; i += size) {
        resultArr.push(arr.slice(i, i+size));
      }
      return resultArr;
    };

    return {
      rows: splitArrayInChunks(scoreMatrixList, matrixSize)
    };
  }

  matrixToList(scoreMatrix: {id: number, value: number}[][]): {id: number, value: number}[] {
    const flatten = (arr) => [].concat.apply([], arr);

    return flatten(scoreMatrix);
  }

  matrixListSum(matrixList1, matrixList2) {
    return matrixList1.map((cell, cellIndex) => {
      const value1 = cell.value ? parseInt(cell.value, 10) : 0;
      const value2 = matrixList2[cellIndex] ? parseInt(matrixList2[cellIndex].value, 10) : 0;
      return {
        ...cell,
        value: value1 + value2,
      }
    })
  }

}
