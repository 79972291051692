import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { KeyaccountPlan } from '../../../keyaccount-plan.model';
import { sliderOptions } from '../../../keyaccountplans/activity/activity-planning/slider-optionts';

@Component({
  selector: 'masterplanner2-activity-dashboard',
  templateUrl: './activity-dashboard.component.html',
  styleUrls: ['./activity-dashboard.component.css']
})
export class ActivityDashboardComponent implements OnInit {
  Math = Math;

  @Input()
  public keyaccountPlans$: Observable<KeyaccountPlan[]>;

  sliderOptions = Object.assign({}, sliderOptions, {readOnly: true});
  selectedKeyaccountPlanUids: {[uid: string]: boolean} = {};

  constructor() { }

  ngOnInit() {
  }

  getStrategicActions(keyaccountPlan: KeyaccountPlan) {
    const newStrategicActions = (keyaccountPlan.strategicActions ? keyaccountPlan.strategicActions : []).map(action => {
      const newSubActivities = (action.subActivities ? action.subActivities : []).map(subAction => {
        return {
          ...subAction,
          startDate: (subAction.startDate ? (subAction.startDate as any).toDate() : null),
          endDate  : (subAction.endDate ? (subAction.endDate as any).toDate() : null),
        }
      });
      return {
        ...action,
        subActivities: newSubActivities,
        startDate    : (action.startDate ? (action.startDate as any).toDate() : null),
        endDate      : (action.endDate ? (action.endDate as any).toDate() : null),
      };
    });
    // only actions wich are not realised
    return newStrategicActions.filter(action => !action.realised);
  }

  toggleOpen(uid: string) {
    this.selectedKeyaccountPlanUids[uid] = !this.isOpen(uid);
  }

  isOpen(uid: string): boolean {
    return this.selectedKeyaccountPlanUids[uid];
  }

}
