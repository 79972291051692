import { Component, Input, OnInit } from '@angular/core';
import { AbstractFormArrayListComponent } from '../abstract-form-array-list/abstract-form-array-list.component'
import { FormGroup, NG_VALUE_ACCESSOR, Validators, FormBuilder } from '@angular/forms';
import { UserGroupService } from "@masterplanner2/users";
import { Observable } from 'rxjs';
import { map, filter, pluck, switchMap } from "rxjs/operators";
import { KeyaccountPlanService } from "../../keyaccount-plan.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector   : 'masterplanner2-buying-behavior',
  templateUrl: './buying-behavior.component.html',
  styleUrls  : ['./buying-behavior.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: BuyingBehaviorComponent, multi: true}
  ]
})
export class BuyingBehaviorComponent extends AbstractFormArrayListComponent implements OnInit {
  @Input()
  title: string;

  parentForm: FormGroup;
  buyingbehaviors$: Observable<string[]>;

  constructor(protected formBuilder: FormBuilder,
              private userGroupService: UserGroupService,
              private keyaccountPlanService: KeyaccountPlanService,
              activatedRoute: ActivatedRoute) {
    super(formBuilder);

    this.buyingbehaviors$ = activatedRoute.params.pipe(
      pluck('id'),
      filter(id=> !!id),
      switchMap(keyaccountPlanUid => {
        return this.keyaccountPlanService.getKeyaccountPlan(<string>keyaccountPlanUid).pipe(
          switchMap(keyaccountPlan => {
            return keyaccountPlan.groupUid
              ? this.userGroupService.getUserGroup(keyaccountPlan.groupUid).pipe(
                  map(userGroup => userGroup // usergroup might not be found
                    ? userGroup.buyingbehaviors.map(buyingbehavior => buyingbehavior.description)
                    : []
                  )
              )
              : null;
          })
        )
      }),
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.parentForm = this.createForm();    
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      behaviors: this.form,
      explanation: [],
    })
  }

  createItemControls(): FormGroup {
    return this.formBuilder.group({
      'uid'       : [, Validators.required],
      'name'      : [, Validators.required],
      'swotType'  : [, Validators.required],
      'swotReason': [, Validators.required],
    })
  }

  setDisabledState(isDisabled: boolean): void {
    this.parentForm[isDisabled ? 'disable' : 'enable']({emitEvent: false});
    super.setDisabledState(isDisabled);
  }

  registerOnChange(fn: any): void {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe()
    }
    this.onChangeSubscription = this.parentForm.valueChanges.subscribe(value => fn(value));
  }

  writeValue(items: any): void {
    if (!items) {
      items = [];
    }
      super.writeValue(items.behaviors);
      this.parentForm.patchValue(items);
  }
}

