import { AngularFirestore } from '@angular/fire/firestore';
import { combineLatest, of } from 'rxjs';
import { catchError, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { CustomerService } from '../../../../apps/masterplanner-client/src/app/customer.service';
import { UserService } from './user.service';
import { flatten } from "@angular/compiler";
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "./user.service";
import * as i3 from "../../../../apps/masterplanner-client/src/app/customer.service";
export class Keyaccount {
}
export class TeamMember {
}
export class KeyaccountService {
    constructor(db, userService, customerService) {
        this.db = db;
        this.userService = userService;
        this.customerService = customerService;
        this.collectionName = 'keyaccounts';
        this.keyaccountCollection = db.collection(this.collectionName);
        this.keyaccounts$ = userService.getCurrentUser().pipe(switchMap(user => {
            if (!user) {
                return of([]);
            }
            if (user.roles.includes('ADMIN')) {
                return this.getKeyaccountBy();
            }
            else if (user.roles.includes('GROUPADMIN')) {
                return this.getKeyaccountBy('groupUid', user.groupUid);
            }
            else if (user.roles.includes('ACCOUNTMANAGER') && user.roles.includes('ACCOUNTVIEWER')) {
                const keyaccounts = [...user.keyaccountsView, ...user.keyaccounts];
                return combineLatest(keyaccounts.map(keyaccount => this.getKeyaccountBy('uid', keyaccount))).pipe(map(flatten));
            }
            else if (user.roles.includes('ACCOUNTMANAGER')) {
                const keyaccounts = user.keyaccounts;
                return combineLatest(keyaccounts.map(keyaccount => this.getKeyaccountBy('uid', keyaccount))).pipe(map(flatten));
            }
            else if (user.roles.includes('ACCOUNTVIEWER')) {
                const keyaccounts = user.keyaccountsView;
                return combineLatest(keyaccounts.map(keyaccount => this.getKeyaccountBy('uid', keyaccount))).pipe(map(flatten));
            }
            else {
                // No role?
                console.error('No matching role found to filter keyaccountPlans', user);
                return of([]);
            }
        }), shareReplay(1), catchError(err => {
            console.error('Error retrieving keyaccounts', err);
            return of([]);
        }));
    }
    /**
     *
     * @param field
     * @param value
     */
    getKeyaccountBy(field, value) {
        const queryFn = field ? ref => ref.where(field, '==', value) : ref => ref;
        return this.db.collection(this.collectionName, queryFn).valueChanges().pipe(catchError(err => {
            console.error('Error retrieving keyaccountPlans:', err);
            return of([]);
        }));
    }
    getServiceLineUids() {
        return this.getCustomerForCurrentUser()
            .pipe(map(customer => {
            if (!customer || !customer.servicelines) {
                return [];
            }
            return customer.servicelines.map(s => s.uid);
        }));
    }
    getCustomerForCurrentUser() {
        return this.userService.getCurrentUser()
            .pipe(switchMap(user => {
            if (!user) {
                return of(null);
            }
            return this.customerService.getCustomer(user.groupUid);
        }));
    }
    getKeyaccounts() {
        return this.keyaccounts$;
    }
    getKeyaccount(keyaccountUid) {
        return this.keyaccounts$
            .pipe(map(keyaccounts => keyaccounts.find(keyaccount => keyaccount.uid === keyaccountUid)));
    }
    getKeyaccountForGroupUid(groupUid) {
        return this.keyaccounts$
            .pipe(map(keyaccounts => keyaccounts.filter(keyaccount => keyaccount.groupUid === groupUid)));
    }
    getKeyAccountsForCurrentUsersGroup() {
        return this.userService.getCurrentUser()
            .pipe(switchMap(user => {
            if (!user) {
                return of([]);
            }
            return this.getKeyaccountForGroupUid(user.groupUid);
        }));
    }
    editKeyaccount(uid, keyaccount) {
        return this.keyaccountCollection.doc(uid).update(Object.assign({}, keyaccount, { uid: uid }));
    }
    setServicelineRevenues(uid, servicelines, importYearRange) {
        // servicelines contain all servicelines from the import
        // it might not contain a service line from an earlier import
        // existing service lines need to have their values reset to zero for the years that fall in the range of the import
        // the range can be extracted here as min / max from all given servicelines
        const initialRevenueValue = {};
        for (let i = importYearRange.start; i <= importYearRange.end; i++) {
            initialRevenueValue[i] = 0;
        }
        // TODO: refactor: extract / isolate calls to firebase to support mocking in test
        return this.getServiceLineUids()
            .pipe(first(), map((serviceLineUids) => {
            // Set all existing revenues to the default value for the year range of the import
            return serviceLineUids.reduce((existingRevenueUpdates, serviceLineUid) => {
                existingRevenueUpdates[serviceLineUid] = Object.assign({}, initialRevenueValue);
                return existingRevenueUpdates;
            }, {});
        }), map(toUpdateRevenues => {
            // apply imported values over the default values for imported revenues, overwrite existing if needed
            Object.keys(servicelines).forEach(serviceLineUid => {
                toUpdateRevenues[serviceLineUid] = Object.assign({}, initialRevenueValue, servicelines[serviceLineUid]);
            });
            return toUpdateRevenues;
        }), map(toUpdateRevenues => {
            return Object.keys(toUpdateRevenues).map(serviceLineUid => {
                // update each revenue, merge with existing data to keep values for years outside of the imported range
                return this.keyaccountCollection.doc(uid)
                    .collection('revenues')
                    .doc(serviceLineUid)
                    .set(toUpdateRevenues[serviceLineUid], { merge: true });
            });
        }), map(serviceLinePromises => {
            return this.keyaccountCollection.doc(uid)
                .update({ revenueImportedAt: new Date() })
                .then(() => Promise.all(serviceLinePromises));
        }))
            .toPromise();
    }
    createKeyaccount(keyaccount) {
        return this.keyaccountCollection.add(keyaccount)
            .then(docRef => {
            return docRef.update({ uid: docRef.id }).then(() => docRef);
        });
    }
    canEdit(keyaccountUid) {
        return combineLatest(this.userService.getCurrentUser(), this.getKeyaccount(keyaccountUid)).pipe(map(([user, keyaccount]) => {
            if (!user) {
                return false;
            }
            else if (user.roles.includes('ADMIN')) {
                return true;
            }
            else if (user.roles.includes('GROUPADMIN')) {
                return keyaccount.groupUid === user.groupUid;
            }
            else if (user.roles.includes('ACCOUNTMANAGER')) {
                return user.keyaccounts.includes(keyaccount.uid);
            }
            else {
                // No role or account viewer
                return false;
            }
        }));
    }
}
KeyaccountService.ngInjectableDef = i0.defineInjectable({ factory: function KeyaccountService_Factory() { return new KeyaccountService(i0.inject(i1.AngularFirestore), i0.inject(i2.UserService), i0.inject(i3.CustomerService)); }, token: KeyaccountService, providedIn: "root" });
