import { Component, Input, OnInit } from '@angular/core';
import { AbstractFormArrayListComponent } from '../abstract-form-array-list/abstract-form-array-list.component';
import { FormGroup, NG_VALUE_ACCESSOR, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector   : 'masterplanner2-dmus',
  templateUrl: './dmus.component.html',
  styleUrls  : ['./dmus.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: DmusComponent, multi: true}
  ]
})
export class DmusComponent extends AbstractFormArrayListComponent implements OnInit {
  @Input()
  title: string;

  dmuRoles = ['INFLUENCER', 'DECISIONMAKER'];
  parentForm: FormGroup;

  constructor(protected formBuilder: FormBuilder) {
    super(formBuilder);
  }

  ngOnInit() {
    super.ngOnInit();
    this.parentForm = this.createForm();
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      contacts    : this.form,
      swotTypeOT  : [, Validators.required],
      swotReasonOT: [, Validators.required],
      swotTypeSW  : [],
      swotReasonSW: []
    })
  }

  createItemControls(): FormGroup {
    return this.formBuilder.group({
      'uid'              : [, Validators.required],
      'firstname'        : [, Validators.required],
      'prefix'           : [, Validators.required],
      'surname'          : [, Validators.required],
      'function'         : [, Validators.required],
      'role'             : [, Validators.required],
      'attitude'         : [, Validators.required],
      'visitingIntensity': [, Validators.required],
    });
  }

  registerOnChange(fn: any): void {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe()
    }
    this.onChangeSubscription = this.parentForm.valueChanges.subscribe(value => fn(value));
  }

  setDisabledState(isDisabled: boolean): void {
    this.parentForm[isDisabled ? 'disable' : 'enable']({emitEvent: false});
    super.setDisabledState(isDisabled);
  }

  writeValue(items: any): void {
    if (!items) {
      items = [];
    }
    super.writeValue(items.contacts);
    this.parentForm.patchValue(items);
  }

}
