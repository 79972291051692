<table id="accountstrategies" class="table table-condensed accountstrategies">
  <ng-container *ngFor="let row of actions; let i = index;">
    <ng-container *ngIf="!row.fromOldPlan && !row.realised && !row.manual">
      <tr>
        <th>{{ 'STRATEGICISSUE' | translate }} </th>
        <td>{{ row.question }}</td>
      </tr>
      <tr>
        <th>{{ 'STRATEGICOPTION' | translate }}</th>
        <td>{{ row.option }}</td>
      </tr>
      <tr>
        <th>{{ 'MAINACTIVITY' | translate }}</th>
        <td>{{ row.action }}</td>
      </tr>
      <tr><td colspan="2"></td></tr>
    </ng-container>
  </ng-container>
</table>
