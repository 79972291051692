<table class="table activityplanspro-table">
  <ng-container *ngFor="let row of activities; let i = index;" >
    <ng-container *ngIf="!row.realised" class="activityplans-tablerow clearfix">

        <tr>
          <td width="50%">
            <strong class="control-label" >{{ 'KEY_ACTION' | translate }} </strong><br/>
            {{ row.action }}
          </td>
          <td width="50%">
            <strong class="control-label">{{ 'BY_WHOM' | translate }} </strong><br/>
            {{ row.responsible}}
          </td>
        </tr>
        <tr>
          <td>
            <strong class="control-label">{{ 'STARTDATE' | translate }} </strong><br/>
            {{ row.startDate?.toDate() | date: 'dd-MM-yyyy' }}
          </td>
          <td>
            <strong class="control-label">{{ 'REALISEDDATE' | translate }} </strong><br/>
            {{ row.endDate?.toDate() | date: 'dd-MM-yyyy' }}
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <strong>{{ 'ACTIVITY_COMMENTS'| translate }}</strong><br/>
            {{ row.activityComments }}
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <strong>{{ 'CREALISED_RESULT_LABEL' | translate }}</strong><br/>
            {{ row.result }}
          </td>
        </tr>
        <tr>
          <td colspan="3">
          <masterplanner2-sub-activities-flat [activities]="row.subActivities" *ngIf="!row.realised"></masterplanner2-sub-activities-flat>
          </td>
        </tr>
    </ng-container>
  </ng-container>
</table>
