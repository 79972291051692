import { CdkTableModule } from '@angular/cdk/table';
import { ErrorHandler, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from "@angular/fire/storage";
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatMenuModule } from "@angular/material";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgxFirebaseTranslateModule } from '@masterplanner2/ngx-firebase-translate';
import { AuthGuard, AuthTokenGuard, EmailLoginComponent, UserCreateComponentComponent, UserModuleConfig, UsersGridComponent, UsersModule } from '@masterplanner2/users';
import { NgbModule, NgbPopoverModule, NgbProgressbarModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NxModule } from '@nrwl/nx';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import * as Sentry from "@sentry/browser";
import { ToasterModule } from 'angular2-toaster';
import 'hammerjs';
import { Ng5SliderModule } from "ng5-slider";
import { ImageCropperModule } from "ngx-image-cropper";
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { BrickwallComponent } from './customer-edit/brickwall/brickwall.component';
import { CustomerEditComponent } from './customer-edit/customer-edit.component';
import { FeatureTogglesComponent } from './customer-edit/feature-toggles/feature-toggles.component';
import { ImageCropperComponent } from './customer-edit/image-cropper/image-cropper.component';
import { ImagePreviewComponent } from './customer-edit/image-preview/image-preview.component';
import { ImageUploadPreviewComponent } from './customer-edit/image-upload-preview/image-upload-preview.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { KeyaccountEditComponent } from './keyaccount-edit/keyaccount-edit.component';
import { KeyaccountPlanCreateComponent } from './keyaccount-plan-create/keyaccount-plan-create.component';
import { DeleteKeyaccountplanDialogComponent } from './keyaccount-plans-list/delete-keyaccountplan-dialog/delete-keyaccountplan-dialog.component';
import { KeyaccountPlansListComponent } from './keyaccount-plans-list/keyaccount-plans-list.component';
import { ActivityCalendarComponent } from './keyaccountplans/activity/activity-calendar/activity-calendar.component';
import { ActivityPlanningComponent } from './keyaccountplans/activity/activity-planning/activity-planning.component';
import { SubActivitiesComponent } from './keyaccountplans/activity/activity-planning/sub-activities/sub-activities.component';
import { ActivityComponent } from './keyaccountplans/activity/activity.component';
import { AnalysisComponent } from './keyaccountplans/analysis/analysis.component';
import { BrickwallScoreComponent } from './keyaccountplans/analysis/brickwall-score/brickwall-score.component';
import { BuyingpowerComponent } from './keyaccountplans/analysis/buyingpower/buyingpower.component';
import { CriticalContactsComponent } from './keyaccountplans/analysis/critical-contacts/critical-contacts.component';
import { OpportunitiesComponent as AnalysisOpportunitiesComponent } from './keyaccountplans/analysis/opportunities/opportunities.component';
import { ProjectsComponent } from './keyaccountplans/analysis/projects/projects.component';
import { ServicelinesComponent } from './keyaccountplans/analysis/servicelines/servicelines.component';
import { DashboardComponent } from './keyaccountplans/dashboard/dashboard.component';
import { HeaderLogosComponent } from './keyaccountplans/header-logos/header-logos.component';
import { ActivityLessonsComponent } from './keyaccountplans/lessons/activity-lessons/activity-lessons.component';
import { LessonsComponent } from './keyaccountplans/lessons/lessons.component';
import { LocaleFileDirective } from './keyaccountplans/locale-file.directive';
import { MatrixComponent } from "./keyaccountplans/matrix/matrix.component";
import { ScoreMatrixComponent } from './keyaccountplans/matrix/score-matrix/score-matrix.component';
import { StrategicOptionsComponent } from './keyaccountplans/matrix/strategic-questions/strategic-options/strategic-options.component';
import { StrategicQuestionsComponent } from './keyaccountplans/matrix/strategic-questions/strategic-questions.component';
import { TotalMatrixComponent } from './keyaccountplans/matrix/total-matrix/total-matrix.component';
import { NavBarComponent } from './keyaccountplans/nav-bar/nav-bar.component';
import { AccountGoalsFlatComponent } from './keyaccountplans/print-flat/account-goals-flat/account-goals-flat.component';
import { AccountStrategyFlatComponent } from './keyaccountplans/print-flat/account-strategy-flat/account-strategy-flat.component';
import { ActivityLessonsFlatComponent } from './keyaccountplans/print-flat/activity-lessons-flat/activity-lessons-flat.component';
import { ActivityPlanningFlatComponent } from './keyaccountplans/print-flat/activity-planning-flat/activity-planning-flat.component';
import { SubActivitiesFlatComponent } from './keyaccountplans/print-flat/activity-planning-flat/sub-activities-flat/sub-activities-flat.component';
import { BrickwallFlatComponent } from './keyaccountplans/print-flat/brickwall-flat/brickwall-flat.component';
import { BuyingpowerFlatComponent } from './keyaccountplans/print-flat/buyingpower-flat/buyingpower-flat.component';
import { CriticalContactsFlatComponent } from './keyaccountplans/print-flat/critical-contacts-flat/critical-contacts-flat.component';
import { MatrixFlatComponent } from './keyaccountplans/print-flat/matrix-flat/matrix-flat.component';
import { OtRatingFlatComponent } from './keyaccountplans/print-flat/ot-rating-flat/ot-rating-flat.component';
import { PrintClickDirective } from './keyaccountplans/print-flat/print-click.directive';
import { PrintFlatComponent } from './keyaccountplans/print-flat/print-flat.component';
import { RevenueFlatComponent } from './keyaccountplans/print-flat/revenue-flat/revenue-flat.component';
import { AccountRevenuesComponent } from './keyaccountplans/analysis/account-revenues/account-revenues.component';
import { RevenuePrintComponent } from './keyaccountplans/print-flat/revenue-print/revenue-print.component';
import { StrategicActionsFlatComponent } from './keyaccountplans/print-flat/strategic-actions-flat/strategic-actions-flat.component';
import { StrategicOptionsFlatComponent } from './keyaccountplans/print-flat/strategic-questions-flat/strategic-options-flat/strategic-options-flat.component';
import { StrategicQuestionsFlatComponent } from './keyaccountplans/print-flat/strategic-questions-flat/strategic-questions-flat.component';
import { SwRatingFlatComponent } from './keyaccountplans/print-flat/sw-rating-flat/sw-rating-flat.component';
import { PrintInputDirective } from './keyaccountplans/print/print-input.directive';
import { PrintComponent } from './keyaccountplans/print/print.component';
import { RefreshToasterComponent } from "./keyaccountplans/profile/abstract-tab.component";
import { BuyingBehaviorComponent } from './keyaccountplans/profile/buying-behavior/buying-behavior.component';
import { CompetitorsComponent } from './keyaccountplans/profile/competitors/competitors.component';
import { DevelopmentsComponent } from './keyaccountplans/profile/developments/developments.component';
import { DmusComponent } from './keyaccountplans/profile/dmus/dmus.component';
import { FinancesComponent } from './keyaccountplans/profile/finances/finances.component';
import { MainActivitiesComponent } from './keyaccountplans/profile/main-activities/main-activities.component';
import { OpportunitiesComponent } from './keyaccountplans/profile/opportunities/opportunities.component';
import { ProfileComponent } from './keyaccountplans/profile/profile.component';
import { PurchaseProcessComponent } from './keyaccountplans/profile/purchase-process/purchase-process.component';
import { RevenueComponent } from './keyaccountplans/profile/revenue/revenue.component';
import { StakeholdersComponent } from './keyaccountplans/profile/stakeholders/stakeholders.component';
import { SwotTypeComponent } from './keyaccountplans/profile/swot-type/swot-type.component';
import { StrategicActionsComponent } from './keyaccountplans/strategy/strategic-actions/strategic-actions.component';
import { StrategyComponent } from './keyaccountplans/strategy/strategy.component';
import { FreeformSwotComponent } from './keyaccountplans/swot/freeform-swot/freeform-swot.component';
import { OtRatingComponent } from './keyaccountplans/swot/ot-rating/ot-rating.component';
import { SwRatingComponent } from './keyaccountplans/swot/sw-rating/sw-rating.component';
import { SwotTableComponent } from './keyaccountplans/swot/swot-table/swot-table.component';
import { SwotComponent } from './keyaccountplans/swot/swot.component';
import { KeyaccountsListComponent } from './keyaccounts-list/keyaccounts-list.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { MySettingsComponent } from './my-settings/my-settings.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { SettingsMenuPageComponent } from './settings-menu-page/settings-menu-page.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { TranslationComponent } from './translation/translation.component';
import '@progress/kendo-angular-intl/locales/nl/all';
import localeNl from '@angular/common/locales/nl';
import { registerLocaleData } from '@angular/common';
import { BundleListComponent } from './bundles/bundle-list/bundle-list.component';
import { BundleCreateComponent } from './bundles/bundle-create/bundle-create.component';
import { DashboardComponent as BundleDashboardComponent } from './bundles/dashboard/dashboard.component';
import { ActivityDashboardComponent } from './bundles/dashboard/activity-dashboard/activity-dashboard.component';
import { ScoreDashboardComponent } from './bundles/dashboard/score-dashboard/score-dashboard.component';
import { TurnoverDashboardComponent } from './bundles/dashboard/turnover-dashboard/turnover-dashboard.component';
import { BundleDeleteDialogComponent } from './bundles/bundle-delete-dialog/bundle-delete-dialog.component';
import { BundleRevenueComponent } from './bundles/dashboard/bundle-revenue/bundle-revenue.component';
import { RevenueChartComponent } from './bundles/dashboard/revenue-chart/revenue-chart.component';
import { ImportComponent } from './import/import.component';
import { CanDeactivateTabGuard } from './keyaccountplans/can-deactivate-tab.guard';

registerLocaleData(localeNl, 'nl-NL');

Sentry.init({
  defaultIntegrations: false,
  dsn: "https://b13fee07fd73451c967a2fd3bca7e98b@sentry.io/1361351"
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    Sentry.captureException(error.originalError || error);
    console.error(error);
    throw error;
  }
}

const routes = [
  // user settings & login
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: EmailLoginComponent },
  { path: 'settings', component: SettingsMenuPageComponent, canActivate: [AuthGuard] },
  { path: 'settings/users', component: UsersGridComponent, canActivate: [AuthGuard] },
  { path: 'settings/user/:id', component: UserCreateComponentComponent, canActivate: [AuthGuard] },
  { path: 'settings/new-user', component: UserCreateComponentComponent, canActivate: [AuthGuard] },
  { path: 'settings/my-settings', component: MySettingsComponent, canActivate: [AuthGuard]},

  // customers
  { path: 'settings/customers', component: CustomerListComponent, canActivate: [AuthGuard] },
  { path: 'settings/new-customer', component: CustomerEditComponent, canActivate: [AuthGuard] },
  { path: 'settings/customer/:id', component: CustomerEditComponent, canActivate: [AuthGuard] },

  // keyaccounts
  { path: 'settings/keyaccounts', component: KeyaccountsListComponent, canActivate: [AuthGuard] },
  { path: 'settings/new-keyaccount', component: KeyaccountEditComponent, canActivate: [AuthGuard] },
  { path: 'settings/keyaccount/:id', component: KeyaccountEditComponent, canActivate: [AuthGuard] },

  // bundles
  { path: 'settings/bundles', component: BundleListComponent, canActivate: [AuthGuard]},
  { path: 'settings/new-bundle', component: BundleCreateComponent, canActivate: [AuthGuard]},
  { path: 'settings/new-bundle/:id', component: BundleCreateComponent, canActivate: [AuthGuard]},
  { path: 'settings/bundle/:id', component: BundleCreateComponent, canActivate: [AuthGuard]},
  { path: 'settings/dashboard/:id', component: BundleDashboardComponent, canActivate: [AuthGuard]},

  // keyaccountplans
  { path: 'keyaccountplans', component: KeyaccountPlansListComponent, canActivate: [AuthGuard] },
  { path: 'keyaccountplan/:id/dashboard', component: DashboardComponent ,canActivate: [AuthGuard], canDeactivate: [CanDeactivateTabGuard]},
  { path: 'keyaccountplan/:id/profile', component: ProfileComponent ,canActivate: [AuthGuard], canDeactivate: [CanDeactivateTabGuard]},
  { path: 'keyaccountplan/:id/analysis', component: AnalysisComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateTabGuard] },
  { path: 'keyaccountplan/:id/swot', component: SwotComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateTabGuard] },
  { path: 'keyaccountplan/:id/matrix', component: MatrixComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateTabGuard] },
  { path: 'keyaccountplan/:id/strategy', component: StrategyComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateTabGuard] },
  { path: 'keyaccountplan/:id/activity', component: ActivityComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateTabGuard] },
  { path: 'keyaccountplan/:id/lessons', component: LessonsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateTabGuard] },
  { path: 'keyaccountplan/:id/print', component: PrintFlatComponent, canActivate: [AuthTokenGuard], canDeactivate: [CanDeactivateTabGuard]},
  { path: 'keyaccountplan/new-plan', component: KeyaccountPlanCreateComponent, canActivate: [AuthGuard] },
  { path: 'keyaccountplan/new-plan/:id', component: KeyaccountPlanCreateComponent, canActivate: [AuthGuard] },

  // import
  { path: 'settings/import', component: ImportComponent, canActivate: [AuthGuard] },


  // translation
  { path: 'settings/translation', component: TranslationComponent, canActivate: [AuthGuard]},
];

const userModuleConfig: UserModuleConfig = {
  loginRedirectUrl: environment.loginRedirectUrl,
  redirectAfterLogin: '/keyaccountplans',
  firebaseCfg: environment.firebase
};

@NgModule({
  declarations: [
    AppComponent,
    SettingsMenuPageComponent,
    MainMenuComponent,
    KeyaccountsListComponent,
    KeyaccountPlansListComponent,
    ToolbarComponent,
    CustomerListComponent,
    CustomerEditComponent,
    KeyaccountEditComponent,
    ProfileComponent,
    CompetitorsComponent,
    OpportunitiesComponent,
    DevelopmentsComponent,
    PurchaseProcessComponent,
    BuyingBehaviorComponent,
    DmusComponent,
    SwotTypeComponent,
    AnalysisComponent,
    ServicelinesComponent,
    ProjectsComponent,
    AnalysisOpportunitiesComponent,
    BuyingpowerComponent,
    CriticalContactsComponent,
    NavBarComponent,
    SwotComponent,
    OtRatingComponent,
    SwRatingComponent,
    FreeformSwotComponent,
    KeyaccountPlanCreateComponent,
    MainActivitiesComponent,
    MatrixComponent,
    ScoreMatrixComponent,
    TotalMatrixComponent,
    StrategicQuestionsComponent,
    StrategicOptionsComponent,
    DashboardComponent,
    StrategyComponent,
    StrategicActionsComponent,
    ActivityComponent,
    ActivityPlanningComponent,
    ActivityCalendarComponent,
    SubActivitiesComponent,
    LessonsComponent,
    ActivityLessonsComponent,
    DeleteKeyaccountplanDialogComponent,
    RevenueComponent,
    RevenuePrintComponent,
    FinancesComponent,
    StakeholdersComponent,
    FeatureTogglesComponent,
    SwotTableComponent,
    BrickwallComponent,
    BrickwallScoreComponent,
    ImageCropperComponent,
    ImageUploadPreviewComponent,
    RefreshToasterComponent,
    TranslationComponent,
    ImagePreviewComponent,
    HeaderLogosComponent,
    MySettingsComponent,
    ScrollTopComponent,
    LocaleFileDirective,
    PrintComponent,
    PrintInputDirective,
    PrintFlatComponent,
    BuyingpowerFlatComponent,
    CriticalContactsFlatComponent,
    OtRatingFlatComponent,
    SwRatingFlatComponent,
    MatrixFlatComponent,
    StrategicQuestionsFlatComponent,
    StrategicActionsFlatComponent,
    AccountGoalsFlatComponent,
    AccountStrategyFlatComponent,
    StrategicOptionsFlatComponent,
    ActivityPlanningFlatComponent,
    ActivityLessonsFlatComponent,
    SubActivitiesFlatComponent,
    PrintClickDirective,
    RevenueFlatComponent,
    BrickwallFlatComponent,
    AccountRevenuesComponent,
    BundleListComponent,
    BundleCreateComponent,
    BundleDashboardComponent,
    ActivityDashboardComponent,
    ScoreDashboardComponent,
    TurnoverDashboardComponent,
    BundleDeleteDialogComponent,
    BundleRevenueComponent,
    RevenueChartComponent,
    ImportComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    NxModule.forRoot(),
    UsersModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    NgxFirebaseTranslateModule,
    ToasterModule.forRoot(),
    NgSelectModule,
    CdkTableModule,
    MatMenuModule,
    ReactiveFormsModule,
    NgbProgressbarModule,
    NgbRatingModule,
    NgbPopoverModule,
    ChartsModule,
    BrowserAnimationsModule,
    GaugesModule,
    NgbModule,
    InputsModule,
    DateInputsModule,
    Ng5SliderModule,
    MatDialogModule,
    ImageCropperModule,
    IntlModule
  ],
  providers: [
    { provide: UserModuleConfig, useValue: userModuleConfig },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: LOCALE_ID, useValue: 'nl-NL' }
  ],
  bootstrap: [AppComponent],
  entryComponents: [DeleteKeyaccountplanDialogComponent, RefreshToasterComponent, PrintFlatComponent, BundleDeleteDialogComponent]
})
export class AppModule {
}
