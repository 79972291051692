<div class="col-sm-12">
  <div class="panel panel-primary">
    <div class="sw-heading col-sm-12 panel-heading">
      <div class="pull-left col-sm-8">
        <h2>
          <div class="col-sm-10">{{ 'TRANSLATIONS_MASTERPLANNER' | uppercase | translate }}</div>
        </h2>
      </div>
    </div>
    <div class="form-group clearfix">
      <form name="editForm" id="editForm" class="form-horizontal" role="form" [formGroup]="newLocaleForm" (submit)="doSaveNewLocale()">
        <div class="form-group col-sm-12">
          <div class="row">
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-12">
                  <h4>{{ 'CREATE_CUSTOMER_SPECIFIC_LOCALE' | translate }}</h4>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <label for="newLocaleCustomerName">{{ 'CUSTOMER' | translate }}</label>
                </div>
                <div class="col-sm-4">
                  <label for="newLocaleCustomerName">{{ 'TRANSLATE_LOCALE' | translate }}</label>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <select name="newLocaleCustomerName" formControlName="customer" class="form-control">
                    <option value=""></option>
                    <option *ngFor="let customer of customers$ | async" [ngValue]="customer">{{ customer.name }}</option>
                  </select>
                </div>
                <div class="col-sm-4">
                  <select name="newLocaleLocales" formControlName="locale" class="form-control">
                    <option *ngFor="let locale of mainLocales$ | async" [ngValue]="locale">{{ locale.name }}</option>
                  </select>
                </div>
                <div class="col-sm-2">
                  <button class="btn btn-success" type="submit">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="separator col-sm-12">
      </div>
      <form name="editForm" id="editForm" class="form-horizontal" role="form" [formGroup]="newTranslationForm" (submit)="doSaveNewTranslation()">
        <div class="form-group col-sm-12">
          <div class="row">
            <div class="col-sm-12">
              <h4>{{ 'NEW_TRANSLATION' | translate }}</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5">
              <label for="newTranslationName" class="control-label">{{ 'TRANSLATE_NAME' | translate }}</label>
            </div>
            <div class="col-sm-6">
              <label for="newTranslationValue" class="control-label">{{ 'TRANSLATE_VALUE' | translate }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5">
              <input type="text" class="form-control" name="newTranslationName" formControlName="name">
              <label for="newTranslationLocale" class="control-label">{{ 'TRANSLATE_LOCALE' | translate }}</label>
              <select name="newTranslationLocale" formControlName="locale" class="form-control">
                <option *ngFor="let locale of locales$ | async" [ngValue]="locale">{{ locale.name }}</option>
              </select>
            </div>
            <div class="col-sm-6">
              <textarea name="newTranslationValue" class="form-control" formControlName="value"></textarea>
            </div>
            <div class="col-sm-1">
              <!-- todo submit form -->
              <button class="btn btn-success" type="submit">Save</button>
            </div>
          </div>
        </div>
      </form>
      <div class="separator col-sm-12">
      </div>
      <form name="editForm" id="editForm" class="form-horizontal" role="form" [formGroup]="editTranslationsForm">
        <div class="form-group col-sm-12">
          <div class="row">
            <div class="col-sm-12">
              <h4>{{ 'EDIT_TRANSLATIONS' | translate }}</h4>
            </div>
          </div>
          <div class="row" style="margin-bottom: 25px;">
            <div class="col-sm-12">
              <label for="editLocale" class="control-label">{{ 'TRANSLATE_LOCALE' | translate }}</label>
              <select formControlName="locale" name="editLocale" class="form-control">
                <option *ngFor="let locale of locales$ | async" [ngValue]="locale">{{ locale.name }}</option>
              </select>
            </div>
          </div>
          <div class="row translate-row" *ngFor="let translation of translations$ | async">
            <div class="col-sm-5">
              <label>{{ translation[0] }}</label>
              <button *ngIf="editTranslationsForm.get(translation[0]).disabled" class="btn btn-warning pull-right"
                (click)="enableControl(translation[0])">Edit</button>
            </div>
            <div class="col-sm-7">
              <p *ngIf="editTranslationsForm.get(translation[0]).disabled">{{ translation[1] }}</p>
              <div *ngIf="!editTranslationsForm.get(translation[0]).disabled">
                <textarea name="{{ translation[0] }}" [formControlName]="translation[0]" class="form-control"></textarea>
                <div class="btn-group">
                  <button class="btn btn-success" (click)="saveControl(translation[0])">Save</button>
                  <button class="btn btn-danger" (click)="disableControl(translation[0])">Cancel</button>
                </div>
              </div>
            </div>
            <hr class="col-sm-12">
          </div>
        </div>
      </form>
    </div>
  </div>
</div>