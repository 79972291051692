<form name="editForm" id="editForm" class="form-horizontal col-sm-12" [formGroup]="bundleForm">
  <div class="panel panel-primary">
    <div class="sw-heading col-sm-12 panel-heading">
      <div class="pull-left"><h2>{{ 'BUNDLE' | translate }}</h2></div>
    </div>
    <div class="form-group">
      <div class="form-group">
        <label for="cBundlecode" class="col-sm-2 control-label">{{ 'CBUNDLECODE' | translate }}</label>
        <div class="col-sm-4">
          <input type="text" formControlName="name"
                 class="form-control"
                 id="cBundlecode"
                 placeholder=""
                 required
          >
        </div>
        <label for="cDescription" class="col-sm-2 control-label">{{ 'CDESCRIPTION' | translate }}</label>
        <div class="col-sm-4">
          <input type="text"
                 class="form-control"
                 id="cDescription"
                 placeholder=""
                 formControlName="description"
          >
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label" >{{ 'CCUSTOMERNAME' | translate }}</label>
        <div class="col-sm-4">
          <ng-select [items]="groups$ | async"
                     id="idCustomer"
                     placeholder="Select company"
                     bindValue="uid"
                     bindLabel="name"
                     clearAllText="Clear"
                     formControlName="groupUid">
          </ng-select>
        </div>
      </div>
      <div *ngIf="(filterOptions$ | async) as filterOptions" [formGroup]="searchForm">
        <div class="form-group">
          <div class="separator">{{ 'FILTER_MASTERPLANNERS' | translate }}</div>
          <div class="form-group col-sm-12" style="padding-top: 5px;">
            <label class="col-sm-2 control-label">{{ 'FILTER_ON_BRANDNAME' | translate }}</label>
            <div class="col-sm-4">
              <ng-select [items]="keyaccounts$ | async"
                         id="idParent"
                         bindValue="uid"
                         bindLabel="name"
                         clearAllText="Clear"
                         formControlName="keyaccountUid">
              </ng-select>
            </div>
            <label class="col-sm-2 control-label">{{ 'FILTER_ON_INDUSTRY' | translate }}</label>
            <div class="col-sm-4">
              <ng-select [items]="(customer$ | async)?.industries"
                         id="cIndustry"
                         placeholder="Select industry"
                         bindValue="uid"
                         bindLabel="description"
                         clearAllText="Clear"
                         formControlName="industry"
              >
              </ng-select>
            </div>
          </div>
          <div class="form-group col-sm-12">
            <label class="col-sm-2 control-label">{{ 'FILTER_ON_COMPANYTYPE' | translate }}</label>
            <div class="col-sm-4">
              <ng-select [items]="(customer$ | async)?.companytypes"
                         id="cCompanytype"
                         placeholder="Select company type"
                         bindValue="uid"
                         bindLabel="description"
                         clearAllText="Clear"
                         formControlName="companyType"
              >
              </ng-select>
            </div>
            <label class="col-sm-2 control-label">{{ 'FILTER_ON_ACCOUNTMANAGER' | translate }}</label>
            <div class="col-sm-4">
              <ng-select [items]="filterOptions.keyaccountManagerName" formControlName="keyaccountManagerName">
              </ng-select>
            </div>
          </div>
          <div class="form-group col-sm-12" style="padding-top: 5px;">
            <label class="col-sm-2 control-label">{{ 'FILTER_ON_STATUS' | translate }}</label>
            <div class="col-sm-4">
              <div class="btn-group">
                <label class="btn btn-default"
                       [class.btn-success]="searchForm.get('status').value.includes('IN PROCESS')"
                       (click)="toggleStatus('IN PROCESS')"
                >{{ 'INPROCESS' | translate }}</label>
                <label class="btn btn-default"
                       [class.btn-success]="searchForm.get('status').value.includes('ACTIVE')"
                       (click)="toggleStatus('ACTIVE')"
                >{{ 'ACTIVE' | translate }}</label>
                <label class="btn btn-default"
                       [class.btn-success]="searchForm.get('status').value.includes('ARCHIVE')"
                       (click)="toggleStatus('ARCHIVE')"
                >{{ 'ARCHIVE' | translate }}</label>
              </div>
            </div>
            <div class="col-sm-6"></div>
          </div>
          <div class="col-sm-2"></div>
          </div>
      </div>
      <div class="form-group">
        <div class="separator">{{ 'SELECT_MASTERPLANNERS' | translate }}</div>
        <div class="col-sm-12 info-text"
             style="margin-bottom: 10px">{{ 'BUNDLE_POS_EXPLANATION' | translate }}</div>
        <div class="col-sm-12">
          <div class="col-sm-5"
               style="width: 47%;min-height: 200px;border: 1px solid silver;border-radius: 5px;padding-right: 0;margin-right: 0px;padding-left: 0;">
            <table id="tblmasterplannerstoselect"
                   class="table tblmasterplannerstoselect mptable">
              <tr class="pos-header">
                <th colspan="4">{{ 'AVAILABLE_MASTERPLANNERS' | translate }}</th>
              </tr>
              <tr>
                <th class="col-sm-4 brandname"> {{ 'CBRANDNAME' | translate }}</th>
                <th class="col-sm-4 version"> {{ 'VERSION' | translate }}</th>
                <th class="col-sm-2 targetyear"> {{ 'ITARGETYEAR' | translate }}</th>
                <th class="col-sm-2 status"> {{ 'STATUS' | translate }}</th>
              </tr>
              <tr *ngFor="let plan of (filteredKeyaccountPlans$ | async)"
                  [class.selectedrow]="selectedLeft.includes(plan.uid)"
                  (click)="toggleRow('left', plan.uid)"
                  (dblclick)="moveToRight(plan)"
              >
                <td class="form-group"><span>{{ plan.keyaccountName }}</span></td>
                <td class="form-group"><span>{{ plan.version }}</span></td>
                <td class="form-group"><span>{{ plan.targetYear }}</span></td>
                <td class="form-group"><span>{{ plan.status | translate}}</span></td>
              </tr>
            </table>
          </div>
          <div class="col-sm-1" style="width: 6%; padding: 14px">
            <div class="pos-buttons text-center">
              <button
                class="btn btn-default"
                (click)="moveToRight()"
                title="{{'SELECT' | translate}}"
                id="btnselect"
              >
                <i class="glyphicon glyphicon-chevron-right"></i>
              </button>
              <button
                class="btn btn-default"
                (click)="moveToLeft()"
                title="{{'DESELECT' | translate}}"
                id="btndeselect"
              >
                <i class="glyphicon glyphicon-chevron-left"></i>
              </button>
              <button
                class="btn btn-default"
                (click)="moveAllToRight()"
                title="{{'SELECTALL' | translate}}"
              >
                <i class="glyphicon glyphicon-chevron-right psa-all-left"></i>
                <i class="glyphicon glyphicon-chevron-right psa-all-right"></i>
              </button>
              <button
                class="btn btn-default psa-deselectall"
                (click)="moveAllToLeft()"
                title="{{'DESELECTALL' | translate}}"
                data-entity="masterplanners"
                data-selection="deselectall"
              >
                <i class="glyphicon glyphicon-chevron-left psa-all-left"></i>
                <i class="glyphicon glyphicon-chevron-left psa-all-right"></i>
              </button>
            </div>
          </div>
          <div class="col-sm-5"
               style="width: 47%;min-height: 200px;border: 1px solid silver;border-radius: 5px;padding-right: 0;margin-right: 0px;padding-left: 0;">
            <table id="tblmasterplannersselected" class="table tblmasterplannersselected mptable">
              <tr class="pos-header">
                <th colspan="4">{{ 'CHOSEN_MASTERPLANNERS' | translate }}</th>
              </tr>
              <tr>
                <th class="col-sm-4 brandname"> {{ 'CBRANDNAME' | translate }}</th>
                <th class="col-sm-4 version"> {{ 'VERSION' | translate }}</th>
                <th class="col-sm-2 targetyear"> {{ 'ITARGETYEAR' | translate }}</th>
                <th class="col-sm-2 status"> {{ 'STATUS' | translate }}</th>
              </tr>
              <tr *ngFor="let plan of (selectedKeyaccountPlans$ | async)"
                  [class.selectedrow]="selectedRight.includes(plan.uid)"
                  (click)="toggleRow('right', plan.uid)"
                  (dblclick)="moveToLeft(plan)"
              >
                <td class="form-group"><span>{{ plan.keyaccountName }}</span></td>
                <td class="form-group"><span>{{ plan.version }}</span></td>
                <td class="form-group"><span>{{ plan.targetYear }}</span></td>
                <td class="form-group"><span>{{ plan.status | translate}}</span></td>
              </tr>
            </table>
          </div>
          <span class="msg-error label label-warning pull-right"
                *ngIf="(multiplePlansForKeyaccount$ | async) === true"
          >{{ 'DUPLICATEBRANDS' | translate }}</span>
        </div>
        <div class="col-sm-12">
          <div class="btn btn-primary pull-right btn-dashboard"
               [class.disabled]="!(bundleForm.valid && selectedKeyaccountPlans$.value.length)"
               (click)="createDashboard()"
          >
            {{ 'CREATE_DASHBOARD' | translate }}
            <i class="glyphicon glyphicon-dashboard"></i>
          </div>
        </div>
        <div>

        </div>
      </div>
    </div>
  </div>
</form>
