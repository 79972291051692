<table  [formGroup]="parentForm"
  id="dmus"
  class="table table-condensed buyingpowerindices col-sm-12">
  <thead>
    <tr>
      <th colspan="5">
        {{ 'DMU_TABLE_HEADER' | translate }}
        <button
          type="button"
          class="btn ng-scope no-btn success pull-right"
          (click)="addItem()"
          title="{{ 'NEW' | translate }}"
        >
          <i class="glyphicon glyphicon-plus "></i>
        </button>
      </th>
    </tr>
    <tr>
      <th> {{ 'DMUS_NAME' | translate }} </th>
      <th> {{ 'DMUS_FUNCTION' | translate }} </th>
      <th> {{ 'DMUS_ROLE' | translate }} </th>
    </tr>
  </thead>
  <tbody formArrayName="contacts">
    <tr *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
      <td class="col-sm-4 dmu-name">
        <input
          name="cFirstname"
          type="text"
          class="form-control"
          formControlName="firstname"
        >
        <input
          name="cPrefix"
          type="text"
          class="form-control"
          formControlName="prefix"
        >
        <input
          name="cSurname"
          type="text"
          class="form-control"
          formControlName="surname"
        >
      </td>
      <td>
        <input
          name="cFunction"
          type="text"
          class="form-control"
          formControlName="function"
        >
      </td>
      <td>
        <select
          class="form-control"
          formControlName="role">
          <option *ngFor="let role of dmuRoles" value="{{ role }}">{{ role | translate }}</option>
        </select>
      </td>
      <td class="text-right">
        <button type="button"
                class="btn ng-scope no-btn danger deletebutton "
                (click)="removeItem(i)"
                title="{{ 'DELETE' | translate }}"
        >
          <i class="glyphicon glyphicon-trash "></i>
        </button>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
        <th> {{ 'QUALIFICATION' | translate }} </th>
        <th> {{ 'REASON' | translate }} </th>
    </tr>
    <tr>
      <td>
          <masterplanner2-swot-type formControlName="swotTypeOT" mode="OT"></masterplanner2-swot-type>
      </td>
      <td>
        <input class="form-control" formControlName="swotReasonOT">
      </td>
    </tr>
  </tfoot>
</table>



