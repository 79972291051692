import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'masterplanner2-swot-type',
  templateUrl: './swot-type.component.html',
  styleUrls: ['./swot-type.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: SwotTypeComponent, multi: true}
  ]
})
export class SwotTypeComponent implements OnInit, OnDestroy, ControlValueAccessor {

  onChangeSubscription: Subscription;
  onTouchedSubscription: Subscription;
  form: FormGroup;
  @Input()
  mode: 'OT' | 'SW' = 'OT';
  swotTypes = [];

  constructor(private formBuilder: FormBuilder) {
    this.form = this.buildForm();
  }

  buildForm(): FormGroup {
    return this.formBuilder.group({
      swotType: [],
    })
  } 

  ngOnInit() {
    this.swotTypes = this.mode === 'OT' ? ['OPPORTUNITY', 'THREAT', 'NEUTRAL'] : ['STRENGTH', 'WEAKNESS', 'NEUTRAL']
  }

  ngOnDestroy() {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe();
    }
    if (this.onTouchedSubscription) {
      this.onTouchedSubscription.unsubscribe();
    }
  }

  registerOnChange(fn: any): void {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe()
    }
    this.onChangeSubscription = this.form.get('swotType').valueChanges.subscribe(value => fn(value));
  }

  registerOnTouched(fn: any): void {
    if (this.onTouchedSubscription) {
      this.onTouchedSubscription.unsubscribe()
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.form[isDisabled ? 'disable' : 'enable']({emitEvent: false});
  }

  writeValue(items: any): void {
    this.form.patchValue({swotType: items});
  }
}
