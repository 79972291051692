import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BuyingpowerComponent } from "../../analysis/buyingpower/buyingpower.component";
import { BehaviorSubject, ConnectableObservable, Observable, of } from "rxjs";
import { KeyaccountPlanService } from "../../keyaccount-plan.service";
import { DomainValue } from "../../../customer.model";
import { BuyingPower, BuyingPowerScore } from '../../../keyaccount-plan.model';
import { map, publishReplay, shareReplay, tap } from 'rxjs/operators';

@Component({
  selector: 'masterplanner2-buyingpower-flat',
  templateUrl: './buyingpower-flat.component.html',
  styleUrls: ['./buyingpower-flat.component.css']
})
export class BuyingpowerFlatComponent implements OnInit, OnChanges {
  Math = Math;

  @Input()
  buyingpowers: {[uid: string]: BuyingPower} = {};

  @Input()
  buyingcriteria: DomainValue[];

  @Input()
  customerName: string;

  /** Includes the extra manual buying behavior */
  public allBuyingcriteria$: BehaviorSubject<DomainValue[]> = new BehaviorSubject([]);

  sum$: Observable<{ companyTotalWeightedScore: number, companyTotalRestScore: number, competitor1Score: number, competitor2Score: number }>;
  scores$: Observable<{ [buyingcriterionUid: string]: BuyingPowerScore}>;


  constructor(private keyaccountPlanService: KeyaccountPlanService) {

  }

  ngOnInit(): void {
    this.allBuyingcriteria$.next([...this.buyingcriteria, {uid: 'extra', description:''}]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.scores$ =  of(this.keyaccountPlanService.calculateBuyingpowerScores(this.buyingpowers)
      .filter(buyingCriteria => buyingCriteria.uid !== 'extra' || buyingCriteria.name)
      // Make the uid the index of a map with the scores
      .reduce((acc, criteriumScore) => {
        acc[criteriumScore.uid] = criteriumScore;
        return acc;
      }, {})
    ).pipe(tap(console.log), shareReplay(1));

    this.sum$ = this.scores$.pipe(
      map((buyingcriteria) => this.keyaccountPlanService.calculateBuyingpowerTotals(buyingcriteria)),
      tap(buyingcriteriaSums => console.log('buyingcriteria sums', buyingcriteriaSums)),
    );
  }
}
