import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { Customer } from '../customer.model';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { filter, first, pluck, switchMap, tap, shareReplay } from 'rxjs/operators';
import { KeyaccountService, Keyaccount, UserService } from '@masterplanner2/users';
import * as pushid from "pushid";
import { CustomerService } from '../customer.service';

@Component({
  selector: 'masterplanner2-keyaccount-edit',
  templateUrl: './keyaccount-edit.component.html',
  styleUrls: ['./keyaccount-edit.component.css']
})
export class KeyaccountEditComponent implements OnInit, OnDestroy {
  public canEdit$: Observable<boolean>;
  public isAdmin$: Observable<boolean>;
  public isAnyAdmin$: Observable<boolean>;

  groups$: Observable<Customer[]>;
  keyaccounts$: Observable<Keyaccount[]>;
  form: FormGroup;
  editSubscription: Subscription;
  isEditing$ = new BehaviorSubject(false);
  // canEdit$: Observable<boolean>;

  constructor(
    private formBuilder: FormBuilder,
    activatedRoute: ActivatedRoute,
    private keyaccountService: KeyaccountService,
    private userService: UserService,
    private toasterService: ToasterService,
    customerService: CustomerService,
    private router: Router,
  ) {
    this.keyaccounts$ = keyaccountService.getKeyaccounts();
    this.groups$ = customerService.getCustomers();
    this.canEdit$ = activatedRoute.params.pipe(
      pluck('id'),
      filter(id=> !!id),
      switchMap(keyaccountUid => keyaccountService.canEdit(<string>keyaccountUid)),
      shareReplay(1)
    )
    this.isAdmin$ = userService.getCurrentUserHasAnyRole(['ADMIN']);
    this.isAnyAdmin$ = userService.getCurrentUserHasAnyRole(['ADMIN', 'GROUPADMIN']);

    this.form = formBuilder.group({
      'uid': [],
      'groupUid': [],
      'name': [, Validators.required],
      'parent': [],
      'logo': [],
      'strategic': [],
      'teamMembers': formBuilder.array([
      ]),
      'nonTeamMembers': formBuilder.array([
      ]),
    });

    this.editSubscription = activatedRoute.params.pipe(
      pluck('id'),
      filter(id=> !!id),
      tap(id => this.isEditing$.next(true)),
      switchMap(customerId => this.keyaccountService.getKeyaccount(<string>customerId).pipe(first())),
      tap((keyaccount: Keyaccount) =>  {
        this.form.reset();
        if(!keyaccount.teamMembers) {
          keyaccount.teamMembers = [];
        }
        const length = keyaccount.teamMembers.length;
        for(let i=0;i<length;i++) {
          this.getTeamMemberFormArray(keyaccount.teamMembers[i].isPartOfTeam).push(this.createTeamMemberControls());
        }

        this.form.patchValue(keyaccount)
      })
    ).subscribe();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if(this.editSubscription) {
      this.editSubscription.unsubscribe();
    }
  }

  addTeamMember(isPartOfTeam: boolean) {
    const formArray = this.getTeamMemberFormArray(isPartOfTeam);
    const formGroup = this.createTeamMemberControls();
    formGroup.patchValue({
      uid: pushid(),
      isPartOfTeam: isPartOfTeam,
    });
    formArray.push(formGroup);
  }

  removeTeamMember(i, isPartOfTeam: boolean) {
    this.getTeamMemberFormArray(isPartOfTeam).removeAt(i);
  }

  getTeamMemberFormArray(isPartOfTeam): FormArray {
    return this.form.get(isPartOfTeam ? 'teamMembers' : 'nonTeamMembers') as FormArray;
  }

  getTeamMemberControls(isPartOfTeam): AbstractControl[] {
    const formArray = this.form.get(isPartOfTeam ? 'teamMembers' : 'nonTeamMembers') as FormArray;
    return formArray.controls
  }

  createTeamMemberControls(): FormGroup {
    return this.formBuilder.group({
      'uid':[,Validators.required],
      'firstname':[,Validators.required],
      'prefix':[],
      'surname':[,Validators.required],
      'function':[,Validators.required],
      'role':[,Validators.required],
      'external':[],
      'isPartOfTeam':[,Validators.required],
    })
  }

  doSave() {
    const uid = this.form.get('uid').value;
    const keyaccount = this.mapToDatabase(this.form.value);
    console.log(uid, keyaccount);
    return (uid ? this.keyaccountService.editKeyaccount(uid, keyaccount) : this.keyaccountService.createKeyaccount(keyaccount).then(()=>{}))
      .then(() => {
        this.toasterService.pop('success', uid ? 'Edited keyaccount successfull' : 'Created keyaccount successfull');
        this.router.navigateByUrl('/settings/keyaccounts');
      })
      .catch(err => {
        console.error(err);
        this.toasterService.pop('error', 'Error creating keyaccount: ' + err.message);
      })
      ;

  }

  mapToDatabase(keyaccountFormValue: any) {
    return {
      ...keyaccountFormValue,
      teamMembers: [
        ...keyaccountFormValue.teamMembers,
        ...keyaccountFormValue.nonTeamMembers
      ]};
  }

}
