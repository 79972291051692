import { Options } from "ng5-slider";

function colorForValue(value) {
  if (value <= 10)
    return '#FF0000';
  if (value <= 20)
    return '#FF5500';
  if (value <= 30)
    return '#FF9900';
  if (value <= 40)
    return '#FFCC00';
  if (value <= 50)
    return '#FFBB00';
  if (value <= 60)
    return '#FFFF00';
  if (value <= 70)
    return '#CCFF00';
  if (value <= 80)
    return '#88FF00';
  if (value <= 90)
    return '#55FF00';
  return '#00FF00';
}

export const sliderOptions: Options = {
  showSelectionBar: true,
  floor: 0,
  ceil: 100,
  ticksArray: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  showTicks: true,
  hideLimitLabels: true,
  hidePointerLabels: true,
  translate: function (value) {
    return value + '%';
  },
  getSelectionBarColor: colorForValue,
  getPointerColor: colorForValue,
  getTickColor: colorForValue,
};
