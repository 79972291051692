import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KeyaccountService } from '@masterplanner2/users';

export interface ImportWarning {
  message: string;
  elements: string[];
}

export interface NameToUid {
  [name: string]: string;
}

export interface AliasToName {
  [name: string]: string;
}


@Injectable({
  providedIn: 'root'
})

export abstract class AbstractImporterService {

  protected keyaccountNameToUid$: Observable<{ [name: string]: string }>;

  protected constructor(
    protected keyaccountService: KeyaccountService,
  ) {

    this.keyaccountNameToUid$ = keyaccountService.getKeyAccountsForCurrentUsersGroup().pipe(
      map(keyaccounts => {
        const keyAccountNameMap = keyaccounts.reduce((accumulator, item) => {
          accumulator[item.name] = item.uid;
          return accumulator;
        }, {});
        return keyAccountNameMap;
      })
    );

  }

  abstract import(importData: any[]): Promise<ImportWarning[]>;


  protected formatWarnings(warnings: ImportWarning[]) {
    const aggregatedWarnings = warnings.reduce((acc, e) => {
      if (acc[e.message]) {
        acc[e.message] = [...acc[e.message], ...e.elements];
      } else {
        acc[e.message] = e.elements;
      }
      return acc;
    }, {});

    return Object.keys(aggregatedWarnings)
      .map(message => `${ message }: ${ aggregatedWarnings[message]
        .filter((value, index, list) => list.indexOf(value) === index) // unique
        .join(", ") }`
      ).join(". ");
  }
}
