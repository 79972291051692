import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector   : 'masterplanner2-finances',
  templateUrl: './finances.component.html',
  styleUrls  : ['./finances.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: FinancesComponent, multi: true}
  ]
})
export class FinancesComponent implements OnInit, OnDestroy, ControlValueAccessor {
  drives = [
    {id: 1, label: 'TAB3_DRIVEN_COST_DRIVEN'},
    {id: 2, label: 'TAB3_DRIVEN_VALUE_DRIVEN'},
    {id: 3, label: 'TAB3_DRIVEN_UNKNOWN'}
  ];
  @Input()
  keyaccountName: string;

  public form: FormGroup;

  onChangeSubscription: Subscription;
  onTouchedSubscription: Subscription;

  protected onChangeCallback: (any) => void;

  constructor(formBuilder: FormBuilder) {
    this.form = formBuilder.group({
      'drive'               : [],
      'maincosts'           : [],
      'yearFinancefigures'  : [],
      'grossoperatingincome': [],
      'netoperatingincome'  : [],
      'solvencypercentage'  : [],
    })
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe();
    }
    if (this.onTouchedSubscription) {
      this.onTouchedSubscription.unsubscribe();
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe()
    }
    this.onChangeSubscription = this.form.valueChanges.subscribe(value => fn(this.form.getRawValue()));
  }

  registerOnTouched(fn: any): void {
    if (this.onTouchedSubscription) {
      this.onTouchedSubscription.unsubscribe()
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.form[(isDisabled ? 'disable' : 'enable')]();
  }

  writeValue(items: any): void {
    if(!items) { // possibly a null / undefined value
      items = [];
    }

    this.form.patchValue(items, );
  }

}
