import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { ToasterService } from 'angular2-toaster';
import { UserService, User } from '@masterplanner2/users';
import { TranslationService, Locale } from '../translation/translation.service';

@Component({
  selector: 'masterplanner2-my-settings',
  templateUrl: './my-settings.component.html',
  styleUrls: ['./my-settings.component.css']
})
export class MySettingsComponent {

  form: FormGroup;
  languages$: Observable<Locale[]>;
  currentUser$: Observable<User>;

  constructor(
    formBuilder: FormBuilder,
    private userService: UserService,
    private toasterService: ToasterService,
    private translationService: TranslationService,
  ) {
    this.form = formBuilder.group({
      'uid'             : [],
      'phonenumber'     : [, Validators.required],
      'language'        : [, Validators.required],
    });

    this.currentUser$ = this.userService.getCurrentUser().pipe(
      tap(user => this.form.patchValue(user))
    );
    this.languages$ = this.translationService.getLocales();
  }

  doSave(){
    const uid = this.form.get('uid').value;
    this.userService.updateUser(uid, this.form.value)
    .then(()=> this.toasterService.pop('success', 'USER', 'updated succesfully.'))
    .catch(error => {
      console.error(error);
      this.toasterService.pop('error', 'USER', 'update failed.')
    });
  }
}
