/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./main-menu/main-menu.component.ngfactory";
import * as i3 from "./main-menu/main-menu.component";
import * as i4 from "../../../../libs/users/src/lib/user.service";
import * as i5 from "../../../../libs/users/src/lib/auth.service";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "../../../../node_modules/angular2-toaster/src/toaster-container.component.ngfactory";
import * as i9 from "angular2-toaster/src/toaster-container.component";
import * as i10 from "angular2-toaster/src/toaster.service";
import * as i11 from "./scroll-top/scroll-top.component.ngfactory";
import * as i12 from "./scroll-top/scroll-top.component";
import * as i13 from "@angular/platform-browser";
import * as i14 from "./app.component";
import * as i15 from "@ngx-translate/core";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "brandtab pull-right col-lg-7 toprow"], ["id", "userinfo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "masterplanner2-main-menu", [], null, null, null, i2.View_MainMenuComponent_0, i2.RenderType_MainMenuComponent)), i1.ɵdid(2, 114688, null, 0, i3.MainMenuComponent, [i4.UserService, i5.AuthService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["id", "appheader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "col-lg-5 toprow"], ["id", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["routerLink", "/keyaccountplans"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i7.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "notLight ng-scope"], ["src", "assets/images/logo_masterplanner.png"], ["style", "margin-left: -1px; margin-top: 1px"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_1)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["id", "viewarea"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(11, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "toaster-container", [], null, null, null, i8.View_ToasterContainerComponent_0, i8.RenderType_ToasterContainerComponent)), i1.ɵdid(13, 245760, null, 0, i9.ToasterContainerComponent, [i10.ToasterService, i1.ChangeDetectorRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "masterplanner2-scroll-top", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onWindowScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_ScrollTopComponent_0, i11.RenderType_ScrollTopComponent)), i1.ɵdid(15, 49152, null, 0, i12.ScrollTopComponent, [i13.DOCUMENT], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/keyaccountplans"; _ck(_v, 4, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.hasCurrentUser$)); _ck(_v, 7, 0, currVal_3); _ck(_v, 11, 0); _ck(_v, 13, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "masterplanner2-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 180224, null, 0, i14.AppComponent, [i15.TranslateService, i4.UserService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("masterplanner2-root", i14.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
