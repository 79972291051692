import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'masterplanner2-bundle-delete-dialog',
  templateUrl: './bundle-delete-dialog.component.html',
  styleUrls: ['./bundle-delete-dialog.component.css']
})
export class BundleDeleteDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<BundleDeleteDialogComponent>) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close(false);
  }

  delete() {
    this.dialogRef.close(true);
  }
}

