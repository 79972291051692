<div class="form-group col-sm-12 competitors">
  <table [formGroup]="form"
    id="competitors"
    class="table table-condensed competitors col-sm-12">
    <tr>
      <th>{{ 'CCOMPETITORNAME' | translate }}</th>
      <th>{{ 'CMAINPRODUCTS' | translate }}</th>
      <th>{{ 'CSTRENGTHS' | translate }}</th>
      <th>{{ 'CWEAKNESSES' | translate }}</th>
      <th>
        <button
          type="button"
          class="btn ng-scope no-btn success pull-right"
          (click)="addItem()"
          title="{{ 'NEW' | translate }}"
        >
          <i class="glyphicon glyphicon-plus "></i>
        </button>
      </th>
    </tr>
    <tr *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
      <td>
        <input
          type="text"
          class="form-control"
          formControlName="name"
        >
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          formControlName="mainProducts"
        >
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          formControlName="strengths"
        >
      </td>
      <td>
        <input
          type="text"
          class="form-control"
          formControlName="weaknesses"
        >
      </td>
      <td>
        <button type="button"
                class="btn ng-scope no-btn danger deletebutton "
                (click)="removeItem(i)"
                title="{{ 'DELETE' | translate }}"
        >
          <i class="glyphicon glyphicon-trash "></i>
        </button>
      </td>
    </tr>
  </table>
</div>
