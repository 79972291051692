import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, ConnectableObservable, Observable } from 'rxjs';
import { FeatureToggleValues, KeyaccountPlan, YearlyValues } from '../../../keyaccount-plan.model';
import { map, publishReplay, shareReplay } from 'rxjs/operators';
import { KeyaccountPlanService } from '../../../keyaccountplans/keyaccount-plan.service';
import { DmuService } from '../../../keyaccountplans/dmu.service';

@Component({
  selector: 'masterplanner2-score-dashboard',
  templateUrl: './score-dashboard.component.html',
  styleUrls: ['./score-dashboard.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoreDashboardComponent implements OnInit {

  @Input()
  public keyaccountPlans$: Observable<KeyaccountPlan[]>;
  public keyaccountPlansCalculated$: Observable<KeyaccountPlan & {score:{brickwall: number, sow: number, buyingpower: number, dmu: number}}[]>;

  public brickwallScore$: Observable<number>;
  public companyIndexScore$: Observable<number>;
  public sow$: Observable<number>;
  public dmuScore$: Observable<number>;
  public Object = Object;
  public Math = Math;
  public showScoreTable = false;

  @Input()
  featureToggles: FeatureToggleValues;


  constructor(private keyaccountPlanService: KeyaccountPlanService,
              private dmuService: DmuService) { }

  ngOnInit() {
    // @ts-ignore
    this.keyaccountPlansCalculated$ = this.keyaccountPlans$.pipe(
      map(keyaccountPlans => {
        const currentYear: number = new Date().getFullYear();

        return keyaccountPlans.map(plan => {
          return {
            ...plan,
            score: {
              brickwall: plan.brickwall ? this.getValueAsNumber(this.keyaccountPlanService.countBrickwall(plan.brickwall)): 0,
              sow: plan.sow[currentYear] ? this.getValueAsNumber(plan.sow[currentYear]) : 0,
              buyingpower: plan.buyingpowers ? this.keyaccountPlanService.calculateBuyingpowerTotals(plan.buyingpowers).companyIndex: 0,
              dmu: plan.dmus.contacts ? this.dmuService.calculateDmuScore(plan.dmus.contacts): 0,
            }
          };
        })
      }),
      shareReplay(1),
    );

    this.brickwallScore$ = this.getAvarageBrickwallScore();
    this.companyIndexScore$ = this.getAvarageCompanyIndexScore();
    this.sow$ = this.getAvarageSow();
    this.dmuScore$ = this.getAvarageDmuScore();

  }

  private getAvarageBrickwallScore(): Observable<number> {
    return this.keyaccountPlansCalculated$.pipe(
      map(keyaccountPlans => keyaccountPlans.map(plan => plan.score.brickwall)),
      map((brickwallScores: number[]) => brickwallScores.filter((brickwallScore: number) => brickwallScore > 0)),
      map((brickwallScores: number[]) => brickwallScores.reduce((acc, val) => acc + val, 0) / brickwallScores.length)
    );
  }
  
  private getAvarageCompanyIndexScore(): Observable<number> {
    return this.keyaccountPlansCalculated$.pipe(
      map(keyaccountPlans => keyaccountPlans.map( plan => plan.score.buyingpower)),
      map((companyIndexScores: number[]) => companyIndexScores.filter((companyIndexScore: number) => companyIndexScore > 0)),
      map((companyIndexScores: number[]) => companyIndexScores.reduce((acc, val) => acc + val, 0) / companyIndexScores.length)
    );
  }

  private getAvarageSow(): Observable<number> {
    const currentYear: number = new Date().getFullYear();

    return this.keyaccountPlansCalculated$.pipe(
      map(keyaccountPlans => keyaccountPlans.map(plan => plan.score.sow)),
      map((sows: number[]) => sows.filter((sow: number) => sow > 0)),
      map((sows: number[]) => sows.reduce((acc, val) => acc + val - 5, 0) / sows.length)
    );
  }

  private getAvarageDmuScore(): Observable<number> {
    return this.keyaccountPlansCalculated$.pipe(
      map(keyaccountPlans => keyaccountPlans.map(plan => plan.score.dmu)),
      map((dmuScores: number[]) => dmuScores.filter((dmuScore: number) => dmuScore > 0)),
      map((dmuScores: number[]) => dmuScores.reduce((acc, val) => acc + val, 0) / dmuScores.length)
    );
  }  

  private getValueAsNumber(v: any) {
    if(Array.isArray(v))
      return 0;
    if( typeof v === 'string')
      return parseInt(v, 10);
    return v;
  }
}
