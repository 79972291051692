import { Component, OnInit } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";
import { AbstractFormArrayListComponent } from "../abstract-form-array-list/abstract-form-array-list.component";

@Component({
  selector   : 'masterplanner2-stakeholders',
  templateUrl: './stakeholders.component.html',
  styleUrls  : ['./stakeholders.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: StakeholdersComponent, multi: true}
  ]
})
export class StakeholdersComponent extends AbstractFormArrayListComponent {

  createItemControls(): FormGroup {
    return this.formBuilder.group({
      'uid'                 : [, Validators.required],
      'firstname'           : [, Validators.required],
      'prefix'              : [],
      'surname'             : [, Validators.required],
      'function'            : [, Validators.required],
      'role'                : [],
      'externalorganisation': [],
    })
  }
}
