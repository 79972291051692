export const OrderTypeMap = {
  '1. Service Contract':'Onderhoud & Beheer',
  '2. Cluster Contract':'Onderhoud & Beheer',
  '3. Onderhouds Contract':'Onderhoud & Beheer',
  '4. Project':'Projecten',
  '5. Elektriciteit':'Energie',
  '6. Gas':'Energie',
  '7. Overig':'Overig',
  '':'Overig'
};
