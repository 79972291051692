import { Component, OnInit, Input } from '@angular/core';
import { AbstractFormArrayListComponent } from "../../profile/abstract-form-array-list/abstract-form-array-list.component";
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";

@Component({
  selector   : 'masterplanner2-projects',
  templateUrl: './projects.component.html',
  styleUrls  : ['./projects.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: ProjectsComponent, multi: true}
  ]
})
export class ProjectsComponent extends AbstractFormArrayListComponent implements OnInit {
  @Input()
  title: string;
  
  parentForm: FormGroup;
  statuses = ['WON', 'LOST'];

  constructor(protected formBuilder: FormBuilder) {
    super(formBuilder);
  }

  ngOnInit() {
    super.ngOnInit();
    this.parentForm = this.createForm();
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      projects: this.form,
      swotType: [,Validators.required],
      swotReason: [,Validators.required],
    })
  }

  createItemControls(): FormGroup {
    return this.formBuilder.group({
      'uid':             [, Validators.required],
      'opportunityName': [, Validators.required],
      'status':          [, Validators.required],
      'statusReason':    [, Validators.required],
      'expiryDate':      [, Validators.required], 
    });
  }

  setDisabledState(isDisabled: boolean): void {
    this.parentForm[isDisabled ? 'disable' : 'enable']({emitEvent: false});
    super.setDisabledState(isDisabled);
  }

  registerOnChange(fn: any): void {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe()
    }
    this.onChangeSubscription = this.parentForm.valueChanges.subscribe(value => fn(value));
  }

  writeValue(items: any): void {
    if (!items) {
      items = [];
    }
    super.writeValue(items.projects);
    this.parentForm.patchValue(items);
  }
}
