import { Injectable } from '@angular/core';
import { AbstractTabComponent } from './profile/abstract-tab.component';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateTabGuard implements CanDeactivate<AbstractTabComponent> {
  canDeactivate(tabComponent: AbstractTabComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(!tabComponent.canDeactivate()) {
     return confirm("You have changes in the keyaccountplan. If you continue your changes will be lost");
    }
    return true;
  }

  constructor() { }
}
