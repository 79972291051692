<table
  id="dmus"
  [formGroup]="parentForm"
  class="table table-condensed buyingpowerindices col-sm-12">
  <thead>
    <tr>
      <th colspan="5"> {{ 'DMU_TABLE_HEADER' | translate }} </th>
    </tr>
    <tr>
      <th> {{ 'DMUS_NAME' | translate }} </th>
      <th> {{ 'DMUS_FUNCTION' | translate }} </th>
      <th> {{ 'DMUS_ROLE' | translate }} </th>
      <th> {{ 'DMUS_ATTITUDE' | translate }} </th>
      <th> {{ 'DMUS_VISITINGINTENSITY' | translate }} </th>
    </tr>
  </thead>
  <!-- foreach DMU -->
  <tbody formArrayName="contacts">
    <tr *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
      <td class="col-sm-5 dmu-name">
        <input
          name="cFirstname"
          type="text"
          class="form-control"
          formControlName="firstname"
        >
        <input
          name="cPrefix"
          type="text"
          class="form-control"
          formControlName="prefix"
        >
        <input
          name="cSurname"
          type="text"
          class="form-control"
          formControlName="surname"
        >
      </td>
      <td>
        <input
          name="cFunction"
          type="text"
          class="form-control"
          formControlName="function"
        >
      </td>
      <td>
        <select class="form-control"
                id="role"
                formControlName="role"
        >
                <option *ngFor="let role of dmuRoles" 
                        [value]="role.name">
                  {{ role.name | translate }}
                </option>
        </select>
      </td>
      <td>
        <select class="form-control"
                formControlName="attitude"
                id="attitude"
                [ngClass]="row.get('attitude').value ? row.get('attitude').value.toLowerCase() : ''"
        >
                <option *ngFor="let attitude of attitudes"
                        [value]="attitude.name">
                  {{ attitude.name | translate }}
                </option>
        </select>
      </td>
      <td>
        <select class="form-control"
                id="visitingIntensity"
                formControlName="visitingIntensity"
                [ngClass]="row.get('visitingIntensity').value ? row.get('visitingIntensity').value.toLowerCase() : ''"
        >
                <option *ngFor="let intensity of visitingIntensities" 
                        [value]="intensity.name">
                  {{ intensity.name | translate }}
                </option>
        </select>
      </td>
    </tr>
  </tbody>
</table>
<div class="col-sm-12">
  <div class="row" [formGroup]="parentForm">
    <div class="col-sm-4 form-group">
      <label for="swotTypeSW" class="control-label">{{ 'QUALIFICATION' | translate }}</label>
      <masterplanner2-swot-type formControlName="swotTypeSW" [mode]="'SW'" name="swotTypeSW"></masterplanner2-swot-type>
    </div>
    <div class="col-sm-6 form-group">
      <label for="swotTypeSW" class="control-label">{{ 'REASON' | translate }}</label>
      <input type="text" class="form-control" formControlName="swotReasonSW">
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <h2 class="total-dmu-score">
        {{ 'DMUSCORE' | translate }} : {{ dmuScore$ | async }}
      </h2>      
    </div>
    <div class="col-sm-6">
      <h2 class="total-dmu-score">
        {{ 'DMUTOTALVISITS' | translate }} : {{ dmuVisits$ | async }}
      </h2>      
    </div>
  </div>
</div>
