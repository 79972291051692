<form name="editForm" id="editForm" class="form-horizontal  col-sm-12 " [formGroup]="form" (submit)="doSave()" role="form">
  <div class="panel panel-primary">
    <div class="sw-heading col-sm-12 panel-heading">
      <div class="pull-left"><h2>{{ 'Customer' | uppercase | translate }}</h2></div>
    </div>
    <div class="form-group">
      <div class="form-group">
        <label for="cCustomername" class="col-sm-2 control-label">{{ 'CCUSTOMERNAME' | translate }}</label>
        <div class="col-sm-4">
          <input type="text"
                 class="form-control"
                 id="cCustomername"
                 placeholder=""
                 formControlName="name"
          >
        </div>
        <label for="cContact" class="col-sm-2 control-label">{{ 'CCONTACT' | translate }}</label>
        <div class="col-sm-4">
          <input type="text"
                 class="form-control"
                 id="cContact"
                 placeholder=""
                 formControlName="contact"
          >
        </div>
      </div>
      <div class="form-group">
        <label for="cAddress" class="col-sm-2 control-label">{{ 'CADDRESS' | translate }}</label>
        <div class="col-sm-4">
          <input type="text"
                 class="form-control"
                 id="cAddress"
                 placeholder=""
                 formControlName="address"
          >
        </div>
        <label for="cPostalcode" class="col-sm-2 control-label">{{ 'CPOSTALCODE' | translate }}</label>
        <div class="col-sm-4">
          <input type="text"
                 class="form-control"
                 id="cPostalcode"
                 placeholder=""
                 formControlName="zipcode"
          >
        </div>
      </div>
      <div class="form-group">
        <label for="cCity" class="col-sm-2 control-label">{{ 'CCITY' | translate }}</label>
        <div class="col-sm-4">
          <input type="text"
                 class="form-control"
                 id="cCity"
                 placeholder=""
                 formControlName="city"
          >
        </div>
        <label for="cCountry" class="col-sm-2 control-label">{{ 'CCOUNTRY' | translate }}</label>
        <div class="col-sm-4">
          <input type="text"
                 class="form-control"
                 id="cCountry"
                 placeholder=""
                 formControlName="country"
          >
        </div>
      </div>
      <div class="form-group">
        <label for="cPhone" class="col-sm-2 control-label">{{ 'CPHONE' | translate }}</label>
        <div class="col-sm-4">
          <input type="text"
                 class="form-control"
                 id="cPhone"
                 placeholder=""
                 formControlName="phonenumber"
          >
        </div>
      </div>
      <div class="form-group">
        <label for="cLogo" class="col-sm-2 control-label">{{ 'CLOGO' | translate }}</label>
        <div class="col-sm-4">
          <masterplanner2-image-cropper *ngIf="form.get('uid').value" [path]="'customer/' + form.get('uid').value + '/images/logo'" ></masterplanner2-image-cropper>
          <div class="logo pull-left">
          </div>
        </div>
      </div>

      <div class="form-group col-sm-12">
        <div class="separator col-sm-12">
          {{ 'TOGGLES' | translate }}
        </div>
        <div class="col-sm-12">
          <div class="col-sm-6">{{ 'TITLE_FEATURE_TOGGLES' | translate }}</div>
          <div class="col-sm-6">{{ 'TITLE_STRATEGIC_FEATURE_TOGGLES' | translate }}</div>
          <div class="col-md-6">
            <masterplanner2-feature-toggles formControlName="featureToggles"></masterplanner2-feature-toggles>
          </div>
          <div class="col-md-6">
            <masterplanner2-feature-toggles formControlName="strategicFeatureToggles"></masterplanner2-feature-toggles>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <div class="separator col-sm-12">
          {{ 'BRANDS' | translate }}
        </div>
        <div class="col-sm-12">
          <ul class="media-list brand-list">
            <li *ngFor="let keyaccount of (keyaccounts$ | async)" class="media col-sm-3">
              <span class="media-left" style="display: block">
                <masterplanner2-image-preview [path]="'keyaccount/' + keyaccount.uid + '/images/logo'"></masterplanner2-image-preview>
                <h4 class="media-heading">{{ keyaccount.name }}</h4>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div class="form-group col-sm-12" *ngIf="hasBrickwallEnabled()">
        <div class="separator col-sm-12" (click)="togglePanel('brickwallCriteria')">
          <span [innerHTML]="'BRICKWALLCRITERIUMS' | translate"></span>
          <span>
                        (<span>{{ domainCounts['brickwallCriteria'] | async }}</span>)&nbsp;
                        <i class="glyphicon"
                           [ngClass]="{
                               'glyphicon-plus' : (isPanelCollapsed('brickwallCriteria') | async),
                               'glyphicon-minus' : !(isPanelCollapsed('brickwallCriteria') | async)
                            }"
                        ></i>
                    </span>
        </div>
        <masterplanner2-brickwall formControlName="brickwallCriteria"></masterplanner2-brickwall>
      </div>

      <div class="form-group col-sm-12">
        <div class="separator col-sm-12" (click)="togglePanel('servicelines')">
          <span > {{ 'SERVICELINES' | translate }}</span>
          <span>
                        (<span > {{ domainCounts['servicelines'] | async }} </span>)
                    </span>
          <span>
                        <i class="glyphicon"
                           [ngClass]="{
                               'glyphicon-plus' : (isPanelCollapsed('servicelines') | async),
                               'glyphicon-minus' : !(isPanelCollapsed('servicelines') | async)
                            }"
                        ></i>
                    </span>
        </div>
        <div class="col-sm-12" *ngIf="!(isPanelCollapsed('servicelines') | async)">
          <table formArrayName="servicelines" id="servicelines" class="table table-striped table-bordered servicelines">
            <tr>
              <th class="col-sm-6"> {{ 'CDESCRIPTION' | translate }}</th>
              <th class="col-sm-1"> {{ 'IORDER' | translate }}</th>
              <th class="col-sm-1">
                <button
                  type="button"
                  class="btn no-btn success"
                  (click)="addDomainValue('servicelines')"
                  title="{{ 'NEW' | translate }}"
                >
                  <i class="glyphicon glyphicon-plus "></i>
                </button>
              </th>
            </tr>
            <tr *ngFor="let row of getDomainControls('servicelines'); let i = index;" [formGroupName]="i" >
              <td class="form-group">
                <input type="text"
                       class="form-control"
                       formControlName="description"
                >
              </td>
              <td class="form-group">
                <input type="number"
                       class="form-control"
                       formControlName="order"
                >
              </td>
              <td>
                <button type="button"
                        class="btn no-btn danger deletebutton "
                        (click)="removeDomainvalue('servicelines', i)"
                        title="{{ 'DELETE' | translate }}"
                >
                  <i class="glyphicon glyphicon-trash "></i>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <div class="separator col-sm-12" (click)="togglePanel('industries')">
          <span>{{ 'INDUSTRIES' | translate }}</span>
          <span>
                        (<span> {{ domainCounts['industries'] | async }}</span>)
          </span>
          <span>
                        <i class="glyphicon"
                           [ngClass]="{
                               'glyphicon-plus' :  (isPanelCollapsed('industries') | async),
                               'glyphicon-minus' : !(isPanelCollapsed('industries') | async)
                            }"
                        ></i>
                    </span>

        </div>
        <div class="col-sm-12" *ngIf="!(isPanelCollapsed('industries') | async)">
          <table formArrayName="industries" id="industries" class="table table-striped table-bordered industries">
            <tr>
              <th class="col-sm-6"> {{ 'CDESCRIPTION' | translate }}</th>
              <th class="col-sm-1"> {{ 'IORDER' | translate }}</th>
              <th class="col-sm-1">
                <button
                  type="button"
                  class="btn no-btn success"
                  (click)="addDomainValue('industries')"
                  title="{{ 'NEW' | translate }}"
                >
                  <i class="glyphicon glyphicon-plus "></i>
                </button>
              </th>
            </tr>
            <tr *ngFor="let row of getDomainControls('industries'); let i = index;" [formGroupName]="i" >
              <td class="form-group">
                <input type="text"
                       class="form-control"
                       formControlName="description"
                >
              </td>
              <td class="form-group">
                <input type="number"
                       class="form-control"
                       formControlName="order"
                >
              </td>
              <td>
                <button type="button"
                        class="btn no-btn danger deletebutton "
                        (click)="removeDomainvalue('industries', row)"
                        title="{{ 'DELETE' | translate }}"
                >
                  <i class="glyphicon glyphicon-trash "></i>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <div class="separator col-sm-12" (click)="togglePanel('companytypes')">
          <span>{{ 'COMPANYTYPES' | translate }}</span>
          <span>
                        (<span>{{ domainCounts['companytypes'] | async }}</span>)
                    </span>
          <span>
                        <i class="glyphicon"
                           [ngClass]="{
                               'glyphicon-plus' : (isPanelCollapsed('companytypes')  | async),
                               'glyphicon-minus' : !(isPanelCollapsed('companytypes')  | async)
                            }"
                        ></i>
                    </span>
        </div>
        <div class="col-sm-12" *ngIf="!(isPanelCollapsed('companytypes') | async)">
          <table formArrayName="companytypes" id="companytypes" class="table table-striped table-bordered companytypes">
            <tr>
              <th class="col-sm-6"> {{ 'CDESCRIPTION' | translate }}</th>
              <th class="col-sm-1"> {{ 'IORDER' | translate }}</th>
              <th class="col-sm-1">
                <button
                  type="button"
                  class="btn no-btn success"
                  (click)="addDomainValue('companytypes')"
                  title="{{ 'NEW' | translate }}"
                >
                  <i class="glyphicon glyphicon-plus "></i>
                </button>
              </th>
            </tr>
            <tr *ngFor="let row of getDomainControls('companytypes'); let i = index;" [formGroupName]="i" >
              <td class="form-group">
                <input type="text"
                       class="form-control"
                       formControlName="description"
                >
              </td>
              <td class="form-group">
                <input type="number"
                       class="form-control"
                       formControlName="order"
                >
              </td>
              <td>
                <button type="button"
                        class="btn no-btn danger deletebutton "
                        (click)="removeDomainvalue('companytypes', row)"
                        title="{{ 'DELETE' | translate }}"
                >
                  <i class="glyphicon glyphicon-trash "></i>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <div class="separator col-sm-12" (click)="togglePanel('buyingbehaviors')">
          <span> {{ 'BUYINGBEHAVIORS' | translate }}</span>
          <span >
                        (<span> {{ domainCounts['buyingbehaviors'] | async }}</span>)
                    </span>
          <span>
                        <i class="glyphicon"
                           [ngClass]="{
                               'glyphicon-plus' : (isPanelCollapsed('buyingbehaviors') | async),
                               'glyphicon-minus' : !(isPanelCollapsed('buyingbehaviors') | async)
                            }"
                        ></i>
                    </span>

        </div>
        <div class="col-sm-12" *ngIf="!(isPanelCollapsed('buyingbehaviors') | async)">
          <table formArrayName="buyingbehaviors" id="buyingbehaviors" class="table table-striped table-bordered buyingbehaviors">
            <tr>
              <th class="col-sm-6"> {{ 'CDESCRIPTION' | translate }}</th>
              <th class="col-sm-1"> {{ 'IORDER' | translate }}</th>
              <th class="col-sm-1">
                <button
                  type="button"
                  class="btn no-btn success"
                  (click)="addDomainValue('buyingbehaviors')"
                  title="{{ 'NEW' | translate }}"
                >
                  <i class="glyphicon glyphicon-plus "></i>
                </button>
              </th>
            </tr>
            <tr *ngFor="let row of getDomainControls('buyingbehaviors'); let i = index;" [formGroupName]="i" >
              <td class="form-group">
                <input type="text"
                       class="form-control"
                       formControlName="description"
                >
              </td>
              <td class="form-group">
                <input type="number"
                       class="form-control"
                       formControlName="order"
                >
              </td>
              <td>
                <button type="button"
                        class="btn no-btn danger deletebutton "
                        (click)="removeDomainvalue('buyingbehaviors', row)"
                        title="{{ 'DELETE' | translate }}"
                >
                  <i class="glyphicon glyphicon-trash "></i>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <div class="separator col-sm-12" (click)="togglePanel('buyingcriteria')">
          <span> {{ 'BUYINGCRITERIA' | translate }}</span>
          <span >
                        (<span> {{ domainCounts['buyingcriteria'] | async }}</span>)
                    </span>
          <span>
                        <i class="glyphicon"
                            [ngClass]="{
                                'glyphicon-plus' : (isPanelCollapsed('buyingcriteria') | async),
                                'glyphicon-minus' : !(isPanelCollapsed('buyingcriteria') | async)
                            }"
                        ></i>
                    </span>

        </div>
        <div class="col-sm-12" *ngIf="!(isPanelCollapsed('buyingcriteria') | async)">
          <table formArrayName="buyingcriteria" id="buyingcriteria" class="table table-striped table-bordered buyingcriteria">
            <tr>
              <th class="col-sm-6"> {{ 'CDESCRIPTION' | translate }}</th>
              <th class="col-sm-1"> {{ 'IORDER' | translate }}</th>
              <th class="col-sm-1">
                <button
                  type="button"
                  class="btn no-btn success"
                  (click)="addDomainValue('buyingcriteria')"
                  title="{{ 'NEW' | translate }}"
                >
                  <i class="glyphicon glyphicon-plus "></i>
                </button>
              </th>
            </tr>
            <tr *ngFor="let row of getDomainControls('buyingcriteria'); let i = index;" [formGroupName]="i" >
              <td class="form-group">
                <input type="text"
                        class="form-control"
                        formControlName="description"
                >
              </td>
              <td class="form-group">
                <input type="number"
                        class="form-control"
                        formControlName="order"
                >
              </td>
              <td>
                <button type="button"
                        class="btn no-btn danger deletebutton "
                        (click)="removeDomainvalue('buyingcriteria', row)"
                        title="{{ 'DELETE' | translate }}"
                >
                  <i class="glyphicon glyphicon-trash "></i>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!--<div class="form-group col-sm-12">-->
        <!--<div class="separator col-sm-12" (click)="togglePanel('customerbpis')">-->
          <!--<span> {{ 'CUSTOMERBPIS' | translate }}</span>-->
          <!--<span> (<span>{{ (customerbpis$ | async).length }}</span>) </span>-->
          <!--<span>-->
                        <!--<i class="glyphicon"-->
                           <!--[ngClass]="{-->
                               <!--'glyphicon-plus' : (isPanelCollapsed('customerbpis') | async),-->
                               <!--'glyphicon-minus' : !(isPanelCollapsed('customerbpis') | async)-->
                            <!--}"-->
                        <!--&gt;</i>-->
                <!--</span>-->
        <!--</div>-->
        <!--<div class="col-sm-12" *ngIf="isPanelCollapsed('customerbpis') | async">-->
          <!--<table id="customerbpis" class="table table-striped table-bordered customerbpis">-->
            <!--<tr>-->
              <!--<th class="col-sm-9"> {{ 'CDESCRIPTION' | translate }}</th>-->
              <!--<th class="col-sm-1 text-center"> {{ 'LOPEN' | translate }}</th>-->
              <!--<th class="col-sm-1"> {{ 'IORDER' | translate }}</th>-->
              <!--<th class="col-sm-1">-->
                <!--<button-->
                  <!--type="button"-->
                  <!--class="btn no-btn success"-->
                  <!--(click)="addDomainvalue('customerbpis')"-->
                  <!--title="{{ 'NEW' | translate }}"-->
                <!--&gt;-->
                  <!--<i class="glyphicon glyphicon-plus "></i>-->
                <!--</button>-->
              <!--</th>-->
            <!--</tr>-->
            <!--<tr *ngFor="let row of (customerbpis$ | async)">-->
              <!--<td class="form-group">-->
                <!--<input type="text"-->
                       <!--class="form-control"-->
                <!--&gt;-->
              <!--</td>-->
              <!--<td class="form-group  text-center">-->
                <!--<button type="button"-->
                        <!--class="btn btn-default btn-iToppriority btn-toggle "-->
                        <!--title="{{ 'ITOPPRIORITY' | translate }}"-->
                <!--&gt;-->
                  <!--<i class="glyphicon "-->
                     <!--[class]="-->
                            <!--{-->
                                <!--'glyphicon-ok' : row.lOpen == 1,-->
                                <!--'glyphicon-remove' : row.lOpen == 0-->
                            <!--}-->
                       <!--">-->
                  <!--</i>-->
                <!--</button>-->
              <!--</td>-->
              <!--<td class="form-group">-->
                <!--<input type="number"-->
                       <!--class="form-control"-->
                <!--&gt;-->
              <!--</td>-->
              <!--<td>-->
                <!--<button type="button"-->
                        <!--class="btn no-btn danger deletebutton "-->
                        <!--(click)="removeDomainvalue('oCustomerbpis', row)"-->
                        <!--title="{{ 'DELETE' | translate }}"-->
                <!--&gt;-->
                  <!--<i class="glyphicon glyphicon-trash "></i>-->
                <!--</button>-->
              <!--</td>-->
            <!--</tr>-->
          <!--</table>-->
        <!--</div>-->
      <!--</div>-->
    </div>
  </div>
  <button type="submit" [disabled]="!form.valid" class="btn btn-primary">Save</button>

</form>
