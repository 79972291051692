<div class="row">
  <div class="col-sm-12">
  <table width="100%">
    <thead>
    <th></th>
    <th *ngFor="let year of yearSpan" class="text-center">{{ year }}</th>
    </thead>
    <tbody *ngIf="featureToggles?.orderIntakeTarget" masterplanner2-bundle-revenue [keyaccountPlans$]="keyaccountPlans$" [yearSpan]="yearSpan" property="orderIntakeTarget"></tbody>
    <tbody *ngIf="featureToggles?.orderIntake" masterplanner2-bundle-revenue [keyaccountPlans$]="keyaccountPlans$" [yearSpan]="yearSpan" property="orderIntake"></tbody>
    <tbody *ngIf="featureToggles?.orderBacklog" masterplanner2-bundle-revenue [keyaccountPlans$]="keyaccountPlans$" [yearSpan]="yearSpan" property="orderBacklog"></tbody>
    <tbody masterplanner2-bundle-revenue [keyaccountPlans$]="keyaccountPlans$" [yearSpan]="yearSpan" property="targetRevenue"></tbody>
    <tbody masterplanner2-bundle-revenue [keyaccountPlans$]="keyaccountPlans$" [yearSpan]="yearSpan" property="customerRevenue"></tbody>
  </table>
  </div>
</div>


