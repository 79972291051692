import { Directive, ElementRef, OnInit, OnDestroy, Input } from '@angular/core';
import { UserService } from '@masterplanner2/users';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Directive({
  selector: '[localeFile]'
})
export class LocaleFileDirective implements OnInit, OnDestroy {

  element: HTMLElement;
  languageSubscription: Subscription;

  @Input()
  localeFile : string;

  constructor(private elementRef: ElementRef, private userService: UserService) {
    this.element = elementRef.nativeElement;
  }

  ngOnInit() {
    this.languageSubscription = this.userService.getCurrentUserMainLanguage().pipe(
      tap(newLanguage => {
        if (this.localeFile) {
          const attributeMap = { 'A': 'href', 'IMG': 'src' };
          const attributeName = attributeMap[this.element.tagName];

          if (attributeName) {
            const split = this.localeFile.split('.');
            const extension = split.pop();
            const fileName = split.join().replace(/,/g, '.');

            this.element.setAttribute(attributeName, fileName + '_' + newLanguage + '.' + extension)
          } else {
            console.error('localeFile directive only works on Anchors and IMGs');
          }
        } else {
          console.error('localeFile cannot find attribute "url"');
        }
      })
    ).subscribe();
  }

  ngOnDestroy(){
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

}
