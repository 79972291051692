<div class="col-md-6 col-md-offset-3" *ngIf="hasActivationCode$ | async; else login">
  <div *ngIf="loginResult$ | async as loginResult">Login result {{ loginResult | json }}</div>
  <div *ngIf="(emailFromStorage$ | async) && !loginResult">Logging in...</div>
  <div *ngIf="!(emailFromStorage$ | async) && !loginResult">
    <h2>Login</h2>
    <div *ngIf="hasActivationCode$ | async"> Please re-confirm your email address</div>
    <form name="form" [formGroup]="form" (submit)="submitLogin()" role="form" >
      <div class="form-group">
        <label for="email">Email</label>
        <input type="text" name="email" id="email" class="form-control" formControlName="email" autocomplete="off" >
        <span class="help-block ng-hide">Email is required</span>
      </div>
      <div class="form-actions">
        <button type="submit" [disabled]="!form.valid" class="btn btn-primary">Login</button>
      </div>
    </form>
  </div>
</div>

<ng-template #login>
  <div class="col-md-6 col-md-offset-3">
    <h2>Login</h2>
    <div *ngIf="didSendEmail$ | async; else loginForm">
      <p>Email is send</p>
      <button  class="btn btn-primary" (click)="tryAgain()">Resend email</button>
    </div>
  </div>
</ng-template>

<ng-template #loginForm>
  <form name="form" [formGroup]="form" (submit)="submitEmail()" role="form" >
    <div class="form-group">
      <label for="email">Email</label>
      <input type="text" name="email" id="email" class="form-control" formControlName="email" autocomplete="off" >
      <span class="help-block ng-hide">Email is required</span>
    </div>
    <div class="form-actions">
      <button type="submit" [disabled]="!form.valid" class="btn btn-primary">Send email</button>
    </div>
  </form>
</ng-template>
