<div class="panel panel-primary">
    <div class="sw-heading col-xs-12 panel-heading">
        <div class="pull-left col-xs-8">
            <h2> {{ 'CREATE_NEW_KEYACCOUNTPLAN' | translate }} </h2>
        </div>
    </div>
    <form [formGroup]="form" (submit)="doSave()">
        <div class="row">
            <div class="col-xs-6" style="padding-left:15px;">
                <div class="form-group">
                    <p class="info-text">{{ 'CREATE_NEW_MASTERPLANNER_EXPLANATION_' | translate }}</p>

                    <label for="customer">
                        {{ 'CHOOSE_CUSTOMER_FOR_NEW_MASTERPLANNER' | translate }}
                    </label>
                    <select class="form-control"
                            name="customer"
                            formControlName="customer">
                        <option *ngFor="let customer of customers$ | async" [ngValue]="customer">{{ customer.name }}</option>
                    </select>

                    <label for="keyaccount">
                        {{ 'CHOOSE_KEYACCOUNT_FOR_NEW_MASTERPLANNER' | translate }}
                    </label>
                    <select class="form-control"
                            name="keyaccount"
                            formControlName="keyaccount">
                        <option *ngFor="let keyaccount of keyaccounts$ | async" [ngValue]="keyaccount">{{ keyaccount.name }}</option>
                    </select>

                    <label for="keyaccountManager">
                        {{ 'CHOOSE_KEYACCOUNT_MANAGER_FOR_NEW_MASTERPLANNER' | translate }}
                    </label>              
                    <select class="form-control"
                            name="keyaccountManager"
                            formControlName="keyaccountManager">
                        <option *ngFor="let user of managers$ | async" [ngValue]="user">{{ user.name }}</option>
                    </select>
                </div>
            </div>     
        </div><!--row-->
        <div class="row">
            <div class="col-xs-12">
                <div class="row">
                    <div class="col-xs-2">
                        <label for="targetYear">
                            {{ 'INEWTARGETYEAR' | translate }}
                        </label>
                    </div>
                    <div class="col-xs-5">
                        <label for="version">
                            {{ 'CNEWVERSION' | translate }}
                        </label>                    
                    </div>
                </div>
            </div>
            <div class="col-xs-2">
                <div class="form-group">
                    <select class="form-control"
                            name="targetYear"
                            formControlName="targetYear">
                        <option *ngFor="let year of targerYears" [ngValue]="year">{{ year }}</option>
                    </select>
                </div>
            </div>
            <div class="col-xs-2">
                <div class="form-group">
                    <div class="input-group">
                        <span class="input-group-addon">{{ form.get('targetYear').value }}</span>
                        <input  type="text" 
                                name="version"
                                class="form-control" 
                                formControlName="version">
                    </div>
                </div>
            </div>
            <div class="col-xs-12">
                <button type="submit" class="btn btn-success" [disabled]="!form.valid">{{ 'SAVE' | translate }}</button>
            </div>
        </div>
    </form> 
</div>
    