import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import UserCredential = firebase.auth.UserCredential;
import { ToasterService } from 'angular2-toaster';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { UserModuleConfig } from '../users-module-config';

@Component({
  selector: 'masterplanner2-email-login',
  templateUrl: './email-login.component.html',
  styleUrls: ['./email-login.component.css']
})
export class EmailLoginComponent implements OnInit {

  form: FormGroup;
  routeQueryMap: Observable<ParamMap>;
  hasActivationCode$: Observable<boolean>;
  emailFromStorage$: Observable<string | null>;
  loginResult$: Observable<boolean>;
  didSendEmail$ = new BehaviorSubject(false);

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toasterService: ToasterService,
    private userModuleConfig: UserModuleConfig,
    protected localStorage: LocalStorage) {

    this.form = formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])]
    });

    this.routeQueryMap = activatedRoute.queryParamMap;
    const requiredParams = ['oobCode', 'mode', 'apiKey'];
    // apiKey=abc&oobCode=xyz&mode=signIn&lang=en
    this.hasActivationCode$ = this.routeQueryMap.pipe(map(paramMap =>
      (paramMap.has('oobCode') && paramMap.has('mode') && paramMap.has('apiKey'))
    ));

    this.emailFromStorage$ = <Observable<string>>localStorage.getItem('emailForSignIn');


    this.loginResult$ = combineLatest(this.hasActivationCode$, this.emailFromStorage$).pipe(
      first(),
      switchMap(([hasActivationCode, emailFromStorage]) => {
        if(hasActivationCode && emailFromStorage) {
          return of(this.doLogin(this.router.url, emailFromStorage)).pipe(map(credentials => !!credentials));
        } else {
          return of(false);
        }
      })
    );
  }

  ngOnInit() {
  }

  submitEmail(): Promise<void> {
    const email = this.form.get('email').value;
    return this.sendLoginToEmail(email);
  }

  submitLogin(): Promise<UserCredential | void> {
    const email = this.form.get('email').value;
    return this.doLogin(this.router.url, email);
  }

  tryAgain() {
    this.didSendEmail$.next(false);
  }

  private sendLoginToEmail(email) {
    return this.authService.sendLoginEmail(email)
      .then(() => {
        this.toasterService.pop('success', 'Login email send', 'Follow the link you received in your mailbox');
        this.didSendEmail$.next(true);
        this.localStorage.setItem('emailForSignIn', email).toPromise();
      })
      .catch(err => {
        console.error(err);
        this.toasterService.pop('error', 'Error sending email. This can happen if the email is unknown');
      })
    ;
  }

  private doLogin(url, email): Promise<UserCredential | void> {
    return this.authService.handleLoginWith(url, email)
      .then((credentials) => {
        this.router.navigateByUrl(this.userModuleConfig.redirectAfterLogin);
        this.localStorage.removeItem('emailForSignIn').toPromise();
        return credentials;
      })
      .catch(err => {
        this.localStorage.removeItem('emailForSignIn').toPromise();
        if (err) {
          switch (err.code) {
            case 'auth/invalid-action-code':
              this.toasterService.pop('error', 'Invalid action code. This can happen if the code is malformed, expired, or has already been used.');
              this.router.navigateByUrl('/login');
              return;
          }
        }
        console.log(err);
        this.toasterService.pop('error', 'Error logging in.');

      })
  }

}
