<form name="editForm" id="editForm" class="form-horizontal col-sm-12" [formGroup]="form" (submit)="doSave()" role="form">
  <div class="panel panel-primary">
    <div class="sw-heading col-sm-12 panel-heading">
      <div class="pull-left"><h2>{{ 'CREATEUSER' | uppercase | translate }}</h2>
      </div>
    </div>
      <div class="form-group">
        <label for="name" class="col-sm-2 control-label">{{ 'CNAME' | translate }}</label>
        <div class="col-sm-4">
          <input type="text"
                 class="form-control"
                 id="name"
                 formControlName="name"
                 required
          >
        </div>
      </div>
      <div class="form-group">
        <label for="cEmail" class="col-sm-2 control-label">{{ 'CEMAIL' | translate }}</label>
        <div class="col-sm-4">
          <input type="text"
                 class="form-control"
                 id="cEmail"
                 formControlName="email"
                 [disabled]="isEditing$ | async"
                 [readonly]="isEditing$ | async"
                 placeholder=""
          >
        </div>
        <label for="cTelefoon" class="col-sm-2 control-label">{{ 'CTELEFOON' | translate }}</label>
        <div class="col-sm-4">
          <input type="text"
                 class="form-control"
                 id="cTelefoon"
                 formControlName="phonenumber"
                 placeholder=""
          >
        </div>
      </div>
      <div class="form-group">
        <label for="idCustomer" class="col-sm-2 control-label">{{ 'CUSTOMER' | translate }}</label>
        <div class="col-sm-4">
          <ng-select [items]="groups$ | async"
                     id="idCustomer"
                     placeholder="Select company"
                     bindValue="uid"
                     bindLabel="name"
                     clearAllText="Clear"
                     formControlName="groupUid">
          </ng-select>
        </div>
        <label for="cLanguage" class="col-sm-2 control-label">{{ 'CLANGUAGE' | translate }}</label>
        <div class="col-sm-4">
          <select name="cLanguage" formControlName="language" class="form-control">
            <option *ngFor="let language of languages$ | async" [value]="language.code">{{ language.name }}</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label for="jProfiles" class="col-sm-2 control-label">{{ 'JPROFILES' | translate }}</label>
        <div class="col-sm-4">
          <ng-select [items]="roles$ | async"
                     id="jProfiles"
                     [multiple]="true"
                     placeholder="Select roles"
                     bindValue="value"
                     clearAllText="Clear"
                     formControlName="roles">
          </ng-select>
        </div>
        <div class="col-sm-6" style="min-height:40px; margin-bottom: 10px;">
          <div *ngIf="isAccountManager() | async">
            <label for="jBrands" class="col-sm-4 control-label">{{ 'JBRANDS' | translate }}</label>
            <div class="col-sm-8">
              <ng-select [items]="keyaccounts$ | async"
                         [multiple]="true"
                         id="jBrands"
                         placeholder="Select keyaccounts"
                         bindValue="uid"
                         bindLabel="name"
                         clearAllText="Clear"
                         formControlName="keyaccounts">
              </ng-select>
            </div>
          </div>
          <div *ngIf="isAccountViewer() | async">
            <label for="jBrandsviewer" class="col-sm-4 control-label" >{{ 'JBRANDSVIEWER' | translate }}</label>
            <div class="col-sm-8">
              <ng-select [items]="keyaccounts$ | async"
                         [multiple]="true"
                         id="jBrandsviewer"
                         placeholder="Select keyaccounts"
                         bindValue="uid"
                         bindLabel="name"
                         clearAllText="Clear"
                         formControlName="keyaccountsView">
              </ng-select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="lEnabled" class="col-sm-2 control-label">{{ 'LENABLED' | translate }}</label>
          <div class="col-sm-1 left">
            <input type="checkbox"
                   class=""
                   id="lEnabled"
                   formControlName="enabled"
            />
          </div>
        </div>
      </div>
    </div>
    <button type="submit" [disabled]="!form.valid" class="btn btn-primary">Save</button>

</form>
