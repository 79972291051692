import { Component, Input, OnInit } from '@angular/core';
import { ImageCroppedEvent } from "ngx-image-cropper";
import { AngularFireStorage } from "@angular/fire/storage";
import { Observable, of, Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { AbstractUploaderComponent } from "./abstract-uploader-component";

@Component({
  selector: 'masterplanner2-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.css']
})
export class ImageCropperComponent extends AbstractUploaderComponent {

  @Input()
  path: string;

  croppedImage: any = '';
  cropperReady = false;

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.cropperReady = true;
  }

  loadImageFailed () {
    console.log('Load failed');
  }

  getPath() {
    return this.path;
  }

  getFile(){
    return this.changeImageDataToFile(this.croppedImage);
  }

  changeImageDataToFile(imageData: string): Blob | File {
    const [mimeStr, base64Str] = imageData.split(','),
      mime = mimeStr.match(/:(.*?);/)[1],
      bStr = atob(base64Str);
    let n = bStr.length;
    const u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bStr.charCodeAt(n);
    }
    // return new File([u8arr], 'logo', {type: mime});
    return new Blob([u8arr], {type: mime});
  }
}
