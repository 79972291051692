import { Component, ViewChild } from '@angular/core';
import { ToasterService } from 'angular2-toaster';

import { parse, ParseConfig } from 'papaparse';
import { IntakeImporterService } from '../intake-importer.service';
import { RevenueImporterService } from '../revenue-importer.service';

@Component({
  selector: 'masterplanner2-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportComponent {

  private static ConfigDefaults = {
    header: true,
    encoding: 'utf8',
    dynamicTyping: false,
    skipEmptyLines: 'greedy'
  };

  public importProcessing = false;

  @ViewChild('orderIntake') intakeFileInput;
  @ViewChild('revenue') revenueFileInput;

  constructor(
    private toasterService: ToasterService,
    private intakeImporterService: IntakeImporterService,
    private revenueImporterService: RevenueImporterService,
  ) {

  };

  private static clearImportInput(input) {
    input.value = '';
  }

  private showErrorMessage(errorMessage) {
    this.toasterService.pop('error', 'Import', `Parsing csv failed: ${ errorMessage }`);
    this.importProcessing = false;
  }

  private showWarningsMessage(warningMessage: string) {
    this.toasterService.pop('warning', 'Import', `Import gave warnings: ${ warningMessage }`);
  }

  private showSuccessMessage() {
    this.toasterService.pop('success', 'Import', `Succesfully imported`);
    this.importProcessing = false;
  }

  doImportRevenue(inputElement) {
    const config = {
      delimiter: ','
    };

    this.doImport(inputElement, {...config, ...ImportComponent.ConfigDefaults}, this.revenueImporterService);
  }

  doImportOrderIntake(inputElement) {
    const config = {
      delimiter: ';'
    };

    this.doImport(inputElement, {...config, ...ImportComponent.ConfigDefaults}, this.intakeImporterService);
  }

  private doImport(inputElement, config, importer) {
    if (inputElement.files.length === 0) {
      return;
    }
    this.importProcessing = true;

    //TODO translation
    // The error handler is triggered on read / permission failures. But not on csv structure errors
    const callbacks = {
      error: (err) => this.showErrorMessage(err),
      complete: (parseResult) => {
        importer.import(parseResult.data)
          .then((warnings) => {
            if (warnings.length > 0) {
              this.showWarningsMessage(importer.formatWarnings(warnings));
            }
            this.showSuccessMessage();
            ImportComponent.clearImportInput(inputElement);
          }, (err) => this.showErrorMessage(err));
      }
    };

    parse(<File>inputElement.files[0], <ParseConfig>{...config, ...callbacks});
  }

}
