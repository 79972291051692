var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import { isObject } from 'util';
import * as i0 from "@angular/core";
export class IntakeImporterTransformService {
    constructor() {
    }
    transform(data, keyaccountNameMap, keyaccountsNameToIdMap) {
        const mapObject = (obj, fn) => {
            const result = {};
            Object.keys(obj).map(key => result[key] = fn(obj[key]));
            return result;
        };
        const replaceKeyaccountNameWithUid = (entry) => {
            const { 'naam klant': name } = entry, values = __rest(entry, ['naam klant']);
            const keyaccountName = keyaccountNameMap[name] ? keyaccountNameMap[name] : name;
            if (!keyaccountsNameToIdMap[keyaccountName]) {
                return { message: 'Keyaccount is not found for \'naam klant\'', elements: [keyaccountName] };
            }
            return Object.assign({ uid: keyaccountsNameToIdMap[keyaccountName] }, values);
        };
        const convertIntakeValue = (value) => {
            const numericValue = parseInt(value.replace(/[.,]/g, ''), 10);
            return isNaN(numericValue) ? 0 : numericValue;
        };
        const convertIntakeValues = (entry) => {
            const { uid } = entry, values = __rest(entry, ["uid"]);
            return Object.assign({ uid }, (mapObject(values, convertIntakeValue)));
        };
        const sumValuesPerYear = (entry) => {
            const { uid, 'Eindtotaal': total } = entry, values = __rest(entry, ["uid", 'Eindtotaal']);
            //TODO: implement overflow detection or in any case make explicit what the limit is for a valid value
            //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number#Properties
            // to bad it does not actually become infinity but instead is capped at Number.MAX_VALUE
            const add = (a, b) => (a || 0) + b;
            const newValues = Object.keys(values).reduce((accumulator, periodLabel) => {
                const [, year] = periodLabel.split('.');
                accumulator[year] = add(accumulator[year], values[periodLabel]);
                return accumulator;
            }, {});
            return Object.assign({ uid }, newValues);
        };
        const mergeItem = (item, existing) => {
            if (!existing) {
                return item;
            }
            Object.keys(item).forEach(key => {
                if (key !== 'uid') {
                    if (existing[key]) {
                        existing[key] = item[key] + existing[key];
                    }
                    else {
                        existing[key] = existing[key];
                    }
                }
            });
            return existing;
        };
        const sumValuesPerClient = (accumulator, item) => {
            if (item.message !== undefined) {
                accumulator.warnings.push(item);
            }
            else {
                accumulator.data[item['uid']] = mergeItem(item, accumulator.data[item['uid']]);
            }
            return accumulator;
        };
        const withWarning = (fn) => {
            return (item) => {
                if (item.message !== undefined) {
                    return item;
                }
                else {
                    return fn(item);
                }
            };
        };
        const removeUids = (transformed) => {
            Object.keys(transformed).forEach(key => {
                delete transformed[key]['uid'];
            });
            return transformed;
        };
        const roundToThousands = (tree) => {
            Object.keys(tree).forEach(key => {
                if (isObject(tree[key])) {
                    roundToThousands(tree[key]);
                }
                else {
                    tree[key] = Math.round(tree[key] / 100000);
                }
            });
            return tree;
        };
        const accumulated = data
            .map(replaceKeyaccountNameWithUid)
            .map(withWarning(convertIntakeValues))
            .map(withWarning(sumValuesPerYear))
            .reduce(sumValuesPerClient, { data: {}, warnings: [] });
        return Object.assign({ data: roundToThousands(removeUids(accumulated.data)) }, accumulated);
    }
}
IntakeImporterTransformService.ngInjectableDef = i0.defineInjectable({ factory: function IntakeImporterTransformService_Factory() { return new IntakeImporterTransformService(); }, token: IntakeImporterTransformService, providedIn: "root" });
