<table
  id="strategicoptions"
  st-table="dispStrategicoptions"
  st-safe-src="vm.oStrategicoptions"
  class="table table-condensed  strategicissues col-sm-11"
  [formGroup]="form"
>
  <tr *ngIf="hasNoOptionLeft$ | async">
    <td>
      <div class="col-sm-12">
        <div class="col-sm-2">
          <span style="width: 77px; height: 35px; float:left;">
          <button type="button"
                  class="btn ng-scope no-btn success addbutton "
                  (click)="addItem()"
                  title="{{ 'ADD' | translate }}"
          >
          <i class="glyphicon glyphicon-plus "></i>
          </button>
          </span>
        </div>
      </div>
    </td>
  </tr>
  <tr *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
    <ng-container *ngIf="!row.value.realised">
      <td colspan="12">
        <div class="col-sm-12">
          <div class="col-sm-2" >
            <span style="width: 77px; height: 35px; float:left;">
            <button type="button"
                    class="btn ng-scope no-btn success addbutton "
                    (click)="addItem()"
                    title="{{ 'ADD' | translate }}"
                    *ngIf="(firstNotRealisedOptionIndex$ | async) === i"
            >
            <i class="glyphicon glyphicon-plus "></i>
            </button>
            </span>
            <label> {{ 'OPTION' | translate }}&nbsp;{{ i + 1 }}</label>
          </div>
          <div class="col-sm-7">
            <input class="form-control"
                    formControlName="description"
                    style="width: 100%"
            >
          </div>
          <div class="col-sm-1">
            <label *ngIf="scores$ | async as score" class="label label-default totalscore"
                    [ngClass]="{
                                                  'label-danger': score[i] < 0,
                                                  'label-success': score[i] > 0
                                              }"
            >
              <span>{{ score[i] }}</span>
            </label>
          </div>
          <div class="col-sm-1 text-right">
            <kendo-switch formControlName="execute" ></kendo-switch>
          </div>
          <div class="col-sm-1 text-right">
            <button type="button"
                    class="btn ng-scope no-btn danger deletebutton "
                    (click)="removeItem(i)"
                    title="{{ 'DELETE' | translate }}"
                    *ngIf="!row.get('execute').value && getItemControls().length > 1"
            >
              <i class="glyphicon glyphicon-trash "></i>
            </button>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="col-sm-12">
            <div class="col-sm-1 " ></div>
            <div class="col-sm-2 text-center" >
              {{ 'STRATEGIC_OPTION_RATING1' | translate }}
            </div>
            <div class="col-sm-2 text-center" >
              {{ 'STRATEGIC_OPTION_RATING2' | translate }}
            </div>
            <div class="col-sm-2 text-center" >
              {{ 'STRATEGIC_OPTION_RATING3' | translate }}
            </div>
            <div class="col-sm-2 text-center" >
              {{ 'STRATEGIC_OPTION_RATING4' | translate }}
            </div>
            <div class="col-sm-2 text-center" >
              {{ 'STRATEGIC_OPTION_RATING5' | translate }}
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="col-sm-12">
            <div class="col-sm-1 " ></div>
            <div class="col-sm-2 text-center" *ngFor="let ratingNr of [1,2,3,4,5]">
              <select [formControlName]="'rating' + ratingNr">
                <option [value]="-2">-2</option>
                <option [value]="-1">-1</option>
                <option [value]="0">0</option>
                <option [value]="1">1</option>
                <option [value]="2">2</option>
              </select>
            </div>
          </div>
        </div>
      </td>
    </ng-container>
  </tr>
</table>
