import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from "@angular/fire/firestore";
import {UserService} from "@masterplanner2/users";
import {Customer} from "./customer.model";
import {Observable} from "rxjs/internal/Observable";
import { shareReplay, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private readonly collectionName = 'customers';

  private customers$: Observable<Customer[]>;
  private customerCollection: AngularFirestoreCollection<Customer>;


  constructor(private db: AngularFirestore, userService: UserService) {
    this.customerCollection = this.db.collection(this.collectionName);
    this.customers$ = userService.getCurrentUser().pipe(
      switchMap(user => {
          if (user.roles.includes('ADMIN')) {
            return this.customerCollection.valueChanges();
          } else {
            return this.db.collection<Customer>(this.collectionName, ref => ref.where('uid', '==', user.groupUid)).valueChanges();
          }
        }
      ),
      shareReplay(1)
    );

  }

  getCustomer(uid: string): Observable<Customer> {
    return <Observable<any>> this.customerCollection.doc(uid).valueChanges();
  }

  getCustomers() {
    return this.customers$;
  }

  createCustomer(customer: Customer) {
    return this.customerCollection.add(<Customer>this.mapToDatabase(customer))
      .then(docRef => {
        return docRef.update({uid: docRef.id}).then(() => docRef);
      });
  }

  editCustomer(uid: string, customer: Customer) {
    return this.customerCollection.doc(customer.uid).update({ ...this.mapToDatabase(customer), uid: uid });
  }

  private mapToDatabase(customer: Customer) {
    return {
      ...customer,
      servicelines: this.sortByorder(customer.servicelines),
      industries: this.sortByorder(customer.industries),
      companytypes: this.sortByorder(customer.companytypes),
      buyingbehaviors: this.sortByorder(customer.buyingbehaviors),
    };
  }


  sortByorder(items: object[]): object[] {
    const copy = [...items];

    function compare(a,b) {
      if (a.order < b.order)
        return -1;
      if (a.order > b.order)
        return 1;
      return 0;
    }

    return copy.sort(compare);
  }
}
