import { AngularFirestore } from "@angular/fire/firestore";
import { UserService } from "@masterplanner2/users";
import { shareReplay, switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "../../../../libs/users/src/lib/user.service";
export class CustomerService {
    constructor(db, userService) {
        this.db = db;
        this.collectionName = 'customers';
        this.customerCollection = this.db.collection(this.collectionName);
        this.customers$ = userService.getCurrentUser().pipe(switchMap(user => {
            if (user.roles.includes('ADMIN')) {
                return this.customerCollection.valueChanges();
            }
            else {
                return this.db.collection(this.collectionName, ref => ref.where('uid', '==', user.groupUid)).valueChanges();
            }
        }), shareReplay(1));
    }
    getCustomer(uid) {
        return this.customerCollection.doc(uid).valueChanges();
    }
    getCustomers() {
        return this.customers$;
    }
    createCustomer(customer) {
        return this.customerCollection.add(this.mapToDatabase(customer))
            .then(docRef => {
            return docRef.update({ uid: docRef.id }).then(() => docRef);
        });
    }
    editCustomer(uid, customer) {
        return this.customerCollection.doc(customer.uid).update(Object.assign({}, this.mapToDatabase(customer), { uid: uid }));
    }
    mapToDatabase(customer) {
        return Object.assign({}, customer, { servicelines: this.sortByorder(customer.servicelines), industries: this.sortByorder(customer.industries), companytypes: this.sortByorder(customer.companytypes), buyingbehaviors: this.sortByorder(customer.buyingbehaviors) });
    }
    sortByorder(items) {
        const copy = [...items];
        function compare(a, b) {
            if (a.order < b.order)
                return -1;
            if (a.order > b.order)
                return 1;
            return 0;
        }
        return copy.sort(compare);
    }
}
CustomerService.ngInjectableDef = i0.defineInjectable({ factory: function CustomerService_Factory() { return new CustomerService(i0.inject(i1.AngularFirestore), i0.inject(i2.UserService)); }, token: CustomerService, providedIn: "root" });
