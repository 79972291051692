import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@masterplanner2/users';
import { Observable, Subscription } from 'rxjs';
import { tap } from "rxjs/operators";

@Component({
  selector: 'masterplanner2-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
  title = 'masterplanner-client';
  hasCurrentUser$: Observable<boolean>;
  usersSubscription: Subscription;

  public constructor(translate: TranslateService, userService: UserService) {
    this.hasCurrentUser$ = userService.hasCurrentUser();
    this.usersSubscription = userService.getCurrentUser().pipe(
      tap(user => translate.use(user && user.language ? user.language : 'en-US'))
    ).subscribe();

  }

  ngOnDestroy() {
    if(this.usersSubscription) {
      this.usersSubscription.unsubscribe();
    }
  }
}
