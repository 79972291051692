var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import { isObject } from 'util';
import * as i0 from "@angular/core";
export class RevenueImporterTransformService {
    constructor() {
    }
    transform(data, keyaccountNameMap, orderTypeMap, keyaccountsNameToIdMap, servicelinesNameToIdMap) {
        const replaceKeyaccountName = (entry) => {
            const { 'Zakenpartner Naam': name } = entry, values = __rest(entry, ['Zakenpartner Naam']);
            const keyaccountName = keyaccountNameMap[name] ? keyaccountNameMap[name] : name;
            if (!keyaccountsNameToIdMap[keyaccountName]) {
                return { message: 'Keyaccount is not found for \'Zakenpartner Naam\'', elements: [keyaccountName] };
            }
            return Object.assign({ 'Zakenpartner Naam': keyaccountsNameToIdMap[keyaccountName] }, values);
        };
        const mapCategoryToProductGroup = (entry) => {
            const { 'Soort order': orderType } = entry, values = __rest(entry, ['Soort order']);
            const productGroup = orderTypeMap[orderType] ? orderTypeMap[orderType] : orderType;
            if (!servicelinesNameToIdMap[productGroup]) {
                return { message: `Product group or serviceline is not found for order type`, elements: [productGroup] };
            }
            return Object.assign({ 'Soort order': servicelinesNameToIdMap[productGroup] }, values);
        };
        const convertRevenueValue = (value) => {
            const numericValue = parseInt(value.replace(/[\s.,€]/g, ''), 10);
            return isNaN(numericValue) ? 0 : numericValue;
        };
        const convertRevenueValues = (entry) => {
            const { 'Omzet': revenue } = entry, values = __rest(entry, ['Omzet']);
            return Object.assign({ 'Omzet': convertRevenueValue(revenue) }, values);
        };
        const mergeByNameByOrderTypeByYear = (accumulator, item) => {
            if (item.message !== undefined) {
                accumulator.warnings.push(item);
            }
            else {
                new Updater(accumulator.data, item)
                    .by('Zakenpartner Naam')
                    .by('Soort order') // servicelines / product groups
                    .addBy('jaar', 'Omzet');
            }
            return accumulator;
        };
        const withWarning = (fn) => {
            return (item) => {
                if (item.message !== undefined) {
                    return item;
                }
                else {
                    return fn(item);
                }
            };
        };
        const roundToThousands = (tree) => {
            Object.keys(tree).forEach(key => {
                if (isObject(tree[key])) {
                    roundToThousands(tree[key]);
                }
                else {
                    tree[key] = Math.round(tree[key] / 1000);
                }
            });
            return tree;
        };
        const accumulated = data
            .map(replaceKeyaccountName)
            .map(withWarning(mapCategoryToProductGroup))
            .map(withWarning(convertRevenueValues))
            .reduce(mergeByNameByOrderTypeByYear, { data: {}, warnings: [] });
        return Object.assign({ data: roundToThousands(accumulated.data) }, accumulated);
    }
}
RevenueImporterTransformService.ngInjectableDef = i0.defineInjectable({ factory: function RevenueImporterTransformService_Factory() { return new RevenueImporterTransformService(); }, token: RevenueImporterTransformService, providedIn: "root" });
class Updater {
    constructor(accumulator, item) {
        this.accumulator = accumulator;
        this.item = item;
    }
    by(property) {
        const name = this.item[property];
        const existing = this.accumulator[name];
        if (!existing) {
            this.accumulator[name] = {};
        }
        this.accumulator = this.accumulator[name];
        return this;
    }
    addBy(keyColumn, valueColumn) {
        const keyName = this.item[keyColumn];
        const value = this.item[valueColumn];
        this.accumulator[keyName] = (this.accumulator[keyName] || 0) + value;
    }
}
