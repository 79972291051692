import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'masterplanner2-purchase-process',
  templateUrl: './purchase-process.component.html',
  styleUrls: ['./purchase-process.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: PurchaseProcessComponent, multi: true}
  ]
})
export class PurchaseProcessComponent implements OnInit, OnDestroy, ControlValueAccessor  {
  form: FormGroup;

  onChangeSubscription: Subscription;
  onTouchedSubscription: Subscription;

  constructor(private formBuilder: FormBuilder) {
    this.form = this.buildForm();
  }

  buildForm(): FormGroup {
    return this.formBuilder.group({
      specifyDecision: [],
      selectDecision: [],
      contractDecision: [],
      orderDecision: [],
      monitorDecision: [],
      evaluateDecision: [],

      specifyInvolved: [],
      selectInvolved: [],
      contractInvolved: [],
      orderInvolved: [],
      monitorInvolved: [],
      evaluateInvolved: [],

      explanation: [],
      swotType: [],
      swotReason: [],
    })
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe();
    }
    if (this.onTouchedSubscription) {
      this.onTouchedSubscription.unsubscribe();
    }
  }

  registerOnChange(fn: any): void {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe()
    }
    this.onChangeSubscription = this.form.valueChanges.subscribe(value => fn(value));
  }

  registerOnTouched(fn: any): void {
    if (this.onTouchedSubscription) {
      this.onTouchedSubscription.unsubscribe()
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.form[isDisabled ? 'disable' : 'enable']({emitEvent: false});
  }

  writeValue(items: any): void {
    if (items) {
      this.form.patchValue(items);
    }
  }
}
