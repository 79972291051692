import { Component, OnInit } from '@angular/core';
import { Customer } from "../customer.model";
import { CustomerService } from "../customer.service";
import { Observable } from "rxjs/internal/Observable";
import { DataSource } from '@angular/cdk/table';

@Component({
  selector: 'masterplanner2-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent implements OnInit {
  displayedColumns = ['name', 'contact', 'address', 'zipcode', 'city', 'country', 'actionsColumn'];

  dataSource: DataSource<Customer>;

  constructor(private customerService: CustomerService) { }

  ngOnInit() {
    this.dataSource = new CustomerDataSource(this.customerService.getCustomers());
  }

}


export class CustomerDataSource extends DataSource<Customer> {
  constructor(private customers$: Observable<Customer[]>) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Customer[]> {
    return this.customers$;
  }

  disconnect() {}
}
