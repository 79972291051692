<div *ngIf="(customer$ | async) as customer" class="panel panel-primary" [formGroup]="form" role="form">
  <div class="sw-heading col-sm-12 panel-heading">
    <h2>{{ 'BUNDLES' | translate }}</h2>
  </div>
  <table cdk-table [dataSource]="filteredBundles$ | async"
         class="table table-bordered table-striped table-hover"
         [trackBy]="trackByUId"
         *ngIf="(filterOptions$ | async) as filterOptions">
    <!-- Name Column -->

    <!-- Customer Column -->
    <ng-container cdkColumnDef="name">
      <td cdk-header-cell *cdkHeaderCellDef width="25%">
        <label class="control-label"> {{ 'NAME'  | translate }}</label>
        <hr>
        <input type="text" class="form-control" formControlName="name">
      </td>
      <td cdk-cell *cdkCellDef="let row"> {{ row.name }}</td>
    </ng-container>


    <ng-container cdkColumnDef="description">
      <td cdk-header-cell *cdkHeaderCellDef width="30%">
        <label class="control-label"> {{ 'DESCRIPTION'  | translate }}</label>
        <hr>
        <input type="text" class="form-control" formControlName="description">
      </td>
      <td cdk-cell *cdkCellDef="let row"> {{ row.description }}</td>
    </ng-container>

    <ng-container cdkColumnDef="numberOfPlans">
      <td cdk-header-cell *cdkHeaderCellDef width="10%">
        <label class="control-label"> {{ 'COUNT'  | translate }}</label>
        <hr>
      </td>
      <td cdk-cell *cdkCellDef="let row"> {{ row.keyaccountPlans.length }}</td>
    </ng-container>

    <ng-container cdkColumnDef="customerName">
      <td cdk-header-cell *cdkHeaderCellDef width="20%">
        <label class="control-label">{{ 'CUSTOMER_NAME' | translate }}</label>
        <hr>
        <ng-select  [items]="filterOptions.customerName"
                    formControlName="customerName">
        </ng-select>
      </td>
      <td cdk-cell *cdkCellDef="let row"> {{ row.customerName }}</td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container cdkColumnDef="actionsColumn">
      <th cdk-header-cell *cdkHeaderCellDef>
        <a class="btn btn-success btn-xs" title=" {{ 'NEW' | translate }}" [routerLink]="['/settings/new-bundle']">
          <i class="glyphicon glyphicon-plus"></i>
        </a>
        <hr>
        <button class="btn btn-xs reset-btn" (click)="resetFiltersForm()">
          <i class="glyphicon glyphicon-remove"></i>
        </button>
      </th>
      <td cdk-cell *cdkCellDef="let element">
          <a routerLink="/settings/bundle/{{element.uid}}" class="btn btn-default btn-xs btn-primary" title="{{ 'EDIT_PLAN' | translate }}">
            <i class="glyphicon glyphicon-pencil"></i>
          </a>
          <!--<a [routerLink]="['/settings/new-bundle/' + element.uid]" class="btn btn-default btn-xs btn-warning" title="{{ 'NEW_PLAN' | translate }}"-->
             <!--style="margin-left: 3px;">-->
            <!--<i class="glyphicon glyphicon-map-marker"></i>-->
          <!--</a>-->
          <a [routerLink]="['/settings/dashboard/' + element.uid]" class="btn btn-default btn-xs btn-primary" title="{{ 'DASHBOARD' | translate }}"
             style="margin-left: 3px;">
            <i class="glyphicon glyphicon-dashboard"></i>
          </a>
          <button type="button" class="btn btn-default btn-xs btn-danger" title="{{ 'REMOVE_PLAN' | translate }}" (click)="onDelete(element.uid)"
                  style="margin-left:3px;">
            <i class="glyphicon glyphicon-trash"></i>
          </button>
      </td>
    </ng-container>

    <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
