<div id="header-logos" class="logos pull-left col-sm-2">
  <div class="row" style="height: 37px;">
    <div class="col-sm-12 vcenter">
      <masterplanner2-image-preview [path]="'customer/' + groupUid + '/images/logo'"></masterplanner2-image-preview>
    </div>
  </div>
  <div class="row" style="height: 37px;">
    <div class="col-sm-12 vcenter">
      <masterplanner2-image-preview [path]="'keyaccount/' + keyaccountUid + '/images/logo'"></masterplanner2-image-preview>
    </div>
  </div>
</div>