<table  [formGroup]="parentForm"
  class="table table-condensed buyingpowerindices col-sm-12">
  <thead>
    <tr>
      <th colspan="5">
          {{ 'WON_LOST_PROJECTS' | translate }}
        <button
          type="button"
          class="btn ng-scope no-btn success pull-right"
          (click)="addItem()"
          title="{{ 'NEW' | translate }}"
        >
          <i class="glyphicon glyphicon-plus "></i>
        </button>
      </th>
    </tr>
    <tr>
        <th width="30%">{{ 'OPPORTUNITYNAME' | translate }}</th>
        <th width="15%">{{ 'STATUS' | translate }}</th>
        <th width="35%">{{ 'STATUS_REASON' | translate }}</th>
        <th width="20%">{{ 'EXPIRY_DATE' | translate }}</th>
    </tr>
  </thead>
  <tbody formArrayName="projects">
    <tr *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
      <td>
        <input  formControlName="opportunityName"
                type="text"
                class="form-control  text-left"
        >
      </td>
      <td>
        <select class="form-control"
                formControlName="status"
        >
          <option *ngFor="let status of statuses" value="{{ status }}">{{ status | translate }}</option>
        </select>
      </td>
      <td>
        <input formControlName="statusReason"
                type="text"
                class="form-control  text-left"
        >
      </td>     
      <td>
        <input formControlName="expiryDate"
                type="text"
                class="form-control  text-left"
        >
      </td>     
      <td class="text-right">
        <button type="button"
                class="btn ng-scope no-btn danger deletebutton "
                (click)="removeItem(i)"
                title="{{ 'DELETE' | translate }}"
        >
          <i class="glyphicon glyphicon-trash "></i>
        </button>
      </td>
    </tr>
    </tbody>
</table>
<table [formGroup]="parentForm" class="table table-condensed buyingpowerindices col-sm-12">
    <tr>
      <th>{{ 'QUALIFICATION' | translate }}</th>
      <th>{{ 'REASON' | translate }}</th>
    </tr>
    <tr>
      <td>
        <masterplanner2-swot-type formControlName="swotType" [mode]="'SW'"></masterplanner2-swot-type>
      </td>
      <td>
        <input class="form-control" formControlName="swotReason">
      </td>
    </tr>
</table>




