/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bundle-revenue.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./bundle-revenue.component";
var styles_BundleRevenueComponent = [i0.styles];
var RenderType_BundleRevenueComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BundleRevenueComponent, data: {} });
export { RenderType_BundleRevenueComponent as RenderType_BundleRevenueComponent };
function View_BundleRevenueComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("SHOW")); _ck(_v, 1, 0, currVal_0); }); }
function View_BundleRevenueComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("HIDE")); _ck(_v, 1, 0, currVal_0); }); }
function View_BundleRevenueComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "td", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf[_v.context.index]; _ck(_v, 2, 0, currVal_0); }); }
function View_BundleRevenueComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "th", [["class", "text-right"], ["width", "20%"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 1), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "a", [["class", "show_more"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.expandKeyaccountPlans = !_co.expandKeyaccountPlans) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "i", [["class", "glyphicon"]], null, null, null, null, null)), i1.ɵdid(8, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "glyphicon-menu-down": 0, "glyphicon-menu-up": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BundleRevenueComponent_2)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BundleRevenueComponent_3)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BundleRevenueComponent_4)), i1.ɵdid(15, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "glyphicon"; var currVal_2 = _ck(_v, 9, 0, !_co.expandKeyaccountPlans, _co.expandKeyaccountPlans); _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_3 = !_co.expandKeyaccountPlans; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.expandKeyaccountPlans; _ck(_v, 13, 0, currVal_4); var currVal_5 = _co.yearSpan; _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 4).transform((i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.property)) + "_GRAPH"))); _ck(_v, 2, 0, currVal_0); }); }
function View_BundleRevenueComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getValueAsNumber(_v.parent.context.$implicit[_co.property][_v.context.$implicit]); _ck(_v, 1, 0, currVal_0); }); }
function View_BundleRevenueComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "th", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BundleRevenueComponent_7)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.yearSpan; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.keyaccountName; _ck(_v, 2, 0, currVal_0); }); }
function View_BundleRevenueComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BundleRevenueComponent_6)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.keyaccountPlans$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BundleRevenueComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.UpperCasePipe, []), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BundleRevenueComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BundleRevenueComponent_5)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.sum$)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.expandKeyaccountPlans; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_BundleRevenueComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tbody", [["masterplanner2-bundle-revenue", ""]], null, null, null, View_BundleRevenueComponent_0, RenderType_BundleRevenueComponent)), i1.ɵdid(1, 114688, null, 0, i4.BundleRevenueComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BundleRevenueComponentNgFactory = i1.ɵccf("tbody[masterplanner2-bundle-revenue]", i4.BundleRevenueComponent, View_BundleRevenueComponent_Host_0, { keyaccountPlans$: "keyaccountPlans$", property: "property", yearSpan: "yearSpan" }, {}, []);
export { BundleRevenueComponentNgFactory as BundleRevenueComponentNgFactory };
