import { Component } from '@angular/core';
import { StrategicActionsComponent } from "../../strategy/strategic-actions/strategic-actions.component";
import { FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { sliderOptions } from "../../activity/activity-planning/slider-optionts";

@Component({
  selector: 'masterplanner2-activity-lessons',
  templateUrl: './activity-lessons.component.html',
  styleUrls: ['./activity-lessons.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: ActivityLessonsComponent, multi: true}
  ]
})
export class ActivityLessonsComponent extends StrategicActionsComponent {
  Object = Object;

  sliderOptions = sliderOptions;

  createItemControls(): FormGroup {
    const formGroup = super.createItemControls();
    // Disable controls that are read-only
    formGroup.get('startDate').disable();
    formGroup.get('endDate').disable();
    formGroup.get('action').disable();
    formGroup.get('responsible').disable();
    formGroup.get('result').disable();
    return formGroup;
  }

  removeItem(i: number) {
    if(!this.form.disabled) {
      const activityControl = this.getItemFormArray().at(i).value;
      this.getItemFormArray().at(i).patchValue({...activityControl, deleted: true})
    } else {
      console.log('Cannot remove an item of a disabled control');
    }
  }
}
