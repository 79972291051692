<input type="file" accept="image/jpeg, image/png" (change)="setUploadFileChangeEvent($event)" />

<div>
  <image-cropper *ngIf="canUpload$ | async"
    [imageChangedEvent]="uploadFileChangedEvent$.getValue()"
    [resizeToWidth]="128"
    [maintainAspectRatio]="false"
    format="png"
    outputType="both"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (loadImageFailed)="loadImageFailed()"
    style="max-height: 33vh"
    [style.display]="cropperReady ? null : 'none'"
  ></image-cropper>
  <button *ngIf="croppedImage" type="button" (click)="uploadImage()"
          [class]="'progress-button ' + (uploadState$ | async)" data-style="shrink" data-horizontal="">
    <span class="content">Upload</span>
    <span class="progress"><span class="progress-inner" [style.width]="(uploadProgress$ | async) + '%'" style="opacity: 1;"></span></span>
  </button>
</div>
<img *ngIf="croppedImage ? croppedImage : (downloadUrl$ | async) as url" class="upload-preview" [src]="url" />
