/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./delete-keyaccountplan-dialog.component";
var styles_DeleteKeyaccountplanDialogComponent = [];
var RenderType_DeleteKeyaccountplanDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DeleteKeyaccountplanDialogComponent, data: {} });
export { RenderType_DeleteKeyaccountplanDialogComponent as RenderType_DeleteKeyaccountplanDialogComponent };
export function View_DeleteKeyaccountplanDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(5, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵted(6, null, [" ", " "])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, null, 8, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(9, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 6, "div", [["class", "btn-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(12, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(14, 0, null, null, 2, "button", [["class", "btn btn-danger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(15, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("DELETE_KEYACCOUNTPLAN")); _ck(_v, 2, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("MP_REMOVE_CONFIRM")); _ck(_v, 6, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform("CLOSE")); _ck(_v, 12, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 15, 0, i0.ɵnov(_v, 16).transform("DELETE")); _ck(_v, 15, 0, currVal_4); }); }
export function View_DeleteKeyaccountplanDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "masterplanner2-delete-keyaccountplan-dialog", [], null, null, null, View_DeleteKeyaccountplanDialogComponent_0, RenderType_DeleteKeyaccountplanDialogComponent)), i0.ɵdid(1, 114688, null, 0, i3.DeleteKeyaccountplanDialogComponent, [i1.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeleteKeyaccountplanDialogComponentNgFactory = i0.ɵccf("masterplanner2-delete-keyaccountplan-dialog", i3.DeleteKeyaccountplanDialogComponent, View_DeleteKeyaccountplanDialogComponent_Host_0, {}, {}, []);
export { DeleteKeyaccountplanDialogComponentNgFactory as DeleteKeyaccountplanDialogComponentNgFactory };
