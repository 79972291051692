import { OnDestroy, OnInit } from '@angular/core';
import { FormArray } from "@angular/forms";
import { StrategicActionsComponent } from "../../strategy/strategic-actions/strategic-actions.component";
import { sliderOptions } from './slider-optionts';
import { map, shareReplay, tap } from "rxjs/operators";
export class ActivityPlanningComponent extends StrategicActionsComponent {
    constructor() {
        super(...arguments);
        this.Math = Math;
        this.Object = Object;
        this.dashboardView = false;
        this.showGotik = false;
        this.showActivityTurnover = false;
        this.showActivityType = false;
        this.showActivityComments = false;
        this.sliderOptions = sliderOptions;
        this.allowNew = false;
        this.expanded = {};
    }
    ngOnInit() {
        this.progresses$ = this.form.valueChanges.pipe(map(formValue => {
            return formValue.map(row => {
                if (row.subActivities && row.subActivities.length) {
                    const progress = row.subActivities.map(subActivity => subActivity.progress ? subActivity.progress : 0)
                        .reduce((acc, cur) => acc + cur, 0) / row.subActivities.length;
                    return progress;
                }
                else {
                    return row.progress;
                }
            });
        }), tap(progresses => console.log(progresses)), shareReplay(1));
        this.progressCalculationSubscription = this.progresses$.pipe(tap(progresses => {
            progresses.forEach((progress, index) => {
                const progressControl = this.form.controls[index].get('progress');
                if (progressControl.value != progress) {
                    progressControl.setValue(progress, { emitEvent: false });
                }
            });
        })).subscribe();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.progressCalculationSubscription) {
            this.progressCalculationSubscription.unsubscribe();
        }
    }
    // To lessons learned
    realised(index) {
        const realisedControl = this.form.controls[index].get('realised');
        realisedControl.setValue(true);
    }
    updateGotik(index, char) {
        const gotikControl = this.form.controls[index].get('gotik');
        const oldValue = gotikControl.value[char];
        let newValue = oldValue + 1;
        if (newValue === 4) {
            newValue = 0;
        }
        gotikControl.setValue(Object.assign({}, gotikControl.value, { [char]: newValue }));
    }
    showGotikOnDashboard(gotik) {
        let showGotik = false;
        Object.keys(gotik).forEach(char => {
            if (gotik[char] > 0) {
                showGotik = true;
            }
        });
        return showGotik;
    }
}
