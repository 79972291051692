import { Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs/internal/Subscription";
import * as pushid from "pushid";

export abstract class AbstractFormArrayListComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input()
  formControlName: any;
  @Input()
  templateValue: any;

  form: FormArray;

  onChangeSubscription: Subscription;
  onTouchedSubscription: Subscription;

  protected onChangeCallback: (any) => void;

  constructor(protected formBuilder: FormBuilder) {
    this.form = this.formBuilder.array([]);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe();
    }
    if (this.onTouchedSubscription) {
      this.onTouchedSubscription.unsubscribe();
    }
  }

  removeItem(i: number) {
    if(!this.form.disabled) {
      this.getItemFormArray().removeAt(i);
    } else {
      console.log('Cannot remove an item of a disabled control');
    }
  }

  getItemControls(): AbstractControl[] {
    return this.getItemFormArray().controls;
  }

  getItemFormArray(): FormArray {
    return this.form as FormArray
  }

  addItem(templateValue = null) {
    if(this.form.disabled) {
      console.log('Cannot add an item to a disabled control');
      return
    }
    const formArray = this.getItemFormArray();
    const formGroup = this.createItemControls();
    if(templateValue) {
      formGroup.patchValue(templateValue);
    } else if(this.templateValue) {
      formGroup.patchValue(this.templateValue);
    }

    formGroup.patchValue({
      order: formArray.length + 1,
      uid  : pushid(),
    });

    formArray.push(formGroup);
  }

  abstract createItemControls(): FormGroup;

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe()
    }
    this.onChangeSubscription = this.form.valueChanges.subscribe(value => fn(this.form.getRawValue()));
  }

  registerOnTouched(fn: any): void {
    if (this.onTouchedSubscription) {
      this.onTouchedSubscription.unsubscribe()
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.form[isDisabled ? 'disable' : 'enable']({emitEvent: false});
  }

  writeValue(items: any): void {
    if(!items) { // possibly a null / undefined value
      items = [];
    }
    for (let i = this.getItemFormArray().length; i < items.length; i++) {
      const newItemControls = this.createItemControls();
      newItemControls[this.form.disabled ? 'disable' : 'enable']({emitEvent: false});
      this.getItemFormArray().push(newItemControls);
    }
    while(this.getItemFormArray().length > items.length) {
      this.getItemFormArray().removeAt(this.getItemFormArray().length - 1);
    }

    this.form.patchValue(items, );
  }

}
