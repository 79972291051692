import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConnectableObservable, Subscription } from 'rxjs';
import { map, publishReplay } from 'rxjs/operators';
import { FeatureToggleValues, LastUpdatedRevenues } from '../../../keyaccount-plan.model';
import { tag } from 'rxjs-spy/operators';
import * as firebase from 'firebase/app';
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'masterplanner2-account-revenues',
  templateUrl: './account-revenues.component.html',
  styleUrls: ['./account-revenues.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: AccountRevenuesComponent, multi: true }
  ],
})
export class AccountRevenuesComponent  implements OnInit, ControlValueAccessor {

  @Input()
  yearSpan: number[];

  @Input()
  featureToggles: FeatureToggleValues;

  @Input()
  lastUpdatedRevenues: LastUpdatedRevenues;

  @Input()
  orderIntakeImportedAt: any;

  @Input()
  revenueImportedAt: any;

  

  currentYear: number = new Date().getFullYear();
  estimatedSowValues = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  profitMarginValues = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

  form: FormGroup;
  customerRevenueIndex$: ConnectableObservable<{[key: string]: number}>;

  onChangeSubscription: Subscription;
  onTouchedSubscription: Subscription;

  constructor(private formBuilder: FormBuilder) {  
  }

  ngOnInit() {
    this.form = this.buildForm(this.formBuilder);
    Object.keys(this.form.controls).forEach((controlKey: string) => {
      if (this.form.controls[controlKey] instanceof FormGroup) {
        this.yearSpan.forEach((year: number) => {
          (<FormGroup>this.form.controls[controlKey]).addControl('' + year, this.formBuilder.control(0));
        });
      }
    });
    this.customerRevenueIndex$ = this.customerRevenueIndex();
    this.customerRevenueIndex$.connect();
  }

  private buildForm(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      orderIntakeTarget: formBuilder.group({}),
      orderIntake: formBuilder.group({
        swotType: []
      }),
      orderBacklog: formBuilder.group({
        swotType: []
      }),
      orderSwotReason: [],
      targetRevenue: formBuilder.group({}),
      customerRevenue: formBuilder.group({
        swotType: []
      }),
      sow: formBuilder.group({
        swotType: []
      }),
      profitMargin: formBuilder.group({}),
      revenueSwotReason: []
    });
  }

  private customerRevenueIndex(): ConnectableObservable<{[key: string]: number}> {
    return this.form.get('customerRevenue').valueChanges.pipe(
      tag('customer revenues'),
      map(years => Object.keys(years).map(year => {
        const previousYear = <any>year -1;
        return {  
          year: year, index: (years[previousYear] ? Math.round(years[year] / years[previousYear] * 100) : 100)}
      })),
      map(valueArr => valueArr.reduce((acc, newValue) => {
        newValue.index > 0 ? acc[newValue.year] = newValue.index : acc[newValue.year] = 0;
        return acc;
      }, {})),
      tag('customer revenue indexes'),
      publishReplay(1),
    ) as ConnectableObservable<{[key: string]: number}>;
  }

  registerOnChange(fn: any): void {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe()
    }
    this.onChangeSubscription = this.form.valueChanges.subscribe(value => {
      fn(value)
    });
  }

  registerOnTouched(fn: any): void {
    if (this.onTouchedSubscription) {
      this.onTouchedSubscription.unsubscribe()
    }
  }

  writeValue(items: any): void {
    if(!items) {
      items = {};
    }
    this.form.patchValue(items);
  }

  setDisabledState(isDisabled: boolean): void {
    this.form[isDisabled ? 'disable' : 'enable']({emitEvent: false});
  }

}
