<div class="form-group col-sm-12 no-sidepadding">
  <table
    *ngIf="scores$ | async as scores"
    id="buyingpowerindices"
    [formGroup]="form"
    class="table table-condensed buyingpowerindices col-sm-12">
    <tr>
      <th class="col-sm-4" style="width: 30%">
        {{ 'BUYINGPOWERINDICES_TABLE_HEADER' | translate }}
      </th>
      <th class="col-sm-1" style="width: 10%">
        {{ 'BUYINGPOWERINDICES_IMPORTANCE' | translate }}
      </th>
      <th class="col-sm-1" style="width: 10%">
        {{ 'BUYINGPOWERINDICES_SCORE' | translate }} {{ customerName }}
      </th>
      <th class="col-sm-2" style="width: 30%">
        {{ 'BUYINGPOWERINDICES_WEIGHTSCORE' | translate }} {{ customerName }}
      </th>
      <th class="competitor" style="width: 10%">
        <input
          type="text"
          class="form-control"
          formControlName="competitor1"
          placeholder="{{ 'FILL_IN' | translate  }}"
        >
      </th>
      <th class="competitor" style="width: 10%">
        <input
          type="text"
          class="form-control"
          formControlName="competitor2"
          placeholder="{{ 'FILL_IN' | translate  }}"
        >
      </th>
    </tr>
    <tr *ngFor="let buyingcriterion of (allBuyingcriteria$ | async)" [formGroupName]="buyingcriterion.uid">
      <td class="col-sm-4">
        <input
          type="text"
          class="form-control"
          formControlName="name"
        >
      </td>
      <td class="rating">
        <ngb-rating formControlName="importance" max="5"></ngb-rating>
      </td>
      <td class="rating">
        <ngb-rating formControlName="score" max="5"></ngb-rating>
      </td>
      <td class="progressbar">
        <div class="bar-container" *ngIf="scores[buyingcriterion.uid] as score">
          <div class="progressbar score" [style.width]="Math.round(score.weightedScore / 25 * 100) + '%'" max="25">{{ Math.round(score.weightedScore) }}</div>
          <div class="progressbar rest" [style.width]="Math.round(score.restScore / 25 * 100) + '%'" max="25">{{ Math.round(score.restScore) }}</div>    
        </div>
      </td>
      <td class="rating">
        <ngb-rating formControlName="competitor1" max="5"></ngb-rating>
      </td>
      <td class="rating">
        <ngb-rating formControlName="competitor2" max="5"></ngb-rating>
      </td>
    </tr>
    <tfoot *ngIf="(sum$ | async) as score">
        <tr>
          <td></td>
          <th colspan="2" class="text-right">{{ 'TOTAL' | translate }}</th>
          <td class="progressbar">
            <div class="bar-container" >
              <div class="progressbar score" [style.width]="score.companyIndex + '%'">{{ Math.round(score.companyTotalWeightedScore) }}</div>
              <div class="progressbar rest" [style.width]="(100 - score.companyIndex) + '%'">{{ Math.round(score.companyTotalRestScore) }}</div>              
            </div>
          </td>
          <td class="text-center">
            <span class="text-center">{{ score.competitor1Score }}</span>
          </td>
          <td class="text-center">
            <span class="text-center">{{ score.competitor2Score }}</span>
          </td>
        </tr>
        <tr>
          <th class="no-border-top">{{ 'QUALIFICATION'| translate }}</th>
          <th class="no-border-top" colspan="3">{{ 'REASON'| translate }}</th>
        </tr>
        <tr>
          <td>
            <masterplanner2-swot-type formControlName="swotType" [mode]="'SW'"></masterplanner2-swot-type>
          </td>
          <td colspan="3">
            <input type="text" formControlName="swotReason" class="form-control">
          </td>
        </tr>
        <tr class="totalline">
          <th colspan="4" class="text-right">
            <h2>{{ 'BUYINGPOWERINDEX' | translate }} : {{ Math.round(score.companyIndex) }}</h2>
          </th>
          <th class="text-right">
            <h2>{{ Math.round(score.competitor1Index) }}</h2>
          </th>
          <th class="text-right">
            <h2>{{ Math.round(score.competitor2Index) }}</h2>
          </th>
      </tr>
    </tfoot>
  </table>
