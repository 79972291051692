import { Component } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { AbstractTabComponent } from "../profile/abstract-tab.component";


@Component({
  selector: 'masterplanner2-lessons',
  templateUrl: './lessons.component.html',
  styleUrls: ['./lessons.component.css']
})
export class LessonsComponent extends AbstractTabComponent {

  buildForm(): FormGroup {
    return this.formBuilder.group({
      'uid'             : [],
      'strategicActions': [],
    });
  }

  mapToDatabase(keyaccountPlan) {
    return {
      ...keyaccountPlan,
    };
  }


  mapFromDatabase(keyaccountPlan) {
    const newStrategicActions = keyaccountPlan.strategicActions.map(action => {
      const newSubActivities = (action.subActivities ? action.subActivities : []).map(subAction => {
        return {
          ...subAction,
          startDate: (subAction.startDate ? subAction.startDate.toDate() : null),
          endDate: (subAction.endDate ? subAction.endDate.toDate() : null),
        }
      });
      return {
        ...action,
        subActivities: newSubActivities,
        startDate: (action.startDate ? action.startDate.toDate() : null),
        endDate: (action.endDate ? action.endDate.toDate() : null),
      };
    });
    return {
      ...keyaccountPlan,
      strategicActions: newStrategicActions,
    };
  }

}
