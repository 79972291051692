/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-logos.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../customer-edit/image-preview/image-preview.component.ngfactory";
import * as i3 from "../../customer-edit/image-preview/image-preview.component";
import * as i4 from "@angular/fire/storage";
import * as i5 from "./header-logos.component";
var styles_HeaderLogosComponent = [i0.styles];
var RenderType_HeaderLogosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderLogosComponent, data: {} });
export { RenderType_HeaderLogosComponent as RenderType_HeaderLogosComponent };
export function View_HeaderLogosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "logos pull-left col-sm-2"], ["id", "header-logos"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "row"], ["style", "height: 37px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-sm-12 vcenter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "masterplanner2-image-preview", [], null, null, null, i2.View_ImagePreviewComponent_0, i2.RenderType_ImagePreviewComponent)), i1.ɵdid(4, 114688, null, 0, i3.ImagePreviewComponent, [i4.AngularFireStorage], { path: [0, "path"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "row"], ["style", "height: 37px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "col-sm-12 vcenter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "masterplanner2-image-preview", [], null, null, null, i2.View_ImagePreviewComponent_0, i2.RenderType_ImagePreviewComponent)), i1.ɵdid(8, 114688, null, 0, i3.ImagePreviewComponent, [i4.AngularFireStorage], { path: [0, "path"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (("customer/" + _co.groupUid) + "/images/logo"); _ck(_v, 4, 0, currVal_0); var currVal_1 = (("keyaccount/" + _co.keyaccountUid) + "/images/logo"); _ck(_v, 8, 0, currVal_1); }, null); }
export function View_HeaderLogosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "masterplanner2-header-logos", [], null, null, null, View_HeaderLogosComponent_0, RenderType_HeaderLogosComponent)), i1.ɵdid(1, 49152, null, 0, i5.HeaderLogosComponent, [], null, null)], null, null); }
var HeaderLogosComponentNgFactory = i1.ɵccf("masterplanner2-header-logos", i5.HeaderLogosComponent, View_HeaderLogosComponent_Host_0, { groupUid: "groupUid", keyaccountUid: "keyaccountUid" }, {}, []);
export { HeaderLogosComponentNgFactory as HeaderLogosComponentNgFactory };
