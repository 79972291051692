<div name="editForm" id="editForm" class="form-horizontal col-sm-12" [formGroup]="form" (submit)="doSave()" role="form">
  <div class="panel panel-primary clearfix" *ngIf="(keyaccountPlan$ | async) as keyaccountPlan">
    <div class="sw-heading col-sm-12 panel-heading">
      <div class="pull-left col-sm-8">
        <h2>
          <masterplanner2-header-logos [groupUid]="keyaccountPlan.groupUid" 
                                       [keyaccountUid]="keyaccountPlan.keyaccountUid"
          ></masterplanner2-header-logos>
          <div class="col-sm-10">{{ 'ACTIVITYPLAN_PRO' | uppercase | translate }}</div>
        </h2>
        <div class="col-sm-12 version">{{ 'VERSION' | translate }}:{{ keyaccountPlan.version }}
          {{ 'ITARGETYEAR' | translate }}:{{ keyaccountPlan.targetYear }}
        </div>
      </div>
      <masterplanner2-nav-bar (save)="doSave()" [canSave]="canEdit$ | async"></masterplanner2-nav-bar>
    </div>

    <div class="form-group strategicoption-pro">
      <div class="separator col-sm-12">
        {{ 'TITLE_YEARPLAN' | translate }} {{ keyaccountPlan.customerName}}
      </div>
      <div class="col-sm-12">
        <div class="form-group col-sm-9">
          <b><p class="info-text" [innerHTML]="'ACTIVITYPLANPRO_EXPLANATION_PRO' | translate "></p></b>
        </div>
      </div>
    </div>

    <masterplanner2-activity-planning
      [showActivityType]="keyaccountPlan.featureToggles.activityType"
      [showGotik]="keyaccountPlan.featureToggles.gotik"
      [showActivityTurnover]="keyaccountPlan.featureToggles.activityTurnover"
      [showActivityComments]="keyaccountPlan.featureToggles.activityComments"
      [allowNew]="true"
      formControlName="strategicActions"
    >

    </masterplanner2-activity-planning>

    <masterplanner2-activity-calendar [activities]="activities$"></masterplanner2-activity-calendar>
  </div>
</div>
