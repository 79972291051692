<div class="col-sm-12">
  <div class="panel panel-primary">
    <div class="sw-heading col-sm-12 panel-heading clearfix">
      <div class="pull-left col-sm-8">
        <h2>
          {{ 'IMPORT' | translate }}
        </h2>
      </div>
    </div>
    <div class="form-group clearfix">
      <div id="processing-overlay" *ngIf="importProcessing">
        <img src="../../assets/images/loading.gif" title="Import processing">
        <p><b>{{ 'IMPORT PROCESSING' | translate }}</b></p>
      </div>

      <div class="form-horizontal">
        <div class="form-group col-sm-12">
          <div class="row">
            <label for="turnover" class="col-sm-2 control-label">{{ 'TURNOVER' | translate }}</label>
            <div class="col-sm-4">
              <input #revenue type="file"
                     class="form-control"
                     id="turnover"
                     accept=".csv"
              >
            </div>
            <div class="col-sm-2">
              <button class="btn btn-success" (click)="doImportRevenue(revenue)">{{ 'IMPORT' | translate }}</button>
            </div>
          </div>
        </div>
      </div>

      <div class="form-horizontal">
        <div class="form-group col-sm-12">
          <div class="row">
            <label for="turnover" class="col-sm-2 control-label">{{ 'ORDERINTAKE' | translate }}</label>

            <div class="col-sm-4">
              <input #orderIntake type="file"
                     class="form-control"
                     id="orderintake"
                     accept=".csv"
              >
            </div>
            <div class="col-sm-2">
              <button class="btn btn-success"
                      (click)="doImportOrderIntake(orderIntake)">{{ 'IMPORT' | translate }}</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

