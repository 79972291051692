import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'masterplanner2-delete-keyaccountplan-dialog',
  template: `
  <h2 mat-dialog-title>{{ 'DELETE_KEYACCOUNTPLAN' | translate }}</h2>

  <mat-dialog-content>
   {{ 'MP_REMOVE_CONFIRM' | translate }}
  </mat-dialog-content>
  
  <mat-dialog-actions>
    <div class="btn-group">
      <button class="btn btn-primary"(click)="close()">{{ 'CLOSE' | translate }}</button>
      <button class="btn btn-danger"(click)="delete()">{{ 'DELETE' | translate }}</button>
    </div>
  </mat-dialog-actions>
  `,
  styles: []
})
export class DeleteKeyaccountplanDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DeleteKeyaccountplanDialogComponent>) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close(false);
  }

  delete() {
    this.dialogRef.close(true);
  }

}
