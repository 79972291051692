import { Component, OnInit } from '@angular/core';
import { AbstractFormArrayListComponent } from "../../../profile/abstract-form-array-list/abstract-form-array-list.component";
import { FormGroup, NG_VALUE_ACCESSOR, Validators, FormBuilder } from "@angular/forms";
import { ConnectableObservable, Observable, Subscription } from "rxjs";
import { map, publishReplay, startWith } from "rxjs/operators";

@Component({
  selector: 'masterplanner2-strategic-options',
  templateUrl: './strategic-options.component.html',
  styleUrls: ['./strategic-options.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: StrategicOptionsComponent, multi: true}
  ]
})
export class StrategicOptionsComponent extends AbstractFormArrayListComponent implements OnInit {
  
  scores$: ConnectableObservable<any>;
  scoreSubscription: Subscription;
  hasNoOptionLeft$: ConnectableObservable<boolean>;
  hasNoOptionLeftSubscription: Subscription;
  firstNotRealisedOptionIndex$: ConnectableObservable<number>;
  firstNotRealisedOptionIndexSubscription: Subscription;

  constructor(protected formBuilder: FormBuilder){
    super(formBuilder);
  }
  
  createItemControls(): FormGroup {
    return this.formBuilder.group({
      'uid'         : [],
      'order'       : [],
      'description' : [,Validators.required],
      'execute'     : [],
      'rating1'     : [],
      'rating2'     : [],
      'rating3'     : [],
      'rating4'     : [],
      'rating5'     : [],
      'realised'    : [],
    })

  }

  ngOnInit() {
    super.ngOnInit();

    this.scores$ = this.form.valueChanges.pipe(
      startWith([]),
      map(options => {
        return options.map(strategicOption => {
          return [1,2,3,4,5].map(i => {
            const score = parseInt(strategicOption['rating' + i], 10);
            return score ? score : 0;
          }).reduce((acc, nxt) => acc + nxt, 0);
        })
      }),
      publishReplay(1),
    ) as ConnectableObservable<number[]>;
    this.scoreSubscription = this.scores$.connect();

    this.hasNoOptionLeft$ = this.form.valueChanges.pipe(
      startWith([]),
      map(options => {
        return options.filter(strategicOption => !strategicOption.realised).length === 0
      }),
      publishReplay(1),
    ) as ConnectableObservable<boolean>;
    this.hasNoOptionLeftSubscription = this.hasNoOptionLeft$.connect();

    this.firstNotRealisedOptionIndex$ = this.form.valueChanges.pipe(
      startWith([]),
      map( options => {
        return options.findIndex(option => !option.realised);
      }),
      publishReplay(1),
    ) as ConnectableObservable<number>;
    this.firstNotRealisedOptionIndexSubscription = this.firstNotRealisedOptionIndex$.connect();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if(this.scoreSubscription) {
      this.scoreSubscription.unsubscribe();
    }
    if(this.hasNoOptionLeftSubscription) {
      this.hasNoOptionLeftSubscription.unsubscribe();
    }
    if(this.firstNotRealisedOptionIndexSubscription) {
      this.firstNotRealisedOptionIndexSubscription.unsubscribe();
    }
  }

}
