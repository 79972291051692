<table id="dmus" class="table">
  <thead>
  <tr>
    <th colspan="5" style="text-align: left"> {{ 'DMU_TABLE_HEADER' | translate }} </th>
  </tr>
  <tr>
    <th style="text-align: left"> {{ 'DMUS_NAME' | translate }} </th>
    <th style="text-align: left"> {{ 'DMUS_FUNCTION' | translate }} </th>
    <th style="text-align: left"> {{ 'DMUS_ROLE' | translate }} </th>
    <th style="text-align: left"> {{ 'DMUS_ATTITUDE' | translate }} </th>
    <th style="text-align: left"> {{ 'DMUS_VISITINGINTENSITY' | translate }} </th>
  </tr>
  </thead>
  <!-- foreach DMU -->
  <tbody >
  <tr *ngFor="let contact of contacts; let i = index;">
    <td>
      {{ contact.firstname}} {{ contact.prefix }} {{ contact.surname }}
    </td>
    <td>
      {{ contact.function }}
    </td>
    <td>
      {{ contact.role | translate }}
    </td>
    <td>
      {{ contact.attitude | translate }}
    </td>
    <td>
      {{ contact.visitingIntensity | translate }}
    </td>
  </tr>
  </tbody>
  <tfoot>
    <tr>
      <th>
        {{ 'DMUSCORE' | translate }} : {{ score }}
      </th>
      <th colspan="4">
          {{ 'DMUTOTALVISITS' | translate }} : {{ visits }}
      </th>
    </tr>
  </tfoot>
</table>
