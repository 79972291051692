import { Component, Input, OnInit } from '@angular/core';
import { KeyaccountPlan } from '../../../keyaccount-plan.model';

@Component({
  selector: 'masterplanner2-account-goals-flat',
  templateUrl: './account-goals-flat.component.html',
  styleUrls: ['./account-goals-flat.component.css']
})
export class AccountGoalsFlatComponent {
  @Input()
  accountGoals;

  @Input()
  showOtherGoals = false;

  constructor() { }

}
