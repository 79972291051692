import { Component, Input, OnInit } from '@angular/core';
import { KeyaccountPlanService } from '../../keyaccount-plan.service';

@Component({
  selector: 'masterplanner2-brickwall-flat',
  templateUrl: './brickwall-flat.component.html',
  styleUrls: ['./brickwall-flat.component.css']
})
export class BrickwallFlatComponent implements OnInit {

  @Input()
  criteria;

  constructor(private keyaccountPlanService: KeyaccountPlanService) { }

  ngOnInit() {
  }

  countBrickwall(brickwallCriteria: any[]) {
    return this.keyaccountPlanService.countBrickwall(brickwallCriteria);
  }

}
