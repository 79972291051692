import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { Observable } from 'rxjs';
import { DomainValue } from '../../customer.model';
import { AbstractTabComponent } from "../profile/abstract-tab.component";
import { map } from "rxjs/operators";
import { KeyaccountPlan } from "../../keyaccount-plan.model";
import { toNumbers } from '../utils';


@Component({
  selector       : 'masterplanner2-strategy',
  templateUrl    : './strategy.component.html',
  styleUrls      : ['./strategy.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StrategyComponent extends AbstractTabComponent {
  Math = Math;

  buyingcriteria$: Observable<DomainValue[]>;
  dmuVisits$: Observable<number>;
  purchaseProcessCollapsed = true;
  buyingPowerCollapsed = true;
  criticalContactsCollapsed = true;
  realisedCollapsed = true;
  otherGoalsCollapsed = true;

  setupObservables() {
    super.setupObservables();
    this.dmuVisits$ = this.keyaccountPlan$.pipe(
      map(keyaccountPlan => this.dmuService.calculateDmuVisits(keyaccountPlan.dmus.contacts))
    );

    this.buyingcriteria$ = this.customer$.pipe(
      map(customer => customer.buyingcriteria),
    );
  }

  buildForm(): FormGroup {
    return this.formBuilder.group({
      'uid'             : [],
      'strategicActions': [],
      'explanation1'    : [, Validators.required],
      'explanation2'    : [, Validators.required],
      'explanation3'    : [, Validators.required],
      'explanation4'    : [, Validators.required],
      'explanation5'    : [, Validators.required],
      // readonly / disabled fields
      'orderIntakeTarget': [],
      'orderIntake'      : [],
      'orderBacklog'     : [],
      'targetRevenue'    : [],
      'customerRevenue'  : [],
      'sow'              : [],
      'dmus'            : [],
      'purchaseProcess' : [],
      'buyingpowers'    : [],
      'brickwall'       : [],        
    });
  }

  patchForm(keyaccountPlan: KeyaccountPlan, canEdit: boolean) {
    super.patchForm(keyaccountPlan, canEdit);
    this.form.get('orderIntakeTarget').disable({emitEvent: false});
    this.form.get('orderIntake').disable({emitEvent: false});
    this.form.get('orderBacklog').disable({emitEvent: false});
    this.form.get('targetRevenue').disable({emitEvent: false});
    this.form.get('customerRevenue').disable({emitEvent: false});
    this.form.get('sow').disable({emitEvent: false});
    this.form.get('dmus').disable({emitEvent: false});
    this.form.get('purchaseProcess').disable({emitEvent: false});
    this.form.get('buyingpowers').disable({emitEvent: false});
    this.form.get('brickwall').disable({emitEvent: false});
  }

  countBrickwall(brickwallCriteria: any[]) {
    return this.keyaccountPlanService.countBrickwall(brickwallCriteria);
  }

  mapToDatabase(keyaccountPlan) {
    const dbKeyaccountPlan = {
      strategicActions: keyaccountPlan.strategicActions,
      accountGoals    : {
        explanation1: keyaccountPlan.explanation1,
        explanation2: keyaccountPlan.explanation2,
        explanation3: keyaccountPlan.explanation3,
        explanation4: keyaccountPlan.explanation4,
        explanation5: keyaccountPlan.explanation5,
      }
    };
    return dbKeyaccountPlan;
  }

  mapFromDatabase(keyaccountPlan) {
    const flatten = (arr) => [].concat.apply([], arr);

    // Flatten the nested strategic questions / options structure to a list op options that are to be executed
    const proposedActionsPerQuestion = (keyaccountPlan.strategicQuestions ? keyaccountPlan.strategicQuestions : [])
      .map(question => {
        return question.options
          .filter(option => option.execute)
          .map(option => ({uid: option.uid, option: option.description, question: question.question}))
      });
    // Flatten nested array.
    const proposedActions = flatten(proposedActionsPerQuestion);
    const strategicActions = (keyaccountPlan.strategicActions ? keyaccountPlan.strategicActions : []);

    // Here we concat new proposed actions with strategicActions.
    const newProposedActions = proposedActions.filter(proposedAction => !strategicActions.find(strategicAction => strategicAction.uid === proposedAction.uid));
    const mergedActions = strategicActions.concat(newProposedActions);

    return {
      ...keyaccountPlan,
      strategicActions: mergedActions,
      explanation1: keyaccountPlan.accountGoals ? keyaccountPlan.accountGoals.explanation1 : '',
      explanation2: keyaccountPlan.accountGoals ? keyaccountPlan.accountGoals.explanation2 : '',
      explanation3: keyaccountPlan.accountGoals ? keyaccountPlan.accountGoals.explanation3 : '',
      explanation4: keyaccountPlan.accountGoals ? keyaccountPlan.accountGoals.explanation4 : '',
      explanation5: keyaccountPlan.accountGoals ? keyaccountPlan.accountGoals.explanation5 : '',
      orderIntakeTarget: keyaccountPlan.orderIntakeTarget ? toNumbers(keyaccountPlan.orderIntakeTarget) : {},
      orderIntake: keyaccountPlan.orderIntake ? toNumbers(keyaccountPlan.orderIntake) : {},
      orderBacklog: keyaccountPlan.orderBacklog ? toNumbers(keyaccountPlan.orderBacklog) : {},
      targetRevenue: keyaccountPlan.targetRevenue ? toNumbers(keyaccountPlan.targetRevenue) : {},
      customerRevenue: keyaccountPlan.customerRevenue ? toNumbers(keyaccountPlan.customerRevenue) : {}
    };
  }

}
