import { Component, OnInit } from '@angular/core';
import { AbstractFormArrayListComponent } from "../../../profile/abstract-form-array-list/abstract-form-array-list.component";
import { FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { sliderOptions } from '../slider-optionts';

@Component({
  selector: 'masterplanner2-sub-activities',
  templateUrl: './sub-activities.component.html',
  styleUrls: ['./sub-activities.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: SubActivitiesComponent, multi: true}
  ]
})
export class SubActivitiesComponent extends AbstractFormArrayListComponent {
  sliderOptions = sliderOptions;

  createItemControls(): FormGroup {
    return this.formBuilder.group({
      'uid'        : [],
      'description': [],
      'responsible': [],
      'result'     : [],
      'startDate'  : [],
      'endDate'    : [],
      'progress'   : [],
      'realised'   : [],
    });
  }

}
