<table class="table">
  <tr *ngIf="showHeader">
    <th style="text-align: right"></th>
    <td *ngFor="let year of yearSpan" style="text-align: center">{{ year }}</td>
  </tr>
  <tr>
    <th style="text-align: right">
      {{ name.toUpperCase() | translate }}
    </th>
    <td *ngFor="let year of yearSpan" style="text-align: center">
      <ng-container *ngIf="mode === 'default'"> {{ revenueMap[year] }}</ng-container>
      <ng-container *ngIf="mode === 'estimatedSow'">{{ 'SOW_' + revenueMap[year] | translate }}</ng-container>
      <ng-container *ngIf="mode === 'profitMargin'">{{ 'MAR_' + revenueMap[year] | translate }}</ng-container>
    </td>
  </tr>
  <tr *ngIf="showIndex">
    <th style="text-align: right">
      {{ 'INDEX_LASTYEAR_' + name.toUpperCase() | translate }}
    </th>
    <td *ngFor="let year of yearSpan" style="text-align: center">
        {{ index[year] }}%
    </td>
  </tr>
</table>
