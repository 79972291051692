import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subscription } from "rxjs";
import { map, tap } from 'rxjs/operators';
import { ScoreMatrix } from "../../../keyaccount-plan.model";

@Component({
  selector: 'masterplanner2-score-matrix',
  templateUrl: './score-matrix.component.html',
  styleUrls: ['./score-matrix.component.css'],
})
export class ScoreMatrixComponent implements OnInit, OnDestroy{
  @Input()
  SWs: string[];

  @Input()
  OTs: string[];

  @Input()
  formControlName: any;

  @Output()
  setScore = new EventEmitter<{id: number, value: number}[][]>();

  @Input()
  scoreMatrix: {id: number, value: number}[][];

  form: FormArray;

  flippedMatrix = [];

  valueChangesSubcription: Subscription;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.form = this.formBuilder.array(
      this.scoreMatrix.map(row => {
        return this.formBuilder.array(row.map(cell => {
          return this.formBuilder.group({id: [], value: [0]})
        }))
      }));

    this.valueChangesSubcription  = this.form.valueChanges.subscribe(rows => this.flippedMatrix = this.rowsToColumns(rows));
    this.form.patchValue(this.scoreMatrix);
  }

  rowsToColumns (rows) {
    const flippedMatrix = [];
    rows.forEach((row, rowIndex) => {
      row.forEach((column, columnIndex) => {
        if(!flippedMatrix[columnIndex]) {
          flippedMatrix[columnIndex] = [];
        }
        if (column.value) {
          flippedMatrix[columnIndex][rowIndex] = parseInt(column.value, 10);
        }
      })
    });
    console.log(flippedMatrix);
    return flippedMatrix;
  };

  ngOnDestroy() {
    this.valueChangesSubcription.unsubscribe();
  }


  getSwName(i) {
    return this.SWs? this.SWs[i] : '';
  }

  isScoreAvailable(cellIndex: number, rowIndex: number, requestedScore: number): boolean {
    if (this.flippedMatrix[cellIndex][rowIndex] !== requestedScore) {
      if (this.flippedMatrix[cellIndex].find(score => score === requestedScore)) { 
        return false;
      }
    }
    return true;
  }

  saveScore() {
    this.setScore.emit(this.form.value);
  }

  resetScore() {
    this.form.controls.forEach(row => (<FormArray>row).controls.forEach(cell => cell.patchValue({value:0})));
    this.setScore.emit(this.form.value);
  }
}
