<div *ngIf="(customer$ | async) as customer" class="panel panel-primary" [formGroup]="form" role="form">
  <div class="sw-heading col-sm-12 panel-heading">
    <h2>{{ 'KEYACCOUNTPLANS' | translate }}</h2>
  </div>
  <table cdk-table [dataSource]="filteredKeyaccountPlans$ | async"
                   class="table table-bordered table-striped table-hover"
                   [trackBy]="trackByUId"
                   *ngIf="(filterOptions$ | async) as filterOptions">
    <!-- Name Column -->

    <ng-container cdkColumnDef="customerName">
      <td cdk-header-cell *cdkHeaderCellDef width="15%">
        <label class="control-label">{{ 'CUSTOMER_NAME' | translate }}</label>
        <hr>
        <ng-select  [items]="filterOptions.customerName"
                    formControlName="customerName">
        </ng-select>
      </td>
      <td cdk-cell *cdkCellDef="let row"> {{ row.customerName }}</td>
    </ng-container>

    <!-- Customer Column -->
    <ng-container cdkColumnDef="account">
      <td cdk-header-cell *cdkHeaderCellDef width="20%">
        <label class="control-label"> {{ 'KEYACCOUNT'  | translate }}</label>
        <hr>
        <ng-select  [items]="filterOptions.keyaccountName"
                    formControlName="keyaccountName">
        </ng-select>
      </td>
      <td cdk-cell *cdkCellDef="let row"> {{ row.keyaccountName }}</td>
    </ng-container>

    <ng-container cdkColumnDef="targetYear">
      <td cdk-header-cell *cdkHeaderCellDef width="10%">
        <label class="control-label"> {{ 'TARGET_YEAR'  | translate }}</label>
        <hr>
        <input type="text" class="form-control" formControlName="targetYear">
      </td>
      <td cdk-cell *cdkCellDef="let row"> {{ row.targetYear }}</td>
    </ng-container>

    <ng-container cdkColumnDef="version">
      <td cdk-header-cell *cdkHeaderCellDef width="15%">
        <label class="control-label"> {{ 'VERSION'  | translate }}</label>
        <hr>
        <input type="text" class="form-control" formControlName="version">
      </td>
      <td cdk-cell *cdkCellDef="let row"> {{ row.version }}</td>
    </ng-container>

    <ng-container cdkColumnDef="accountmanager">
      <td cdk-header-cell *cdkHeaderCellDef width="15%">
        <label class="control-label"> {{ 'ACCOUNTMANAGER'  | translate }}</label>
        <hr>
        <ng-select  [items]="filterOptions.keyaccountManagerName"
                    formControlName="keyaccountManagerName">
        </ng-select>
      </td>
      <td cdk-cell *cdkCellDef="let row"> {{ row.keyaccountManagerName }}</td>
    </ng-container>

    <ng-container cdkColumnDef="status">
      <td cdk-header-cell *cdkHeaderCellDef width="10%">
        <label class="control-label"> {{ 'STATUS'  | translate }}</label>
        <hr>
        <ng-select  [items]="filterOptions.status"
                    formControlName="status">
        </ng-select>
      </td>
      <td cdk-cell *cdkCellDef="let row"> {{ row.status }}</td>
    </ng-container>


    <!-- Symbol Column -->
    <ng-container cdkColumnDef="actionsColumn">
      <th cdk-header-cell *cdkHeaderCellDef>
        <a *ngIf="canAdd() | async" class="btn btn-success btn-xs" title=" {{ 'NEW' | translate }}" [routerLink]="['/keyaccountplan/new-plan']">
          <i class="glyphicon glyphicon-plus"></i>
        </a>
        <hr>
        <button class="btn btn-xs reset-btn" (click)="resetFiltersForm()">
          <i class="glyphicon glyphicon-remove"></i>
        </button>
      </th>
      <td cdk-cell *cdkCellDef="let element">
        <ng-container *ngIf="canEdit(element) | async; else readOnly">
          <a routerLink="/keyaccountplan/{{element.uid}}/profile" class="btn btn-default btn-xs btn-primary" title="{{ 'EDIT_PLAN' | translate }}">
            <i class="glyphicon glyphicon-pencil"></i>
          </a>
          <a [routerLink]="['/keyaccountplan/new-plan/' + element.uid]" class="btn btn-default btn-xs btn-warning" title="{{ 'NEW_PLAN' | translate }}"
            style="margin-left: 3px;">
            <i class="glyphicon glyphicon-map-marker"></i>
          </a>
          <button type="button" class="btn btn-default btn-xs btn-danger" title="{{ 'REMOVE_PLAN' | translate }}" (click)="onDelete(element.uid)"
            style="margin-left:3px;">
            <i class="glyphicon glyphicon-trash"></i>
          </button>
          <button *ngIf="customer.featureToggles.showExport" type="button" class="btn btn-default btn-xs" title="{{ 'EXPORT' | translate }}" [masterplanner2PrintClick]="element.uid"><i class="glyphicon glyphicon-export"></i></button>
        </ng-container>
        <ng-template #readOnly>
          <a routerLink="/keyaccountplan/{{ element.uid }}/profile"
             class="btn btn-default btn-xs "
             title="{{ 'VIEW_PLAN' | translate }}"
             *ngIf="!(canEdit(element) | async)"
          >
            <i class="glyphicon glyphicon-sunglasses"></i>
          </a>
        </ng-template>
      </td>
    </ng-container>

    <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
