import { combineLatest, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from '@masterplanner2/users';
import { catchError, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { CustomerService } from '../customer.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "../../../../../libs/users/src/lib/user.service";
import * as i3 from "../customer.service";
export class Bundle {
}
export class BundleService {
    constructor(db, userService, customerService) {
        this.db = db;
        this.userService = userService;
        this.customerService = customerService;
        this.collectionName = 'bundles';
        this.bundleCollection = db.collection(this.collectionName);
        const flatten = arr => [].concat.apply([], arr);
        this.bundles$ = userService.getCurrentUser().pipe(switchMap(user => {
            if (!user) {
                return of([]);
            }
            if (user.roles.includes('ADMIN')) {
                return this.getBundleBy();
            }
            else if (user.roles.includes('GROUPADMIN')) {
                return this.getBundleBy('groupUid', user.groupUid);
            }
            else if (user.roles.includes('ACCOUNTMANAGER') || user.roles.includes('ACCOUNTVIEWER')) {
                return this.getBundleBy('userUid', user.uid);
            }
            else {
                // No role?
                console.error('No matching role found to filter bundles', user);
                return of([]);
            }
        }), shareReplay(1), catchError(err => {
            console.error('Error retrieving bundles', err);
            return of([]);
        }));
    }
    getBundleBy(field, value) {
        const queryFn = field ? ref => ref.where(field, '==', value) : ref => ref;
        return this.db.collection(this.collectionName, queryFn).valueChanges().pipe(catchError(err => {
            console.error('Error retrieving bundles:', err);
            return of([]);
        }));
    }
    getBundles() {
        return this.bundles$;
    }
    getBundle(uid) {
        return this.bundleCollection.doc(uid).valueChanges().pipe(map(bundle => (Object.assign({ keyaccountPlans: [] }, bundle))));
    }
    editBundle(uid, bundle) {
        const doc = this.bundleCollection.doc(uid);
        if ('groupUid' in bundle) {
            return this.customerService.getCustomer(bundle.groupUid).pipe(first()).toPromise()
                .then(customer => {
                return doc.update(Object.assign({}, bundle, { uid: uid, customerName: customer.name })).then(() => doc.ref);
            });
        }
        else {
            return doc.update(Object.assign({}, bundle, { uid: uid })).then(() => doc.ref);
        }
    }
    createBundle(customerUid, keyaccountPlanUids, name, description, originalBundle) {
        const bundle = originalBundle ? this.createBundleClone(originalBundle) : { name, description, keyaccountPlans: [] };
        const currentUser$ = this.userService.getCurrentUser();
        const customer$ = this.customerService.getCustomer(customerUid);
        return combineLatest([customer$, currentUser$]).pipe(first()).toPromise()
            .then(([customer, currentUser]) => {
            return this.bundleCollection.add(Object.assign({}, bundle, { groupUid: customerUid, customerName: customer.name, userUid: currentUser.uid, keyaccountPlans: keyaccountPlanUids })).then(docRef => {
                return docRef.update({ uid: docRef.id }).then(() => docRef);
            });
        });
    }
    deleteBundle(uid) {
        return this.bundleCollection.doc(uid).delete();
    }
    createBundleClone(original) {
        const clonedBundle = Object.assign({}, original, { uid: null });
        return clonedBundle;
    }
}
BundleService.ngInjectableDef = i0.defineInjectable({ factory: function BundleService_Factory() { return new BundleService(i0.inject(i1.AngularFirestore), i0.inject(i2.UserService), i0.inject(i3.CustomerService)); }, token: BundleService, providedIn: "root" });
