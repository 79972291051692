import { Component, OnDestroy } from '@angular/core';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { KeyaccountPlan } from '../../keyaccount-plan.model';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  tap,
  switchMap
} from 'rxjs/operators';
import { AbstractTabComponent } from "../profile/abstract-tab.component";

@Component({
  selector   : 'masterplanner2-swot',
  templateUrl: './swot.component.html',
  styleUrls  : ['./swot.component.css']
})
export class SwotComponent extends AbstractTabComponent implements OnDestroy {
  canLock$: Observable<boolean>;
  hasMatrix$: Observable<boolean>;
  keyaccountPlanWithChanges$: Observable<KeyaccountPlan>;

  swotScoreListSubscription: Subscription;

  patchForm(keyaccountPlan: KeyaccountPlan, canEdit: boolean) {
    super.patchForm(keyaccountPlan, canEdit);
    if (keyaccountPlan.matrix && keyaccountPlan.matrix.length) {
      // Disable formfields if there is already a matrix.
      Object.keys(this.form.controls).map(key => this.form.get(key).disable());
    }
  }

  setupObservables() {
    this.hasMatrix$ = this.keyaccountPlan$.pipe(
      map(keyaccountPlan => !!(keyaccountPlan.matrix && keyaccountPlan.matrix.length)),
      tap(hasMatrix => console.log('has Matrix:', hasMatrix)),
      distinctUntilChanged(),
      shareReplay(1),
    );

    this.keyaccountPlanWithChanges$ = combineLatest([this.keyaccountPlan$, this.form.valueChanges.pipe(startWith({}), tap(form => console.log('f', form)))]).pipe(
      // Merge model with the form value changes.
      map(([keyaccountPlan, changes]) => {
        console.log('Changes', keyaccountPlan, changes);
        return {...keyaccountPlan, ...<any>changes}
      }),
      shareReplay(1),
    );

    //using User to get preffered language, translationService.currentLang is not set on reload.
    const translations$ = this.user$.pipe(
      switchMap(user => {
        if (user) {
          return this.translationService.getTranslation(user.language);
        } else {
          return of({});
        }
      })
    );

    // List with every item that has a meaningfull swot qualification (not neutral or invalid).
    const swotAnalysis$: Observable<{uid: string, name: string, category: string, swotType: string}[]> = combineLatest([this.keyaccountPlanWithChanges$, translations$]).pipe(  // Collect all swot qualifications.
      map(([keyaccountPlan, translations]) => {
        const servicelines = (keyaccountPlan.servicelines ? Object.keys(keyaccountPlan.servicelines)
          .map(key => ({...keyaccountPlan.servicelines[key], uid: key})) : []);

        // Now we create a giant list of swot analysis
        return [
          //  == Opportunity/Threat ==
          //   - keyaccountRevenue
          {
            uid     : 'keyaccount_revenue',
            name    : this.getTranslationIfTranslated('SWOT_KEYACCOUNTREVENUE', translations),
            category: 'turnover',
            swotType: keyaccountPlan.keyaccountRevenue.swotType
          },
          //   - mission
          {
            uid     : 'mission',
            name    : this.getTranslationIfTranslated('SWOT_MISSION', translations),
            category: 'mission',
            swotType: keyaccountPlan.missionSwotType},
          //   - mainactivities1, mainactivities2 *multi (max 2)
          ...(keyaccountPlan.mainActivities && keyaccountPlan.mainActivities.activities ? keyaccountPlan.mainActivities.activities : []).map(activity => {
            return {uid: activity.uid, name: activity.activity, category: 'mainactivities', swotType: activity.swotType}
          }),
          //   - lpt1 (leads/prospects/tenders) *multi
          ...(keyaccountPlan.opportunities ? keyaccountPlan.opportunities : []).map(oppertunity => {
            return {
              uid     : oppertunity.uid,
              name    : oppertunity.name,
              category: 'oppertunities',
              swotType: oppertunity.swotTypeOT
            }
          }),
          //   - lightdevopps (market developments & strategy to deploy) *multi
          ...(keyaccountPlan.developments ? keyaccountPlan.developments : []).map(development => {
            return {
              uid     : development.uid,
              name    : development.name,
              category: 'developments',
              swotType: development.swotType
            }
          }),

          // - proswstrats (strenghts & weaknesses and strategy to deploy) *multi
          ...(keyaccountPlan.strengthAndWeaknesses ? keyaccountPlan.strengthAndWeaknesses : []).map(sw => {
            return {uid: sw.uid, name: sw.name, category: 'strengthAndWeaknesses', swotType: sw.swotType}
          }),
          // - purchasingprocess
          {
            uid     : 'purchaseProcess',
            name    : this.getTranslationIfTranslated('SWOT_PURCHASEPROCESS', translations),
            category: 'turnover',
            swotType: keyaccountPlan.purchaseProcess.swotType
          },
          // - buyingbehavior *multi
          ...(keyaccountPlan.buyingBehavior && keyaccountPlan.buyingBehavior.behaviors ? keyaccountPlan.buyingBehavior.behaviors : []).map(buyingBehavior => {
            return {
              uid     : buyingBehavior.uid,
              name    : buyingBehavior.name,
              category: 'strengthAndWeaknesses',
              swotType: buyingBehavior.swotType
            }
          }),
          // - dmus
          {
            uid     : 'dmus',
            name    : this.getTranslationIfTranslated('SWOT_DMUS', translations),
            category: 'dmus',
            swotType: keyaccountPlan.dmus.swotTypeOT
          },

          // - freeformot *multi
          ...(keyaccountPlan.freeformOT ? keyaccountPlan.freeformOT : []),

          // Strength/Weakness
          // - orderIntake
          keyaccountPlan.featureToggles.orderIntake ?
            {
              uid: 'orderintake',
              name: this.getTranslationIfTranslated('SWOT_ORDERINTAKE', translations),
              category: 'orderrealised',
              swotType: keyaccountPlan.orderIntake.swotType
            }
          : {},
          // - orderBacklog
          keyaccountPlan.featureToggles.orderBacklog ?
            {
              uid     : 'orderbacklog',
              name    : this.getTranslationIfTranslated('SWOT_ORDERBACKLOG', translations),
              category: 'orderbacklog',
              swotType: keyaccountPlan.orderBacklog.swotType
            }
          : {},
          // - customerRevenue
          {
            uid     : 'customerRevenue',
            name    : this.getTranslationIfTranslated('SWOT_CUSTOMERREVENUE', translations),
            category: 'turnover',
            swotType: keyaccountPlan.customerRevenue.swotType
          },
          // - sow
          {
            uid     : 'sow',
            name    : this.getTranslationIfTranslated('SWOT_SOW', translations),
            category: 'sow',
            swotType: keyaccountPlan.sow.swotType
          },
          // - businessunits competitors *multi
          // competitor1
          ...servicelines
            .filter(serviceline => serviceline.competitor1)
            .map(serviceline => {
              return {
                uid     : serviceline.uid + 'competitor1',
                name    : serviceline.description + ' ' + this.getTranslationIfTranslated('SWOT_COMPETITIVE_POSITION', translations) + ' ' + serviceline.competitor1,
                category: 'servicelines',
                swotType: serviceline.positionCompetition1
              }
            }),
          // competitor2
          ...servicelines
            .filter(serviceline => serviceline.competitor2)
            .map(serviceline => {
              return {
                uid     : serviceline.uid + 'competitor2',
                name    : serviceline.description + ' ' + this.getTranslationIfTranslated('SWOT_COMPETITIVE_POSITION', translations) + ' ' + serviceline.competitor2,
                category: 'servicelines',
                swotType: serviceline.positionCompetition2
              }
            }),
          // - project
          {
            uid: 'projects',
            name    : this.getTranslationIfTranslated('SWOT_PROJECTS', translations),
            category: 'projects',
            swotType: keyaccountPlan.projects ? keyaccountPlan.projects.swotType : ''
          },
          // - lpt *multi
          ...(keyaccountPlan.opportunities ? keyaccountPlan.opportunities : []).map(oppertunity => {
            return {
              uid     : oppertunity.uid,
              name    : oppertunity.name,
              category: 'oppertunities',
              swotType: oppertunity.swotTypeSW
            }
          }),

          // - buyingpowerindex
          {
            uid     : 'buyingpowerindex',
            name    : this.getTranslationIfTranslated('SWOT_BUYINGPOWERINDEX', translations),
            category: 'buyingpowerindex',
            swotType: keyaccountPlan.buyingpowers.swotType
          },
          // - dmu
          {
            uid     : 'critical_contacts',
            name    : this.getTranslationIfTranslated('SWOT_CRITICAL_CONTACTS', translations),
            category: 'critical_contacts',
            swotType: keyaccountPlan.dmus.swotTypeSW
          },

          // - freeformsw *multi
          ...(keyaccountPlan.freeformSW ? keyaccountPlan.freeformSW : []),
        ]
      }),
      map(swotList => {
        // Filter neutral and not allowed values
        return swotList.filter(row => ['OPPORTUNITY', 'THREAT', 'STRENGTH', 'WEAKNESS'].includes(row.swotType));
      }),
      distinctUntilChanged(),
      shareReplay(1),
    );


    // Patch possible matrix values when freeform fields changes
    this.swotScoreListSubscription = combineLatest([swotAnalysis$, this.hasMatrix$]).pipe(
      tap(([swotAnalysis, hasMatrix]) => {
        if(hasMatrix) {
          // bail out if there is already a matrix
          return;
        }

        // Merge scores from oldList in newList, for both ot-rating and sw-rating component
        const addScores = (newList, oldList) => {

          return newList.map((newItem: {uid: string, name: string, category: string, swotType: string}) => {
            const oldItem = oldList ? oldList.find( item => item.uid === newItem.uid) : null;

            if(['STRENGTH', 'WEAKNESS'].includes(newItem.swotType)) {
              return {
                ...newItem,
                relevance  : oldItem ? oldItem.relevance : null,
                distinction: oldItem ? oldItem.distinction : null,
                score      : oldItem ? oldItem.score : null,
                inMatrix   : oldItem ? oldItem.inMatrix : null,
              }
            }
            if(['OPPORTUNITY', 'THREAT'].includes(newItem.swotType)) {
              return {
                ...newItem,
                score      : oldItem ? oldItem.score : 0
              }
            }
            return newItem;
          })
        };

        // Opportunities
        const opportunitiesControl = this.form.get('swotOpportunities');
        const opportunitiesList = swotAnalysis.filter(row => row.swotType === 'OPPORTUNITY');
        if (this.diffSwot(opportunitiesControl.value, opportunitiesList)) {
          opportunitiesControl.patchValue(addScores(opportunitiesList, opportunitiesControl.value));
        }

        // Threats
        const threatsControl = this.form.get('swotThreats');
        const threatsList = swotAnalysis.filter(row => row.swotType === 'THREAT');
        if (this.diffSwot(threatsControl.value, threatsList)) {
          threatsControl.patchValue(addScores(threatsList, threatsControl.value));
        }

        // Strengths
        const strengthsControl = this.form.get('swotStrengths');
        const strengthsList = swotAnalysis.filter(row => row.swotType === 'STRENGTH');
        if (this.diffSwot(strengthsControl.value, strengthsList)) {
          strengthsControl.patchValue(addScores(strengthsList, strengthsControl.value));
        }

        // Weaknesses
        const weaknessesControl = this.form.get('swotWeaknesses');
        const weaknessesList = swotAnalysis.filter(row => row.swotType === 'WEAKNESS');
        if (this.diffSwot(weaknessesControl.value, weaknessesList)) {
          weaknessesControl.patchValue(addScores(weaknessesList, weaknessesControl.value), {emitEvent: false});
        }
      }),
    ).subscribe();

    this.canLock$ = this.keyaccountPlanWithChanges$.pipe(
      map(({swotOpportunities, swotThreats, swotStrengths, swotWeaknesses}) => this.canLock({
        swotOpportunities,
        swotThreats,
        swotStrengths,
        swotWeaknesses
      })),
      distinctUntilChanged(),
      tap(canLock => console.log('CAN LOCK', canLock)),
      shareReplay(1),
    );

  }

  buildForm() {
    return this.formBuilder.group({
      'uid'              : [],
      'freeformOT'       : [],
      'swotOpportunities': [],
      'swotThreats'      : [],

      'freeformSW'    : [],
      'swotStrengths' : [],
      'swotWeaknesses': [],
    });
  }

  canLock(plan: { swotOpportunities: any[], swotThreats: any[], swotStrengths: any[], swotWeaknesses: any[] }): boolean {
    const proposal = this.keyaccountPlanService.getMatrixProposal(plan);
    const threatNumber = proposal.filter(row => row.swotType === 'THREAT').length;
    const opportunityNumber = proposal.filter(row => row.swotType === 'OPPORTUNITY').length;
    const strengthNumber = proposal.filter(row => row.swotType === 'STRENGTH').length;
    const weaknessNumber = proposal.filter(row => row.swotType === 'WEAKNESS').length;

    console.log(proposal.length, opportunityNumber, threatNumber, strengthNumber, weaknessNumber);
    return threatNumber === 3 && opportunityNumber === 3
      && strengthNumber === 3 && weaknessNumber === 3;
  }

  /** Create the matrix and lock the swot */
  lockSwot() {
    const plan = this.form.value;
    const uid = plan.uid;
    if (!this.form.disabled && this.canLock(plan)) {
      const keyaccountPlan = this.mapToDatabase(plan);
      const proposal = this.keyaccountPlanService.getMatrixProposal(plan);
      console.log(uid, proposal, keyaccountPlan);
      return (this.keyaccountPlanService.editKeyaccountPlan(uid, {...keyaccountPlan, matrix: proposal}))
        .then(() => {
          // Disable formfields if there is already a matrix.
          Object.keys(this.form.controls).map(key => this.form.get(key).disable());

          this.toasterService.pop('success', 'Saved matrix successfull');
        })
        .catch(err => {
          console.error(err);
          this.toasterService.pop('error', 'Error saving the matrix: ' + err.message);
        });

    } else {
      this.toasterService.pop('error', 'Cannot save the matrix');
    }
  }

  diffSwot(arr1: { uid: string, name: string }[], arr2: { uid: string, name: string }[]): boolean {
    if (arr1 === null || arr2 === null) {
      return true;
    }

    if (arr1.length !== arr2.length) {
      return true;
    }
    for (let i = 0; i < arr1.length; i++) {
      if ((arr1[i].uid !== arr2[i].uid) || (arr1[i].name !== arr2[i].name)) {
        return true;
      }
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this.swotScoreListSubscription) {
      this.swotScoreListSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  getTranslationIfTranslated(title: string, translations) {
    return translations && translations[title] ? translations[title] : title;
  }

}
