import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

@Component({
  selector: '[masterplanner2-revenue-print]',
  templateUrl: './revenue-print.component.html',
  styleUrls: ['./revenue-print.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RevenuePrintComponent implements OnInit, OnChanges {

  @Input()
  name: string;

  @Input()
  value: {[key: string]: number};
  value$ = new ReplaySubject<{[key: string]: number}>();

  @Input()
  yearSpan: Number[];
  yearSpan$ = new ReplaySubject<Number[]>();

  @Input()
  showIndex = false;

  @Input()
  highlightCurrentYear = false;

  @Input()
  lastRowHighlight = false;

  @Input()
  mode: 'default' | 'profitMargin' | 'estimatedSow' = 'default';

  estimatedSowValues = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  profitMarginValues = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

  index$: Observable<{[key: string]: number}>;

  currentYear: number = new Date().getFullYear();

  constructor() {
    this.index$ = this.value$.pipe(
      map(years => Object.keys(years).map(year => {
        const previousYear = <any>year -1;
        return {
          year: year, index: (years[previousYear] ? Math.round(years[year] / years[previousYear] * 100) : 100)}
      })),
      map(valueArr => valueArr.reduce((acc, newValue) => {
        newValue.index > 0 ? acc[newValue.year] = newValue.index : acc[newValue.year] = 0;
        return acc;
      }, {})),
      tap(value => console.log(value, this)),
      // shareReplay(1),
    );
  }

  ngOnInit() {
    this.value$.next(this.value);
    this.yearSpan$.next(this.yearSpan);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.value$.next(this.value);
    this.yearSpan$.next(this.yearSpan);
  }

}
