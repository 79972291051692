import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { BuyingPowerTotals, KeyaccountPlan } from "../../keyaccount-plan.model";
import { User, UserService } from "@masterplanner2/users";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { KeyaccountPlanService } from "../keyaccount-plan.service";
import { BodyOutputType, Toast, ToasterService } from "angular2-toaster";
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  pluck,
  shareReplay,
  skip,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CustomerService } from "../../customer.service";
import { Customer } from "../../customer.model";
import { TranslateService } from "@ngx-translate/core";
import { DmuService } from "../dmu.service";
import { diff } from 'deep-diff';

export abstract class AbstractTabComponent implements OnInit, OnDestroy {
  keyaccountPlan$: Observable<KeyaccountPlan>;
  user$: Observable<User>;
  userMainLanguage$: Observable<string>;
  customer$: Observable<Customer>;
  buyingPowerScores$: Observable<BuyingPowerTotals>;
  dmuScore$: Observable<number>;
  yearSpan$: Observable<number[]>;
  canEdit$: Observable<boolean>;

  currentYear = (new Date()).getFullYear();
  form: FormGroup;

  protected editSubscription: Subscription;
  protected laterChangesSubscription: Subscription;
  protected componentDestroyed$ = new Subject();

  constructor(
    protected formBuilder: FormBuilder,
    activatedRoute: ActivatedRoute,
    protected keyaccountPlanService: KeyaccountPlanService,
    protected userService: UserService,
    protected toasterService: ToasterService,
    protected customerService: CustomerService,
    protected translationService: TranslateService,
    protected dmuService: DmuService,
  ) {
    this.form = this.buildForm();
    console.log(translationService.currentLang);

    this.user$ = userService.getCurrentUser();
    this.userMainLanguage$ = userService.getCurrentUserMainLanguage();
    const componentName = this.constructor.name;
    this.keyaccountPlan$ = activatedRoute.params.pipe(
      pluck('id'),
      filter(id=> !!id),
      switchMap(keyaccountPlanId => this.keyaccountPlanService.getKeyaccountPlan(<string>keyaccountPlanId)),
      tap(keyaccountPlan => console.log('keyaccountPlan ' + componentName, keyaccountPlan)),
      takeUntil(this.componentDestroyed$),
      shareReplay(1),
    );

    this.customer$ = this.keyaccountPlan$.pipe(
      map(keyaccountPlan => keyaccountPlan.groupUid),
      distinctUntilChanged(),
      tap(groupUid => console.log('groepje', groupUid)),
      filter(id=> !!id),
      switchMap(groupUid => this.customerService.getCustomer(groupUid)),
      tap(customer => console.log('customer', customer)),
      shareReplay(1),
    );

    this.canEdit$ = this.keyaccountPlan$.pipe(
      switchMap((keyaccountPlan) => this.keyaccountPlanService.canEdit(keyaccountPlan)),
      distinctUntilChanged(),
      shareReplay(1),
    );

    this.yearSpan$ = this.keyaccountPlan$.pipe(
      map(keyaccountPlan => {
        return keyaccountPlanService.getYearSpan(keyaccountPlan.targetYear);
      }),
      shareReplay(1),
    );

    this.buyingPowerScores$ = this.keyaccountPlan$.pipe(
      map(keyaccountPlan => this.keyaccountPlanService.calculateBuyingpowerTotals(keyaccountPlan.buyingpowers))
    );

    this.dmuScore$ = this.keyaccountPlan$.pipe(
      map(keyaccountPlan => this.dmuService.calculateDmuScore(keyaccountPlan.dmus.contacts))
    );

    // Allows derived classes to setup observables without overriding the whole constructor.
    this.setupObservables();
  }

  ngOnInit() {
    this.editSubscription = combineLatest([this.keyaccountPlan$, this.canEdit$]).pipe(
      first(),
      tap(([keyaccountPlan, canEdit]) =>  {
        this.form.reset();
        const transformedKeyaccountPlan = this.mapFromDatabase(keyaccountPlan);
        this.patchForm(transformedKeyaccountPlan, canEdit);
        this.form.markAsPristine();
      }),
      takeUntil(this.componentDestroyed$),
    ).subscribe();

    this.laterChangesSubscription = this.keyaccountPlan$.pipe(
      skip(1),
      switchMap(keyaccountPlan => this.canEdit$.pipe(map(canEdit => [keyaccountPlan, canEdit]))),
      tap(([keyaccountPlan, canEdit]) => {
        const transformedKeyaccountPlan = this.mapFromDatabase(keyaccountPlan);
        const formKeys = Object.keys(this.form.controls);
        const formValues = formKeys.map(key => ({key, value: transformedKeyaccountPlan[key]}))
          .reduce((acc, cur) => ({...acc, [cur.key]: cur.value}), {});
        this.toasterService.clear('keyaccount-reload');

        const myDiff = diff(formValues, this.form.getRawValue());

        // tslint:disable-next-line:triple-equals
        const hasfilteredDiff = myDiff ? myDiff.filter(diffItem => !(diffItem.lhs == diffItem.rhs)).length > 0 : false; // filter things like lhs undefined, and rhs null
        if(!hasfilteredDiff) {
          console.log('external changes, but no diff', myDiff);
          return;
        } else {
          console.log('external changes!', myDiff);
        }
        const toast: Toast = {
          type: 'warning',
          title: 'Changes by other user(s).',
          toastId: 'keyaccount-reload',
          timeout: 0,
          body: RefreshToasterComponent,
          bodyOutputType: BodyOutputType.Component,
          data: {
            keyaccountPlan,
            doRefresh: () => {
              this.patchForm(<KeyaccountPlan>formValues, <boolean>canEdit);
            },
            doCancel: () => {
            },
          }
        };
        this.toasterService.pop(toast);

      }),
      takeUntil(this.componentDestroyed$),
    ).subscribe();
  }

  ngOnDestroy(){
    if(this.editSubscription) {
      this.editSubscription.unsubscribe();
    }
    if(this.laterChangesSubscription) {
      this.laterChangesSubscription.unsubscribe();
    }
    this.componentDestroyed$.next();
  }

  doSave(): Promise<any> {
    if(this.form.disabled) {
      console.log('Cannot save a disabled form');
      return;
    }
    const uid = this.form.get('uid').value;
    const keyaccountPlan = this.mapToDatabase(this.form.value);
    console.log('saving', this.form.value);
    this.form.markAsPristine();
    return (this.keyaccountPlanService.editKeyaccountPlan(uid, keyaccountPlan))
      .then(() => {
        this.toasterService.pop('success',  'Saved successfull');
      })
      .catch(err => {
        console.error(err);
        this.toasterService.pop('error', 'Error saving: ' + err.message);
      });
  }

  abstract buildForm(): FormGroup;

  canDeactivate(): boolean {
    return !this.form.dirty || this.form.disabled; // The form is made pristine on save.
  };

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue =true;
    }
  }

  /**
   * Called from the constructor to allow for additional observable initializations
   */
  setupObservables() { }

  patchForm(keyaccountPlan: KeyaccountPlan, canEdit: boolean) {
    if(!canEdit) {
      this.form.disable();
    }
    this.form.patchValue(keyaccountPlan);
  }

  mapFromDatabase(keyaccountPlan): KeyaccountPlan {
    return keyaccountPlan;
  }

  mapToDatabase(keyaccountPlan): Partial<KeyaccountPlan> {
    return keyaccountPlan;
  }

}


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'refresh-toaster-component',
  template: `<div><p>Content has changed. </p><button style="color:black" (click)="doRefresh()">Refresh</button><button style="color:black" (click)="doCancel()">Cancel</button></div>`
})
export class RefreshToasterComponent {
  public toast: Toast;

  constructor() { }

  doRefresh() {
    console.log('refresh', this);
    if(this.toast && this.toast.data && this.toast.data.doRefresh) {
      this.toast.data.doRefresh();
    }
  }

  doCancel() {
    if(this.toast && this.toast.data && this.toast.data.doCancel) {
      this.toast.data.doCancel(this.toast);
    }
  }
}
