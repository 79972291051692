import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { TranslationService, Locale } from './translation.service';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ToasterService } from 'angular2-toaster';
import { CustomerService } from '../customer.service';
import { Customer } from '../customer.model';

@Component({
  selector: 'masterplanner2-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.css']
})
export class TranslationComponent implements OnInit {

  newLocaleForm: FormGroup;
  newTranslationForm: FormGroup;
  editTranslationsForm: FormGroup;
  locales$: Observable<Locale[]>;

  mainLocales$: Observable<Locale[]>;

  translations$: Observable<any[]>;
  customers$: Observable<Customer[]>;

  constructor(
      private formBuilder: FormBuilder, 
      private translationService: TranslationService, 
      private toasterService: ToasterService,
      private customerService: CustomerService
    ) { 
    this.newLocaleForm = this.formBuilder.group({
      'customer': [],
      'locale': [],
    });
    this.newTranslationForm = this.formBuilder.group({
      'name': [],
      'locale': [],
      'value': [],
    });
    this.editTranslationsForm = this.formBuilder.group({
      'locale': []     
    });
  }

  ngOnInit() {
    this.locales$ = this.translationService.getLocales();
    this.mainLocales$ = this.translationService.getMainLocales();
    this.customers$ = this.customerService.getCustomers();

    this.translations$ = this.editTranslationsForm.get('locale').valueChanges.pipe(
      switchMap(locale => {
        return locale ? this.translationService.getTranslations(locale.code): null;
      }),
      map(translations => translations ? Object.entries(translations): null),
      tap(translations => {
        if (translations) {
          translations.forEach(translation => {
            this.editTranslationsForm.addControl(translation[0], new FormControl('', []))
            this.editTranslationsForm.get(translation[0]).patchValue(translation[1]);
            this.editTranslationsForm.get(translation[0]).disable({emitEvent: false});
          })
        }
      })
    )
  }
  
  enableControl(field: string) {
    this.editTranslationsForm.get(field).enable();
  }

  disableControl(field: string) {
    this.editTranslationsForm.get(field).disable({emitEvent: false});
  }

  saveControl(field: string) {
    const locale = this.editTranslationsForm.get('locale').value;
    const translated = {};

    translated[field] = this.editTranslationsForm.get(field).value;

    this.translationService.editTranslation(locale.code, translated)
    .then(()=> this.toasterService.pop('success', field, 'succesfully edited.'))
    .catch(error => console.error(error));

    this.editTranslationsForm.get(field).disable({emitEvent:false});
  }

  doSaveNewTranslation() {
    const locale = this.newTranslationForm.get('locale').value;
    const translated = {};
    translated[this.newTranslationForm.get('name').value] = this.newTranslationForm.get('value').value;

    if (locale) {
      this.translationService.editTranslation(locale.code, translated)
      .then(()=> this.toasterService.pop('success', 'NEW TRANSLATION', 'added succesfully.'))
      .catch(error => console.error(error));
    }
    this.newTranslationForm.reset();
  }

  doSaveNewLocale() {
    const customer = this.newLocaleForm.get('customer').value;
    const language = this.newLocaleForm.get('locale').value;

    if (customer && language){
      const code = language.code + '_' + customer.uid;
      const name = customer.name + ' - ' + language.name;

      this.translationService.createLocale(code, name)
        .then(()=> {
          this.toasterService.pop('success', 'NEW LOCALE', 'created succesfully.')
          this.newLocaleForm.reset();
        })
        .catch(error => {
          this.toasterService.pop('error', 'NEW LOCALE', 'create failed.')
          console.error(error);
        });
    }
  }

}
