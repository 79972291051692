import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormArrayListComponent } from '../../profile/abstract-form-array-list/abstract-form-array-list.component';
import { ConnectableObservable } from 'rxjs';
import { map, publishReplay, tap } from 'rxjs/operators';
import { DmuService } from '../../dmu.service';

@Component({
  selector   : 'masterplanner2-critical-contacts',
  templateUrl: './critical-contacts.component.html',
  styleUrls  : ['./critical-contacts.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: CriticalContactsComponent, multi: true}
  ]
})
export class CriticalContactsComponent extends AbstractFormArrayListComponent implements OnInit {

  parentForm: FormGroup;

  dmuScore$: ConnectableObservable<number>;
  dmuVisits$: ConnectableObservable<number>;

  dmuRoles = {};
  visitingIntensities = {};
  attitudes = {};

  constructor(protected formBuilder: FormBuilder, private dmuService: DmuService) {
    super(formBuilder);

    this.dmuRoles = dmuService.getDmuRoles();
    this.visitingIntensities = dmuService.getVisitingIntensities();
    this.attitudes = dmuService.getAttitudes();

    this.dmuScore$ = this.form.valueChanges.pipe(
      map(() => this.form.getRawValue()),
      map(contacts => this.dmuService.calculateDmuScore(contacts)),
      publishReplay(1)
    ) as ConnectableObservable<number>;

    this.dmuVisits$ = this.form.valueChanges.pipe(
      map(contacts => this.dmuService.calculateDmuVisits(contacts)),
      publishReplay(1)
    ) as ConnectableObservable<number>;
  }

ngOnInit() {
    super.ngOnInit();
    this.parentForm = this.createForm();
    this.dmuScore$.connect();
    this.dmuVisits$.connect();
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      contacts    : this.form,
      swotTypeOT  : [],
      swotReasonOT: [],
      swotTypeSW  : [, Validators.required],
      swotReasonSW: [, Validators.required],
    })
  }

  createItemControls(): FormGroup {
    return this.formBuilder.group({
      'uid'              : [, Validators.required],
      'firstname'        : [, Validators.required],
      'prefix'           : [, Validators.required],
      'surname'          : [, Validators.required],
      'function'         : [, Validators.required],
      'role'             : [, Validators.required],
      'attitude'         : [, Validators.required],
      'visitingIntensity': [, Validators.required],
    });
  }

  registerOnChange(fn: any): void {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe()
    }
    this.onChangeSubscription = this.parentForm.valueChanges.subscribe(value => fn(this.parentForm.getRawValue()));
  }

  setDisabledState(isDisabled: boolean): void {
    this.parentForm[isDisabled ? 'disable' : 'enable']({emitEvent: false});
    super.setDisabledState(isDisabled);
  }

  writeValue(items: any): void {
    if (!items) {
      items = [];
    }
    super.writeValue(items.contacts);
    this.parentForm.patchValue(items);

    //disable dmu fields
    this.form.controls.forEach(row => {
      row.get('firstname').disable({emitEvent: false})
      row.get('prefix').disable({emitEvent: false})
      row.get('surname').disable({emitEvent: false})
      row.get('function').disable({emitEvent: false})
      row.get('role').disable({emitEvent: false})
    })
  }

}

  
