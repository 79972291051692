import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';
import { AbstractImporterService, ImportWarning } from './import/abstract-importer.service';
import { KeyaccountNameMap } from './import/keyaccountNameMap';
import { IntakeImporterTransformService } from './intake-importer-transform.service';
import { YearlyValues } from './keyaccount-plan.model';
import { KeyaccountService } from '@masterplanner2/users';

export interface IntakeImportRow {
  // 'naam klant': string;
  [property: string]: string;
}


export interface IntakeImportResults {
  [uid: string]: YearlyValues
}

export type IntakeImportItem = IntakeImportRow | ImportWarning;

@Injectable({
  providedIn: 'root'
})

export class IntakeImporterService extends AbstractImporterService {

  constructor(
    keyaccountService: KeyaccountService,
    private intakeImporterTransform: IntakeImporterTransformService
  ) {
    super(keyaccountService);
  }

  import(intakeImportdata: IntakeImportRow[]): Promise<ImportWarning[]> {
    if (intakeImportdata.length < 1) {
      return Promise.reject('The document is empty');
    }

    if (Object.keys(intakeImportdata[0]).indexOf('naam klant') === -1) {
      return Promise.reject('The document does not have a valid header, missing column: \'naam klant\'');
    }

    return this.keyaccountNameToUid$.pipe(
      first(),
      map(keyaccountsNameToIdMap =>
        this.intakeImporterTransform.transform(intakeImportdata, KeyaccountNameMap, keyaccountsNameToIdMap)
      )
    ).toPromise().then(({data, warnings}) => {
      console.log('RESULT', intakeImportdata, data, warnings);

      const savePromises = Object.keys(data)
        .map(keyaccountUid => {
          return this.keyaccountService.editKeyaccount(keyaccountUid, {
            orderIntake: data[keyaccountUid],
            orderIntakeImportedAt: new Date()
          });
        });

      console.log('to Store: ', data);
      return Promise.all(savePromises).then(() => warnings);
    });
  }
}
