export const KeyaccountNameMap = {
  'ABN 20 B.V.': 'ABN AMRO',
  'ABN AMRO': 'ABN AMRO',
  'ABN Amro Bank NV': 'ABN AMRO',
  'ABN AMRO Bank N.V.': 'ABN AMRO',
  'ABN AMRO CCA-N': 'ABN AMRO',
  'ABN AMRO Foppingadreef 22': 'ABN AMRO',
  'ABN AMRO Gustav Mahlerlaan': 'ABN AMRO',
  'ABN AMRO Holding N.V.': 'ABN AMRO',
  'Academisch Ziekenhuis Maastricht': 'AZM',
  'Achmea B.V.': 'Achmea',
  'Achmea Centrale Dienstverlening': 'Achmea',
  'Achmea – FD&P': 'Achmea',
  'Achmea Pensioen en Leven.': 'Achmea',
  'Achmea Pensioen en Levensverzekering': 'Achmea',
  'Achmea Pens. Levensv. NV': 'Achmea',
  'Achmea Schadeverzekeringen N.V.': 'Achmea',
  'Amsterdam Airport Schiphol': 'Schiphol',
  'ASML Netherlands B.V.': 'ASML',
  'Campina Maasdam': 'Friesland Campina',
  'De Meeuw Oirschot B.V.': 'De Meeuw',
  'Dow Benelux B.V.': 'DOW',
  'DOW Benelux bv': 'DOW',
  'DOW Benelux B.V. (FARMSUM)': 'DOW',
  'Dow Chemical Company Limited': 'DOW',
  'DOW Deutschland Anlagengesellschaft MBH': 'DOW',
  'Dow France S.A.S.': 'DOW',
  'DOW OLEFINVERBUND GMBH': 'DOW',
  'DOW Silicones UK Limited': 'DOW',
  'Envida': 'Envida',
  'Envida Mockstraat': 'Envida',
  'FrieslandCampina Cheese & Butter B.V.': 'Friesland Campina',
  'FrieslandCampina Cheese & Butter BV': 'Friesland Campina',
  'FrieslandCampina Cheese': 'Friesland Campina',
  'FrieslandCampina Creamy Creations BV': 'Friesland Campina',
  'FrieslandCampina DMV B.V.': 'Friesland Campina',
  'FrieslandCampina Domo B.V.': 'Friesland Campina',
  'Friesland campina': 'Friesland Campina',
  'FrieslandCampina Industry': 'Friesland Campina',
  'FrieslandCampina Kievit B.V.': 'Friesland Campina',
  'FrieslandCampina Nederland B.V.': 'Friesland Campina',
  'FrieslandCampina Nederland BV': 'Friesland Campina',
  'FrieslandCampina Riedel B.V.': 'Friesland Campina',
  'Riedel B.V.': 'Friesland Campina',
  'Geodis Benelux Holding BV': 'Geodis',
  'Geodis Logistics Netherlands B.V.': 'Geodis',
  'Geodis Logistics Netherlands BV': 'Geodis',
  'Geodis Logistics Netherlands': 'Geodis',
  'Holland Casino': 'Holland Casino',
  'Holland Casino Breda': 'Holland Casino',
  'Holland Casino Eindhoven': 'Holland Casino',
  'Holland Casino Groningen': 'Holland Casino',
  'Holland Casino Hoofddorp': 'Holland Casino',
  'Holland Casino N.V.': 'Holland Casino',
  'Holland Casino Rotterdam': 'Holland Casino',
  'Holland Casino Valkenburg': 'Holland Casino',
  'Kadaster': 'Kadaster',
  'Merck Sharp & Dohme B.V.': 'MSD',
  'Oce-Technologies B.V.': 'Oce Technologies',
  'ProRail B.V.': 'ProRail',
  'ProRail': 'ProRail',
  'Radboudumc': 'Radboudumc',
  'Rijkswaterstaat Corporate Dienst': 'RWS',
  'Rijkswaterstaat Diensten Corporate': 'RWS',
  'Rijkswaterstaat Dienst Zeeland': 'RWS',
  'Rijkswaterstaat Grote Projecten': 'RWS',
  'Rijkswaterstaat IJsselmeergebied': 'RWS',
  'Rijkswaterstaat Landelijke Diensten': 'RWS',
  'Rijkswaterstaat Midden-Nederland': 'RWS',
  'Rijkswaterstaat Noord-Holland': 'RWS',
  'Rijkswaterstaat Oost Nederland': 'RWS',
  'Rijkswaterstaat Oost-Nederland': 'RWS',
  'Rijkswaterstaat Regionale diensten': 'RWS',
  'Rijkswaterstaat Regionalediensten': 'RWS',
  'Rijkswaterstaat Zee & Delta': 'RWS',
  'Rijkswaterstaat Zeeland': 'RWS',
  'SABIC Capital B.V.': 'Sabic',
  'SABIC Europe': 'Sabic',
  'Sabic Petrochemicals B.V.': 'Sabic',
  'Schiphol Airport Retail B.V.': 'Schiphol',
  'Schiphol Group': 'Schiphol',
  'Schiphol Nederland B.V.': 'Schiphol',
  'Schiphol Nederland BV': 'Schiphol',
  'Schiphol Real Estate B.V.': 'Schiphol',
  'Schiphol Real Estate': 'Schiphol',
  'Shell Global Solutions International': 'SHELL',
  'Shell Global Solutions International B.V.': 'SHELL',
  'Shell Global Solutions International B.V': 'SHELL',
  'Shell Global Solutions': 'SHELL',
  'Shell Nederland Chemie B.V.': 'SHELL',
  'Shell Nederland Raffinaderij B.V.': 'SHELL',
  'Shell Nederland Verkoopmaatschappij bv': 'SHELL',
  'Shell Nederland Verkoopmaatschappij B.V.': 'SHELL',
  'Shell Ned. Raff./Chemie BV': 'SHELL',
  'Shell Technology Centre Amsterdam': 'SHELL',
  'Shell UK Limited': 'SHELL',
  'Sitech Manufacturing Services C.V.': 'Sitech Services',
  'Sitech Services B.V.': 'Sitech Services',
  'Sitech Site Services B.V': 'Sitech Services',
  'St Achmea DutRet Pr Fund': 'Achmea',
  'Stg. Zuyderland CARE': 'Zuyderland',
  'Stg. Zuyderland GGZ': 'Zuyderland',
  'Stg. Zuyderland Medisch Centrum': 'Zuyderland',
  'Stg. Zuyderland Thuiszorg': 'Zuyderland',
  'Stichting Amphia': 'Amphia',
  'Stichting de Zorgboog': 'De Zorgboog',
  'Stichting Envida': 'Envida',
  'Stichting Zuyderland Medisch Centrum': 'Zuyderland',
  'Stichting Zuyderland Medisch centrum': 'Zuyderland',
  'Stichting Zuyderland Zorg': 'Zuyderland',
  'St. Zuyderland': 'Zuyderland',
  'TenneT TSO B.V.': 'TenneT',
  'TenneT TSO BV': 'TenneT',
  'UMC St Radboud': 'Radboudumc',
  'USG Operations V.O.F.': 'USG Industrial Utilities',
  'Zuyderland Thuiszorg B.V.': 'Zuyderland'
};
