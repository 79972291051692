import { Injectable } from '@angular/core';
import { isObject } from 'util';
import { AliasToName, ImportWarning, NameToUid } from './import/abstract-importer.service';
import { RevenueImportItem, RevenueImportResults, RevenueImportRow } from './revenue-importer.service';


@Injectable({
  providedIn: 'root'
})
export class RevenueImporterTransformService {

  constructor() {
  }

  transform(
    data: RevenueImportRow[],
    keyaccountNameMap: AliasToName,
    orderTypeMap: AliasToName,
    keyaccountsNameToIdMap: NameToUid,
    servicelinesNameToIdMap: NameToUid
  ):
    { data: RevenueImportResults, warnings: ImportWarning[] } {

    const replaceKeyaccountName = (entry: RevenueImportRow): RevenueImportItem => {
      const {'Zakenpartner Naam': name, ...values} = entry;
      const keyaccountName = keyaccountNameMap[name] ? keyaccountNameMap[name] : name;

      if (!keyaccountsNameToIdMap[keyaccountName]) {
        return {message: 'Keyaccount is not found for \'Zakenpartner Naam\'', elements: [keyaccountName]};
      }
      return {'Zakenpartner Naam': keyaccountsNameToIdMap[keyaccountName], ...values};
    };

    const mapCategoryToProductGroup = (entry: RevenueImportRow): RevenueImportItem => {
      const {'Soort order': orderType, ...values} = entry;
      const productGroup = orderTypeMap[orderType] ? orderTypeMap[orderType] : orderType;
      if (!servicelinesNameToIdMap[productGroup]) {
        return {message: `Product group or serviceline is not found for order type`, elements: [productGroup]};
      }
      return {'Soort order': servicelinesNameToIdMap[productGroup], ...values};
    };

    const convertRevenueValue = (value: string): number => {
      const numericValue = parseInt(value.replace(/[\s.,€]/g, ''), 10);
      return isNaN(numericValue) ? 0 : numericValue;
    };

    const convertRevenueValues = (entry: RevenueImportRow): RevenueImportRow => {
      const {'Omzet': revenue, ...values} = entry;
      return {'Omzet': convertRevenueValue(<string>revenue), ...values};
    };


    const mergeByNameByOrderTypeByYear = (accumulator, item: RevenueImportItem) => {
      if ((<ImportWarning>item).message !== undefined) {
        accumulator.warnings.push(item);
      } else {
        new Updater(accumulator.data, item)
          .by('Zakenpartner Naam')
          .by('Soort order') // servicelines / product groups
          .addBy('jaar', 'Omzet');
      }

      return accumulator;
    };

    const withWarning = (fn) => {
      return (item: RevenueImportItem) => {
        if ((<ImportWarning>item).message !== undefined) {
          return item;
        } else {
          return fn(item);
        }
      };
    };

    const roundToThousands = (tree) => {
      Object.keys(tree).forEach(key => {
        if (isObject(tree[key])) {
          roundToThousands(tree[key]);
        } else {
          tree[key] = Math.round(tree[key] / 1000);
        }
      });
      return tree;
    };

    const accumulated = data
      .map(replaceKeyaccountName)
      .map(withWarning(mapCategoryToProductGroup))
      .map(withWarning(convertRevenueValues))
      .reduce(mergeByNameByOrderTypeByYear, {data: {}, warnings: []})
    ;

    return {data: roundToThousands(accumulated.data), ...accumulated};
  }
}

class Updater {
  constructor(private accumulator, private item) {
  }

  by(property: string) {
    const name = this.item[property];
    const existing = this.accumulator[name];

    if (!existing) {
      this.accumulator[name] = {};
    }

    this.accumulator = this.accumulator[name];
    return this;
  }

  addBy(keyColumn: string, valueColumn: string) {
    const keyName = this.item[keyColumn];
    const value = this.item[valueColumn];
    this.accumulator[keyName] = (this.accumulator[keyName] || 0) + value;
  }
}
