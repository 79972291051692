import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { DomainValue } from "../../customer.model";
import { KeyaccountPlan } from '../../keyaccount-plan.model';
import { AbstractTabComponent } from "../profile/abstract-tab.component";
import { toNumbers, toNumber } from '../utils';

@Component({
  selector: 'masterplanner2-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalysisComponent extends AbstractTabComponent {
  servicelines$: Observable<DomainValue[]>;
  buyingcriteria$: Observable<DomainValue[]>;

  setupObservables() {
    this.servicelines$ = this.customer$.pipe(
      map(customer => customer.servicelines),
    );
    this.buyingcriteria$ = this.customer$.pipe(
      map(customer => customer.buyingcriteria),
    );
  }

  buildForm() {
    return this.formBuilder.group({
      'uid': [],
      'accountRevenues': [],
      'servicelines': [],
      'projects': [],
      'opportunities': [],
      'buyingpowers': [],
      'dmus': [],
      'brickwall': [],
    });
  }

  mapFromDatabase(keyaccountPlan: KeyaccountPlan): any {
    return {
      ...keyaccountPlan,
      accountRevenues: {
        orderIntakeTarget: keyaccountPlan.orderIntakeTarget,
        orderIntake: keyaccountPlan.orderIntake,
        orderBacklog: keyaccountPlan.orderBacklog,
        targetRevenue: keyaccountPlan.targetRevenue,
        customerRevenue: keyaccountPlan.customerRevenue,
        orderSwotReason: keyaccountPlan.orderSwotReason ? keyaccountPlan.orderSwotReason : null,
        revenueSwotReason: keyaccountPlan.revenueSwotReason ? keyaccountPlan.revenueSwotReason : null,
        sow: keyaccountPlan.sow,
        profitMargin: keyaccountPlan.profitMargin
      },
      orderIntakeTarget: keyaccountPlan.orderIntakeTarget ? toNumbers(keyaccountPlan.orderIntakeTarget) : {},
      orderIntake: keyaccountPlan.orderIntake ? toNumbers(keyaccountPlan.orderIntake) : {},
      orderBacklog: keyaccountPlan.orderBacklog ? toNumbers(keyaccountPlan.orderBacklog) : {},
      targetRevenue: keyaccountPlan.targetRevenue ? toNumbers(keyaccountPlan.targetRevenue) : {},
      customerRevenue: keyaccountPlan.customerRevenue ? toNumbers(keyaccountPlan.customerRevenue) : {},
      opportunities: keyaccountPlan.opportunities ? keyaccountPlan.opportunities.map(opportunity => { return { ...opportunity, totalDevelopmentCost: opportunity.totalDevelopmentCost ? toNumber(opportunity.totalDevelopmentCost) : 0, bokaValue: opportunity.bokaValue ? toNumber(opportunity.bokaValue) : 0} }) : []
    };
  }

  mapToDatabase(keyaccountPlan: any): Partial<KeyaccountPlan> {
    const partialKeyaccountPlan = {
      ...keyaccountPlan,
      orderIntakeTarget: keyaccountPlan.accountRevenues.orderIntakeTarget,
      orderIntake: keyaccountPlan.accountRevenues.orderIntake,
      orderBacklog: keyaccountPlan.accountRevenues.orderBacklog,
      targetRevenue: keyaccountPlan.accountRevenues.targetRevenue,
      customerRevenue: keyaccountPlan.accountRevenues.customerRevenue,
      orderSwotReason: keyaccountPlan.accountRevenues.orderSwotReason ? keyaccountPlan.accountRevenues.orderSwotReason : null,
      revenueSwotReason: keyaccountPlan.accountRevenues.revenueSwotReason ? keyaccountPlan.accountRevenues.revenueSwotReason : null,
      sow: keyaccountPlan.accountRevenues.sow,
      profitMargin: keyaccountPlan.accountRevenues.profitMargin
    }
    delete partialKeyaccountPlan.accountRevenues;
    return partialKeyaccountPlan;
  };
}
