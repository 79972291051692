import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from "@angular/router";
import { KeyaccountPlanService } from "../keyaccount-plan.service";
import { Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";

@Component({
  selector: 'masterplanner2-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit, OnDestroy {

  /** name should match the route path suffix */
  steps = [
    { name: 'dashboard', displayName: '01_DASHBOARD', forMatrix: false},
    { name: 'profile',   displayName: '02_ACCOUNT_PROFILE', forMatrix: false },
    { name: 'analysis',  displayName: '03_ACCOUNT_ANALYSE', forMatrix: false },
    { name: 'swot',      displayName: '04_SWOT_ANALYSE', forMatrix: false },
    { name: 'matrix',    displayName: '05_CONFRONTATION_MATRIX', forMatrix: true },
    { name: 'strategy',  displayName: '06_ACCOUNT_STRATEGY_GOALS', forMatrix: false },
    { name: 'activity',  displayName: '07_ACCOUNT_ACTIVITY_PLAN', forMatrix: false },
    { name: 'lessons',   displayName: '08_ACCOUNT_LESSONS_LEARNED', forMatrix: false },
  ];

  @Input()
  canSave: boolean;

  @Output()
  save = new EventEmitter();

  currentStep: number;
  keyaccountPlanUid: string;
  canGoNext: boolean;
  canGoPrevious: boolean;

  stepsForm: FormGroup;
  noMatrix = true;

  paramIdSubscriptions: Subscription;
  featuresSubscriptions: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute, 
    private location: Location,
    private fb: FormBuilder,
    private router: Router,
    private keyaccountPlanService: KeyaccountPlanService
  ) { }

  ngOnInit() {
    this.paramIdSubscriptions = this.activatedRoute.paramMap.subscribe(params => {
      this.keyaccountPlanUid = (params.get('id'));
    });

    this.featuresSubscriptions = this.activatedRoute.paramMap.pipe(
      switchMap(params => this.keyaccountPlanService.getKeyaccountPlan(params.get('id')))
    ).subscribe(keyAccountPlan => {
      this.noMatrix = (keyAccountPlan && keyAccountPlan.featureToggles && keyAccountPlan.featureToggles.noMatrix);
    });

    const pathSuffix = this.location.path(false).split('/')[3];
    this.currentStep = this.steps.findIndex(step => step.name === pathSuffix);

    this.stepsForm = this.fb.group({
      stepsControl: [this.steps[this.currentStep]]
    });

    this.canGoNext = !!this.getNextStep();
    this.canGoPrevious = !!this.getPreviousStep();
  }

  ngOnDestroy() {
    if(this.paramIdSubscriptions) {
      this.paramIdSubscriptions.unsubscribe();
    }
    if(this.featuresSubscriptions) {
      this.featuresSubscriptions.unsubscribe();
    }
  }

  changeStepViaDropdown(event: any) {
    this.save.emit(event);
    this.router.navigate(['/keyaccountplan/' + this.keyaccountPlanUid + '/' + this.getRelevantSteps()[event.target.selectedIndex].name]);
  }

  goToPreviousStep(event: any) {
    const previousStep = this.getPreviousStep();
    if(previousStep) {
      if(this.canSave) {
        this.save.emit(event);
      }
      this.router.navigate(['/keyaccountplan/' + this.keyaccountPlanUid + '/' + previousStep.name]);
    }
  }

  goToNextStep(event: any) {
    const nextStep = this.getNextStep();
    if(nextStep) {
      if(this.canSave) {
        this.save.emit(event);
      }
      this.router.navigate(['/keyaccountplan/' + this.keyaccountPlanUid + '/' + nextStep.name]);
    }
  }

  getRelevantSteps() {
    return this.steps.filter(step => !step.forMatrix || !this.noMatrix);
  }

  doSave(event: any){
    this.save.emit();
  }

  saveAndClose(event: any) {
    this.save.emit();
    this.router.navigate(['/keyaccountplans']);
  }

  private getNextStep() {
    let nextStepNr = this.currentStep + 1;
    while(this.noMatrix && this.steps[nextStepNr] && this.steps[nextStepNr].forMatrix) {
      nextStepNr++;
    }
    return this.steps[nextStepNr];
  }

  private getPreviousStep() {
    let previousStepNr = this.currentStep - 1;
    while(this.noMatrix && this.steps[previousStepNr] && this.steps[previousStepNr].forMatrix) {
      previousStepNr--;
    }
    return this.steps[previousStepNr];
  }
}
