<div class="row">
  <div class="col-sm-12">
    <div class="separator col-sm-12">
      {{ 'ACTIVITYPLAN_DASHBOARD' | translate }}
    </div>
  </div>
  <div class=" col-sm-12 form-group" style="margin-bottom: 20px">
    <div *ngFor="let plan of (keyaccountPlans$ | async)" class="col-sm-12 mp-activityplan">
      <div *ngIf="getStrategicActions(plan) as activities" class="panel panel-default">
        <div class="panel-heading" role="tab">
          <h4 class="panel-title">
            <a role="button" class="accordiongroup-toggle" (click)="toggleOpen(plan.uid)" style="cursor: pointer">
              <div class="col-sm-2 text-right">
                <masterplanner2-image-preview [path]="'keyaccount/' + plan.keyaccountUid + '/images/logo'"
                                              class="pull-right" maxHeight="20px"></masterplanner2-image-preview>
              </div>
              <div class="col-sm-4">{{ plan.keyaccountName }}</div>
              <div class="col-sm-2">{{ plan.version }}</div>
              <div class="col-sm-1">{{ plan.targetYear }}</div>
              <div class="col-sm-3">{{'NUMACTIVITIES' | translate}}:
                {{ activities.length }}
              </div>
            </a>
          </h4>
        </div>
        <div class="col-sm-12" *ngIf="activities.length && isOpen(plan.uid)">
          <table id="activityplans" class="table table-condensed activityplans col-sm-12">
            <tr>
              <th style="width:25%"> {{ 'CACTIVITYPLAN' | translate }} </th>
              <th style="width:25%"> {{ 'CRESPONSIBLE' | translate }} </th>
              <th style="width:25%"> {{ 'DTREALISED' | translate }} </th>
              <th style="width:25%"> {{ 'PROGRESS' | translate }} </th>
            </tr>
            <tr *ngFor="let row of activities">
              <td>
                <span title="{{ row.action }}">{{ row.action }}</span>
              </td>
              <td>
                <span title="{{ row.responsible }}">{{ row.responsible }}</span>
              </td>
              <td>
                <span>{{ row.endDate | date: 'dd-MM-yyyy'}}</span>
              </td>
              <td>
                <div class="progress-bar-dashboard-wrapper">
                  <div class="progress-bar-dashboard" *ngIf="row.progress as progress ">
                    <div id="progress-bar-dashboard-fill" class="text-center"
                         [ngStyle]="{ 'width': Math.round(progress) + '%' }"
                         [ngClass]="{ 'orange': progress < 33, 'yellow': progress >= 33 && progress < 67, 'green': progress >= 67 }">
                      <span *ngIf="progress >= 10">{{ Math.round(row.progress) }} %</span>
                    </div>
                    <div class="progress-bar-dashboard" *ngIf="!row.progress">
                      <span>0%</span>
                    </div>
                  </div>
                </div>

              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
