import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UsersGridComponent } from './users-grid/users-grid.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserCreateComponentComponent } from './user-create-component/user-create-component.component';
import { TranslateModule } from '@ngx-translate/core';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { EmailLoginComponent } from './email-login/email-login.component';
import { CdkTableModule } from '@angular/cdk/table';
import { RouterModule } from '@angular/router';
import { UserGroupPipe } from './user-group.pipe';
import { UserGroupService } from './user-group.service';
import { ToasterModule } from 'angular2-toaster';
import { NgSelectModule } from '@ng-select/ng-select';
import { DefaultUserModuleConfig, UserModuleConfig } from './users-module-config';
import { UserKeyaccountsPipe } from './user-keyaccounts.pipe';
import { UserPipe } from './user-pipe';
import { AuthGuard } from './auth-guard.service';
import { AuthTokenGuard } from './auth-token-guard.service';




@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TranslateModule,
    CdkTableModule,
    ToasterModule,
    NgSelectModule,
  ],
  declarations: [
    UsersGridComponent,
    UserCreateComponentComponent,
    EmailLoginComponent,
    UserGroupPipe,
    UserKeyaccountsPipe,
    UserPipe,
  ],
  providers: [
    UserService,
    UserGroupService,
    AuthService,
    AuthGuard,
    AuthTokenGuard,
    {provide: UserModuleConfig, useValue: DefaultUserModuleConfig}
  ],
  exports: [
    UsersGridComponent,
    UserCreateComponentComponent,
    EmailLoginComponent,
    UserGroupPipe,
    UserKeyaccountsPipe,
    UserPipe,
  ]
})
export class UsersModule {
}
