<tr *ngIf="sum$ | async as sum">
  <th class="text-right" width="20%">{{ (property | uppercase) + '_GRAPH' | translate }}<br/>
    <a (click)="expandKeyaccountPlans = ! expandKeyaccountPlans" class="show_more">
      <i class="glyphicon" [ngClass]="{'glyphicon-menu-down': !expandKeyaccountPlans, 'glyphicon-menu-up': expandKeyaccountPlans}"></i>
      <span *ngIf="!expandKeyaccountPlans"> {{ 'SHOW' | translate }} </span>
      <span *ngIf="expandKeyaccountPlans"> {{ 'HIDE' | translate }} </span>
    </a>
  </th>
  <td *ngFor="let year of yearSpan; index as i" class="text-center"><div>{{ sum[i] }}</div></td>
</tr>
<ng-container *ngIf="expandKeyaccountPlans">
  <tr *ngFor="let plan of (keyaccountPlans$ | async)">
    <th class="text-right">{{ plan.keyaccountName }}</th>
    <td *ngFor="let year of yearSpan; index as i" class="text-center">{{ getValueAsNumber(plan[property][year]) }}</td>
  </tr>
</ng-container>

