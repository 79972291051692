import { Component, Input, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KeyaccountPlanService } from '../keyaccount-plan.service';
import { User, UserService } from '@masterplanner2/users';
import { CustomerService } from '../../customer.service';
import { TranslateService } from '@ngx-translate/core';
import { DmuService } from '../dmu.service';
import { bufferTime, debounceTime, filter, first, map, pluck, shareReplay, switchMap, tap } from 'rxjs/operators';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { BuyingPowerTotals, KeyaccountPlan, ScoreMatrix, ScoreMatrixUserList } from '../../keyaccount-plan.model';
import { Customer, DomainValue } from '../../customer.model';
function computedStyleToInlineStyle(element, options:any = {}) {
  if (!element) {
    throw new Error("No element specified.");
  }

  if (options.recursive) {
    Array.from(element.children).forEach(child => {
      computedStyleToInlineStyle(child, options);
    });
  }

  const computedStyle = getComputedStyle(element);
  Array.from(computedStyle).forEach(property => {
    element.style[property] = computedStyle.getPropertyValue(property);
  });
}

function convertImagesToBase64 (element) {

  const regularImages = element.querySelectorAll("img");
  const canvas = element.ownerDocument.createElement('canvas');
  const ctx = canvas.getContext('2d');
  regularImages.forEach(  imgElement => {
    // preparing canvas for drawing
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    canvas.width = imgElement.naturalWidth;
    canvas.height = imgElement.naturalHeight;
    ctx.drawImage(imgElement, 0, 0);
    // by default toDataURL() produces png image, but you can also export to jpeg
    // checkout function's documentation for more details
    const dataURL = canvas.toDataURL();
    imgElement.setAttribute('src', dataURL);
  });
  canvas.remove();
}

@Component({
  selector: 'masterplanner2-print-flat',
  templateUrl: './print-flat.component.html',
  styleUrls: ['./print-flat.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PrintFlatComponent implements OnInit {
  readonly keyaccountplanId$ = new ReplaySubject();
  ready$: Observable<any>;
  @Input()
    testing;

  keyaccountPlan$: Observable<KeyaccountPlan>;
  user$: Observable<User>;
  userMainLanguage$: Observable<string>;
  customer$: Observable<Customer>;
  buyingPowerScores$: Observable<BuyingPowerTotals>;
  dmuScore$: Observable<number>;
  yearSpan$: Observable<number[]>;
  canEdit$: Observable<boolean>;

  developments$: Observable<any>;
  developmentOpportunities$: Observable<any>;
  strengthAndWeaknesses$: Observable<any>;
  strengthAndWeaknessDeployStrategy$: Observable<any>;
  servicelinesTotal$: Observable<any>;

  buyingcriteria$: Observable<DomainValue[]>;
  totalMatrix$: Observable<ScoreMatrix>;

  currentYear = (new Date()).getFullYear();

  constructor(
    activatedRoute: ActivatedRoute,
    protected keyaccountPlanService: KeyaccountPlanService,
    protected userService: UserService,
    protected customerService: CustomerService,
    protected translationService: TranslateService,
    protected dmuService: DmuService,
  ) {

    this.user$ = userService.getCurrentUser();
    this.userMainLanguage$ = userService.getCurrentUserMainLanguage();
    activatedRoute.params.pipe(
      pluck('id'),
      filter(id=> !!id),
      tap(id => this.keyaccountplanId$.next((id))),
      first()).subscribe();

    this.keyaccountPlan$ = this.keyaccountplanId$.pipe(
      filter(id=> !!id),
      switchMap(keyaccountPlanId => this.keyaccountPlanService.getKeyaccountPlan(<string>keyaccountPlanId)),
      tap(keyaccountPlan => console.log('keyaccountPlan', keyaccountPlan)),
      shareReplay(1),
    );

    this.customer$ = this.keyaccountPlan$.pipe(
      switchMap(keyaccountPlan => this.customerService.getCustomer(keyaccountPlan.groupUid)),
      tap(customer => console.log('customer', customer)),
      shareReplay(1),
    );

    this.canEdit$ = this.keyaccountPlan$.pipe(
      switchMap((keyaccountPlan) => this.keyaccountPlanService.canEdit(keyaccountPlan)),
      shareReplay(1),
    );

    this.yearSpan$ = this.keyaccountPlan$.pipe(
      map(keyaccountPlan => {
        return this.keyaccountPlanService.getYearSpan(keyaccountPlan.targetYear)
      }),
      shareReplay(1),
    );

    this.servicelinesTotal$ = combineLatest(this.keyaccountPlan$, this.yearSpan$).pipe(
      map(([keyaccountPlan, yearSpan]) => {
        const servicelines = keyaccountPlan.servicelines ?  keyaccountPlan.servicelines : {};
        return yearSpan.map(year => {
          const yearlySum = Object.keys(servicelines).reduce((acc, newValue) => {
            return acc + (servicelines[newValue] && servicelines[newValue][year] ? parseInt(<any>servicelines[newValue][year], 10) : 0)
          }, 0);
          return {year: year, sum: yearlySum}
        })
      }),
      map(yearlySumArr => yearlySumArr.reduce((acc, newValue) => {acc[newValue.year] = newValue.sum; return acc}, {})),
      // shareReplay(1),
    );

    this.buyingPowerScores$ = this.keyaccountPlan$.pipe(
      map(keyaccountPlan => this.keyaccountPlanService.calculateBuyingpowerTotals(keyaccountPlan.buyingpowers))
    );

    this.dmuScore$ = this.keyaccountPlan$.pipe(
      map(keyaccountPlan => this.dmuService.calculateDmuScore(keyaccountPlan.dmus.contacts))
    );

    this.developments$ = this.keyaccountPlan$.pipe(map(keyaccountPlan => keyaccountPlan.developments ? keyaccountPlan.developments.filter(development => development.type === 'development') : []));
    this.developmentOpportunities$         = this.keyaccountPlan$.pipe(map(keyaccountPlan => keyaccountPlan.developments ? keyaccountPlan.developments.filter(development => development.type === 'opportunities') : []));
    this.strengthAndWeaknesses$            = this.keyaccountPlan$.pipe(map(keyaccountPlan => keyaccountPlan.strengthAndWeaknesses ? keyaccountPlan.strengthAndWeaknesses.filter(development => development.type === 'sw') : []));
    this.strengthAndWeaknessDeployStrategy$ = this.keyaccountPlan$.pipe(map(keyaccountPlan => keyaccountPlan.strengthAndWeaknesses ? keyaccountPlan.strengthAndWeaknesses.filter(development => development.type === 'strategy') : []));

    this.buyingcriteria$ = this.customer$.pipe(
      map(customer => customer.buyingcriteria),
    );

    const scoreMatrixLists$: Observable<ScoreMatrixUserList> = this.keyaccountPlan$.pipe(
      switchMap(keyaccountPlan => this.keyaccountPlanService.getScoreMatrixUserList(keyaccountPlan.uid)),
      shareReplay(1),
    );

    this.totalMatrix$ = scoreMatrixLists$.pipe(
      map(matrixes => {
        const sumList = matrixes
          .reduce((totalMatrix, userMatrix) => this.matrixListSum(totalMatrix, userMatrix.cells), this.createEmptyMatrixList());
        return this.listToMatrix(sumList);

      }),
      tap(totalMatrix => console.log('total matrix:', totalMatrix)),
    );

    this.customer$.subscribe()
  }

  ngOnInit() {
  }


  getDomainDescription(domainList: {uid: string, description: string}[], uid: string): string {
    const domainValue = domainList.find(t => t.uid === uid);
    return domainValue ? domainValue.description : '-';
  }

  createEmptyMatrixList(matrixSize = 6): {id: number, value: number}[] {
    return Array.from({length: matrixSize * matrixSize}, () => ({})).map((v, i) => ({id: i + 1, value: 0}));
  }

  listToMatrix(scoreMatrixList: {id: number, value: number}[], matrixSize = 6): ScoreMatrix {
    const splitArrayInChunks = (arr, size) => {
      const resultArr = [];
      for(let i = 0; i < arr.length; i += size) {
        resultArr.push(arr.slice(i, i+size));
      }
      return resultArr;
    };

    return {
      rows: splitArrayInChunks(scoreMatrixList, matrixSize)
    };
  }

  matrixListSum(matrixList1, matrixList2) {
    return matrixList1.map((cell, cellIndex) => {
      const value1 = cell.value ? parseInt(cell.value, 10) : 0;
      const value2 = matrixList2[cellIndex] ? parseInt(matrixList2[cellIndex].value, 10) : 0;
      return {
        ...cell,
        value: value1 + value2,
      }
    })
  }
}
