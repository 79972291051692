import { Injectable } from '@angular/core';
import { isObject } from 'util';
import { AliasToName, ImportWarning, NameToUid } from './import/abstract-importer.service';
import { IntakeImportItem, IntakeImportResults, IntakeImportRow } from './intake-importer.service';

@Injectable({
  providedIn: 'root'
})
export class IntakeImporterTransformService {

  constructor() {
  }

  transform(
    data: IntakeImportRow[],
    keyaccountNameMap: AliasToName,
    keyaccountsNameToIdMap: NameToUid
  ):
    { data: IntakeImportResults, warnings: ImportWarning[] } {

    const mapObject = (obj, fn) => {
      const result = {};
      Object.keys(obj).map(key => result[key] = fn(obj[key]));
      return result;
    };

    const replaceKeyaccountNameWithUid = (entry: IntakeImportRow): IntakeImportItem => {
      const {'naam klant': name, ...values} = entry;
      const keyaccountName = keyaccountNameMap[name] ? keyaccountNameMap[name] : name;

      if (!keyaccountsNameToIdMap[keyaccountName]) {
        return {message: 'Keyaccount is not found for \'naam klant\'', elements: [keyaccountName]};
      }
      return {uid: keyaccountsNameToIdMap[keyaccountName], ...values};
    };

    const convertIntakeValue = (value: string): number => {
      const numericValue = parseInt(value.replace(/[.,]/g, ''), 10);
      return isNaN(numericValue) ? 0 : numericValue;
    };

    const convertIntakeValues = (entry: IntakeImportRow): IntakeImportRow => {
      const {uid, ...values} = entry;
      return {uid, ...(mapObject(values, convertIntakeValue))};
    };

    const sumValuesPerYear = (entry: IntakeImportRow): IntakeImportRow => {
      const {uid, 'Eindtotaal': total, ...values} = entry;
      //TODO: implement overflow detection or in any case make explicit what the limit is for a valid value
      //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number#Properties
      // to bad it does not actually become infinity but instead is capped at Number.MAX_VALUE
      const add = (a, b) => (a || 0) + b;

      const newValues = Object.keys(values).reduce((accumulator, periodLabel) => {
        const [, year] = periodLabel.split('.');
        accumulator[year] = add(accumulator[year], values[periodLabel]);
        return accumulator;
      }, {});

      return {uid, ...newValues};
    };

    const mergeItem = (item, existing) => {
      if (!existing) {
        return item;
      }

      Object.keys(item).forEach(key => {
        if (key !== 'uid') {
          if (existing[key]) {
            existing[key] = item[key] + existing[key];
          } else {
            existing[key] = existing[key];
          }
        }
      });

      return existing;
    };

    const sumValuesPerClient = (accumulator, item: IntakeImportItem) => {
      if ((<ImportWarning>item).message !== undefined) {
        accumulator.warnings.push(item);
      } else {
        accumulator.data[item['uid']] = mergeItem(item, accumulator.data[item['uid']]);
      }
      return accumulator;
    };

    const withWarning = (fn) => {
      return (item: IntakeImportItem) => {
        if ((<ImportWarning>item).message !== undefined) {
          return item;
        } else {
          return fn(item);
        }
      };
    };

    const removeUids = (transformed) => {
      Object.keys(transformed).forEach(key => {
        delete transformed[key]['uid'];
      });
      return transformed;
    };

    const roundToThousands = (tree) => {
      Object.keys(tree).forEach(key => {
        if (isObject(tree[key])) {
          roundToThousands(tree[key]);
        } else {
          tree[key] = Math.round(tree[key] / 100000);
        }
      });
      return tree;
    };

    const accumulated = data
      .map(replaceKeyaccountNameWithUid)
      .map(withWarning(convertIntakeValues))
      .map(withWarning(sumValuesPerYear))
      .reduce(sumValuesPerClient, {data: {}, warnings: []});

    return {data: roundToThousands(removeUids(accumulated.data)), ...accumulated};
  }
}
