<!-- hack to get the style in the word export -->
<ng-container *ngIf="(customer$ | async) as customer else noCustomer">
  <ng-container *ngIf="(keyaccountPlan$ | async) as keyaccountPlan else noPlan">
    <ng-container *ngIf="(yearSpan$ | async) as yearSpan">
      <div id="printCanvas">
        <h1 style="text-align: center">Key Account Plan {{ keyaccountPlan.keyaccountName }}</h1>
        <h4 style="text-align: center">{{ keyaccountPlan.version }}</h4>

        <!-- account profile -->
        <h2 style="page-break-before: always">Account profile</h2>

        <h3>{{ 'ACCOUNT_INFORMATION' | translate }}</h3>

        <masterplanner2-revenue-flat *ngIf="keyaccountPlan.featureToggles.brancheRevenue"
                                     [revenueMap]="keyaccountPlan.brancheRevenue"
                                     [showHeader]="true"
                                     [yearSpan]="yearSpan"
                                     [name]="'brancherevenue'"
                                     [showIndex]="true"
        ></masterplanner2-revenue-flat>
        <masterplanner2-revenue-flat
                                [showHeader]="!keyaccountPlan.featureToggles.brancheRevenue"
                                [revenueMap]="keyaccountPlan.keyaccountRevenue"
                                [yearSpan]="yearSpan"
                                [name]="'keyaccountrevenue'"
                                [showIndex]="true"
        ></masterplanner2-revenue-flat>

        <!-- Revenue table -->
        <p><strong>{{ 'EXPLANATION_REMARKS' | translate }}</strong><br/>
          {{ keyaccountPlan.profileRemarks }}
        </p>
        <p>
          <strong>{{ 'CINDUSTRY' | translate }}</strong>: {{ getDomainDescription(customer.industries, keyaccountPlan.industry) }}
          <br/>
          <strong>{{ 'CCOMPANYTYPE' | translate }}</strong>: {{ getDomainDescription(customer.companytypes, keyaccountPlan.companyType) }}
          <br/>
        </p>
        <p><strong>{{ 'EXPLANATION_MISSION' | translate }}</strong><br/>
          {{ keyaccountPlan.mission }}
        </p>
        <p><strong>{{ 'EXPLANATION_STRATEGY' | translate }}</strong><br/>
          {{ keyaccountPlan.strategy }}
        </p>
        <p><strong>{{ 'CMAINGOALS_PRO' | translate }}</strong><br/>
          {{ keyaccountPlan.mainGoals }}
        </p>

        <h3>{{ 'MAINACTIVITIES_DESC' | translate }}</h3>
        <p>{{ keyaccountPlan?.mainActivities?.description}}</p>
        <table class="table">
          <tr *ngFor="let activity of keyaccountPlan?.mainActivities?.activities; let i = index">
            <th>{{ i }}</th><td>{{ activity.activity }}</td>
          </tr>
        </table>
        <p>&nbsp;</p>

        <h3>{{ 'CORGANOGRAM_PRO' | translate }} {{ keyaccountPlan.keyaccountName }}</h3>
        <masterplanner2-image-upload-preview
          [path]="'keyaccountplan/' + keyaccountPlan.uid + '/images/organogram'"></masterplanner2-image-upload-preview>
        <p>&nbsp;</p>
        <!-- Departments -->
        <p><strong>{{ 'IEMPLOYEESTOTAL_PRO' | translate }}</strong> {{ keyaccountPlan.employeesTotalCompany }}</p>
        <table class="table table-bordered">
          <thead>
          <tr>
            <th style="text-align: left">{{ 'DEPARTMENT_NAME' | translate }}</th>
            <th style="text-align: left">{{ 'NUMBER' | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of keyaccountPlan.departments">
            <td>{{ row.name }}</td>
            <td>{{ row.numEmployees }}</td>
          </tr>
          </tbody>
        </table>

        <h3>{{ keyaccountPlan.keyaccountName }} {{ 'MAINCOMPETITORS_PRO' | translate }}</h3>
        <table class="table table-bordered">
          <thead>
          <tr>
            <th style="text-align: left">{{ 'CCOMPETITORNAME' | translate }}</th>
            <th style="text-align: left">{{ 'CMAINPRODUCTS' | translate }}</th>
            <th style="text-align: left">{{ 'CSTRENGTHS' | translate }}</th>
            <th style="text-align: left">{{ 'CWEAKNESSES' | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of keyaccountPlan.competitors">
            <td>{{ row.name }}</td>
            <td>{{ row.mainProducts }}</td>
            <td>{{ row.strengths }}</td>
            <td>{{ row.weaknesses }}</td>
          </tr>
          </tbody>
        </table>

        <h3>{{ 'LPTS' | translate }} {{ keyaccountPlan.keyaccountName }}</h3>
        <table class="table table-bordered">
          <thead>
          <tr>
            <th style="text-align: left">{{ 'COPPORTUNITYNAME' | translate }}</th>
            <th style="text-align: left">{{ 'CBUSINESSUNIT' | translate }}</th>
            <th style="text-align: left">{{ 'CEXPECTEDAWARDDATE' | translate }}</th>
            <th style="text-align: left">{{ 'CEXECUTIONSTARTDATE' | translate }}</th>
            <th style="text-align: left">{{ 'CTOTALDEVELOPMENTCOST' | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of keyaccountPlan.opportunities">
            <td>{{ row.name }}</td>
            <td>{{ row.businessunit }}</td>
            <td>{{ row.expectedAwardDate }}</td>
            <td>{{ row.executionStartDate }}</td>
            <td>{{ row.totalDevelopmentCost }}</td>
          </tr>
          </tbody>
        </table>

        <h3>{{ keyaccountPlan.keyaccountName }} {{ 'EXTERNALDEVELOPMENTS_PRO' | translate }}</h3>
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>{{ keyaccountPlan.keyaccountName }} {{ 'PRODEVELOPMENTS' | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of (developments$ | async)">
            <td>{{ row.name }}</td>
          </tr>
          </tbody>
        </table>
        <p>&nbsp;</p>
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>{{ keyaccountPlan.keyaccountName }} {{ 'PROOPPORTUNITIES' | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of (developmentOpportunities$ | async)">
            <td>{{ row.name }}</td>
          </tr>
          </tbody>
        </table>

        <h3>{{ keyaccountPlan.keyaccountName }} {{ 'STRENGTHWEAKNESSEN_PRO' | translate }}</h3>
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>{{ keyaccountPlan.keyaccountName }} {{ 'PROSTRENGTHWEAKNESS' | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of (strengthAndWeaknesses$ | async)">
            <td>{{ row.name }}</td>
          </tr>
          </tbody>
        </table>
        <p>&nbsp;</p>
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>{{ keyaccountPlan.keyaccountName }} {{ 'PROSTRENGTHWEAKNESSDEPLOY' | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of (strengthAndWeaknessDeployStrategy$ | async)">
            <td>{{ row.name }}</td>
          </tr>
          </tbody>
        </table>

        <!-- Purchasing process -->
        <h3>{{ keyaccountPlan.keyaccountName }} {{ 'PURCHASEPROCESS_PRO' | translate }}</h3>

        <table *ngIf="keyaccountPlan.purchaseProcess as purchaseProcess" class="table table-bordered">
          <tr>
            <th width="25%"></th>
            <th width="12%"> {{ 'SPECIFY' | translate }}</th>
            <th width="12%"> {{ 'SELECT' | translate }}</th>
            <th width="12%"> {{ 'CONTRACT' | translate }}</th>
            <th width="12%"> {{ 'ORDER' | translate }}</th>
            <th width="12%"> {{ 'MONITOR' | translate }}</th>
            <th width="12%"> {{ 'EVALUATIONFOLLOWUP' | translate }}</th>
          </tr>
          <tr>
            <th width="25%"> {{ 'FUNCTION_DECISION' | translate }}</th>
            <td width="12%">
              {{ purchaseProcess.specifyDecision }}
            </td>
            <td width="12%">
              {{ purchaseProcess.selectDecision }}
            </td>
            <td width="12%">
              {{ purchaseProcess.contractDecision }}
            </td>
            <td width="12%">
              {{ purchaseProcess.orderDecision }}
            </td>
            <td width="12%">
              {{ purchaseProcess.monitorDecision }}
            </td>
            <td width="12%">
              {{ purchaseProcess.evaluateDecision }}
            </td>
          </tr>
          <tr>
            <th width="25%">{{ 'FUNCTION_PROCESS' | translate }}</th>
            <td width="12%">
              {{ purchaseProcess.specifyInvolved }}
            </td>
            <td width="12%">
              {{ purchaseProcess.selectInvolved }}
            </td>
            <td width="12%">
              {{ purchaseProcess.contractInvolved }}
            </td>
            <td width="12%">
              {{ purchaseProcess.orderInvolved }}
            </td>
            <td width="12%">
              {{ purchaseProcess.monitorInvolved }}
            </td>
            <td width="12%">
              {{ purchaseProcess.evaluateInvolved }}
            </td>
          </tr>
          <tr>
            <td colspan="7">
              <strong>{{ 'GENERAL_REMARK_PURCHASING_PROCESS' | translate }}</strong><br/>
              <p>{{ purchaseProcess.purchaseExplanation }}</p>
            </td>
          </tr>
        </table>

        <!-- Buying behavior -->
        <h3>{{ 'BUYINGBEHAVIOR_PRO' | translate }} {{ keyaccountPlan.keyaccountName }}</h3>
        <table class="table table-bordered">
          <tbody>
          <tr *ngFor="let row of (keyaccountPlan.buyingBehavior?.behaviors | keyvalue)">
            <td>{{ row?.value?.name }}</td>
          </tr>
          </tbody>
        </table>
        <p><strong>{{ 'EXPLANATION' | translate }}</strong><br/>
          {{ keyaccountPlan.buyingBehavior?.explanation }}
        </p>

        <!-- Critical roles -->
        <h3>{{ 'TITLE_DMU_PRO' | translate }} {{ keyaccountPlan.keyaccountName }}</h3>
        <table class="table table-bordered">
          <thead>
          <tr>
            <th style="text-align: left">{{ 'DMUS_NAME' | translate }}</th>
            <th style="text-align: left">{{ 'DMUS_FUNCTION' | translate }}</th>
            <th style="text-align: left">{{ 'DMUS_ROLE' | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of keyaccountPlan.dmus?.contacts">
            <td>{{ row.firstname }} {{ row.prefix }} {{ row.surname }}</td>
            <td>{{ row.function }}</td>
            <td>{{ row.role | translate }}</td>
          </tr>
          </tbody>
        </table>
        <p>&nbsp;</p>


        <!-- Account Analyse -->
        <h2 style="page-break-before: always">{{ 'ACCOUNTANALYSIS_PRO' | uppercase | translate }}</h2>
        <h3> {{ keyaccountPlan.customerName }}
          {{ 'POSITION_AT' | translate }}
          {{ keyaccountPlan.keyaccountName }}
        </h3>
        <p>&nbsp;</p>

        <table class="table table-bordered">
          <thead>
          <tr>
            <td></td>
            <th *ngFor="let year of yearSpan" style="text-align: left">
              {{ year }}
            </th>
          </tr>
          </thead>
          <tbody masterplanner2-revenue-print *ngIf="keyaccountPlan.featureToggles.orderIntakeTarget"
                 [value]="keyaccountPlan.orderIntakeTarget"
                 [yearSpan]="yearSpan"
                 [name]="'orderintaketarget'"
                 [highlightCurrentYear]="true"
          ></tbody>
          <tbody masterplanner2-revenue-print *ngIf="keyaccountPlan.featureToggles.orderIntake"
                 [value]="keyaccountPlan.orderIntake"
                 [yearSpan]="yearSpan"
                 [name]="'orderintake'"
                 [highlightCurrentYear]="true"
          ></tbody>
          <tbody masterplanner2-revenue-print *ngIf="keyaccountPlan.featureToggles.orderBacklog"
                 [value]="keyaccountPlan.orderBacklog"
                 [yearSpan]="yearSpan"
                 [name]="'orderbacklog'"
                 [highlightCurrentYear]="true"
                 [lastRowHighlight]="true"
          ></tbody>
          <tbody masterplanner2-revenue-print
                 [value]="keyaccountPlan.targetRevenue"
                 [yearSpan]="yearSpan"
                 [name]="'targetrevenue'"
                 [highlightCurrentYear]="true"
          ></tbody>
          <tbody masterplanner2-revenue-print
                 [value]="keyaccountPlan.customerRevenue"
                 [yearSpan]="yearSpan"
                 [name]="'customerrevenue'"
                 [highlightCurrentYear]="true"
                 [showIndex]="true"
          ></tbody>
          <tbody masterplanner2-revenue-print
                 [value]="keyaccountPlan.sow"
                 [yearSpan]="yearSpan"
                 [name]="'estimatedSow'"
                 [mode]="'estimatedSow'"
                 [highlightCurrentYear]="true"
          ></tbody>
          <tbody masterplanner2-revenue-print *ngIf="keyaccountPlan.featureToggles.profitMargin"
                 [value]="keyaccountPlan.profitMargin"
                 [yearSpan]="yearSpan"
                 [name]="'profitMargin'"
                 [mode]="'profitMargin'"
                 [highlightCurrentYear]="true"
          ></tbody>
        </table>

        <p>&nbsp;</p>
        <h3>{{ 'PRODUCT_SERVICELINES_PRO' | translate }}</h3>
        <table class="table table-bordered table-servicelinespro" style="margin-left: 2px;">
          <tr>
            <th class="text-center" width="20%">{{ 'ACTUALREVENUE' | translate }}</th>
            <th class="text-center" width="8%%" *ngFor="let year of (yearSpan$ | async)">{{ year }}</th>
            <th class="text-center" width="18%"> {{ 'COMPETITION' | translate }}</th>
            <th class="text-center" width="10%">
              <!--<span ng-bind-html="vm.oEndpoint.cCustomername"></span>-->
              <span>{{ 'POSITION' | translate }}</span>
            </th>
          </tr>
          <tr *ngFor="let serviceline of keyaccountPlan.servicelines | keyvalue" class="serviceline">
            <th>
              {{ serviceline.value.description }}
            </th>
            <td class="text" *ngFor="let year of (yearSpan$ | async)"> {{ serviceline.value[year] }}</td>
            <td>{{ serviceline.value.competitor1 }} <br/> {{ serviceline.value.competitor2 }}</td>
            <td>{{ serviceline.value.positionCompetition1 | translate }}
              <br/> {{ serviceline.value.positionCompetition2 |
                translate }}
            </td>
          </tr>
          <tr class="totalrow">
            <th class="text-left" width="20%" style="padding-top: 16px;"
            > {{ 'TOTAL' | translate }}
            </th>
            <th *ngFor="let year of (yearSpan$ | async)" style="text-align: left" width="8%">
              {{ (servicelinesTotal$ | async) && (servicelinesTotal$ | async)[year] }}
            </th>
          </tr>
        </table>

        <p>&nbsp;</p>
        <h3> {{ keyaccountPlan.customerName }} {{ 'PROJECTS_TENDERS' | translate }}</h3>
        <p>&nbsp;</p>
        <table class="table table-condensed buyingpowerindices col-sm-12">
          <thead>
          <tr>
            <th colspan="5">
              {{ 'WON_LOST_PROJECTS' | translate }}
            </th>
          </tr>
          <tr>
            <th width="30%" style="text-align: left">{{ 'OPPORTUNITYNAME' | translate }}</th>
            <th width="15%" style="text-align: left">{{ 'STATUS' | translate }}</th>
            <th width="35%" style="text-align: left">{{ 'STATUS_REASON' | translate }}</th>
            <th width="20%" style="text-align: left">{{ 'EXPIRY_DATE' | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of (keyaccountPlan.projects?.projects | keyvalue)">
            <td>{{ row.value.opportunityName }}</td>
            <td>{{ row.value.status | translate }}</td>
            <td>{{ row.value.statusReason }}</td>
            <td>{{ row.value.expiryDate }}</td>
          </tr>
          </tbody>
        </table>

        <p>&nbsp;</p>
        <h3>{{'LPTS_STEP3' | translate }}</h3>
        <p>&nbsp;</p>
        <table
          id="lpts"
          class="table table-condensed lpts col-sm-12">
          <tr>
            <th style="text-align: left">{{ 'COPPORTUNITYNAME' | translate }}</th>
            <th style="text-align: left">{{ 'CBUSINESSUNIT' | translate }}</th>
            <th style="text-align: left">{{ 'CEXPECTEDAWARDDATE' | translate }}</th>
            <th style="text-align: left">{{ 'CEXECUTIONSTARTDATE' | translate }}</th>
            <th style="text-align: left">{{ 'CTOTALDEVELOPMENTCOST' | translate }}</th>
            <th style="text-align: left">{{ 'BOKAVALUE' | translate }}</th>
            <th style="width: 5%; text-align: left">{{ 'GO%' | translate }}</th>
          </tr>
          <tr *ngFor="let row of (keyaccountPlan.opportunities | keyvalue)">
            <td> {{ row.value.name }}</td>
            <td> {{ row.value.businessunit }}</td>
            <td> {{ row.value.expectedAwardDate }}</td>
            <td> {{ row.value.executionStartDate }}</td>
            <td> {{ row.value.totalDevelopmentCost }}</td>
            <td> {{ row.value.bokaValue }}</td>
            <td> {{ row.value.goPercentage }}</td>
          </tr>
        </table>
        <p>&nbsp;</p>
        <!-- Buying power index -->
        <p>&nbsp;</p>
        <h3>{{ keyaccountPlan.keyaccountName }} {{ 'TITLE_BUYING_POWER_INDEX_PRO' | translate }}</h3>

        <p>&nbsp;</p>
        <masterplanner2-buyingpower-flat
          *ngIf="buyingcriteria$ | async as buyingcriteria"
          [buyingcriteria]="buyingcriteria"
          [buyingpowers]="keyaccountPlan.buyingpowers"
          [customerName]="keyaccountPlan.customerName"
        ></masterplanner2-buyingpower-flat>
        <p>&nbsp;</p>

        <!-- Critical contacts -->
        <h3> {{ keyaccountPlan.customerName }} {{ 'TITLE_DMU_PRO_STEP3' | translate }} {{ keyaccountPlan.keyaccountName }} </h3>
        <masterplanner2-critical-contacts-flat
          [contacts]="keyaccountPlan.dmus?.contacts"></masterplanner2-critical-contacts-flat>
        <p>&nbsp;</p>

        <h3 *ngIf="keyaccountPlan.featureToggles.brickwall">
          {{ keyaccountPlan.customerName }} {{ 'BRICKWALLCRITERIUMS' | translate }} {{ keyaccountPlan.keyaccountName }}
        </h3>
        <masterplanner2-brickwall-flat *ngIf="keyaccountPlan.featureToggles.brickwall" [criteria]="keyaccountPlan.brickwall"></masterplanner2-brickwall-flat>
        <p>&nbsp;</p>

        <!-- SWOT analyse -->
        <h2
          style="page-break-before: always">{{ 'SWOT_PRO' | uppercase | translate }} {{ keyaccountPlan.keyaccountName }}</h2>
        <p>&nbsp;</p>

        <!-- swot table -->
        <masterplanner2-swot-table [keyaccountPlan$]="keyaccountPlan$"></masterplanner2-swot-table>
        <p>&nbsp;</p>
        <p>&nbsp;</p>


        <!-- Matrix -->
        <ng-container *ngIf="!keyaccountPlan.featureToggles.noMatrix">
        <h2 style="page-break-before: always">{{ 'CONFRONTATIONMATRIX_PRO' | translate }}</h2>
        <p>&nbsp;</p>
        <masterplanner2-matrix-flat [matrix]="keyaccountPlan.matrix"
                                    [scoreMatrix]="totalMatrix$ | async"></masterplanner2-matrix-flat>
        <p>&nbsp;</p>
        </ng-container>


        <!-- Strategy -->
        <h2 style="page-break-before: always">{{ 'ACCOUNTSTRATEGY_PRO' | translate }}</h2>
        <ng-container *ngIf="!keyaccountPlan.featureToggles.noMatrix">
          <p>&nbsp;</p>
          <h3>{{ 'TITLE_ACCOUNTSTRATEGY' | translate }}</h3>
          <masterplanner2-strategic-actions-flat
            [actions]="keyaccountPlan.strategicActions"></masterplanner2-strategic-actions-flat>
          <p>&nbsp;</p>
        </ng-container>

        <h3>{{ 'TITLE_ACCOUNTGOALS' | translate }}</h3>
        <masterplanner2-account-goals-flat [showOtherGoals]="keyaccountPlan.featureToggles.otherGoals"
          [accountGoals]="keyaccountPlan.accountGoals"></masterplanner2-account-goals-flat>


        <!-- Activity -->
        <h2 style="page-break-before: always">{{ 'ACTIVITYPLAN_PRO' | translate }}</h2>
        <p>&nbsp;</p>
        <h3>{{ 'TITLE_YEARPLAN' | translate }} {{ keyaccountPlan.customerName }}</h3>
        <masterplanner2-activity-planning-flat
          [activities]="keyaccountPlan.strategicActions"></masterplanner2-activity-planning-flat>
        <p>&nbsp;</p>

      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #noCustomer>No customer</ng-template>
<ng-template #noPlan>No plan</ng-template>
