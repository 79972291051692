import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Customer } from '../../customer.model';
import { CustomerService } from '../../customer.service';
import { UserService } from '@masterplanner2/users';
import { ToasterService } from 'angular2-toaster';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { Bundle, BundleService } from '../bundle.service';
import { BundleDeleteDialogComponent } from '../bundle-delete-dialog/bundle-delete-dialog.component';

@Component({
  selector: 'masterplanner2-bundle-list',
  templateUrl: './bundle-list.component.html',
  styleUrls: ['./bundle-list.component.css']
})
export class BundleListComponent implements OnInit {
  displayedColumns = ['name', 'description', 'numberOfPlans', 'customerName', 'actionsColumn'];

  bundles$: Observable<Bundle[]>;
  filterOptions$: Observable<{ [filterKey: string]: string[]}>;
  filteredBundles$: Observable<Bundle[]>;

  form: FormGroup;
  customer$: Observable<Customer | null>;

  constructor(private bundleService: BundleService,
              private customerService: CustomerService,
              private userService: UserService,
              private toasterService: ToasterService,
              private dialog: MatDialog,
              private formBuilder: FormBuilder
  )
  {
    this.form = formBuilder.group({
      'name'          : [],
      'customerName'  : [],
      'description'   : [],
    });

    this.customer$ = this.userService.getCurrentUser().pipe(
      switchMap(user => user ? this.customerService.getCustomer(user.groupUid) : of(null)),
      shareReplay(1),
    )
  }

  ngOnInit() {
    this.bundles$ = this.bundleService.getBundles();

    this.filteredBundles$ = combineLatest(this.bundles$, this.form.valueChanges.pipe(startWith(this.form.value))).pipe(
      map(([keyaccountPlans, filtersForm]) => {
        const filterKeys = Object.keys(filtersForm);
        const filterMap = filterKeys
          .map(field =>({key: field, value: filtersForm[field] ? filtersForm[field].toLowerCase(): null}))
          .filter(filterField => !!filterField.value)
        ;
        const filterFn = (keyaccountPlan) => {
          return filterMap.reduce((acc, filter) => {
            return acc && keyaccountPlan[filter.key] && keyaccountPlan[filter.key].toString().toLowerCase().includes(filter.value);
          }, true)
        };
        return filterMap.length ? keyaccountPlans.filter(filterFn) : keyaccountPlans;
      })
    );

    this.filterOptions$ = this.filteredBundles$.pipe(
      map(bundles => this.createFilterOptionsFromBundles(bundles)),
    )
  }

  createFilterOptionsFromBundles(bundles: Bundle[], fields = ['name', 'customerName', 'description']): {[filterKey:string]: string[]} {
    // Create the inital options {someField: Set<string>, someOtherField: Set<string>}
    const initialFilterOptions = fields.reduce((acc, field) => ({...acc, [field]: new Set()}), {});

    const filterOptionSets = bundles.reduce((acc, keyaccountPlan) => {
      // Add all the fields to the filterOptions
      fields.forEach(field => {
        if(keyaccountPlan[field]) {
          acc[field].add(keyaccountPlan[field]);
        }
      });
      return acc;
    }, initialFilterOptions);

    // Change Sets to arrays
    return fields.reduce((acc, field) => ({...acc, [field]: Array.from(filterOptionSets[field])}), {});
  }

  onDelete(uid: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.position = { top: '100px' };

    this.dialog.open(BundleDeleteDialogComponent, dialogConfig)
      .afterClosed().subscribe(result => {
      if (result) {
        this.bundleService.deleteBundle(uid)
          .then(() => {
            this.toasterService.pop('success', 'Bundle is succesfully deleted.');
          })
          .catch(error => {
            console.error(error);
          });
      }
    })
  }

  trackByUId(index, item) {
    return item.uid;
  }

  resetFiltersForm(): void {
    this.form.reset();
  }
}
