<table id="opportunities" class="table table-condensed swots col-sm-12" [formGroup]="form">
  <tr class="tableheader">
  </tr>
  <tr>
    <th colspan="4">
      {{ 'TITLE_SCORE_FORM_' + swotType | translate }}
    </th>
  </tr>
  <tr *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i" >
    <td class="col-sm-6">
      <input formControlName="uid" type="hidden" >
      {{ row.get('name').value }}
      <input formControlName="score" type="hidden" >
    </td>
    <td class="col-sm-3 text-right">
      <div class="btn-group">
        <label class="btn" [class.btn-success]="getScore(i) == 1" (click)="setScore(i, 1)" [class.disabled]="!canScore(1) && getScore(i) != 1">1</label>
        <label class="btn" [class.btn-success]="getScore(i) == 2" (click)="setScore(i, 2)" [class.disabled]="!canScore(2) && getScore(i) != 2">2</label>
        <label class="btn" [class.btn-success]="getScore(i) == 3" (click)="setScore(i, 3)" [class.disabled]="!canScore(3) && getScore(i) != 3">3</label>
      </div>
    </td>
  </tr>
</table>
