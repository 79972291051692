<table id="brickwallcriteriums" class="table table-condensed brickwallcriteriums">
  <tr>
    <th> {{ 'BRICKWALL_CRITERIUM' | translate }} </th>
    <th class="text-center" colspan="3"> {{ 'BRICKWALL_ISCORE' | translate }} </th>
    <th class="text-center"> {{ 'BRICKWALL_ITARGET' | translate }} </th>
  </tr>
  <tr *ngFor="let row of criteria; let i = index;" >
    <td class="col-sm-4 text-left">
      {{row.description | translate }}
    </td>
    <td *ngFor="let option of ['0', '1', '2']" id="{{ row['option' + option] }}"
           [ngClass]="{'text-strong': row.score == option }"
    >
      {{ row['option' + option] }}
    </td>
    <td [ngClass]="{'text-strong': (row.target <= row.score)}">
        {{ row['option' + row.target] }}
    </td>
  </tr>
  <tr class="totalline">
    <th colspan="5" style="text-align: left">
      {{ 'BRICKWALLINGSCORE' | translate }} : {{ countBrickwall(criteria) }}
    </th>
  </tr>
</table>
