import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { User } from './user';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly collectionName = 'users';

  private user$: Observable<User | null>;
  private users$: Observable<User[]>;
  private hasCurrentUser$: Observable<boolean>;
  private userCollection: AngularFirestoreCollection<User>;

  constructor(private db: AngularFirestore, private authService: AuthService) {
    this.userCollection = this.db.collection(this.collectionName);
    this.user$ = authService.uid$.pipe(
      tap(uid => console.log({ uid })),
      switchMap(uid => uid ? this.userCollection.doc(uid).valueChanges() : of(null)),
      map(user => user ? this.mapFromDatabase(user) : user),
      switchMap(user => user ?
        db.collection('customers').doc(user.groupUid).valueChanges().pipe(map((group) => {
          return { ...user, group };
        })) : of(null)
      ),
      tap(user => console.log({ user })),
      shareReplay(1)
    );

    this.hasCurrentUser$ = this.user$.pipe(map(user => user !== null));

    this.users$ = this.user$.pipe(
      switchMap(user => {
          let queryFn = ref => ref.where('uid', '==', user.uid);
          if (user.roles.includes('ADMIN')) {
            queryFn = ref => ref;
          } else if (user.roles.includes('GROUPADMIN')) {
            queryFn = ref => ref.where('groupUid', '==', user.groupUid);
          }
          return this.db.collection(this.collectionName, queryFn).valueChanges()
            .pipe(
              map(users => users.map(this.mapFromDatabase))
            );
        }
      ),
      shareReplay(1)
    );

  }

  public getCurrentUser(): Observable<User> {
    return this.user$;
  }

  public getCurrentUserMainLanguage(): Observable<string> {
    return this.user$.pipe( // Default to english if there is no user yet
      map(user => user && user.language ? user.language.split('_')[0] : 'en-US')
    );
  }

  public hasCurrentUser() {
    return this.hasCurrentUser$;
  }

  public getCurrentUserHasAnyRole(roles: any[]): Observable<boolean> {
    return this.user$.pipe(
      map(user => user && user.roles && user.roles.filter(role => roles.includes(role)).length > 0)
    );
  }

  public getUsers(): Observable<User[]> {
    return this.users$;
  }

  public getUser(uid: string): Observable<User> {
    return <Observable<any>> this.userCollection.doc(uid).valueChanges()
      .pipe(
        map(user => this.mapFromDatabase(user))
      );
  }

  public getUserEmail(uid: string): Observable<string> {
    return this.userCollection.doc<User>(uid).valueChanges().pipe(
      map(user => user.email)
    );
  }

  createUser(user: User) {
    const dbUser = this.mapToDatabase(user);
    return this.authService.register(dbUser.email)
      .then(userCredential => this.userCollection.doc(userCredential.user.uid).set({
        ...dbUser,
        uid: userCredential.user.uid
      }))
      ;
  }

  updateUser(uid: string, formUser) {
    return this.userCollection.doc(uid).update({ ...formUser });
  }


  editUser(uid: string, user: User) {
    return this.userCollection.doc(user.uid).update({ ...this.mapToDatabase(user), uid: uid });
  }

  private mapToDatabase(formUser: User) {
    const valueListToBoolObjReducer = (roleObj, role) => {
      roleObj[role] = true;
      return roleObj;
    };
    return {
      ...formUser,
      roles: formUser.roles.reduce(valueListToBoolObjReducer, {}),
      keyaccounts: formUser.roles.includes('ACCOUNTMANAGER') ? formUser.keyaccounts.reduce(valueListToBoolObjReducer, {}) : [],
      keyaccountsView: formUser.roles.includes('ACCOUNTVIEWER') ? formUser.keyaccountsView.reduce(valueListToBoolObjReducer, {}) : []
    };
  }

  private mapFromDatabase(dbUser): User {
    return {
      ...dbUser,
      roles: Object.keys(dbUser.roles).filter(key => dbUser.roles[key]),
      keyaccounts: dbUser.keyaccounts ? Object.keys(dbUser.keyaccounts).filter(key => dbUser.keyaccounts[key]) : [],
      keyaccountsView: dbUser.keyaccountsView ? Object.keys(dbUser.keyaccountsView).filter(key => dbUser.keyaccountsView[key]) : []
    };
  }
}
