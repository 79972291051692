
<div class="col-sm-12" style="margin-bottom:15px;">
  <button *ngIf="allowNew"
          type="button"
          class="btn btn-primary pull-right"
          (click)="addItem()"
          title="{{ 'NEW' | translate }}"
  >
    <b>{{ 'ADD_ACTIVITY' | translate }}</b>
  </button>
</div>

<div class="activityplanspro-table" *ngIf="!dashboardView" [formGroup]="form">
  <div *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
    <div *ngIf="!row.value.realised" class="activityplans-rowseparator clearfix"></div>
    <div *ngIf="!row.value.realised" class="activityplans-tablerow clearfix">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-12" *ngIf="(progresses$ | async) as progresses">
            <button type="button" class="btn pull-right btn-primary" (click)="realised(i)" *ngIf="progresses[i] == 100">
              <span>{{ 'TO_LESSONSLEARNED_PRO' | translate }}</span>
            </button>
          </div>
        </div>
        <div class="row margin-bottom">
          <div class="col-sm-9">
            <label class="control-label" for="action">{{ 'KEY_ACTION' | translate }}</label>
            <input type="text" formControlName="action" name="action" class="form-control tablecellinput">
          </div>
          <div class="col-sm-3">
            <label class="control-label">{{ 'BY_WHOM' | translate }}</label>
            <input type="text" formControlName="responsible" class="form-control">
          </div>
        </div>
        <div class="row margin-bottom">
          <div class="col-md-3 col-lg-2">
            <label class="control-label">{{ 'STARTDATE' | translate }}</label>
            <kendo-datepicker formControlName="startDate" format="dd-MM-yyyy" style="display:block;"></kendo-datepicker>
          </div>
          <div class="col-md-3 col-lg-2">
            <label class="control-label">{{ 'REALISEDDATE' | translate }}</label>
            <kendo-datepicker formControlName="endDate" format="dd-MM-yyyy" style="display:block;"></kendo-datepicker>
          </div>
          <div class="col-md-6 col-lg-8">
            <label class="control-label">{{ 'STATUSBAR' | translate }}</label>
            <ng5-slider formControlName="progress" [options]="Object.assign({}, sliderOptions, {readOnly: row.value.subActivities.length > 0, disabled: row.disabled})"></ng5-slider>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <a (click)="expanded[row.value.uid] = !expanded[row.value.uid]" [attr.aria-expanded]="expanded[row.value.uid]" style="cursor: pointer">
              <i class="glyphicon icon-green" [ngClass]="{'glyphicon-menu-down': !expanded[row.value.uid], 'glyphicon-menu-up': expanded[row.value.uid]}"></i>
              <span *ngIf="!expanded[row.value.uid]" class="text-black"><b> {{ 'SHOW_MORE' | translate }}</b></span>
              <span *ngIf="expanded[row.value.uid]" class="text-black"><b>  {{ 'SHOW_LESS' | translate }}</b></span>
            </a>
          </div>
        </div>

        <div class="activity-details" *ngIf="expanded[row.value.uid]">
          <div class="row">
            <div class="col-sm-4" *ngIf="showActivityType">
              <label for="activityTypes" class="control-label">{{ 'ACTIVITY_TYPES' | translate }}</label>
            </div>
            <div class="col-sm-4">&nbsp;</div>
            <ng-container *ngIf="showActivityTurnover">
              <div class="col-sm-2">
                <label for="turnoverCurrentYear" class="control-label">{{ 'TURNOVER_CURRENTYEAR' | translate }}</label>
              </div>
              <div class="col-sm-2">
                <label for="turnoverNextYear" class="control-label">{{ 'TURNOVER_NEXTYEAR' | translate }}</label>
              </div>
            </ng-container>
          </div>
          <div class="row margin-bottom">
            <div class="col-sm-5" *ngIf="showActivityType">
              <ng-select [items]="activityTypes"
                          placeholder="Select activityType"
                          bindValue="id"
                          bindLabel="label"
                          [multiple]="true"
                          groupBy="group"
                          clearAllText="Clear"
                          formControlName="activityType"
                          name="activityTypes"
              >
                <ng-template ng-label-tmp let-item="item">
                  {{ item.label | translate }}
                </ng-template>
                <ng-template ng-optgroup-tmp let-item="item">
                  {{ item.group | translate }}
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  {{ item.label | translate }}
                </ng-template>
              </ng-select>
            </div>
            <div *ngIf="showGotik || showActivityType" class="col-sm-3" style="padding-top: 5px">
              <label *ngFor="let char of ['g', 'o', 't', 'i', 'k']"
                      class="label"
                      [ngClass]="{
                                            'label-default': row.value.gotik[char] == 0,
                                            'label-danger': row.value.gotik[char] == 1,
                                            'label-warning': row.value.gotik[char] == 2,
                                            'label-success': row.value.gotik[char] == 3
                                }"
                      (click)="updateGotik(i, char)"
                      title="{{char + '_TITLE' | translate }}"
              >{{ char.toUpperCase() | translate }}</label>
              <i class="info glyphicon glyphicon-exclamation-sign icon-popover  "
                  title="{{ 'CLICKFORREMARK' | translate }}"
                  [ngbPopover]="'GOTIK_EXPLANATION' | translate"
                  popoverTitle="{{ 'INFO' | translate }}"
              >
              </i>
            </div>
            <ng-container *ngIf="showActivityTurnover">
              <div class="col-sm-2">
                <kendo-numerictextbox [autoCorrect]="false"
                                      [spinners]="false"
                                      [decimals]="0"
                                      [format]="'c0'"
                                      formControlName="turnoverCurrentYear"
                ></kendo-numerictextbox>
              </div>
              <div class="col-sm-2">
                <kendo-numerictextbox [autoCorrect]="false"
                                      [spinners]="false"
                                      [decimals]="0"
                                      [format]="'c0'"
                                      formControlName="turnoverNextYear"
                ></kendo-numerictextbox>
              </div>
            </ng-container>
          </div>
          <div class="row" *ngIf="showActivityComments">
            <div class="col-sm-12">
              <label for="activityComment" class="control-label">{{ 'ACTIVITY_COMMENTS'| translate }}</label>
              <textarea name="activityComment" formControlName="activityComments" class="form-control"></textarea>
            </div>
          </div>
          <div class="row" style="margin-bottom:15px;">
            <div class="col-sm-12 ">
              <label for="result" class="control-label">{{ 'CREALISED_RESULT_LABEL' | translate }}</label>
              <textarea class="form-control" formControlName="result" name="result"></textarea>
            </div>
          </div>
          <div class="row">
            <masterplanner2-sub-activities formControlName="subActivities" *ngIf="!row.value.realised"></masterplanner2-sub-activities>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="activityplanspro-table" *ngIf="dashboardView" [formGroup]="form">
  <div *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
    <div class="activityplans-tablerow clearfix" *ngIf="!row.value.realised">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-8">
            <label class="control-label">{{ 'KEY_ACTION' | translate }}</label>
            <input type="text" formControlName="action" class="form-control tablecellinput">
          </div>
          <div class="col-sm-4">
            <label class="control-label">{{ 'BY_WHOM' | translate }}</label>
            <input type="text" formControlName="responsible" class="form-control tablecellinput">
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <label class="control-label">{{ 'STARTDATE' | translate }}</label>
            <kendo-dateinput formControlName="startDate" format="dd-MM-yyyy" [readonly]="true"></kendo-dateinput>
          </div>
          <div class="col-sm-3">
            <label class="control-label">{{ 'REALISEDDATE' | translate }}</label>
            <kendo-dateinput formControlName="endDate" format="dd-MM-yyyy" [readonly]="true"></kendo-dateinput>
          </div>
          <div class="col-sm-3" *ngIf="showGotikOnDashboard(row.value.gotik)">
            <label class="control-label">{{ 'GOTIK' | translate }}</label>
            <div style="padding-top:5px;">
                <label *ngFor="let char of ['g', 'o', 't', 'i', 'k']"
                class="label"
                [ngClass]="{
                              'label-default': row.value.gotik[char] == 0,
                              'label-danger': row.value.gotik[char] == 1,
                              'label-warning': row.value.gotik[char] == 2,
                              'label-success': row.value.gotik[char] == 3
                            }"
                        title="{{char + '_TITLE' | translate }}"
                >{{ char.toUpperCase() | translate }}</label>           
            </div>
          </div>
          <div class="col-sm-3">
            <label class="control-label">{{ 'STATUSBAR' | translate }}</label>
            <div class="progress-bar-dashboard-wrapper">
              <div class="progress-bar-dashboard" *ngIf="row.value.progress as progress">
                <div id="progress-bar-dashboard-fill" class="text-center" [ngStyle]="{ 'width': Math.round(progress) + '%' }"
                  [ngClass]="{ 'orange': progress < 33, 'yellow': progress >= 33 && progress < 67, 'green': progress >= 67 }">
                  <span *ngIf="progress >= 10">{{ Math.round(row.value.progress) }} %</span>
                  <!-- progressbar -->
                </div>
                <div class="progress-bar-dashboard" *ngIf="row.value.progress === 0">
                  <span>0%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <hr>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
