import { Injectable } from '@angular/core';
import { tag } from 'rxjs-spy/operators';
import { Customer } from "../customer.model";
import { catchError, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, QueryFn, QuerySnapshot } from '@angular/fire/firestore';
import { UserService, Keyaccount, User } from "@masterplanner2/users";
import {
  BuyingPower,
  BuyingPowerScore,
  BuyingPowerTotals,
  KeyaccountPlan, ScoreMatrixList, ScoreMatrixUserList, YearlyValues
} from '../keyaccount-plan.model';
import { combineLatest, Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class KeyaccountPlanService {

  private readonly collectionName = 'keyaccountPlans';

  private readonly keyaccountPlans$: Observable<KeyaccountPlan[]>;
  private readonly keyaccountPlanCollection: AngularFirestoreCollection<KeyaccountPlan>;


  constructor(private db: AngularFirestore, private userService: UserService) {
    this.keyaccountPlanCollection = db.collection(this.collectionName);
    const flatten = arr => [].concat.apply([], arr);
    this.keyaccountPlans$ = userService.getCurrentUser().pipe(
      tag('user'),
      switchMap(user => {
        if(!user) {
          return of([]);
        }

        if (user.roles.includes('ADMIN')) {
          return this.getKeyaccountPlansBy().pipe(tag('keyaccount plans [admin]'));
        } else if(user.roles.includes('GROUPADMIN')) {
          return this.getKeyaccountPlansBy('groupUid', user.groupUid).pipe(tag('keyaccount plans [groupadmin]'));
        } else if(user.roles.includes('ACCOUNTMANAGER') && user.roles.includes('ACCOUNTVIEWER')) {
          const keyaccounts = [...user.keyaccountsView, ...user.keyaccounts];
          return combineLatest(keyaccounts.map(keyaccount => this.getKeyaccountPlansBy('keyaccountUid', keyaccount).pipe(tag('keyaccount =>' + keyaccount)))).pipe(tag('keyaccount plans [view & manager]'),map(flatten));
        } else if(user.roles.includes('ACCOUNTMANAGER')) {
          const keyaccounts = user.keyaccounts;
          return combineLatest(keyaccounts.map(keyaccount => this.getKeyaccountPlansBy('keyaccountUid', keyaccount).pipe(tag('keyaccount =>' + keyaccount)))).pipe(tag('keyaccount plans [accountmanager]'),map(flatten));
        } else if( user.roles.includes('ACCOUNTVIEWER')) {
          const keyaccounts = user.keyaccountsView;
          return combineLatest(keyaccounts.map(keyaccount => this.getKeyaccountPlansBy('keyaccountUid', keyaccount).pipe(tag('keyaccount =>' + keyaccount)))).pipe(tag('keyaccount plans [view]'), map(flatten));
        } else {
          // No role?
          console.error('No matching role found to filter keyaccountPlans', user);
          return of([]);
        }
      }),
      tag('Keyaccount plans'),
      shareReplay(1),
      catchError(err => {
        console.error('Error retrieving keyaccountPlans', err);
        return of([]);
      }),
    );

  }

  /**
   *
   * @param field
   * @param value
   */
  private getKeyaccountPlansBy(field?: string, value?: string) {
    const queryFn: QueryFn = field ? ref => ref.where(field, '==', value) : ref => ref;
    return this.db.collection<KeyaccountPlan>(this.collectionName, queryFn).valueChanges().pipe(
      catchError(err => {
        console.error('Error retrieving keyaccountPlans:', err);
        return of([]);
      }),
    );
  }

  canRead(keyaccountPlan: KeyaccountPlan): Observable<boolean> {
    return this.userService.getCurrentUser().pipe(
      map(user => {
        if(!user) {
          return false;
        } else if (user.roles.includes('ADMIN')) {
          return true;
        } else if(user.roles.includes('GROUPADMIN')) {
          return keyaccountPlan.groupUid === user.groupUid;
        } else if(user.roles.includes('ACCOUNTMANAGER') && user.roles.includes('ACCOUNTVIEWER')) {
          const keyaccounts = [...user.keyaccountsView, ...user.keyaccounts];
          return keyaccounts.includes(keyaccountPlan.keyaccountUid);
        } else if(user.roles.includes('ACCOUNTMANAGER')) {
          return user.keyaccounts.includes(keyaccountPlan.keyaccountUid);
        } else if( user.roles.includes('ACCOUNTVIEWER')) {
          return user.keyaccountsView.includes(keyaccountPlan.keyaccountUid);
        } else {
          // No role?
          console.error('No matching role found to filter keyaccountPlans', user);
          return false;
        }
      })
    )
  }

  canEdit(keyaccountPlan: KeyaccountPlan): Observable<boolean> {
    return this.userService.getCurrentUser().pipe(
      map(user => {
        if(!user) {
          return false;
        } else if (user.roles.includes('ADMIN')) {
          return true;
        } else if(user.roles.includes('GROUPADMIN')) {
          return keyaccountPlan.groupUid === user.groupUid;
        } else if(user.roles.includes('ACCOUNTMANAGER')) {
          return user.keyaccounts.includes(keyaccountPlan.keyaccountUid);
        } else {
          // No role or account viewer
          return false;
        }
      })
    )
  }

  /**
   * Check if the current user can Add new keyaccountPlans. Optionally you
   * could specify the keyaccount.
   *
   * @param keyaccount
   */
  canAdd(keyaccount?: Keyaccount): Observable<boolean> {
    return this.userService.getCurrentUser().pipe(
      map(user => {
        if(!user) {
          return false;
        } else if (user.roles.includes('ADMIN')) {
          return true;
        } else if(user.roles.includes('GROUPADMIN')) {
          return !keyaccount || (keyaccount.groupUid === user.groupUid);
        } else if(user.roles.includes('ACCOUNTMANAGER')) {
          return !keyaccount || (user.keyaccounts.includes(keyaccount.uid));
        } else {
          // No role or account viewer
          return false;
        }
      })
    )
  }

  getKeyaccountPlans(): Observable<KeyaccountPlan[]> {
    return this.keyaccountPlans$;
  }

  getKeyaccountPlan(uid: string): Observable<KeyaccountPlan> {
    return <Observable<KeyaccountPlan>>this.keyaccountPlanCollection.doc(uid).valueChanges().pipe(
      map(keyaccountPlan => ({
        featureToggles    : {},
        orderIntakeTarget : {},
        orderBacklog      : {},
        orderIntake       : {},
        targetRevenue     : {},
        customerRevenue   : {},
        keyaccountRevenue : {},
        sow               : {},
        profitMargin      : {},
        dmus              : {contacts:[]},
        buyingpowers      : {extra: {}},
        buyingBehavior    : {behaviors: []},
        matrix            : [],
        strategicQuestions: [],

        ...keyaccountPlan
      })), // In case they have no featureToggles yet
    );
  }

  editKeyaccountPlan(uid: string, keyaccountPlan: Partial<KeyaccountPlan> | {[key: string]: any}) {
    return this.keyaccountPlanCollection.doc(uid).update({ ...keyaccountPlan, uid: uid });
  }

  createKeyaccountPlan(customer: Customer, keyaccount: Keyaccount, manager: User, targetYear: number, version: string, originalKeyaccountPlan?: KeyaccountPlan) {
    const keyaccountPlan = originalKeyaccountPlan ? this.createKeyaccountPlanClone(originalKeyaccountPlan, targetYear) : new KeyaccountPlan();
    const brickwall = originalKeyaccountPlan ? originalKeyaccountPlan.brickwall : customer.brickwallCriteria;

    keyaccountPlan.featureToggles = keyaccount.strategic ? customer.strategicFeatureToggles : customer.featureToggles;
    const revenuesPromise = (keyaccount.revenueImportedAt) ?
      this.db.collection('keyaccounts').doc(keyaccount.uid).collection('revenues').get().pipe(first()).toPromise()
      : Promise.resolve(null)
    ;
    return revenuesPromise.then((revenues: QuerySnapshot<YearlyValues> | null) => {
      let customerRevenue;
      const servicelines = customer.servicelines.reduce((acc, serviceline) => {
        acc[serviceline.uid] = serviceline;
        return acc;
      }, {});
      if(revenues) {
        customerRevenue = {};
        revenues.forEach(revenue => {
          servicelines[revenue.id] = {...servicelines[revenue.id], ...revenue.data()};
          Object.keys(revenue.data()).forEach(year => {
            const value = revenue.data()[year];
            customerRevenue[year] = customerRevenue[year] ? customerRevenue[year] + value : value;
          })

        })
      }
      return this.keyaccountPlanCollection.add(<KeyaccountPlan>
        {
          ...(keyaccount.orderIntake ? {
            orderIntake          : keyaccount.orderIntake,
            orderIntakeImportedAt: keyaccount.orderIntakeImportedAt
          } : undefined),
          ...(keyaccount.revenueImportedAt ? {
            revenueImportedAt: keyaccount.revenueImportedAt,
            customerRevenue: customerRevenue,
            servicelines: servicelines,
          } : undefined),
          ...keyaccountPlan,
          groupUid             : customer.uid,
          customerName         : customer.name,
          keyaccountName       : keyaccount.name,
          keyaccountUid        : keyaccount.uid,
          keyaccountManagerUid : manager.uid,
          keyaccountManagerName: manager.name,
          brickwall            : brickwall,
          targetYear           : targetYear,
          version              : '' + targetYear + ' - ' + version,
          status               : 'In process',

        }
      ).then(docRef => {
        return docRef.update({ uid: docRef.id }).then(() => docRef);
      });
    })

  }

  deleteKeyaccountPlan(uid: string): Promise<void> {
    return this.keyaccountPlanCollection.doc(uid).delete();
  }

  getScoreMatrixUserList(keyaccountPlanUid: string): Observable<ScoreMatrixUserList> {
    return this.db.collection<ScoreMatrixList>(this.collectionName + '/' + keyaccountPlanUid + '/scoreMatrix')
      .snapshotChanges().pipe(
        map(matrixesChangeAction => matrixesChangeAction.map(matrix => {
            return {...matrix.payload.doc.data(), userUid: matrix.payload.doc.id};
          }),
        )
      );
  }

  setScoreMatrix(keyaccountPlanUid: string, userUid, scores: ScoreMatrixList): Promise<any> {
    return this.db.collection(this.collectionName + '/' + keyaccountPlanUid + '/scoreMatrix')
      .doc(userUid).set(scores);
  }

  getScoreMatrix(keyaccountPlanUid: string, userUid:string): Observable<ScoreMatrixList> {
    return this.db.collection<ScoreMatrixList>(this.collectionName + '/' + keyaccountPlanUid + '/scoreMatrix')
      .doc(userUid).valueChanges().pipe(map(record => <ScoreMatrixList>{...<ScoreMatrixList>record, userUid}));
  }

  calculateBuyingpowerScores(buyingPowers:  {[uid: string]: BuyingPower} | BuyingPower[]): (BuyingPower & BuyingPowerScore)[] {
    return (Array.isArray(buyingPowers) ? buyingPowers :
      Object.keys(buyingPowers)
          .map(buyingPowerUid => ({...buyingPowers[buyingPowerUid], uid: buyingPowerUid}))
          // Filter the properties that are not an object (swotType, swotReason, competitor1, competitor2)
          .filter(buyingpower => buyingpower && typeof buyingpower === 'object')
    )
    .map(buyingcriteria => {
      const importanceScore = buyingcriteria.importance ? buyingcriteria.importance * 5: 0;

      const weightedScore = buyingcriteria.score ? buyingcriteria.score * (5 / 25) * importanceScore : 0;
      const restScore = importanceScore - weightedScore;

      return {
        ...buyingcriteria,
        uid: buyingcriteria.uid,
        importanceScore,
        weightedScore,
        restScore,
        competitor1Score: buyingcriteria.competitor1 ? buyingcriteria.importance * buyingcriteria.competitor1 : 0,
        competitor2Score: buyingcriteria.competitor2 ? buyingcriteria.importance * buyingcriteria.competitor2 : 0,
      }
    });
  }

  /** Includes all the scores and the totals */
  calculateBuyingpowerTotals(buyingPowers: {[uid: string]: BuyingPower} | BuyingPower[]): BuyingPowerTotals {
    const scores = this.calculateBuyingpowerScores(buyingPowers);

    const totals = scores.reduce((acc, buyingpower) => {
      return {
        companyTotalWeightedScore: acc.companyTotalWeightedScore + buyingpower.weightedScore,
        companyTotalRestScore: acc.companyTotalRestScore + buyingpower.restScore,
        competitor1Score: acc.competitor1Score + buyingpower.competitor1Score,
        competitor2Score: acc.competitor2Score + buyingpower.competitor2Score,
      }
    }, {companyTotalWeightedScore: 0, companyTotalRestScore: 0, competitor1Score: 0, competitor2Score: 0}
    );

    const maxScore = totals.companyTotalRestScore + totals.companyTotalWeightedScore;
    return {
      scores,
      ...totals,
      companyIndex: maxScore ? totals.companyTotalWeightedScore / maxScore * 100 : 0,
      competitor1Index: maxScore ? totals.competitor1Score / maxScore * 100 : 0,
      competitor2Index: maxScore ? totals.competitor2Score / maxScore * 100 : 0,
    }
  }

  countBrickwall(brickwallCriteria: any[]) {
    const iTotalBrickwallscore = brickwallCriteria.reduce((acc, cur) => acc + parseInt(cur.score, 10), 0);
    const iTotalBrickwalltarget =  brickwallCriteria.reduce((acc, cur) => acc + parseInt(cur.target, 10), 0);

    return Math.round(iTotalBrickwallscore > 0 ? (iTotalBrickwallscore / iTotalBrickwalltarget) * 100 : 0);
  }

  getMatrixProposal(plan: {swotOpportunities: any[], swotThreats: any[], swotStrengths: any[], swotWeaknesses: any[]}) {
    return [
      ...(plan.swotOpportunities || []).filter(opportunity => opportunity.score > 0)
        .map(opportunity => ({...opportunity, swotType: 'OPPORTUNITY'})),
      ...(plan.swotThreats || []).filter(threat => threat.score > 0)
        .map(threat => ({...threat, swotType: 'THREAT'})),
      ...(plan.swotStrengths || []).filter(strength => strength.inMatrix > 0)
        .map(strength => ({...strength, swotType: 'STRENGTH'})),
      ...(plan.swotWeaknesses || []).filter(weakness => weakness.inMatrix)
        .map(weakness => ({...weakness, swotType: 'WEAKNESS'})),
    ]
  }

  createKeyaccountPlanClone(keyaccountPlan: KeyaccountPlan, newTargetYear: number): KeyaccountPlan {
    let clonedKeyaccountPlan = {
      ...keyaccountPlan,
      uid: null,
      parentUid: keyaccountPlan.uid,
      strategicActions: (!keyaccountPlan.strategicActions) ? [] : keyaccountPlan.strategicActions
        .map(action => {
          delete action['turnoverCurrentYear'];
          delete action['turnoverNextYear'];
          return {...action, fromOldPlan: true }
        }),
    };

    if (newTargetYear !== keyaccountPlan.targetYear) {
      clonedKeyaccountPlan = { ...clonedKeyaccountPlan, ...this.updatedKeyaccountPlanWithNewYearSpanKeys(keyaccountPlan, newTargetYear)};
    }

    delete clonedKeyaccountPlan.matrix;
    delete clonedKeyaccountPlan.matrixCompleted;
    delete clonedKeyaccountPlan.strategicQuestions;
    delete clonedKeyaccountPlan.accountGoals;
    delete clonedKeyaccountPlan.swotOpportunities;
    delete clonedKeyaccountPlan.swotThreats;
    delete clonedKeyaccountPlan.swotStrengths;
    delete clonedKeyaccountPlan.swotWeaknesses;
    delete clonedKeyaccountPlan.groupUid;
    delete clonedKeyaccountPlan.keyaccountName;
    delete clonedKeyaccountPlan.keyaccountUid;
    delete clonedKeyaccountPlan.keyaccountManagerUid;
    delete clonedKeyaccountPlan.targetYear;
    delete clonedKeyaccountPlan.version;
    delete clonedKeyaccountPlan.customerName;

    return clonedKeyaccountPlan;

  }

  updatedKeyaccountPlanWithNewYearSpanKeys(oldKeyaccountPlan: KeyaccountPlan, newTargetYear: number) {
    const yearlyValuesKeys = ['keyaccountRevenue', 'brancheRevenue', 'orderIntakeTarget', 'orderBacklog', 'orderIntake', 'targetRevenue', 'customerRevenue', 'sow', 'profitMargin'];
    const newYearSpan: number[] = this.getYearSpan(newTargetYear);
    const partialKeyaccountPlan: Partial<KeyaccountPlan> = {};

    // copy yearlyValues objects
    yearlyValuesKeys.forEach(key => {
      partialKeyaccountPlan[key] = { ...oldKeyaccountPlan[key]};


      // remove all previous values
      for (let i = oldKeyaccountPlan.targetYear - 5; i < oldKeyaccountPlan.targetYear + 5; i++) {
        delete partialKeyaccountPlan[key][i];
      };

      newYearSpan.forEach(year => {
        partialKeyaccountPlan[key][year] = oldKeyaccountPlan[key][year] ? oldKeyaccountPlan[key][year] : 0;
      })
    });
    return partialKeyaccountPlan;
  }

  getYearSpan(targetYear: number): number[] {
    const yearSpan: number[] = [];
    for (let i = targetYear - 2; i < targetYear + 4; i++) {
      yearSpan.push(i);
    }
    return yearSpan;
  }
}



