<div class="row">
  <div class="col-sm-12" *ngIf="sums$ | async as sums">
    <kendo-chart>
      <kendo-chart-legend position="bottom" orientation="horizontal"></kendo-chart-legend>
      <kendo-chart-category-axis>
        <kendo-chart-category-axis-item [categories]="yearSpan"></kendo-chart-category-axis-item>
      </kendo-chart-category-axis>
      <kendo-chart-series>
        <kendo-chart-series-item *ngIf="featureToggles?.orderIntakeTarget" [data]="sums.orderIntakeTarget" [name]="'ORDERINTAKETARGET_GRAPH' | translate" type="line">
        </kendo-chart-series-item>
        <kendo-chart-series-item *ngIf="featureToggles?.orderIntake" [data]="sums.orderIntake" [name]="'ORDERINTAKE_GRAPH' | translate">
        </kendo-chart-series-item>
        <kendo-chart-series-item *ngIf="featureToggles?.orderBacklog" [data]="sums.orderBacklog" [name]="'ORDERBACKLOG_GRAPH' | translate">
        </kendo-chart-series-item>
        <kendo-chart-series-item  [data]="sums.targetRevenue" [name]="'TARGETREVENUE_GRAPH' | translate" type="line">
        </kendo-chart-series-item>
        <kendo-chart-series-item [data]="sums.customerRevenue" [name]="'CUSTOMERREVENUE_GRAPH' | translate">
        </kendo-chart-series-item>
      </kendo-chart-series>
    </kendo-chart>
  </div>
</div>
