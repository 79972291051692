<div name="editForm" id="editForm" class="form-horizontal col-sm-12" [formGroup]="form" (submit)="doSave()" role="form">
  <div class="panel panel-primary clearfix" *ngIf="(keyaccountPlan$ | async) as keyaccountPlan">
    <div class="sw-heading col-sm-12 panel-heading">
      <div class="pull-left col-sm-8">
        <h2>
          <masterplanner2-header-logos [groupUid]="keyaccountPlan.groupUid" 
                                       [keyaccountUid]="keyaccountPlan.keyaccountUid"
          ></masterplanner2-header-logos>
          <div class="col-sm-10">{{ 'DASHBOARD_PRO' | uppercase | translate }}</div>
        </h2>
        <div class="col-sm-12 version">{{ 'VERSION' | translate }}:{{ keyaccountPlan.version }}
          {{ 'ITARGETYEAR' | translate }}: {{ keyaccountPlan.targetYear }}
        </div>
      </div>
      <masterplanner2-nav-bar (save)="doSave()" [canSave]="canEdit$ | async"></masterplanner2-nav-bar>
    </div>
    <div class="col-sm-12">
      <p class="info-text pull-right text-right" [innerHTML]="'ALL_AMOUNTS_THOUSENDS' | translate "></p>
    </div>
    <div class="col-sm-12">
      <div class="row form-group">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-4">
              <label for="keyaccountName" class="control-label">{{ 'KEYACCOUNT' | translate }}</label>
            </div>
            <div class="col-sm-8">
              <input type="text" class="form-control" name="keyaccountName" formControlName="keyaccountName">
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-3">
              <label for="version" class="control-label">{{ 'VERSION' | translate }}</label>
            </div>
            <div class="col-sm-9">
              <input type="text" class="form-control" name="version" formControlName="version">
            </div>
          </div>
        </div>        
        <div class="col-sm-2">
          <div class="row">
            <div class="col-sm-7">
              <label for="targetYear" class="control-label">{{ 'TARGET_YEAR' | translate }}</label>
            </div>
            <div class="col-sm-5">
              <input type="text" class="form-control" name="targetYear" formControlName="targetYear">
            </div>
          </div>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-4">
              <label for="keyaccountManagerName" class="control-label">{{ 'ACCOUNT_MANAGER' | translate }}</label>
            </div>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="keyaccountManagerName" name="keyaccountManagerName"> 
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-3">
              <label for="status" class="col-sm-2 control-label">{{ 'STATUS' | translate }}</label>
            </div>
            <div class="col-sm-9">
              <select name="status" formControlName="status" class="form-control">
                <option *ngFor="let status of statuses" [value]="status">{{ status }}</option>
              </select>
            </div>
          </div>
        </div>        
      </div>
      <div class="col-sm-12" *ngIf="keyaccountPlan.featureToggles.generalGoal">
        <label for="generalGoal">{{ 'GENERAL_GOAL_KEY_ACCOUNTPLAN' | translate }}</label>
        <textarea name="generalGoal" 
                  formControlName="generalGoal" 
                  class="form-control" 
                  kendoTextArea 
                  [autoSize]="true"
        ></textarea>
      </div>

      <div class="col-sm-12 separator">
        <span>{{ 'DASHBOARD_KEYACCOUNTPLAN_GOALS' | translate }}</span>
      </div>
      <div class="col-sm-12">
        <label>
          <span [innerHTML]="'GOAL_1_TITLE' | translate"></span>
          <a [routerLink]="'/keyaccountplan/' + keyaccountPlan.uid + '/strategy'" title="{{ 'GO_TO_THE_SOURCE' | translate }}">
            &nbsp;<i class="glyphicon glyphicon-new-window"></i>
          </a>
        </label>
      </div>
      <div *ngIf="(yearSpan$ | async) as yearSpan">
        <div class="form-group">
          <ng-container *ngIf="keyaccountPlan.featureToggles.orderIntakeTarget || keyaccountPlan.featureToggles.orderIntake || keyaccountPlan.featureToggles.orderBacklog">
            <div class="col-sm-3"></div>
            <div class="col-sm-7">
                <div class="row">
                    <div class="col-sm-2 text-center border-top" *ngFor="let year of yearSpan">&nbsp;</div>
                </div>
                <div class="row">
                    <div class="col-sm-2 text-center border-top year-column" *ngFor="let year of yearSpan"><span>{{ year }}</span></div>
                </div>
            </div>  
          </ng-container>    
          <masterplanner2-revenue *ngIf="keyaccountPlan.featureToggles.orderIntakeTarget"
                                  formControlName="orderIntakeTarget" 
                                  [yearSpan]="yearSpan"
                                  [name]="'orderintaketarget'"
                                  [popoverTitle]="'ORDERINTAKETARGET_POPOVER'"
          ></masterplanner2-revenue>
          <masterplanner2-revenue *ngIf="keyaccountPlan.featureToggles.orderIntake"
                                  formControlName="orderIntake" 
                                  [yearSpan]="yearSpan"
                                  [name]="'orderintake'"
                                  [popoverTitle]="'ORDERINTAKEREALISED_POPOVER'"
          ></masterplanner2-revenue>
          <masterplanner2-revenue *ngIf="keyaccountPlan.featureToggles.orderBacklog"
                                  formControlName="orderBacklog" 
                                  [yearSpan]="yearSpan"
                                  [name]="'orderbacklog'"
                                  [popoverTitle]="'ORDERBACKLOG_POPOVER'"
          ></masterplanner2-revenue>
        </div>
        <div class="form-group">
          <div class="col-sm-3"></div>
          <div class="col-sm-7">
              <div class="row">
                  <div class="col-sm-2 text-center border-top" *ngFor="let year of yearSpan">&nbsp;</div>
              </div>
              <div class="row">
                  <div class="col-sm-2 text-center border-top year-column" *ngFor="let year of yearSpan"><span>{{ year }}</span></div>
              </div>
          </div>     
          <masterplanner2-revenue formControlName="targetRevenue" 
                                  [yearSpan]="yearSpan"
                                  [name]="'targetrevenue'"
                                  [popoverTitle]="'TARGETREVENUE_POPOVER'"
          ></masterplanner2-revenue>
          <masterplanner2-revenue formControlName="customerRevenue" 
                                  [yearSpan]="yearSpan"
                                  [name]="'customerrevenue'"
                                  [popoverTitle]="'CUSTOMERREVENUE_POPOVER'"
          ></masterplanner2-revenue>          
        </div>
      </div>

                              
      <div class="col-sm-12">
        <kendo-chart>
          <kendo-chart-legend position="bottom" orientation="horizontal"></kendo-chart-legend>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="yearSpan$ | async"></kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
          <kendo-chart-series>
            <kendo-chart-series-item *ngIf="keyaccountPlan.featureToggles.orderIntakeTarget" [data]="orderIntakeTargetData" [name]="'ORDERINTAKETARGET_GRAPH' | translate" type="line">
            </kendo-chart-series-item>
            <kendo-chart-series-item *ngIf="keyaccountPlan.featureToggles.orderIntake" [data]="orderIntakeData" [name]="'ORDERINTAKE_GRAPH' | translate">
            </kendo-chart-series-item>
            <kendo-chart-series-item *ngIf="keyaccountPlan.featureToggles.orderBacklog" [data]="orderBacklogData" [name]="'ORDERBACKLOG_GRAPH' | translate">
            </kendo-chart-series-item>
            <kendo-chart-series-item  [data]="targetRevenueData" [name]="'TARGETREVENUE_GRAPH' | translate" type="line">
            </kendo-chart-series-item>
            <kendo-chart-series-item [data]="customerRevenueData" [name]="'CUSTOMERREVENUE_GRAPH' | translate">
            </kendo-chart-series-item>
          </kendo-chart-series>
        </kendo-chart>

        <!-- GOAL 1 -->
        <div class="row" style="margin-bottom:30px;">
          <div class="col-sm-12" formGroupName="accountGoals">
            <label for="explanation1">{{ 'EXPLANATION' | translate }}</label>
            <textarea name="explanation1" formControlName="explanation1" class="form-control"></textarea>
          </div>
        </div>

        <!-- GOAL 2 -->
        <div class="separator col-sm-12"></div>
        <div class="row" style="margin-bottom:30px;">
          <div class="col-sm-6" formGroupName="accountGoals">
            <div>
              <label>
                <span [innerHTML]="'GOAL_2_TITLE' | translate"></span>
                <a [routerLink]="'/keyaccountplan/' + keyaccountPlan.uid + '/strategy'" title="{{ 'GO_TO_THE_SOURCE' | translate }}">
                  &nbsp;<i class="glyphicon glyphicon-new-window"></i>
                </a>
              </label>
            </div>
            <label for="explanation2">{{ 'EXPLANATION' | translate }}</label>
            <textarea name="explanation2" formControlName="explanation2" class="form-control"></textarea>
          </div>
          <div class="col-sm-3 text-center">
            <kendo-radialgauge [pointer]="[{ value: keyaccountPlan.sow[keyaccountPlan.targetYear -1] ? keyaccountPlan.sow[keyaccountPlan.targetYear - 1] / 100  : 0 }]" style="height: 200px; width: 200px;">
              <kendo-radialgauge-scale-labels [position]="'outside'" format="p">
              </kendo-radialgauge-scale-labels>
              <kendo-radialgauge-scale [minorUnit]="0.1" [majorUnit]="0.20" [max]="1">
                <kendo-radialgauge-scale-ranges>
                  <kendo-radialgauge-scale-range [from]="0" [to]="0.17" color="rgb(255, 122, 0)">
                  </kendo-radialgauge-scale-range>
                  <kendo-radialgauge-scale-range [from]="0.17" [to]="0.34" color="rgb(255, 199, 0)">
                  </kendo-radialgauge-scale-range>
                  <kendo-radialgauge-scale-range [from]="0.34" [to]="1" color="rgb(92, 184, 92)">
                  </kendo-radialgauge-scale-range>
                </kendo-radialgauge-scale-ranges>
              </kendo-radialgauge-scale>
            </kendo-radialgauge>
            <span>
              <b>{{ 'SHARE_OF_WALLET_SCORE' | translate }}: {{ keyaccountPlan.targetYear - 1 }} </b>
              <a [routerLink]="'/keyaccountplan/' + keyaccountPlan.uid + '/analysis'" title="{{ 'GO_TO_THE_SOURCE' | translate }}"><i
                  class="glyphicon glyphicon-new-window"></i></a>
            </span>
          </div>
          <div class="col-sm-3 text-center">
            <kendo-radialgauge [pointer]="[{ value: keyaccountPlan.sow[keyaccountPlan.targetYear] ? keyaccountPlan.sow[keyaccountPlan.targetYear] / 100 : 0 }]" style="height: 200px; width: 200px;">
              <kendo-radialgauge-scale-labels [position]="'outside'" format="p">
              </kendo-radialgauge-scale-labels>
              <kendo-radialgauge-scale [minorUnit]="0.1" [majorUnit]="0.20" [max]="1">
                <kendo-radialgauge-scale-ranges>
                  <kendo-radialgauge-scale-range [from]="0" [to]="0.17" color="rgb(255, 122, 0)">
                  </kendo-radialgauge-scale-range>
                  <kendo-radialgauge-scale-range [from]="0.17" [to]="0.34" color="rgb(255, 199, 0)">
                  </kendo-radialgauge-scale-range>
                  <kendo-radialgauge-scale-range [from]="0.34" [to]="1" color="rgb(92, 184, 92)">
                  </kendo-radialgauge-scale-range>
                </kendo-radialgauge-scale-ranges>
              </kendo-radialgauge-scale>
            </kendo-radialgauge>
            <span>
              <b>{{ 'SHARE_OF_WALLET_SCORE' | translate }}: {{ keyaccountPlan.targetYear }} </b>
              <a [routerLink]="'/keyaccountplan/' + keyaccountPlan.uid + '/analysis'" title="{{ 'GO_TO_THE_SOURCE' | translate }}"><i
                  class="glyphicon glyphicon-new-window"></i></a>
            </span>
          </div>
        </div>


        <!-- GOAL 3 -->
        <div class="separator col-sm-12"></div>
        <div class="row" style="margin-bottom:30px;" *ngIf="(buyingPowerScores$ | async) as buyingPowerScores">
          <div class="col-sm-6" formGroupName="accountGoals">
            <div>
              <label>
                <span [innerHTML]="'GOAL_3_TITLE' | translate"></span>
                <a [routerLink]="'/keyaccountplan/' + keyaccountPlan.uid + '/strategy'" title="{{ 'GO_TO_THE_SOURCE' | translate }}">
                  &nbsp;<i class="glyphicon glyphicon-new-window"></i>
                </a>
              </label>
            </div>
            <label for="explanation3">{{ 'EXPLANATION' | translate }}</label>
            <textarea name="explanation3" formControlName="explanation3" class="form-control"></textarea>
          </div>
          <div class="col-sm-3 text-center">
            <kendo-radialgauge [pointer]="{ value: Math.round(buyingPowerScores.companyIndex) }" style="height: 200px; width: 200px;">
              <kendo-radialgauge-scale-labels>
              </kendo-radialgauge-scale-labels>
              <kendo-radialgauge-scale [minorUnit]="5" [majorUnit]="20" [max]="100">
                <kendo-radialgauge-scale-ranges>
                  <kendo-radialgauge-scale-range [from]="0" [to]="33" color="rgb(255, 122, 0)">
                  </kendo-radialgauge-scale-range>
                  <kendo-radialgauge-scale-range [from]="33" [to]="67" color="rgb(255, 199, 0)">
                  </kendo-radialgauge-scale-range>
                  <kendo-radialgauge-scale-range [from]="67" [to]="100" color="rgb(92, 184, 92)">
                  </kendo-radialgauge-scale-range>
                </kendo-radialgauge-scale-ranges>
              </kendo-radialgauge-scale>
            </kendo-radialgauge>
            <span>
              <b>{{ 'SCORE_BUYING_CRITERIA' | translate }} {{ keyaccountPlan.customerName }} </b>
              <a [routerLink]="'/keyaccountplan/' + keyaccountPlan.uid + '/analysis'" title="{{ 'GO_TO_THE_SOURCE' | translate }}"><i
                  class="glyphicon glyphicon-new-window"></i></a>
            </span>
          </div>
          <div class="col-sm-3 text-center">
            <kendo-radialgauge [pointer]="{ value: Math.round(buyingPowerScores.competitor1Index) }" style="height: 150px; width: 150px;">
              <kendo-radialgauge-scale-labels [position]="'outside'">
              </kendo-radialgauge-scale-labels>
              <kendo-radialgauge-scale [minorUnit]="20" [majorUnit]="20" [max]="100">
                <kendo-radialgauge-scale-ranges>
                  <kendo-radialgauge-scale-range [from]="0" [to]="33" color="rgb(255, 122, 0)">
                  </kendo-radialgauge-scale-range>
                  <kendo-radialgauge-scale-range [from]="33" [to]="67" color="rgb(255, 199, 0)">
                  </kendo-radialgauge-scale-range>
                  <kendo-radialgauge-scale-range [from]="67" [to]="100" color="rgb(92, 184, 92)">
                  </kendo-radialgauge-scale-range>
                </kendo-radialgauge-scale-ranges>
              </kendo-radialgauge-scale>
            </kendo-radialgauge>
            <span>
              <b>{{ 'SCORE_BUYING_CRITERIA' | translate }} {{ keyaccountPlan.buyingpowers.competitor1 }} </b>
              <a [routerLink]="'/keyaccountplan/' + keyaccountPlan.uid + '/analysis'" title="{{ 'GO_TO_THE_SOURCE' | translate }}"><i
                  class="glyphicon glyphicon-new-window"></i></a>
            </span>
            <kendo-radialgauge [pointer]="{ value: Math.round(buyingPowerScores.competitor2Index) }" style="height: 150px; width: 150px;">
              <kendo-radialgauge-scale-labels [position]="'outside'">
              </kendo-radialgauge-scale-labels>
              <kendo-radialgauge-scale [minorUnit]="20" [majorUnit]="20" [max]="100">
                <kendo-radialgauge-scale-ranges>
                  <kendo-radialgauge-scale-range [from]="0" [to]="33" color="rgb(255, 122, 0)">
                  </kendo-radialgauge-scale-range>
                  <kendo-radialgauge-scale-range [from]="33" [to]="67" color="rgb(255, 199, 0)">
                  </kendo-radialgauge-scale-range>
                  <kendo-radialgauge-scale-range [from]="67" [to]="100" color="rgb(92, 184, 92)">
                  </kendo-radialgauge-scale-range>
                </kendo-radialgauge-scale-ranges>
              </kendo-radialgauge-scale>
            </kendo-radialgauge>
            <span>
              <b>{{ 'SCORE_BUYING_CRITERIA' | translate }} {{ keyaccountPlan.buyingpowers.competitor2 }} </b>
              <a [routerLink]="'/keyaccountplan/' + keyaccountPlan.uid + '/analysis'" title="{{ 'GO_TO_THE_SOURCE' | translate }}"><i
                  class="glyphicon glyphicon-new-window"></i></a>
            </span>
          </div>

        </div>
        <div class="separator col-sm-12"></div>
        <!-- GOAL 4 -->
        <div class="row" style="margin-bottom:30px;">
          <div class="col-sm-6" formGroupName="accountGoals">
            <div>
              <label>
                <span [innerHTML]="'GOAL_4_TITLE' | translate"></span>
                <a [routerLink]="'/keyaccountplan/' + keyaccountPlan.uid + '/strategy'" title="{{ 'GO_TO_THE_SOURCE' | translate }}">
                  &nbsp;<i class="glyphicon glyphicon-new-window"></i>
                </a>
              </label>
            </div>
            <label for="explanation4">{{ 'EXPLANATION' | translate }}</label>
            <textarea name="explanation4" formControlName="explanation4" class="form-control"></textarea>
          </div>
          <div class="col-sm-3 text-center">
            <kendo-radialgauge [pointer]="{ value: dmuScore$ | async }" style="height: 200px; width: 200px;">
              <kendo-radialgauge-scale-labels>
              </kendo-radialgauge-scale-labels>
              <kendo-radialgauge-scale [minorUnit]="5" [majorUnit]="20" [max]="100">
                <kendo-radialgauge-scale-ranges>
                  <kendo-radialgauge-scale-range [from]="0" [to]="33" color="rgb(255, 122, 0)">
                  </kendo-radialgauge-scale-range>
                  <kendo-radialgauge-scale-range [from]="33" [to]="67" color="rgb(255, 199, 0)">
                  </kendo-radialgauge-scale-range>
                  <kendo-radialgauge-scale-range [from]="67" [to]="100" color="rgb(92, 184, 92)">
                  </kendo-radialgauge-scale-range>
                </kendo-radialgauge-scale-ranges>
              </kendo-radialgauge-scale>
            </kendo-radialgauge>
            <span>
              <b>{{ 'DMU_SCORE' | translate }} {{ keyaccountPlan.customerName }} </b>
              <a [routerLink]="'/keyaccountplan/' + keyaccountPlan.uid + '/analysis'" title="{{ 'GO_TO_THE_SOURCE' | translate }}"><i
                  class="glyphicon glyphicon-new-window"></i></a>
            </span>
          </div>
        </div>
        <!-- End Goal 4 -->      
        <!-- Goal 5 Othergoals -->
        <div class="separator col-sm-12"></div>
        <div *ngIf="keyaccountPlan.featureToggles.otherGoals || keyaccountPlan.featureToggles.brickwall" class="row" style="margin-bottom:30px;">
          <div class="col-sm-6" formGroupName="accountGoals">
            <div>
              <label>
                <span [innerHTML]="'GOAL_5_TITLE' | translate"></span>
                <a [routerLink]="'/keyaccountplan/' + keyaccountPlan.uid + '/strategy'" title="{{ 'GO_TO_THE_SOURCE' | translate }}">
                  &nbsp;<i class="glyphicon glyphicon-new-window"></i>
                </a>
              </label>
            </div>
            <label for="explanation5">{{ 'EXPLANATION' | translate }}</label>
            <textarea name="explanation5" formControlName="explanation5" class="form-control"></textarea>
          </div>
          <div class="col-sm-3 text-center">
            <kendo-radialgauge [pointer]="{ value: keyaccountPlan.brickwall ? countBrickwall(keyaccountPlan.brickwall): 0 }" style="height: 200px; width: 200px;">
              <kendo-radialgauge-scale-labels>
              </kendo-radialgauge-scale-labels>
              <kendo-radialgauge-scale [minorUnit]="5" [majorUnit]="20" [max]="100">
                <kendo-radialgauge-scale-ranges>
                  <kendo-radialgauge-scale-range [from]="0" [to]="33" color="rgb(255, 122, 0)">
                  </kendo-radialgauge-scale-range>
                  <kendo-radialgauge-scale-range [from]="33" [to]="67" color="rgb(255, 199, 0)">
                  </kendo-radialgauge-scale-range>
                  <kendo-radialgauge-scale-range [from]="67" [to]="100" color="rgb(92, 184, 92)">
                  </kendo-radialgauge-scale-range>
                </kendo-radialgauge-scale-ranges>
              </kendo-radialgauge-scale>
            </kendo-radialgauge>
            <span>
              <b>{{ 'BRICKWALLING_SCORE' | translate }} </b>
              <a [routerLink]="'/keyaccountplan/' + keyaccountPlan.uid + '/analysis'" title="{{ 'GO_TO_THE_SOURCE' | translate }}"><i
                  class="glyphicon glyphicon-new-window"></i></a>
            </span>
          </div>          
        </div>
        <!-- End Goal 5 -->
        <div class="col-sm-12 separator">
          <span>{{ 'KEY_ACCOUNTPLAN_ACTIVITIES' | translate }}</span>
          <a [routerLink]="'/keyaccountplan/' + keyaccountPlan.uid + '/activity'" title="{{ 'GO_TO_THE_SOURCE' | translate }}">
            &nbsp;<i class="glyphicon glyphicon-new-window"></i>
          </a>
        </div>
        <div class="row">
            <masterplanner2-activity-planning [dashboardView]="true" formControlName="strategicActions"></masterplanner2-activity-planning>
        </div>
      </div>
      <!-- END GOALS -->
    </div>
  </div>
</div>
