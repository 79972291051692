export class UserModuleConfig {
  // Used to redirect after clicking the login email link
  loginRedirectUrl: string;
  // After succesfull login redirect to
  redirectAfterLogin: string;
  // Used to create a secondary app to register users without login directly in as that user
  firebaseCfg: any;
}

export const DefaultUserModuleConfig: UserModuleConfig = {
  loginRedirectUrl: 'http://localhost:4200',
  redirectAfterLogin: '/',
  firebaseCfg: {},
};
