<div name="editForm" id="editForm" class="form-horizontal col-sm-12" [formGroup]="form" (submit)="doSave()"
      role="form">
  <div class="panel panel-primary" *ngIf="(keyaccountPlan$ | async) as keyaccountPlan">
    <div class="sw-heading col-sm-12 panel-heading">
      <div class="pull-left col-sm-8">
        <h2>
          <masterplanner2-header-logos [groupUid]="keyaccountPlan.groupUid" 
                                       [keyaccountUid]="keyaccountPlan.keyaccountUid"
          ></masterplanner2-header-logos>
          <div class="col-sm-10">{{ 'ACCOUNTSTRATEGY_PRO' | uppercase | translate }}</div>
        </h2>
        <div class="col-sm-12 version">{{ 'VERSION' | translate }}:{{ keyaccountPlan.version }}
          {{ 'ITARGETYEAR' | translate }}:{{ keyaccountPlan.targetYear }}
        </div>
      </div>
      <masterplanner2-nav-bar (save)="doSave()" [canSave]="canEdit$ | async"></masterplanner2-nav-bar>
    </div>
    <div class="form-group">
      <ng-container *ngIf="!keyaccountPlan.featureToggles.noMatrix">
        <div class="separator col-sm-12">
          {{ 'TITLE_ACCOUNTSTRATEGY' | translate }}
        </div>
        <div class="col-sm-12">
          <div class="form-group col-sm-12">
            <b><p class="info-text"
                  [innerHTML]="'ACCOUNTSTRATEGY_EXPLANATION_PRO' | translate "></p></b>
          </div>
        </div>
        <masterplanner2-strategic-actions formControlName="strategicActions"></masterplanner2-strategic-actions>        
      </ng-container>
      <div class="separator col-sm-12">
        {{ 'TITLE_ACCOUNTGOALS' | translate }}
      </div>
      <div class="col-sm-12 accountgoal">
        <div class="form-group col-sm-12">
          <b>
            <p class="info-text" [innerHTML]="'ACCOUNTGOALS_EXPLANATION_PRO' | translate "></p>
          </b>
        </div>
        <label class="col-sm-12">
          <span>{{ 'GOAL1_TITLE' | translate }}</span>
          <a class="jumpto" [routerLink]="['/keyaccountplan/' + keyaccountPlan.uid + '/analysis']" title="Source">
            <i class="glyphicon glyphicon-new-window"></i>
          </a>
        </label>
        <div *ngIf="(yearSpan$ | async) as yearSpan">
          <div class="col-sm-3">
            <div class="row">
              <div class="col-sm-12 border-top year-column">&nbsp;</div>
            </div>
          </div>
          <div class="col-sm-7">
            <div class="row">
              <div class="col-sm-2 text-center border-top year-column" *ngFor="let year of yearSpan" >
                <span>
                  {{ year }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="row">
              <div class="col-sm-12 border-top year-column">&nbsp;</div>
            </div>
          </div>
          <masterplanner2-revenue *ngIf="keyaccountPlan.featureToggles.orderIntakeTarget" 
                                  formControlName="orderIntakeTarget" 
                                  [yearSpan]="yearSpan" 
                                  [name]="'orderintaketarget'" 
          ></masterplanner2-revenue>
          <masterplanner2-revenue 
                                  formControlName="targetRevenue" 
                                  [yearSpan]="yearSpan" 
                                  [name]="'targetrevenue'" 
          ></masterplanner2-revenue>
          <a (click)="realisedCollapsed = !realisedCollapsed" [attr.aria-expanded]="!realisedCollapsed" aria-controls="realised">
            <i class="glyphicon" [ngClass]="{'glyphicon-menu-down': realisedCollapsed, 'glyphicon-menu-up': !realisedCollapsed}"></i>
            <span *ngIf="realisedCollapsed"> {{ 'SHOW' | translate }} </span>
            <span *ngIf="!realisedCollapsed"> {{ 'HIDE' | translate }} </span>
          </a>
          <span><b> {{ 'REALISED' | translate }} </b></span>
          <div id="realised" [ngbCollapse]="realisedCollapsed">
            <div class="col-sm-3">
              <div class="row">
                <div class="col-sm-12 border-top year-column">&nbsp;</div>
              </div>
            </div>
            <div class="col-sm-7">
              <div class="row">
                <div class="col-sm-2 text-center border-top year-column" *ngFor="let year of yearSpan">
                  <span>
                    {{ year }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="row">
                <div class="col-sm-12 border-top year-column">&nbsp;</div>
              </div>
            </div>
            <masterplanner2-revenue *ngIf="keyaccountPlan.featureToggles.orderIntake" 
                                    formControlName="orderIntake" 
                                    [yearSpan]="yearSpan" 
                                    [name]="'orderintake'" 
            ></masterplanner2-revenue>
            <masterplanner2-revenue *ngIf="keyaccountPlan.featureToggles.orderBacklog" 
                                    formControlName="orderBacklog" 
                                    [yearSpan]="yearSpan" 
                                    [name]="'orderbacklog'" 
            ></masterplanner2-revenue>
            <masterplanner2-revenue formControlName="customerRevenue" 
                                    [yearSpan]="yearSpan" 
                                    [name]="'customerrevenue'" 
            ></masterplanner2-revenue>
          </div>
        </div>

        <label for="explanation1">{{ 'EXPLANATION_GOAL1' | translate }}</label>
        <textarea name="explanation1" formControlName="explanation1" class="form-control"></textarea>
        
        <hr>
        <label class="col-sm-12">
          <span>{{ 'GOAL2_TITLE' | translate }}</span>
          <a class="jumpto" [routerLink]="['/keyaccountplan/' + keyaccountPlan.uid + '/analysis']" title="Source">
            <i class="glyphicon glyphicon-new-window"></i>
          </a>
        </label>
        <div *ngIf="(yearSpan$ | async) as yearSpan">
          <div class="col-sm-3">
            <div class="row">
              <div class="col-sm-12 border-top year-column">&nbsp;</div>
            </div>
          </div>
          <div class="col-sm-7">
            <div class="row">
              <div class="col-sm-2 text-center border-top year-column" *ngFor="let year of yearSpan">
                <span>
                  {{ year }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="row">
              <div class="col-sm-12 border-top year-column">&nbsp;</div>
            </div>
          </div>
          <masterplanner2-revenue formControlName="sow" 
                                  [yearSpan]="yearSpan" 
                                  [name]="'estimatedSow'" 
                                  [mode]="'estimatedSow'"
                                  [popoverTitle]="'ESTIMATED_SOW_EXPLANATION'"
          ></masterplanner2-revenue>
        </div>
        <label for="explanation2">{{ 'EXPLANATION_GOAL2' | translate }}</label>
        <textarea name="explanation2" formControlName="explanation2" class="form-control"></textarea>
        <hr>
        <label class="col-sm-12">
          <span>{{ 'GOAL3_TITLE' | translate }}</span>
          <a class="jumpto" [routerLink]="['/keyaccountplan/' + keyaccountPlan.uid + '/analysis']" title="Source">
            <i class="glyphicon glyphicon-new-window"></i>
          </a>
        </label>
        <ng-container *ngIf="(buyingPowerScores$ | async) as buyingPowerScores">
          <div class="col-sm-4"></div>
          <div class="col-sm-4">
            <span><b>{{ 'SCORE_BUYING_CRITERIA' | translate }} {{ keyaccountPlan.customerName }}: </b> {{ Math.round(buyingPowerScores.companyIndex) }}</span>
          </div>
          <div class="col-sm-4">
            <span><b>{{ 'SCORE_BUYING_CRITERIA' | translate }} {{ keyaccountPlan.buyingpowers.competitor1 }}: </b> {{ Math.round(buyingPowerScores.competitor1Index) }}</span><br>
            <span><b>{{ 'SCORE_BUYING_CRITERIA' | translate }} {{ keyaccountPlan.buyingpowers.competitor2 }}: </b> {{ Math.round(buyingPowerScores.competitor2Index) }}</span>
          </div>
        </ng-container>

        <a (click)="purchaseProcessCollapsed = !purchaseProcessCollapsed"
           [attr.aria-expanded]="!purchaseProcessCollapsed"
           aria-controls="purchaseProcess">
          <i class="glyphicon"
             [ngClass]="{'glyphicon-menu-down': purchaseProcessCollapsed, 'glyphicon-menu-up': !purchaseProcessCollapsed}"></i>
          <span *ngIf="purchaseProcessCollapsed"> {{ 'SHOW' | translate }} </span>
          <span *ngIf="!purchaseProcessCollapsed"> {{ 'HIDE' | translate }} </span>
        </a>
        <span><b> {{ 'PURCHASE_PROCESS' | translate }} </b></span><br>
        <div id="purchaseProcess" [ngbCollapse]="purchaseProcessCollapsed" style="margin-bottom: 15px;">
          <masterplanner2-purchase-process formControlName="purchaseProcess"></masterplanner2-purchase-process>
        </div>

        <a (click)="buyingPowerCollapsed = !buyingPowerCollapsed" [attr.aria-expanded]="!buyingPowerCollapsed"
           aria-controls="buyingpower">
          <i class="glyphicon"
             [ngClass]="{'glyphicon-menu-down': buyingPowerCollapsed, 'glyphicon-menu-up': !buyingPowerCollapsed}"></i>
          <span *ngIf="buyingPowerCollapsed"> {{ 'SHOW' | translate }} </span>
          <span *ngIf="!buyingPowerCollapsed"> {{ 'HIDE' | translate }} </span>
        </a>
        <span><b> {{ 'BUYING_POWER' | translate }} </b></span><br>
        <div id="buyingpower" [ngbCollapse]="buyingPowerCollapsed">
          <masterplanner2-buyingpower *ngIf="buyingcriteria$ | async as buyingcriteria" formControlName="buyingpowers"
                                      [buyingcriteria]="buyingcriteria" [customerName]="keyaccountPlan.customerName">
          </masterplanner2-buyingpower>
        </div>

        <label for="explanation3">{{ 'EXPLANATION_GOAL3' | translate }}</label>
        <textarea name="explanation3" formControlName="explanation3" class="form-control"></textarea>

        <hr>
        <label class="col-sm-12">
          <span>{{ 'GOAL4_TITLE' | translate }}</span>
          <a class="jumpto" [routerLink]="['/keyaccountplan/' + keyaccountPlan.uid + '/analysis']" title="Source">
            <i class="glyphicon glyphicon-new-window"></i>
          </a>
        </label>
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
          <span><b>{{ 'DMU_SCORE' | translate }}: </b>{{ dmuScore$ | async }}</span>
        </div>
        <div class="col-sm-4">
          <span><b>{{ 'TOTAL_NUMBER_DMUS' | translate }}: </b>{{ dmuVisits$ | async }}</span>
        </div>
        <a (click)="criticalContactsCollapsed = !criticalContactsCollapsed"
           [attr.aria-expanded]="!criticalContactsCollapsed"
           aria-controls="critical-contacts">
          <i class="glyphicon"
             [ngClass]="{'glyphicon-menu-down': criticalContactsCollapsed, 'glyphicon-menu-up': !criticalContactsCollapsed}"></i>
          <span *ngIf="criticalContactsCollapsed"> {{ 'SHOW' | translate }} </span>
          <span *ngIf="!criticalContactsCollapsed"> {{ 'HIDE' | translate }} </span>
        </a>
        <span><b>{{ 'ACTUAL_CRITICAL_CONTACTS' | translate }} {{ keyaccountPlan.customerName }}</b></span>
        <div id="critical-contacts" [ngbCollapse]="criticalContactsCollapsed">
          <masterplanner2-critical-contacts formControlName="dmus"></masterplanner2-critical-contacts>
        </div>
        <div class="form-group">
          <label for="explanation4">{{ 'EXPLANATION_GOAL4' | translate }}</label>
          <textarea name="explanation4" formControlName="explanation4" class="form-control"></textarea>
        </div>
        <ng-container *ngIf="keyaccountPlan.featureToggles.otherGoals">
          <hr>
          <label class="col-sm-12">
            <span>{{ 'GOAL5_TITLE' | translate }}</span>
            <a class="jumpto" [routerLink]="['/keyaccountplan/' + keyaccountPlan.uid + '/analysis']" title="Source">
              <i class="glyphicon glyphicon-new-window"></i>
            </a>
          </label>
          <div class="col-sm-4"></div>
          <div class="col-sm-4">
            <span><b>{{ 'BRICKWALL_SCORE' | translate }}: </b>{{ countBrickwall(keyaccountPlan.brickwall) }}</span>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <a  (click)="otherGoalsCollapsed = !otherGoalsCollapsed"
                  [attr.aria-expanded]="!otherGoalsCollapsed"
                  aria-controls="other-goals">
                <i class="glyphicon" [ngClass]="{'glyphicon-menu-down': otherGoalsCollapsed, 'glyphicon-menu-up': !otherGoalsCollapsed}"></i>
                <span *ngIf="otherGoalsCollapsed"> {{ 'SHOW' | translate }} </span>
                <span *ngIf="!otherGoalsCollapsed"> {{ 'HIDE' | translate }} </span>
              </a>
              <span><b>{{ 'ACTUAL_BRICKWALL' | translate }}</b></span>
            </div>
          </div>
          <div id="other-goals" [ngbCollapse]="otherGoalsCollapsed">
            <masterplanner2-brickwall-score formControlName="brickwall"></masterplanner2-brickwall-score>
          </div>          
          <div class="form-group">
            <label for="explanation5">{{ 'EXPLANATION_GOAL5' | translate }}</label>
            <textarea name="explanation5" formControlName="explanation5" class="form-control"></textarea>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
