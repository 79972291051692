<table id="strengths" class="table table-condensed strengths col-sm-12" [formGroup]="form">
  <tr class="tableheader">
  </tr>
  <tr>
    <th colspan="1">
      <span *ngIf="mode === 'strength'">{{ 'SW_STRENGTH_TABLE_HEADER' | translate }}</span>
      <span *ngIf="mode !== 'strength'">{{ 'SW_WEAKNESS_TABLE_HEADER' | translate }}</span>
    </th>
    <th style="width:13%;">
      {{ 'QSWS_STRENGTHS_TABLE_HEADER_DISTINCTION' | translate }}
    </th>
    <th style="width:13%;">
      {{ 'QSWS_STRENGTHS_TABLE_HEADER_RELEVANCE' | translate }}
    </th>
    <th>
      {{ 'QSWS_STRENGTHS_TABLE_HEADER_SCORE' | translate }}
    </th>
    <th>
      {{ 'QSWS_STRENGTHS_TABLE_HEADER_TO_MATRIX' | translate }}
    </th>
  </tr>
  <tr *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i" >
    <td class="col-sm-7">
      <input formControlName="uid" type="hidden" >
      {{ row.get('name').value }}
      <input formControlName="inMatrix" type="hidden" >
    </td>
    <td>
      <select class="form-control"
              formControlName="distinction"
      >
        <option *ngFor="let option of distinctionOptions"
                value="{{ option.value }}">
          {{ option.label | translate }}
        </option>
      </select>
    </td>
    <td>
      <select class="form-control"
              formControlName="relevance"
      >
        <option *ngFor="let option of relevanceOptions"
                value="{{ option.value }}">
          {{ option.label | translate }}
        </option>
      </select>
    </td>
    <td class="col-sm-1">
      <input formControlName="score" class="form-control text-center input-score">
    </td>
    <td class="text-center">
      <button type="button"
              class="btn btn-default btn-tomatrix btn-toggle"
              [ngClass]="{'hidden': !hasScore(i)}"
              [disabled]="form.disabled"
              (click)="toggleInMatrix(i)"
      >
        <i class="glyphicon "
           [ngClass]="{'glyphicon-ok' : inMatrix(i), 'glyphicon-remove' : !inMatrix(i)}">
        </i>
      </button>
    </td>
  </tr>
  <tr>
    <th colspan="3">
        <span class="label pull-right label-warning"
              [innerHTML]="'QSWS_WARNINGMAXTHREESTRENGTHS' | translate "
              *ngIf="toMatrix > 3"
        >
        </span>
    </th>
    <th colspan="2">
        <span class="label totallabel pull-right label-primary">
            {{ 'QSWS_TOTALPOINTS' | translate }}:{{ totalScore }}
        </span>
    </th>

  </tr>
</table>
