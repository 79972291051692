<div class="col-md-12" [formGroup]="form">
  <div class="form-group freeformot col-sm-12"
       style="padding-right: 3px;margin-right: 0; padding-left: 0">
    <label class="col-sm-7 control-label text-left">
      <span> {{ keyaccountName }}</span>
      <span class="info-text" [innerHTML]="'FREEFORMOT_SWOTSPRO' | translate"></span>
    </label>
    <label class="col-sm-2 control-label text-left"
           ng-bind-html="'QUALIFICATION' | translate"
           style="padding-top: 7px; padding-bottom: 10px;"
    ></label>
    <label class="col-sm-2 control-label  text-left"
           [innerHTML]="'REASON' | translate"
           style="padding-top: 7px; padding-bottom: 10px;"
    ></label>
    <button
      type="button"
      class="btn ng-scope no-btn success pull-right"
      (click)="addItem()"
      title="{{ 'NEW' | translate }}"
      style="margin-right: 12px"
    >
      <i class="glyphicon glyphicon-plus "></i>
    </button>

  </div>
  <div  *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i" class="clearfix freeFormRow">
    <div class="col-md-7" style="padding-left: 0">
      <input formControlName="uid" type="hidden" >
      <input formControlName="name" style="width: 550px; margin-left: 15px;" class="slim-input" >
    </div>
    <div class="col-sm-2" style="padding-top: 0px;">
      <!-- {{ mode }} -->
      <masterplanner2-swot-type formControlName="swotType" [mode]="mode"></masterplanner2-swot-type>
    </div>
    <div class="col-sm-3" style="padding-top: 0px;">
        <input type="text" formControlName="swotReason" class="form-control swotReason" title="{{ row.value.swotReason }}">
      <button type="button"
              class="btn ng-scope no-btn danger deletebutton pull-right"
              (click)="removeItem(i)"
              title="{{ 'DELETE' | translate }}"
      >
        <i class="glyphicon glyphicon-trash "></i>
      </button>
    </div>
  </div>
</div>
