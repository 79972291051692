import { Component } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";
import { AbstractFormArrayListComponent } from '../abstract-form-array-list/abstract-form-array-list.component';

@Component({
  selector   : 'masterplanner2-competitors',
  templateUrl: './competitors.component.html',
  styleUrls  : ['./competitors.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: CompetitorsComponent, multi: true}
  ]
})
export class CompetitorsComponent extends AbstractFormArrayListComponent {

  createItemControls(): FormGroup {
    return this.formBuilder.group({
      'uid'         : [, Validators.required],
      'name'        : [, Validators.required],
      'mainProducts': [, Validators.required],
      'strengths'   : [, Validators.required],
      'weaknesses'  : [, Validators.required],
    })
  }
}

