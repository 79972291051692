import { first, map } from 'rxjs/operators';
import { AbstractImporterService } from './import/abstract-importer.service';
import { KeyaccountNameMap } from './import/keyaccountNameMap';
import { IntakeImporterTransformService } from './intake-importer-transform.service';
import { KeyaccountService } from '@masterplanner2/users';
import * as i0 from "@angular/core";
import * as i1 from "../../../../libs/users/src/lib/keyaccount.service";
import * as i2 from "./intake-importer-transform.service";
export class IntakeImporterService extends AbstractImporterService {
    constructor(keyaccountService, intakeImporterTransform) {
        super(keyaccountService);
        this.intakeImporterTransform = intakeImporterTransform;
    }
    import(intakeImportdata) {
        if (intakeImportdata.length < 1) {
            return Promise.reject('The document is empty');
        }
        if (Object.keys(intakeImportdata[0]).indexOf('naam klant') === -1) {
            return Promise.reject('The document does not have a valid header, missing column: \'naam klant\'');
        }
        return this.keyaccountNameToUid$.pipe(first(), map(keyaccountsNameToIdMap => this.intakeImporterTransform.transform(intakeImportdata, KeyaccountNameMap, keyaccountsNameToIdMap))).toPromise().then(({ data, warnings }) => {
            console.log('RESULT', intakeImportdata, data, warnings);
            const savePromises = Object.keys(data)
                .map(keyaccountUid => {
                return this.keyaccountService.editKeyaccount(keyaccountUid, {
                    orderIntake: data[keyaccountUid],
                    orderIntakeImportedAt: new Date()
                });
            });
            console.log('to Store: ', data);
            return Promise.all(savePromises).then(() => warnings);
        });
    }
}
IntakeImporterService.ngInjectableDef = i0.defineInjectable({ factory: function IntakeImporterService_Factory() { return new IntakeImporterService(i0.inject(i1.KeyaccountService), i0.inject(i2.IntakeImporterTransformService)); }, token: IntakeImporterService, providedIn: "root" });
