<table
       id="matrix"
       class="table table-condensed  matrix col-sm-11  pull-left">
  <tr class="tableheader">
    <th class="empty" colspan="2"></th>
    <th class="topheader text-center" colspan="3">
      {{ 'OPPORTUNITIES' | translate }}
    </th>
    <th class="topheader text-center" colspan="3">
      {{ 'THREATS' | translate }}
    </th>
  </tr>
  <tr>
    <td class="empty" width="12.5%" colspan="2"></td>
    <td *ngFor="let swotName of getOtNames()" class="descriptionY" width="12.5%"><span>{{ swotName | translate }}</span></td>
  </tr>
  <tr *ngFor="let row of scoreMatrix?.rows; let rowIndex = index">
    <th *ngIf="rowIndex ===0" rowspan="3"><div class="vertical-text text-center">{{ 'STRENGTHS' | translate }}</div></th>
    <th *ngIf="rowIndex ===3" rowspan="3"><div class="vertical-text text-center">{{ 'WEAKNESSES' | translate }}</div></th>
    <td class="descriptionX" width="12.5%"><span>{{ getSwName(rowIndex) | translate }}</span></td>
    <td *ngFor="let cell of row"
        width="12.5%"
        class="text-center scorecell"
        style="text-align: center; vertical-align: middle"
        [ngClass]="{
                'score-1' : cell.value == '1',
                'score-3' : cell.value == '3',
                'score-5' : cell.value == '5'
           }"
    >
      <ng-container *ngIf="cell.value > 0">{{ cell.value }}</ng-container>
    </td>
  </tr>
</table>
