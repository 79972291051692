<div name="editForm" id="editForm" class="form-horizontal col-sm-12" [formGroup]="form" (submit)="doSave()" role="form">
  <div class="panel panel-primary" *ngIf="(keyaccountPlan$ | async) as keyaccountPlan">
    <div class="sw-heading col-sm-12 panel-heading">
      <div class="pull-left col-sm-8">
        <h2>
          <masterplanner2-header-logos [groupUid]="keyaccountPlan.groupUid" 
                                       [keyaccountUid]="keyaccountPlan.keyaccountUid"
          ></masterplanner2-header-logos>
          <div class="col-sm-10">{{ 'CONFRONTATIONMATRIX_PRO' | uppercase | translate }}</div>
        </h2>
        <div class="col-sm-12 version">{{ 'VERSION' | translate }}:{{ keyaccountPlan.version }}
          {{ 'ITARGETYEAR' | translate }}:{{ keyaccountPlan.targetYear }}
        </div>
      </div>
      <masterplanner2-nav-bar (save)="doSave()" [canSave]="canEdit$ | async"></masterplanner2-nav-bar>
    </div>
    <div class="form-group confrontationmatrixpro">
      <div class="separator col-sm-12">
        {{ 'TITLE_CONFRONTATIONMATRIX' | translate }}
        {{ keyaccountPlan.customerName }}
      </div>
      <div class="col-sm-12">
        <div class="form-group col-sm-8">
          <b><p class="info-text"
                [innerHTML]="'CONFRONTATIONMATRIX_EXPLANATION_PRO' | translate "></p></b>
        </div>
        <div class="form-group col-sm-3 pull-right">
          <button class="btn btn-lockmp pull-right" type="button"
                  [class.btn-primary]="!keyaccountPlan.matrixCompleted"
                  [disabled]="keyaccountPlan.matrixCompleted"
                  (click)="lockMatrix()"

          >
            <i class="glyphicon glyphicon-lock"></i>
            {{ (keyaccountPlan.matrixCompleted ? 'MATRIX_LOCKED' : 'LOCK_MATRIX') | translate }}
          </button>
        </div>
      </div>
      <div class="form-group col-sm-12">

        <masterplanner2-total-matrix *ngIf="(totalMatrix$ | async) as totalMatrix" [SWs]="SWs$ | async"
                                     [OTs]="OTs$ | async" 
                                     [matrix]="totalMatrix.rows"
                                     [canAddStrategicQuestion]="keyaccountPlan.matrixCompleted" 
                                     (addStrategicQuestion)="addStrategicQuestion($event)"
        ></masterplanner2-total-matrix>

        
        <div *ngIf="!keyaccountPlan.matrixCompleted">
          <label class="col-md-12">
            <span [innerHTML]="'TITLE_CONFRONTATIONMATRIX' | translate"></span>
          </label>
          <masterplanner2-score-matrix *ngIf="myScoreMatrix$ | async as myScoreMatrix" [SWs]="SWs$ | async"
                                        [OTs]="OTs$ | async"
                                        [scoreMatrix]="myScoreMatrix.rows"
                                        (setScore)="saveScores($event)"
          ></masterplanner2-score-matrix>
        </div>

        <div *ngIf="keyaccountPlan.matrixCompleted">
          <masterplanner2-total-matrix  *ngFor="let scoreMatrix of scoreMatrixes$ | async"
                                        [SWs]="SWs$ | async"
                                        [OTs]="OTs$ | async"
                                        [matrix]="scoreMatrix.rows"
                                        [userName]="scoreMatrix.name"
                                        [totalMatrix]="false"
                                        [collapsed]="true"
                                        [canAddStrategicQuestion]="false"
          ></masterplanner2-total-matrix>
        </div>
     </div> 

      <div *ngIf="keyaccountPlan.matrixCompleted">
        <div class="separator col-sm-12">
          {{ 'TITLE_STRATEGIC_QUESTIONS' | translate }}
          {{ keyaccountPlan.customerName}}
        </div>
        <masterplanner2-strategic-questions formControlName="strategicQuestions"></masterplanner2-strategic-questions>
      </div>
    </div>
  </div>
</div>
