<div class="separator">{{ 'SCORE_METERS' | translate }}</div>

<div class="row" style="display: flex; min-height: 200px">
  <div class="text-center"*ngIf="featureToggles?.brickwall" style="flex-grow: 1">
    <ng-container *ngIf="(brickwallScore$ | async) as brickwallScore">
    <kendo-radialgauge [pointer]="{ value: brickwallScore }" style="height: 200px; width: 150px;">
      <kendo-radialgauge-scale-labels>
      </kendo-radialgauge-scale-labels>
      <kendo-radialgauge-scale [minorUnit]="5" [majorUnit]="20" [max]="100">
        <kendo-radialgauge-scale-ranges>
          <kendo-radialgauge-scale-range [from]="0" [to]="33" color="rgb(255, 122, 0)">
          </kendo-radialgauge-scale-range>
          <kendo-radialgauge-scale-range [from]="33" [to]="67" color="rgb(255, 199, 0)">
          </kendo-radialgauge-scale-range>
          <kendo-radialgauge-scale-range [from]="67" [to]="100" color="rgb(92, 184, 92)">
          </kendo-radialgauge-scale-range>
        </kendo-radialgauge-scale-ranges>
      </kendo-radialgauge-scale>
    </kendo-radialgauge>
    <span><b>{{ 'BRICKWALL_SCORE' | translate }}</b>: {{ Math.round(brickwallScore) }}</span>
    </ng-container>
  </div>
  <div class="text-center" *ngIf="(companyIndexScore$ | async) as companyIndexScore" style="flex-grow: 1">
    <kendo-radialgauge [pointer]="{ value: companyIndexScore }" >
      <kendo-radialgauge-scale-labels>
      </kendo-radialgauge-scale-labels>
      <kendo-radialgauge-scale [minorUnit]="5" [majorUnit]="20" [max]="100">
        <kendo-radialgauge-scale-ranges>
          <kendo-radialgauge-scale-range [from]="0" [to]="33" color="rgb(255, 122, 0)">
          </kendo-radialgauge-scale-range>
          <kendo-radialgauge-scale-range [from]="33" [to]="67" color="rgb(255, 199, 0)">
          </kendo-radialgauge-scale-range>
          <kendo-radialgauge-scale-range [from]="67" [to]="100" color="rgb(92, 184, 92)">
          </kendo-radialgauge-scale-range>
        </kendo-radialgauge-scale-ranges>
      </kendo-radialgauge-scale>
    </kendo-radialgauge>
    <span><b>{{ 'BUYING_POWER_SCORE' | translate }}</b>: {{ Math.round(companyIndexScore) }}</span>
  </div>
  <div class="text-center" *ngIf="(dmuScore$ | async) as dmuScore" style="flex-grow: 1">
    <kendo-radialgauge [pointer]="{ value: dmuScore }" >
      <kendo-radialgauge-scale-labels>
      </kendo-radialgauge-scale-labels>
      <kendo-radialgauge-scale [minorUnit]="5" [majorUnit]="20" [max]="100">
        <kendo-radialgauge-scale-ranges>
          <kendo-radialgauge-scale-range [from]="0" [to]="33" color="rgb(255, 122, 0)">
          </kendo-radialgauge-scale-range>
          <kendo-radialgauge-scale-range [from]="33" [to]="67" color="rgb(255, 199, 0)">
          </kendo-radialgauge-scale-range>
          <kendo-radialgauge-scale-range [from]="67" [to]="100" color="rgb(92, 184, 92)">
          </kendo-radialgauge-scale-range>
        </kendo-radialgauge-scale-ranges>
      </kendo-radialgauge-scale>
    </kendo-radialgauge>
    <span><b>{{ 'DMU_SCORE' | translate }}</b>: {{ Math.round(dmuScore) }}</span>
  </div>
  <div class="text-center" *ngIf="(sow$ | async) as sow" style="flex-grow: 1">
    <kendo-radialgauge [pointer]="{ value: sow / 100 }">
      <kendo-radialgauge-scale-labels [position]="'outside'" format="p">
      </kendo-radialgauge-scale-labels>
      <kendo-radialgauge-scale [minorUnit]="0.1" [majorUnit]="0.20" [max]="1">
        <kendo-radialgauge-scale-ranges>
          <kendo-radialgauge-scale-range [from]="0" [to]="0.17" color="rgb(255, 122, 0)">
          </kendo-radialgauge-scale-range>
          <kendo-radialgauge-scale-range [from]="0.17" [to]="0.34" color="rgb(255, 199, 0)">
          </kendo-radialgauge-scale-range>
          <kendo-radialgauge-scale-range [from]="0.34" [to]="1" color="rgb(92, 184, 92)">
          </kendo-radialgauge-scale-range>
        </kendo-radialgauge-scale-ranges>
      </kendo-radialgauge-scale>
    </kendo-radialgauge>
    <span><b>{{ 'SOW' | translate }}</b>: {{ Math.round(sow) }}%</span>
  </div>
</div>
<!--<uib-accordion close-others="oneAtATime" class="show-details">-->
  <a (click)="showScoreTable = ! showScoreTable" class="show_more">
    <i class="glyphicon" [ngClass]="{'glyphicon-menu-down': !showScoreTable, 'glyphicon-menu-up': showScoreTable}"></i>
    <span *ngIf="!showScoreTable"> {{ 'SHOW' | translate }} </span>
    <span *ngIf="showScoreTable"> {{ 'HIDE' | translate }} </span>
  </a>
  <div>
    <table *ngIf="showScoreTable; else noScoreTable" class="table table-striped table-bordered">
      <thead>
      <tr>
        <th colspan="4">{{'MASTERPLANNER' | translate }}</th>
        <th *ngIf="featureToggles?.brickwall" style="width: 15%" class="text-center">
          <span>{{ 'BRICKWALLING' | translate }}</span><br>
        </th>
        <th style="width: 15%" class="text-center">
          <span>{{ 'BUYINGPOWER' | translate }}</span>
        </th>
        <th style="width: 15%" class="text-center">
          <span>{{ 'DMUSCORE' | translate }}</span><br>
        </th>
        <th style="width: 15%" class="text-center">
          <span>{{ 'SOW' | translate }}</span><br>
        </th>
      </tr>
      </thead>
      <tbody>
        <tr *ngFor="let plan of (keyaccountPlansCalculated$ | async)">
          <td>
            <masterplanner2-image-preview [path]="'keyaccount/' + plan.keyaccountUid + '/images/logo'" class="pull-right" maxHeight="20px"></masterplanner2-image-preview>
          </td>
          <td>{{ plan.keyaccountName }}</td>
          <td>{{ plan.version }}</td>
          <td>{{ plan.targetYear }}</td>
          <td  *ngIf="featureToggles?.brickwall" class="text-center">
            {{ plan.score.brickwall }}
          </td>
          <td class="text-center">
            {{ Math.round(plan.score.buyingpower) }}
          </td>
          <td class="text-center">
            {{ plan.score.dmu }}
          </td>
          <td class="text-center">
            {{ 'SOW_' + plan.score.sow | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ng-template #noScoreTable>
    <hr>
  </ng-template>
<!--</uib-accordion>-->
