import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { AbstractFormArrayListComponent } from '../../profile/abstract-form-array-list/abstract-form-array-list.component';

@Component({
  selector   : 'masterplanner2-ot-rating',
  templateUrl: './ot-rating.component.html',
  styleUrls  : ['./ot-rating.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: OtRatingComponent, multi: true}
  ]
})
export class OtRatingComponent extends AbstractFormArrayListComponent {
  @Input()
  swotType: 'OPPORTUNITY' | 'THREAT';

  @Input()
  disabled: boolean = false;

  constructor(formBuilder: FormBuilder) {
    super(formBuilder);
  }

  createItemControls(): FormGroup {
    return this.formBuilder.group({
      'uid'  : [, Validators.required],
      'name' : [, Validators.required],
      'score': [, Validators.required]
    });
  }

  setScore(i, score) {
    if (this.form.disabled) {
      console.log('Cannot change score on a disabled field');
      return;
    }
    if (this.getScore(i) == score) {
      this.form.get('' + i).get('score').setValue(null);
    } else if (this.canScore(score)) {
      this.form.get('' + i).get('score').setValue(score);
    }
  }

  canScore(score): boolean {
    if (this.form.disabled) {
      return false;
    }
    const rowValues = this.form.value;
    return !rowValues.find(row => row.score === score);
  }

  getScore(i) {
    return this.form.getRawValue()[i].score;
  }
}
