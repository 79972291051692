import { Component, Input, OnInit } from '@angular/core';
import { AbstractFormArrayListComponent } from '../../profile/abstract-form-array-list/abstract-form-array-list.component';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
  selector: 'masterplanner2-freeform-swot',
  templateUrl: './freeform-swot.component.html',
  styleUrls: ['./freeform-swot.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: FreeformSwotComponent, multi: true}
  ]
})
export class FreeformSwotComponent extends AbstractFormArrayListComponent implements OnInit {
  @Input()
  keyaccountName;

  @Input()
  mode: 'OT' | 'SW' = 'OT';

  constructor(protected formBuilder: FormBuilder) {
    super(formBuilder);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  createItemControls(): FormGroup {
    return this.formBuilder.group({
      'uid'       : [, Validators.required],
      'name'      : [, Validators.required],
      'swotType'  : [, Validators.required],
      'swotReason': [, Validators.required],
    })
  }
}
