<div name="editForm" id="editForm" class="form-horizontal  col-sm-12" [formGroup]="form" (submit)="doSave()" role="form">
  <div class="panel panel-primary" *ngIf="(keyaccountPlan$ | async) as keyaccountPlan">
    <div class="sw-heading col-sm-12 panel-heading">
      <div class="pull-left col-sm-8">
        <h2>
          <masterplanner2-header-logos [groupUid]="keyaccountPlan.groupUid" 
                                       [keyaccountUid]="keyaccountPlan.keyaccountUid"
          ></masterplanner2-header-logos>
          <div class="col-sm-10">{{ 'SWOT_PRO' | uppercase | translate }} {{ keyaccountPlan.keyaccountName }}</div>
        </h2>
        <div class="col-sm-12 version">{{ 'VERSION' | translate }}:{{ keyaccountPlan.version }}
          {{ 'ITARGETYEAR' | translate }}: {{ keyaccountPlan.targetYear }}
        </div>
      </div>
      <masterplanner2-nav-bar (save)="doSave()" [canSave]="canEdit$ | async"></masterplanner2-nav-bar>

    </div>
    <div class="form-group">
      <div class="separator col-sm-12">
        {{ keyaccountPlan.customerName }} {{ 'TITLE_EXTERNALACCOUNT_ANALYSIS' | translate }}
      </div>

      <!-- freeformOT -->
      <masterplanner2-freeform-swot formControlName="freeformOT"></masterplanner2-freeform-swot>

      <!-- oppertunities -->
      <div class="step4sep">
        <div class="separator col-sm-12">
          {{ keyaccountPlan.customerName }} {{ 'OPPORTUNITIES' | translate }}
        </div>
        <div class="col-sm-12">
          <div class="form-group col-sm-12">
            <b><p class="info-text" [innerHTML]="'SCORE_FORM_OPPORTUNITIES_TABLE_EXPLANATION_PRO' | translate "></p></b>
          </div>
        </div>
        <div class="form-group col-sm-12">
          <masterplanner2-ot-rating [swotType]="'OPPORTUNITY'" formControlName="swotOpportunities"></masterplanner2-ot-rating>
        </div>

        <div class="separator col-sm-12">
          {{ keyaccountPlan.customerName }} {{ 'THREATS' | translate }}
        </div>
        <div class="col-sm-12">
          <div class="form-group col-sm-12">
            <b><p class="info-text" [innerHTML]="'SCORE_FORM_THREATS_TABLE_EXPLANATION_PRO' | translate "></p></b>
          </div>
        </div>
        <div class="form-group col-sm-12">
          <masterplanner2-ot-rating [swotType]="'THREAT'" formControlName="swotThreats"></masterplanner2-ot-rating>
        </div>
      </div>

      <div class="separator col-sm-12">
        {{ keyaccountPlan.customerName }} {{ 'TITLE_INTERNALACCOUNT_ANALYSIS' | translate }}
      </div>
      <div class="col-sm-12">
        <p class="info-text faq">
          <a target="_blank" localeFile="assets/pdfs/faq_internalanalyse.pdf">
            {{ 'FAQ_INTERNALANALYSE' | translate }}
            <i class="glyphicon glyphicon-new-window"></i>
          </a>
        </p>
      </div>

      <!-- freeformSW -->
      <masterplanner2-freeform-swot formControlName="freeformSW" mode="SW"></masterplanner2-freeform-swot>

      <!-- Strengths -->
      <div class="separator col-sm-12">
        {{ keyaccountPlan.customerName }} {{ 'STRENGTHS' | translate }}
      </div>
      <div class="form-group col-sm-12">
        <div class="col-sm-12">
          <div class="form-group col-sm-12">
            <b><p class="info-text"
                  [innerHTML]="'SCORE_FORM_STRENGTHS_TABLE_EXPLANATION_PRO' | translate "></p></b>
          </div>
        </div>
        <masterplanner2-sw-rating formControlName="swotStrengths" [mode]="'strength'"></masterplanner2-sw-rating>
      </div>
      <!-- Weaknesses -->
      <div class="separator col-sm-12">
        {{ keyaccountPlan.customerName }} {{ 'WEAKNESSES' | translate }}
      </div>
      <div class="form-group col-sm-12">
        <div class="col-sm-12">
          <div class="form-group col-sm-12">
            <b><p class="info-text"
                  [innerHTML]="'SCORE_FORM_WEAKNESSES_TABLE_EXPLANATION_PRO' | translate "></p></b>
          </div>
        </div>
        <masterplanner2-sw-rating formControlName="swotWeaknesses" [mode]="'weakness'"></masterplanner2-sw-rating>

        <div class="form-group col-sm-3 pull-right">
          <button class="btn btn-lockmp pull-right" type="button"
                  [ngClass]="{'btn-primary' : ( !(hasMatrix$ | async) )}"
                  (click)="lockSwot()"
                  [disabled]="!(canLock$ | async) || (hasMatrix$ | async)"
          >
            <i class="glyphicon glyphicon-lock"></i>
            {{ (( hasMatrix$ | async ) ? 'SWOT_LOCKED' : 'LOCK_SWOT') | translate }}
          </button>
        </div>
      </div>

      <div class="separator col-sm-12">
        {{ 'TITLE_SWOT_TABLE' | translate }}
      </div>

      <masterplanner2-swot-table [keyaccountPlan$]="keyaccountPlanWithChanges$"></masterplanner2-swot-table>
    </div>
  </div>
</div>
