import { map, publishReplay } from "rxjs/operators";
import { AbstractTabComponent } from "../profile/abstract-tab.component";
export class ActivityComponent extends AbstractTabComponent {
    setupObservables() {
        super.setupObservables();
        this.activities$ = this.form.valueChanges.pipe(map(keyaccountPlan => keyaccountPlan.strategicActions ? keyaccountPlan.strategicActions : []), map(mainActivities => {
            // flatten array with activities and sub-activities
            return [].concat.apply([], mainActivities.map(mainActivity => ([
                Object.assign({}, mainActivity, { isMaster: true, description: mainActivity.action }),
                ...(mainActivity.subActivities ? mainActivity.subActivities : [])
                    .map(subActivity => (Object.assign({}, subActivity, { realised: mainActivity.realised })))
            ])));
        }), publishReplay(1));
    }
    buildForm() {
        return this.formBuilder.group({
            'uid': [],
            'strategicActions': [],
        });
    }
    ngOnInit() {
        super.ngOnInit();
        this.activitiesSubscription = this.activities$.connect();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.activitiesSubscription) {
            this.activitiesSubscription.unsubscribe();
        }
    }
    mapFromDatabase(keyaccountPlan) {
        const newStrategicActions = (keyaccountPlan.strategicActions ? keyaccountPlan.strategicActions : []).map(action => {
            const newSubActivities = (action.subActivities ? action.subActivities : []).map(subAction => {
                return Object.assign({}, subAction, { startDate: (subAction.startDate ? subAction.startDate.toDate() : null), endDate: (subAction.endDate ? subAction.endDate.toDate() : null) });
            });
            return Object.assign({}, action, { subActivities: newSubActivities, startDate: (action.startDate ? action.startDate.toDate() : null), endDate: (action.endDate ? action.endDate.toDate() : null) });
        });
        return Object.assign({}, keyaccountPlan, { strategicActions: newStrategicActions });
    }
}
