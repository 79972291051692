<div class="col-sm-12">
  <a *ngIf="!totalMatrix" (click)="collapsed = !collapsed" [attr.aria-expanded]="!collapsed" aria-controls="matrix">
    <i class="glyphicon icon-green" [ngClass]="{'glyphicon-menu-down': collapsed, 'glyphicon-menu-up': !collapsed}"></i>
    <span *ngIf="collapsed" class="text-black"><b> {{ 'CONFRONTATIONMATRIX' | translate }} {{ userName }} </b></span>
    <span *ngIf="!collapsed" class="text-black"><b>  {{ 'CONFRONTATIONMATRIX' | translate }} {{ userName }} </b></span>
  </a>
</div>

<div class="" id="matrix" [ngbCollapse]="collapsed" >
  <div class="col-sm-1 pull-left leftheader">
    <div class="vertical-text text-center" id="leftheader1">
      <span>{{ 'STRENGTHS' | translate }}</span>
    </div>
    <div class="vertical-text text-center" id="leftheader2">
      <span>{{ 'WEAKNESSES' | translate }}</span>
    </div>
  </div>

  <table *ngIf="matrix"
    id="matrix"
    class="table table-condensed  matrix col-sm-11  pull-left">
    <tr class="tableheader">
      <th class="empty"></th>
      <th class="topheader text-center" colspan="3">
        {{ 'OPPORTUNITIES' | translate }}
      </th>
      <th class="topheader text-center" colspan="3">
        {{ 'THREATS' | translate }}
      </th>
    </tr>
    <tr>
      <td class="empty" width="12.5%"></td>
      <td *ngFor="let swotName of OTs" class="descriptionY" width="12.5%"><span>{{ swotName | translate }}</span></td>
    </tr>
    <tr *ngFor="let row of matrix; let rowIndex = index">
      <td class="descriptionX" width="12.5%"><span>{{ getSwName(rowIndex) | translate }}</span></td>
      <td *ngFor="let cell of row"
          width="12.5%"
          class="text-center scorecell"
          [ngClass]="{
                'score-1' : cell.value == '1',
                'score-3' : cell.value == '3',
                'score-5' : cell.value == '5'
           }"
      >
        <div class="btn-group">
          <span [innerHTML]="cell.value" *ngIf="cell.value > 0"></span>
          <button type="button"
                  class="btn no-btn primary pull-right"
                  (click)="addStrategicIssue(cell.id)"
                  *ngIf="cell.value > 0 && canAddStrategicQuestion"
                  style="margin-left: 10px; margin-top: 8px;"
                  title="{{'ADDSTRATEGICISSUE' | translate}}"
          >
            <i class="glyphicon glyphicon-screenshot"></i>
          </button>
        </div>
      </td>
    </tr>
  </table>
</div>
