import { Component, OnInit } from '@angular/core';
import { AbstractFormArrayListComponent } from '../abstract-form-array-list/abstract-form-array-list.component';
import { FormGroup, NG_VALUE_ACCESSOR, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'masterplanner2-main-activities',
  templateUrl: './main-activities.component.html',
  styleUrls: ['./main-activities.component.css'],
  providers  : [
    { provide: NG_VALUE_ACCESSOR, useExisting: MainActivitiesComponent, multi: true }
  ]
})
export class MainActivitiesComponent extends AbstractFormArrayListComponent implements OnInit {

  parentForm: FormGroup;

  constructor(protected formBuilder: FormBuilder) {
    super(formBuilder);
  }

  ngOnInit() {
    super.ngOnInit();
    this.parentForm = this.createForm();
    this.generateRows();
  }

  createItemControls(): FormGroup {
    return this.formBuilder.group({
      'uid'    : [, Validators.required],
      'activity'    : [, Validators.required],
      'swotType'    : [, Validators.required],
      'swotReason'  : [, Validators.required]
    });
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      activities: this.form,
      description: [,Validators.required],
    })
  }

  registerOnChange(fn: any): void {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe()
    }
    this.onChangeSubscription = this.parentForm.valueChanges.subscribe(value => fn(value));
  }

  setDisabledState(isDisabled: boolean): void {
    this.parentForm[isDisabled ? 'disable' : 'enable']({emitEvent: false});
    super.setDisabledState(isDisabled);
  }

  writeValue(items: any): void {
    const itemOrDefault = {activities: [{},{}], description: '', ...items};
    super.writeValue(itemOrDefault.activities);
    this.parentForm.patchValue(itemOrDefault);
  }

  private generateRows(): void {
    for(let i = this.form.length; i < 2;i++) {
      this.addItem();
    }
  }
}
