<form name="editForm" id="editForm" class="form-horizontal col-sm-12" [formGroup]="form" (submit)="doSave()" role="form">
    <div class="panel panel-primary" *ngIf="(currentUser$ | async) as currentUser">
      <div class="sw-heading col-sm-12 panel-heading">
        <div class="pull-left"><h2>{{ 'MY_SETTINGS' | translate }}</h2>
        </div>
      </div>
        <div class="form-group">
          <label for="name" class="col-sm-2 control-label">{{ 'CNAME' | translate }}</label>
          <div class="col-sm-4">
            <input type="text"
                   class="form-control"
                   id="name"
                   [value]="currentUser.name"
                   disabled
            >
          </div>
        </div>
        <div class="form-group">
          <label for="cEmail" class="col-sm-2 control-label">{{ 'CEMAIL' | translate }}</label>
          <div class="col-sm-4">
            <input type="text"
                   class="form-control"
                   id="cEmail"
                   [value]="currentUser.email"
                   disabled
            >
          </div>
          <label for="cTelefoon" class="col-sm-2 control-label">{{ 'CTELEFOON' | translate }}</label>
          <div class="col-sm-4">
            <input type="text"
                   class="form-control"
                   id="cTelefoon"
                   formControlName="phonenumber"
            >
          </div>
        </div>
        <div class="form-group">
          <label for="idCustomer" class="col-sm-2 control-label">{{ 'CUSTOMER' | translate }}</label>
          <div class="col-sm-4">
            <input type="text" [value]="currentUser.groupUid | userGroup | async" class="form-control" disabled>
          </div>
          <label for="cLanguage" class="col-sm-2 control-label">{{ 'CLANGUAGE' | translate }}</label>
          <div class="col-sm-4">
            <select name="language" formControlName="language" class="form-control">
              <option *ngFor="let language of languages$ | async" [value]="language.code">{{ language.name }}</option>
            </select>  
          </div>
        </div>
      </div>
      <button type="submit" [disabled]="!form.valid" class="btn btn-primary">Save</button>  
  </form>
