<div [formGroup]="form">
  <table class="table table-bordered table-servicelinespro">
    <tr>
      <th colspan="9">
        <span>{{ 'PRODUCT_SERVICELINES_PRO' | translate }}</span>
      </th>
    </tr>
    <tr>
      <th class="text-center" width="20%">
        {{ 'ACTUALREVENUE' | translate }}
        <span *ngIf="revenueImportedAt" style="color:gray;font-size: small"><br/>Last import: {{ revenueImportedAt.toDate()  | date: 'dd-MM-yyyy' }}</span>
        <span *ngIf="!revenueImportedAt && lastUpdatedRevenues?.servicelines" style="color:gray;font-size: small"><br/>Last updated: {{ lastUpdatedRevenues.servicelines.toDate()  | date: 'dd-MM-yyyy' }}</span>
      </th>
      <th class="text-center" width="8%" *ngFor="let year of yearSpan" >{{ year }}</th>
      <th class="text-center" width="18%"> {{ 'COMPETITION' | translate }}</th>
      <th class="text-center" width="10%">
        <!--<span ng-bind-html="vm.oEndpoint.cCustomername"></span>-->
        <span>{{ 'POSITION' | translate }}</span>
      </th>
    </tr>
    <tr *ngFor="let serviceline of serviceLines" class="serviceline" [formGroupName]="serviceline.uid">
      <th>
        {{ serviceline.description }}
      </th>
      <td class="text" *ngFor="let year of yearSpan">
        <kendo-numerictextbox [autoCorrect]="false"
                              [spinners]="false"
                              [decimals]="0"
                              [format]="'n0'"
                              [formControlName]="year"
                              [readonly]="!!revenueImportedAt"
        ></kendo-numerictextbox>
      </td>
      <td>
        <input type="text"
              class="form-control"
              formControlName="competitor1"
              placeholder="{{'COMPETITOR1' | translate }}"
        >
        <input type="text"
              class="form-control"
              formControlName="competitor2"
              placeholder="{{'COMPETITOR2' | translate }}"
        >
      </td>
      <td>
        <masterplanner2-swot-type formControlName="positionCompetition1" [mode]="'SW'"></masterplanner2-swot-type>
        <masterplanner2-swot-type formControlName="positionCompetition2" [mode]="'SW'"></masterplanner2-swot-type>
      </td>
    </tr>
    <tr class="totalrow" *ngIf="yearlySum$ | async as yearlySum">
      <th class="text-left" width="20%"
          style="padding-top: 16px;"
          > {{ 'TOTAL' | translate }}</th>
      <th *ngFor="let year of yearSpan" class="text-center" width="8%%">
        {{ formatNumber(yearlySum[year], 'n0') }}
      </th>
      <th class="text-center" width="18%"></th>
      <th class="text-center" width="10%"></th>
    </tr>
  </table>
</div>
