import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';
import { UserService, AuthService } from '@masterplanner2/users';
import { Router } from '@angular/router';

@Component({
  selector: 'masterplanner2-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {

  public username$: Observable<string>;
  public companyname$: Observable<string>;

  constructor(userService: UserService, private authService: AuthService, private router: Router) {
    this.username$ = userService.getCurrentUser().pipe(
      tap(console.log),
      map(user => user ? user.name : null),
      shareReplay(1)
    );
    this.companyname$ = userService.getCurrentUser().pipe(
      map(user => user ? user.group.name : null),
      shareReplay(1)
    );
  }

  ngOnInit() {
  }

  doLogout() {
    return this.authService.logout()
      .then(() => this.router.navigateByUrl('/login'));
  }

}
