  <div class="panel panel-primary" *ngIf="bundle$ | async as bundle">
    <div class="sw-heading col-sm-12 panel-heading">
      <div class="pull-left col-sm-10">
        <h2 style="margin-top: 0">
          <div class="col-sm-12">
            <i class="glyphicon glyphicon-dashboard"></i>
            {{ bundle.name | uppercase | translate }}
          </div>
        </h2>
      </div>
      <div class="pull-right swtoolbar">
        <a class="btn btn-default" routerLink="/settings/bundles" title="Cancel, go back to list">
          <i class="glyphicon glyphicon-remove"></i>
        </a>
      </div>
    </div>

    <div *ngIf="keyaccountPlans$ | async as keyaccountPlans">
      <div class="form-group">
        <div class="separator col-sm-12">{{ 'MASTERPLANNERS_IN_BUNDLE' | translate }}<span class="info-text col-sm-4 pull-right text-right" >{{ 'ALL_AMOUNTS_THOUSENDS' | translate }}</span></div>
        <div class="col-sm-12">
          <a (click)="expandKeyaccountPlans = ! expandKeyaccountPlans" class="show_more">
            <i class="glyphicon" [ngClass]="{'glyphicon-menu-down': !expandKeyaccountPlans, 'glyphicon-menu-up': expandKeyaccountPlans}"></i>
            <span *ngIf="!expandKeyaccountPlans"> {{ 'SHOW' | translate }} </span>
            <span *ngIf="expandKeyaccountPlans"> {{ 'HIDE' | translate }} </span>
          </a>
        </div>
        <table *ngIf="expandKeyaccountPlans; else hideKeyaccountPlans" class="table table-striped table-bordered">
          <tr *ngFor="let plan of keyaccountPlans">
            <td>
              <masterplanner2-image-preview [path]="'keyaccount/' + plan.keyaccountUid + '/images/logo'" maxHeight="20px" class="pull-right"></masterplanner2-image-preview>
            </td>
            <td>{{ plan.keyaccountName }}</td>
            <td>{{ plan.version }}</td>
            <td>{{ plan.targetYear }}</td>
            <td>{{ plan.keyaccountManagerName }}</td>
            <td>{{ plan.status | translate }}
            </td>
            <td>
              <a class="jumpto col-sm-1" routerLink="/keyaccountplan/{{ plan.uid }}/dashboard"
                 title="{{ 'OPENTAB_TITLE' |translate }}" target="_blank"
              >
                <i class="glyphicon glyphicon-new-window"></i>
              </a>
            </td>
          </tr>
        </table>
        <ng-template #hideKeyaccountPlans>
          <div class="row">
            <div class="col-sm-12">
              <hr>
            </div>
          </div>
        </ng-template>


        <masterplanner2-revenue-chart [keyaccountPlans$]="keyaccountPlans$" [featureToggles]="(customer$ | async)?.featureToggles" [yearSpan]="yearSpan"></masterplanner2-revenue-chart>
        <masterplanner2-turnover-dashboard [keyaccountPlans$]="keyaccountPlans$" [featureToggles]="(customer$ | async)?.featureToggles" [yearSpan]="yearSpan"></masterplanner2-turnover-dashboard>
        <masterplanner2-score-dashboard [keyaccountPlans$]="keyaccountPlans$" [featureToggles]="(customer$ | async)?.featureToggles"></masterplanner2-score-dashboard>

        <masterplanner2-activity-dashboard [keyaccountPlans$]="keyaccountPlans$"></masterplanner2-activity-dashboard>


      </div>
    </div>
  </div>

