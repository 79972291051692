<table [formGroup]="form" id="brickwallcriteriums" class="table table-condensed brickwallcriteriums col-sm-12">
  <tr>
    <th> {{ 'BRICKWALL_CRITERIUM' | translate }} </th>
    <th class="text-center"> {{ 'BRICKWALL_ISCORE' | translate }} </th>
    <th class="text-center"> {{ 'BRICKWALL_ITARGET' | translate }} </th>
  </tr>
  <tr *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
    <td class="col-sm-4 text-left">
      {{row.value.description | translate }}
    </td>
    <td class="col-sm-5 text-left">
      <div class="btn-group col-sm-12">
        <label *ngFor="let option of ['0', '1', '2']" id="{{ row.value['option' + option] }}"
               class="btn btn-default col-sm-4"
               (click)="setScore(i, option)"
               [ngClass]="{
                  'btn-danger': row.value.score == option && option == 0,
                  'btn-warning': row.value.score == option && option == 1,
                  'btn-success': row.value.score == option && option == 2
                  }"
        >
          {{ row.value['option' + option] }}
        </label>
      </div>
    </td>
    <td class="col-sm-1 text-center">
        <span class="label brickwall-target label-default"
              [ngClass]="{'label-success': (row.value.target <= row.value.score)}"
        >{{ row.value['option' + row.value.target] }}</span>
    </td>
  </tr>
  <tr class="totalline">
    <th colspan="4">
      <h2>{{ 'BRICKWALLINGSCORE' | translate }} : {{ countBrickwall(form.value) }}</h2>
    </th>
  </tr>
</table>
