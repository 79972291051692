import { Component, OnInit } from '@angular/core';
import { AbstractFormArrayListComponent } from "../../profile/abstract-form-array-list/abstract-form-array-list.component";
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ConnectableObservable } from "rxjs";
import { map, publishReplay, tap } from "rxjs/operators";

@Component({
  selector: 'masterplanner2-strategic-questions',
  templateUrl: './strategic-questions.component.html',
  styleUrls: ['./strategic-questions.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: StrategicQuestionsComponent, multi: true}
  ]
})
export class StrategicQuestionsComponent extends AbstractFormArrayListComponent implements OnInit {
  hasExecutableOptions$: ConnectableObservable<boolean[]>;

  constructor(formBuilder: FormBuilder) {
    super(formBuilder);

    this.hasExecutableOptions$ = this.form.valueChanges.pipe(
      tap(hasExecutables => console.log('values: ', hasExecutables)),
      map(strategicQuestions => {
        return strategicQuestions.map(strategicQuestion => {
          if(!strategicQuestion.options) {
            return false;
          }
          return strategicQuestion.options.filter(option => option.execute).length > 0;
        });
      }),
      tap(hasExecutables => console.log('executables: ', hasExecutables)),
      publishReplay(1),
    ) as ConnectableObservable<boolean[]>;
  }

  ngOnInit() {
    this.hasExecutableOptions$.connect();
  }

  createItemControls(): FormGroup {
    return this.formBuilder.group({
      'uid': [],
      'type': [],
      'matrixCell': [],
      'question': [],
      'options': [],
    })
  }

  addStrategicQuestion(newQuestion: {question: string, matrixCell: number, type: string, options: any[]}): boolean {
    // See if we don't already have this cell
    // TODO
    const questions = this.form.getRawValue();
    if(!questions.find(question => question.matrixCell === newQuestion.matrixCell)) {
      this.addItem(newQuestion);
      return true;
    }
    return false;
  }
}
