import { AbstractTabComponent } from "../profile/abstract-tab.component";
import { toNumbers } from '../utils';
export class DashboardComponent extends AbstractTabComponent {
    constructor() {
        super(...arguments);
        this.orderIntakeTargetData = [];
        this.orderIntakeData = [];
        this.orderBacklogData = [];
        this.targetRevenueData = [];
        this.customerRevenueData = [];
        this.Math = Math;
        this.statuses = ['In process', 'Active', 'Archive'];
    }
    patchForm(keyaccountPlan, canEdit) {
        const yearSpan = this.keyaccountPlanService.getYearSpan(keyaccountPlan.targetYear);
        super.patchForm(keyaccountPlan, canEdit);
        this.orderIntakeTargetData = [];
        this.orderIntakeData = [];
        this.orderBacklogData = [];
        this.targetRevenueData = [];
        this.customerRevenueData = [];
        yearSpan.forEach(year => {
            this.orderIntakeTargetData.push(keyaccountPlan.orderIntakeTarget[year]);
            this.orderIntakeData.push(keyaccountPlan.orderIntake[year]);
            this.orderBacklogData.push(keyaccountPlan.orderBacklog[year]);
            this.targetRevenueData.push(keyaccountPlan.targetRevenue[year]);
            this.customerRevenueData.push(keyaccountPlan.customerRevenue[year]);
        });
        this.form.disable({ emitEvent: false });
        if (canEdit) {
            this.form.get('generalGoal').enable();
            this.form.get('status').enable();
        }
    }
    buildForm() {
        return this.formBuilder.group({
            'uid': [],
            'orderIntakeTarget': [],
            'orderIntake': [],
            'orderBacklog': [],
            'targetRevenue': [],
            'customerRevenue': [],
            'accountGoals': this.formBuilder.group({
                'explanation1': [],
                'explanation2': [],
                'explanation3': [],
                'explanation4': [],
                'explanation5': [],
            }),
            'customerName': [],
            'version': [],
            'targetYear': [],
            'keyaccountName': [],
            'keyaccountManagerName': [],
            'status': [],
            'strategicActions': [],
            'generalGoal': [],
        });
    }
    countBrickwall(brickwallCriteria) {
        return this.keyaccountPlanService.countBrickwall(brickwallCriteria);
    }
    mapFromDatabase(keyaccountPlan) {
        const newStrategicActions = (keyaccountPlan.strategicActions ? keyaccountPlan.strategicActions : []).map(action => {
            const newSubActivities = (action.subActivities ? action.subActivities : []).map(subAction => {
                return Object.assign({}, subAction, { startDate: (subAction.startDate ? subAction.startDate.toDate() : null), endDate: (subAction.endDate ? subAction.endDate.toDate() : null) });
            });
            return Object.assign({}, action, { subActivities: newSubActivities, startDate: (action.startDate ? action.startDate.toDate() : null), endDate: (action.endDate ? action.endDate.toDate() : null) });
        });
        return Object.assign({}, keyaccountPlan, { accountGoals: keyaccountPlan.accountGoals ? keyaccountPlan.accountGoals : { explanation1: null, explanation2: null, explanation3: null, explanation4: null, explanation5: null }, strategicActions: newStrategicActions, orderIntakeTarget: keyaccountPlan.orderIntakeTarget ? toNumbers(keyaccountPlan.orderIntakeTarget) : {}, orderIntake: keyaccountPlan.orderIntake ? toNumbers(keyaccountPlan.orderIntake) : {}, orderBacklog: keyaccountPlan.orderBacklog ? toNumbers(keyaccountPlan.orderBacklog) : {}, targetRevenue: keyaccountPlan.targetRevenue ? toNumbers(keyaccountPlan.targetRevenue) : {}, customerRevenue: keyaccountPlan.customerRevenue ? toNumbers(keyaccountPlan.customerRevenue) : {} });
    }
}
