import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { KeyaccountPlan } from '../keyaccount-plan.model';
import { AngularFirestore, AngularFirestoreCollection, QueryFn } from '@angular/fire/firestore';
import { User, UserService } from '@masterplanner2/users';
import { catchError, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { Customer } from '../customer.model';
import { CustomerService } from '../customer.service';

export class Bundle {
  uid: string;
  groupUid: string;
  userUid?: string;
  customerName: string;
  name: string;
  description: string;
  keyaccountPlans: string[];
}


@Injectable({
  providedIn: 'root'
})
export class BundleService {
  private readonly collectionName = 'bundles';

  private bundles$: Observable<Bundle[]>;
  private bundleCollection: AngularFirestoreCollection<Bundle>;


  constructor(private db: AngularFirestore, private userService: UserService, private customerService: CustomerService) {
    this.bundleCollection = db.collection(this.collectionName);
    const flatten = arr => [].concat.apply([], arr);

    this.bundles$ = userService.getCurrentUser().pipe(
      switchMap(user => {
        if(!user) {
          return of([]);
        }

        if (user.roles.includes('ADMIN')) {
          return this.getBundleBy();
        } else if(user.roles.includes('GROUPADMIN')) {
          return this.getBundleBy('groupUid', user.groupUid);
        } else if(user.roles.includes('ACCOUNTMANAGER') || user.roles.includes('ACCOUNTVIEWER')) {
          return this.getBundleBy('userUid', user.uid);
        } else {
          // No role?
          console.error('No matching role found to filter bundles', user);
          return of([]);
        }
      }),
      shareReplay(1),
      catchError(err => {
        console.error('Error retrieving bundles', err);
        return of([]);
      }),
    );

  }

  private getBundleBy(field?: string, value?: string) {
    const queryFn: QueryFn = field ? ref => ref.where(field, '==', value) : ref => ref;
    return this.db.collection<KeyaccountPlan>(this.collectionName, queryFn).valueChanges().pipe(
      catchError(err => {
        console.error('Error retrieving bundles:', err);
        return of([]);
      }),
    );
  }


  getBundles(): Observable<Bundle[]> {
    return this.bundles$;
  }

  getBundle(uid: string): Observable<Bundle> {
    return <Observable<Bundle>>this.bundleCollection.doc(uid).valueChanges().pipe(
      map(bundle => ({
        keyaccountPlans: [],
        ...bundle
      })),
    );
  }

  editBundle(uid: string, bundle: Partial<Bundle>) {
    const doc = this.bundleCollection.doc<Bundle>(uid);

    if('groupUid' in bundle) {
      return this.customerService.getCustomer(bundle.groupUid).pipe(first()).toPromise()
        .then(customer => {
          return doc.update({ ...bundle, uid: uid, customerName: customer.name }).then(() => doc.ref);
        });
    } else {
      return doc.update({ ...bundle, uid: uid }).then(() => doc.ref);
    }
  }

  createBundle(customerUid: string, keyaccountPlanUids: string[], name: string, description: string, originalBundle?: Bundle) {
    const bundle = originalBundle ? this.createBundleClone(originalBundle) : {name, description, keyaccountPlans: []};
    const currentUser$ = this.userService.getCurrentUser();
    const customer$ = this.customerService.getCustomer(customerUid);
    return combineLatest([customer$, currentUser$]).pipe(first()).toPromise()
      .then(([customer, currentUser]) => {
        return this.bundleCollection.add(<Bundle>
          {
            ...bundle,
            groupUid      : customerUid,
            customerName: customer.name,
            userUid: currentUser.uid,
            keyaccountPlans: keyaccountPlanUids,
          }).then(docRef => {
          return docRef.update({ uid: docRef.id }).then(() => docRef);
        });
      });
  }

  deleteBundle(uid: string): Promise<void> {
    return this.bundleCollection.doc(uid).delete();
  }

  createBundleClone(original: Bundle): Bundle {
    const clonedBundle = {
      ...original,
      uid: null,
    };
    return clonedBundle;
  }
}
