import { Component, Input, OnInit } from '@angular/core';
import { KeyaccountPlan } from "../../../keyaccount-plan.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { KeyaccountPlanService } from "../../keyaccount-plan.service";

@Component({
  selector: 'masterplanner2-swot-table',
  templateUrl: './swot-table.component.html',
  styleUrls: ['./swot-table.component.css']
})
export class SwotTableComponent implements OnInit {

  @Input()
  keyaccountPlan$: Observable<KeyaccountPlan>;
  swotOpportunities$: Observable<any>;
  swotThreats$: Observable<any>;
  swotStrengths$: Observable<any>;
  swotWeaknesses$: Observable<any>;

  constructor(private keyaccountPlanService: KeyaccountPlanService) { }

  ngOnInit() {
    const matrixProposal$ = this.keyaccountPlan$.pipe(map(keyaccountPlan => this.keyaccountPlanService.getMatrixProposal(keyaccountPlan)));

    this.swotOpportunities$ = matrixProposal$.pipe(
      map(proposal => proposal.filter(row => row.swotType === 'OPPORTUNITY')),
    );
    this.swotThreats$ = matrixProposal$.pipe(
      map(proposal => proposal.filter(row => row.swotType === 'THREAT')),
    );
    this.swotStrengths$ = matrixProposal$.pipe(
      map(proposal => proposal.filter(row => row.swotType === 'STRENGTH')),
    );
    this.swotWeaknesses$ = matrixProposal$.pipe(
      map(proposal => proposal.filter(row => row.swotType === 'WEAKNESS')),
    );


  }

}
