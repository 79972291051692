/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main-menu.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../keyaccountplans/locale-file.directive";
import * as i6 from "../../../../../libs/users/src/lib/user.service";
import * as i7 from "./main-menu.component";
import * as i8 from "../../../../../libs/users/src/lib/auth.service";
var styles_MainMenuComponent = [i0.styles];
var RenderType_MainMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainMenuComponent, data: {} });
export { RenderType_MainMenuComponent as RenderType_MainMenuComponent };
export function View_MainMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 27, "div", [["class", "btn-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "btn btn-primary"], ["id", "settings"], ["routerLink", "/settings"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "glyphicon glyphicon-cog"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 4, "a", [["class", "btn btn-primary"], ["id", "btnManual"], ["localeFile", "assets/pdfs/manual_accountmanager.pdf"], ["target", "_blank"]], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i5.LocaleFileDirective, [i1.ElementRef, i6.UserService], { localeFile: [0, "localeFile"] }, null), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "glyphicon glyphicon-new-window"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 12, "a", [["class", "btn btn-primary ellipsis mysettings"], ["id", "btnMysettings"], ["routerLink", "settings/my-settings"]], [[8, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "glyphicon glyphicon-user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\u00A0 ("])), (_l()(), i1.ɵeld(20, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(21, null, ["", ""])), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, [") "])), (_l()(), i1.ɵeld(24, 0, null, null, 3, "button", [["class", "btn btn-primary"], ["id", "btnLogout"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(25, null, [" ", "\u00A0"])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(27, 0, null, null, 0, "i", [["class", "glyphicon glyphicon-log-out"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = "/settings"; _ck(_v, 2, 0, currVal_2); var currVal_4 = "assets/pdfs/manual_accountmanager.pdf"; _ck(_v, 7, 0, currVal_4); var currVal_9 = "settings/my-settings"; _ck(_v, 12, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("SETTINGS")); _ck(_v, 4, 0, currVal_3); var currVal_5 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("MANUAL")); _ck(_v, 8, 0, currVal_5); var currVal_6 = i1.ɵinlineInterpolate(2, "", i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 13).transform(_co.username$)), " (", i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 14).transform(_co.companyname$)), ")"); var currVal_7 = i1.ɵnov(_v, 12).target; var currVal_8 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_6, currVal_7, currVal_8); var currVal_10 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform(_co.username$)); _ck(_v, 17, 0, currVal_10); var currVal_11 = i1.ɵunv(_v, 21, 0, i1.ɵnov(_v, 22).transform(_co.companyname$)); _ck(_v, 21, 0, currVal_11); var currVal_12 = i1.ɵunv(_v, 25, 0, i1.ɵnov(_v, 26).transform("LOGOUT")); _ck(_v, 25, 0, currVal_12); }); }
export function View_MainMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "masterplanner2-main-menu", [], null, null, null, View_MainMenuComponent_0, RenderType_MainMenuComponent)), i1.ɵdid(1, 114688, null, 0, i7.MainMenuComponent, [i6.UserService, i8.AuthService, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MainMenuComponentNgFactory = i1.ɵccf("masterplanner2-main-menu", i7.MainMenuComponent, View_MainMenuComponent_Host_0, {}, {}, []);
export { MainMenuComponentNgFactory as MainMenuComponentNgFactory };
