<table [formGroup]="form"
  id="accountstrategies"
  class="table table-condensed  accountstrategies col-sm-11"
>
  <tr  *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
    <td colspan="12" *ngIf="!row.value.fromOldPlan && !row.value.realised && !row.value.manual">
      <div class="col-sm-12">
        <div class="col-sm-2">
          <label>
            <span [innerHTML]="'STRATEGICISSUE' | translate"></span>
          </label>
        </div>
        <div class="col-sm-10">
          <input formControlName="question"
                 class="form-control"
                 style="font-weight:bold;"
          >
        </div>
        <div class="col-sm-2">
          <label>
            <span [innerHTML]="'STRATEGICOPTION' | translate"></span>
          </label>
        </div>
        <div class="col-sm-10">
          <input formControlName="option"
                 class="form-control"
                 style="font-weight:bold;"
          >
        </div>
        <div class="col-sm-2">
          <label>
            <span [innerHTML]="'MAINACTIVITY' | translate"></span>
          </label>
        </div>
        <div class="col-sm-10">
          <input formControlName="action" class="form-control">
        </div>
      </div>
    </td>
  </tr>
</table>
