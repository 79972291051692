import { AngularFireAuth } from '@angular/fire/auth';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { UserModuleConfig } from './users-module-config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "./users-module-config";
export class AuthService {
    constructor(afAuth, config) {
        this.afAuth = afAuth;
        this.config = config;
        this.authState$ = afAuth.authState;
        this.uid$ = this.authState$.pipe(map((user) => user ? user.uid : null));
    }
    getAuthState() {
        return this.authState$;
    }
    getUid() {
        return this.uid$;
    }
    logout() {
        return this.afAuth.auth.signOut();
    }
    sendLoginEmail(email) {
        const actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be whitelisted in the Firebase Console.
            url: this.config.loginRedirectUrl,
            // This must be true.
            handleCodeInApp: true,
        };
        return this.afAuth.auth.fetchSignInMethodsForEmail(email)
            .then(methods => {
            if (methods.includes('emailLink') || methods.includes('password')) {
                return this.afAuth.auth.sendSignInLinkToEmail(email, actionCodeSettings)
                    .then(noResult => console.log('send email', noResult))
                    .catch(err => console.error('send email error', err));
            }
            else {
                return Promise.reject('User not found or not valid');
            }
        });
    }
    handleLoginWith(url, email) {
        return this.afAuth.auth.signInWithEmailLink(email, url);
    }
    loginWithCustomToken(token) {
        return this.afAuth.auth.signInWithCustomToken(token);
    }
    register(email) {
        const randomPwd = Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);
        // We need to create a secondary firebaseApp as to not login with the user we created.
        const app = this.getFirebaseApp();
        return app.auth().createUserWithEmailAndPassword(email, randomPwd)
            .then((result) => app.auth().signOut().then(() => result));
    }
    getFirebaseApp() {
        if (!this.firebaseApp) {
            this.firebaseApp = firebase.initializeApp(this.config.firebaseCfg, "secondary");
        }
        return this.firebaseApp;
    }
}
AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.AngularFireAuth), i0.inject(i2.UserModuleConfig)); }, token: AuthService, providedIn: "root" });
