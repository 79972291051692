import { AngularFirestore } from '@angular/fire/firestore';
import { of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { UserService } from './user.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "./user.service";
export class UserGroupService {
    constructor(db, userService) {
        this.collectionName = 'customers';
        this.userGroups$ = userService.getCurrentUser().pipe(switchMap(user => {
            if (!user) { // No current user
                return of([]);
            }
            let queryFn = ref => ref.where('uid', '==', user.groupUid);
            if (user.roles.includes('ADMIN')) {
                queryFn = ref => ref;
            }
            return db.collection(this.collectionName, queryFn).valueChanges().pipe(catchError(err => {
                console.error('Error retrieving user groups', err);
                return of([]);
            }));
        }), shareReplay(1));
    }
    getUserGroup(uid) {
        return this.userGroups$.pipe(map(groups => groups.find(group => group.uid === uid)));
    }
    getUserGroups() {
        return this.userGroups$;
    }
}
UserGroupService.ngInjectableDef = i0.defineInjectable({ factory: function UserGroupService_Factory() { return new UserGroupService(i0.inject(i1.AngularFirestore), i0.inject(i2.UserService)); }, token: UserGroupService, providedIn: "root" });
