import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import {DataSource} from '@angular/cdk/table';
import {KeyaccountService, Keyaccount} from "@masterplanner2/users";
import { FormBuilder, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'masterplanner2-keyaccounts-list',
  templateUrl: './keyaccounts-list.component.html',
  styleUrls: ['./keyaccounts-list.component.css']
})
export class KeyaccountsListComponent implements OnInit {
  displayedColumns = ['name', 'group', 'actionsColumn'];

  form: FormGroup;
  keyaccounts$: Observable<Keyaccount[]>;
  filteredKeyaccounts$: Observable<Keyaccount[]>;

  constructor(private keyaccountService: KeyaccountService, formBuilder: FormBuilder) {
    this.form = formBuilder.group({
      'name'      : [],
      'groupName' : [],
    });

  }

  ngOnInit() {
    this.keyaccounts$ = this.keyaccountService.getKeyaccounts();
    this.filteredKeyaccounts$ = combineLatest(this.keyaccounts$, this.form.valueChanges.pipe(startWith(this.form.value))).pipe(
      map(([keyaccountPlans, filtersForm]) => {
        const filterKeys = Object.keys(filtersForm);
        const filterMap = filterKeys
          .map(field =>({key: field, value: filtersForm[field] ? filtersForm[field].toLowerCase(): null}))
          .filter(filterField => !!filterField.value)
        ;
        const filterFn = (keyaccountPlan) => {
          return filterMap.reduce((acc, filter) => {
            return acc && keyaccountPlan[filter.key] && keyaccountPlan[filter.key].toString().toLowerCase().includes(filter.value);
          }, true)
        };
        return filterMap.length ? keyaccountPlans.filter(filterFn) : keyaccountPlans;
      })
    );

  }

  trackByUId(index, item) {
    return item.uid;
  }

  resetFiltersForm(): void {
    this.form.reset();
  }
}


