import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { filter, first, map, tap } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable()
export class AuthTokenGuard implements CanActivate {


  constructor(private authService: AuthService, private router: Router, private userService: UserService) {
  }

  canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const token = routeSnapshot.queryParams.token;
    if(token)
      return this.authService.loginWithCustomToken(token)
        .then((auth) => !!auth)
        .then(() => this.userService.getCurrentUser().pipe(filter(user => !!user), first(), map(user=> !!user)).toPromise());
    else
      return Promise.resolve(true);
  }
}
