import { Component } from '@angular/core';
import { AbstractFormArrayListComponent } from "../../profile/abstract-form-array-list/abstract-form-array-list.component";
import { FormGroup, NG_VALUE_ACCESSOR, FormBuilder } from "@angular/forms";
import { Observable } from "rxjs";
import { KeyaccountPlanService } from '../../keyaccount-plan.service';

@Component({
  selector: 'masterplanner2-brickwall-score',
  templateUrl: './brickwall-score.component.html',
  styleUrls: ['./brickwall-score.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: BrickwallScoreComponent, multi: true}
  ]
})
export class BrickwallScoreComponent extends AbstractFormArrayListComponent {

  constructor(
    private keyaccountPlanService: KeyaccountPlanService, 
    protected formBuilder: FormBuilder) 
  {
    super(formBuilder);
  }

  createItemControls(): FormGroup {
    return this.formBuilder.group({
      uid: [],
      description: [],
      option0: [], 
      option1: [],
      option2: [],
      target: [],
      score: [],
    });
  }

  countBrickwall(brickwallCriteria: any[]) {
    return this.keyaccountPlanService.countBrickwall(brickwallCriteria);
  }

  setScore(rowNr: number, score: number): void {
    if (!this.form.disabled) {
      this.form.get('' + rowNr).get('score').setValue(score);
    }
  }
}
