import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { KeyaccountPlan } from '../keyaccount-plan.model';
import { KeyaccountPlanService } from '../keyaccountplans/keyaccount-plan.service';
import { ToasterService } from 'angular2-toaster';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DeleteKeyaccountplanDialogComponent } from './delete-keyaccountplan-dialog/delete-keyaccountplan-dialog.component';
import { map, startWith, shareReplay, switchMap } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CustomerService } from '../customer.service';
import { UserService } from '@masterplanner2/users';
import { Customer } from '../customer.model';

@Component({
  selector: 'masterplanner2-keyaccount-plans-list',
  templateUrl: './keyaccount-plans-list.component.html',
  styleUrls: ['./keyaccount-plans-list.component.css']
})
export class KeyaccountPlansListComponent implements OnInit {
  displayedColumns = ['customerName', 'account', 'targetYear', 'version', 'accountmanager', 'status', 'actionsColumn'];

  keyaccountPlans$: Observable<KeyaccountPlan[]>;
  filterOptions$: Observable<{ [filterKey: string]: string[]}>;
  filteredKeyaccountPlans$: Observable<KeyaccountPlan[]>;

  form: FormGroup;
  customer$: Observable<Customer | null>;

  constructor(private keyaccountPlanService: KeyaccountPlanService,
              private customerService: CustomerService,
              private userService: UserService,
              private toasterService: ToasterService,
              private dialog: MatDialog,
              private formBuilder: FormBuilder
  )
  {
    this.form = formBuilder.group({
      'customerName'          : [],
      'keyaccountName'        : [],
      'targetYear'            : [],
      'version'               : [],
      'keyaccountManagerName' : [],
      'status'                : [],
    });

    this.customer$ = this.userService.getCurrentUser().pipe(
      switchMap(user => user ? this.customerService.getCustomer(user.groupUid) : of(null)),
      shareReplay(1),
    )
  }

  ngOnInit() {
    this.keyaccountPlans$ = this.keyaccountPlanService.getKeyaccountPlans();

    this.filteredKeyaccountPlans$ = combineLatest(this.keyaccountPlans$, this.form.valueChanges.pipe(startWith(this.form.value))).pipe(
      map(([keyaccountPlans, filtersForm]) => {
        const filterKeys = Object.keys(filtersForm);
        const filterMap = filterKeys
          .map(field =>({key: field, value: filtersForm[field] ? filtersForm[field].toLowerCase(): null}))
          .filter(filterField => !!filterField.value)
        ;
        const filterFn = (keyaccountPlan) => {
          return filterMap.reduce((acc, filter) => {
            return acc && keyaccountPlan[filter.key] && keyaccountPlan[filter.key].toString().toLowerCase().includes(filter.value);
          }, true)
        };
        return filterMap.length ? keyaccountPlans.filter(filterFn) : keyaccountPlans;
      })
    );

    this.filterOptions$ = this.filteredKeyaccountPlans$.pipe(
      map(keyaccountPlans => this.createFilterOptionsFromKeyaccountPlans(keyaccountPlans)),
    )
  }

  createFilterOptionsFromKeyaccountPlans(keyaccountPlans: KeyaccountPlan[], fields = ['status', 'customerName', 'keyaccountName', 'keyaccountManagerName']): {[filterKey:string]: string[]} {
    // Create the inital options {someField: Set<string>, someOtherField: Set<string>}
    const initialFilterOptions = fields.reduce((acc, field) => ({...acc, [field]: new Set()}), {});

    const filterOptionSets = keyaccountPlans.reduce((acc, keyaccountPlan) => {
      // Add all the fields to the filterOptions
      fields.forEach(field => {
        if(keyaccountPlan[field]) {
          acc[field].add(keyaccountPlan[field]);
        }
      });
      return acc;
    }, initialFilterOptions);

    // Change Sets to arrays
    return fields.reduce((acc, field) => ({...acc, [field]: Array.from(filterOptionSets[field])}), {});
  }

  onDelete(uid: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.position = { top: '100px' };

    this.dialog.open(DeleteKeyaccountplanDialogComponent, dialogConfig)
      .afterClosed().subscribe(result => {
        if (result) {
          this.keyaccountPlanService.deleteKeyaccountPlan(uid)
            .then(() => {
              this.toasterService.pop('success', 'Keyaccountplan is succesfully deleted.');
            })
            .catch(error => {
              console.error(error);
            });
        }
      })
  }

  canEdit(keyaccountPlan: KeyaccountPlan): Observable<boolean> {
    return this.keyaccountPlanService.canEdit(keyaccountPlan);
  }

  canAdd(): Observable<boolean> {
    return this.keyaccountPlanService.canAdd();
  }

  trackByUId(index, item) {
    return item.uid;
  }

  resetFiltersForm(): void {
    this.form.reset();
  }
}
