import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConnectableObservable, Subscription } from 'rxjs';
import { map, publishReplay } from 'rxjs/operators';

@Component({
  selector: 'masterplanner2-revenue',
  templateUrl: './revenue.component.html',
  styleUrls: ['./revenue.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: RevenueComponent, multi: true }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RevenueComponent implements OnInit, ControlValueAccessor {

  @Input()
  name: string;

  @Input()
  label: string;

  @Input()
  yearSpan: Number[];

  @Input()
  showIndex = false;

  @Input()
  showSwotType = false;

  @Input()
  swotMode: 'SW' | 'OT' = 'SW';

  @Input()
  highlightCurrentYear = false;

  @Input()
  lastRowHighlight = false;

  @Input()
  popoverTitle: string;

  @Input()
  mode: 'default' | 'profitMargin' | 'estimatedSow' = 'default';

  estimatedSowValues = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  profitMarginValues = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

  form: FormGroup;
  index$: ConnectableObservable<{[key: string]: number}>;

  currentYear: number = new Date().getFullYear();

  onChangeSubscription: Subscription;
  onTouchedSubscription: Subscription;

  constructor(private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({});

    this.index$ = this.form.valueChanges.pipe(
      map(years => Object.keys(years).map(year => {
        const previousYear = <any>year -1;
        return {
          year: year, index: (years[previousYear] ? Math.round(years[year] / years[previousYear] * 100) : 100)}
      })),
      map(valueArr => valueArr.reduce((acc, newValue) => {
        newValue.index > 0 ? acc[newValue.year] = newValue.index : acc[newValue.year] = 0;
        return acc;
      }, {})),
      publishReplay(1),
    ) as ConnectableObservable<{[key: string]: number}>;
    // This is an hot observable, otherwise the initial patched value is not seen by the time we subscribe in the template
    this.index$.connect();
  }


  ngOnInit() {
    this.yearSpan.forEach(year => {
      this.form.addControl('' + year, this.formBuilder.control(0));
    });
    if (this.showSwotType) {
      this.form.addControl('swotType', this.formBuilder.control(null));
    }
  }

  registerOnChange(fn: any): void {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe()
    }
    this.onChangeSubscription = this.form.valueChanges.subscribe(value => {
      console.log('revenue changes', value);
      fn(value)
    });
  }

  registerOnTouched(fn: any): void {
    if (this.onTouchedSubscription) {
      this.onTouchedSubscription.unsubscribe()
    }
  }

  writeValue(items: any): void {
    console.log('write value', items);
    if(!items) {
      items = {};
    }
    this.form.patchValue(items);
  }

  setDisabledState(isDisabled: boolean): void {
    this.form[isDisabled ? 'disable' : 'enable']({emitEvent: false});
  }
}
