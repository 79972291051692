import { AbstractImporterService } from './import/abstract-importer.service';
import { KeyaccountNameMap } from './import/keyaccountNameMap';
import { OrderTypeMap } from './import/orderTypeMap';
import { KeyaccountService, UserService } from '@masterplanner2/users';
import { CustomerService } from './customer.service';
import { combineLatest } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { RevenueImporterTransformService } from './revenue-importer-transform.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../libs/users/src/lib/keyaccount.service";
import * as i2 from "./customer.service";
import * as i3 from "../../../../libs/users/src/lib/user.service";
import * as i4 from "./revenue-importer-transform.service";
export class RevenueImporterService extends AbstractImporterService {
    constructor(keyaccountService, customerService, userService, revenueImporterTransform) {
        super(keyaccountService);
        this.customerService = customerService;
        this.userService = userService;
        this.revenueImporterTransform = revenueImporterTransform;
        this.servicelinesMap$ = userService.getCurrentUser()
            .pipe(switchMap(user => customerService.getCustomer(user.groupUid)), map(customer => customer.servicelines), map(servicelines => {
            return servicelines.reduce((accumulator, item) => {
                accumulator[item.description] = item.uid;
                return accumulator;
            }, {});
        }));
    }
    import(revenueImportData) {
        const expectedHeaderFields = ['Zakenpartner Naam', 'Soort order', 'Omzet', 'jaar'];
        if (revenueImportData.length < 1) {
            return Promise.reject('The document is empty');
        }
        const availableKeys = Object.keys(revenueImportData[0]);
        const missingHeaderFields = expectedHeaderFields
            .filter(propertyName => availableKeys.indexOf(propertyName) === -1);
        if (missingHeaderFields.length > 0) {
            return Promise.reject(`The document does not have a valid header, missing column: ${missingHeaderFields.join(', ')}`);
        }
        const importYearRange = revenueImportData
            .map((line) => parseInt(line.jaar, 10))
            .filter(year => !isNaN(year))
            .reduce((acc, year) => {
            if (!acc) {
                return { start: year, end: year };
            }
            return { start: Math.min(year, acc.start), end: Math.max(year, acc.end) };
        }, null);
        console.log('importYearRange', importYearRange);
        if (!importYearRange) {
            return Promise.reject('Could not get the range of years to be imported');
        }
        // throw an error if the range is too big
        if (importYearRange.end - importYearRange.start >= 20) {
            return Promise.reject(`The range of years to be imported is to large: ${importYearRange.start} - ${importYearRange.end}`);
        }
        return combineLatest([this.keyaccountNameToUid$, this.servicelinesMap$]).pipe(first(), map(([keyaccountsNameToIdMap, servicelinesNameToIdMap]) => this.revenueImporterTransform.transform(revenueImportData, KeyaccountNameMap, OrderTypeMap, keyaccountsNameToIdMap, servicelinesNameToIdMap))).toPromise().then(({ data, warnings }) => {
            console.log('RESULT', data, warnings);
            const savePromises = Object.keys(data).map(keyaccountUid => {
                const servicelineRevenues = data[keyaccountUid];
                return this.keyaccountService.setServicelineRevenues(keyaccountUid, servicelineRevenues, importYearRange);
            });
            return Promise.all(savePromises).then(() => warnings);
        });
    }
}
RevenueImporterService.ngInjectableDef = i0.defineInjectable({ factory: function RevenueImporterService_Factory() { return new RevenueImporterService(i0.inject(i1.KeyaccountService), i0.inject(i2.CustomerService), i0.inject(i3.UserService), i0.inject(i4.RevenueImporterTransformService)); }, token: RevenueImporterService, providedIn: "root" });
