/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scroll-top.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./scroll-top.component";
import * as i4 from "@angular/platform-browser";
var styles_ScrollTopComponent = [i0.styles];
var RenderType_ScrollTopComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScrollTopComponent, data: {} });
export { RenderType_ScrollTopComponent as RenderType_ScrollTopComponent };
export function View_ScrollTopComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "scroll-to-top"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "show-scrollTop": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-primary back-to-top"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollToTop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "glyphicon glyphicon-menu-up"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "scroll-to-top"; var currVal_1 = _ck(_v, 2, 0, _co.windowScrolled); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ScrollTopComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "masterplanner2-scroll-top", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ScrollTopComponent_0, RenderType_ScrollTopComponent)), i1.ɵdid(1, 49152, null, 0, i3.ScrollTopComponent, [i4.DOCUMENT], null, null)], null, null); }
var ScrollTopComponentNgFactory = i1.ɵccf("masterplanner2-scroll-top", i3.ScrollTopComponent, View_ScrollTopComponent_Host_0, {}, {}, []);
export { ScrollTopComponentNgFactory as ScrollTopComponentNgFactory };
