<form name="editForm" id="editForm" class="form-horizontal col-sm-12"  [formGroup]="form" (submit)="doSave()" role="form">
  <div class="panel panel-primary">
    <div class="sw-heading col-sm-12 panel-heading">
      <div class="pull-left"><h2>{{ 'Keyaccount' | uppercase | translate }}</h2></div>
    </div>
    <div class="mp-light">
      <div class="form-group">
        <div class="col-sm-4">
          <label for="cBrandname" class="control-label">{{ 'CBRANDNAME' | translate }}</label>          
          <input type="text"
                 class="form-control"
                 id="cBrandname"
                 placeholder=""
                 formControlName="name"
          >
        </div>
        <div class="col-sm-4">
          <label for="idParent" class="control-label">{{ 'IDPARENT' | translate }}</label>          
          <ng-select [items]="keyaccounts$ | async"
                      id="idParent"
                      placeholder="Select parent"
                      bindValue="uid"
                      bindLabel="name"
                      clearAllText="Clear"
                      formControlName="parent">
          </ng-select>
        </div>        
      </div>
      <div class="form-group">
        <div class="col-sm-4">
          <label for="idCustomer" class="control-label">{{ 'CCUSTOMERNAME' | translate }}</label>          
          <ng-select [items]="groups$ | async"
                      id="idCustomer"
                      placeholder="Select company"
                      bindValue="uid"
                      bindLabel="name"
                      clearAllText="Clear"
                      formControlName="groupUid">
          </ng-select>
        </div>
        <div class="col-sm-4">
          <label for="idStrategic" class="control-label">{{ 'CSTRATEGIC' | translate }}</label><br /> 
          <kendo-switch id="idStrategic" formControlName="strategic"></kendo-switch>
        </div>             
      </div>
      <div class="form-group">
        <div class="col-sm-4">
          <label class="control-label">{{ 'CLOGO' | translate }}</label>
          <masterplanner2-image-cropper *ngIf="form.get('uid').value" [path]="'keyaccount/' + form.get('uid').value + '/images/logo'" ></masterplanner2-image-cropper>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-12">
          <div class="separator col-sm-12 ">
            {{ 'BRANDTEAMMEMBERS' | translate }}
          </div>
          <div class="col-sm-12 teammembertable">
            <table formArrayName="teamMembers" id="teammembers" class="table table-striped table-bordered brandteammembers">
              <tr>
                <th class="col-sm-2"> {{ 'CFIRSTNAME' | translate }}</th>
                <th class="col-sm-1"> {{ 'CPREFIX' | translate }}</th>
                <th class="col-sm-2"> {{ 'CSURNAME' | translate }}</th>
                <th class="col-sm-3"> {{ 'CFUNCTION' | translate }}</th>
                <th class="col-sm-3"> {{ 'CROLE' | translate }}</th>
                <th class="col-sm-1"> {{ 'LEXTERNAL' | translate }}
                  <button
                    type="button"
                    class="btn ng-scope no-btn success"
                    (click)="addTeamMember(true)"
                    title="{{ 'NEW' | translate }}"
                    *ngIf="canEdit$ | async"
                  >
                    <i class="glyphicon glyphicon-plus "></i>
                  </button>
                </th>
              </tr>
              <tr *ngFor="let row of getTeamMemberControls(true); let i = index;" [formGroupName]="i" >
                <td class="form-group">
                  <input type="text" class="form-control" formControlName="firstname">
                </td>
                <td class="form-group">
                  <input type="text" class="form-control" formControlName="prefix">
                </td>
                <td class="form-group">
                  <input type="text" class="form-control" formControlName="surname">
                </td>
                <td class="form-group">
                  <input type="text" class="form-control" formControlName="function">
                </td>
                <td class="form-group">
                  <input type="text" class="form-control" formControlName="role">
                </td>
                <td class="form-group">
                  <label class="btn btn-success" [class.active]="row.get('external').value">
                  <input type="checkbox"
                          class="btn btn-default btn-lexternal btn-toggle"
                          formControlName="external"
                  >
                    <span class="glyphicon glyphicon-ok"></span>
                    <span class="glyphicon glyphicon-remove"></span>
                  </label>
  
                  <button type="button"
                          class="btn ng-scope no-btn danger deletebutton "
                          (click)="removeTeamMember(i, true)"
                          title="{{ 'DELETE' | translate }}"
                          *ngIf="canEdit$ | async"
                  >
                    <i class="glyphicon glyphicon-trash "></i>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="form-group col-sm-12">
          <div class="separator col-sm-12 ">
            {{ 'BRANDNONTEAMMEMBERS' | translate }}
          </div>
          <div class="col-sm-12 teammembertable">
            <table formArrayName="nonTeamMembers" id="brandnonteammembers" class="table table-striped table-bordered brandteammembers">
              <tr>
                <th class="col-sm-2"> {{ 'CFIRSTNAME' | translate }}</th>
                <th class="col-sm-1"> {{ 'CPREFIX' | translate }}</th>
                <th class="col-sm-2"> {{ 'CSURNAME' | translate }}</th>
                <th class="col-sm-3"> {{ 'CFUNCTION' | translate }}</th>
                <th class="col-sm-3"> {{ 'CROLE' | translate }}</th>
                <th class="col-sm-1"> {{ 'LEXTERNAL' | translate }}
                  <button
                    type="button"
                    class="btn ng-scope no-btn success"
                    (click)="addTeamMember(false)"
                    title="{{ 'NEW' | translate }}"
                    *ngIf="canEdit$ | async"
                  >
                    <i class="glyphicon glyphicon-plus "></i>
                  </button>
                </th>
              </tr>
              <tr *ngFor="let row of getTeamMemberControls(false); let i = index;" [formGroupName]="i" >
                <td class="form-group">
                  <input type="text" class="form-control" formControlName="firstname">
                </td>
                <td class="form-group">
                  <input type="text" class="form-control" formControlName="prefix">
                </td>
                <td class="form-group">
                  <input type="text" class="form-control" formControlName="surname">
                </td>
                <td class="form-group">
                  <input type="text" class="form-control" formControlName="function">
                </td>
                <td class="form-group">
                  <input type="text" class="form-control" formControlName="role">
                </td>
                <td class="form-group">
                  <label class="btn btn-success" [class.active]="row.get('external').value">
                    <input type="checkbox"
                            class="btn btn-default btn-lexternal btn-toggle"
                            formControlName="external"
                    >
                    <span class="glyphicon glyphicon-ok"></span>
                    <span class="glyphicon glyphicon-remove"></span>
                  </label>
  
                  <button type="button"
                          class="btn ng-scope no-btn danger deletebutton "
                          (click)="removeTeamMember(i, false)"
                          title="{{ 'DELETE' | translate }}"
                          *ngIf="canEdit$ | async"
                  >
                    <i class="glyphicon glyphicon-trash "></i>
                  </button>
                </td>
              </tr>
            </table>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button type="submit" [disabled]="!form.valid" class="btn btn-primary" *ngIf="(canEdit$ | async) || (isAnyAdmin$ | async)">Save</button>
</form>
