import { Component, OnInit } from '@angular/core';
import { AbstractFormArrayListComponent } from "../../keyaccountplans/profile/abstract-form-array-list/abstract-form-array-list.component";
import { FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'masterplanner2-brickwall',
  templateUrl: './brickwall.component.html',
  styleUrls: ['./brickwall.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: BrickwallComponent, multi: true}
  ]
})
export class BrickwallComponent extends AbstractFormArrayListComponent {
  createItemControls(): FormGroup {
    return this.formBuilder.group({
      uid: [],
      description: [],
      target: [],
      option0: [],
      option1: [],
      option2: [],
    });
  }

}
