import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Directive({
  selector: '[formControlName]'
})
export class PrintInputDirective implements OnInit {

  inputElement: HTMLInputElement;
  valueElement: HTMLElement;

 constructor(private elementRef: ElementRef, private control : NgControl, private activatedRoute: ActivatedRoute, private router: Router) {
    this.inputElement = elementRef.nativeElement;
  }

  ngOnInit() {
    if(!(this.activatedRoute && this.activatedRoute.snapshot.data && this.activatedRoute.snapshot.data.print)) {
      return;
    }

    const tagName = this.inputElement.tagName;
    const allowedTags = ['INPUT', 'SELECT', 'TEXTAREA'];
    if(!allowedTags.includes(tagName) || this.inputElement.type === 'hidden') {
      return;
    }

    this.valueElement = this.inputElement.ownerDocument.createElement('span');
    this.valueElement.innerText = this.inputElement.value;
    this.valueElement.className = 'print-view form-control';
    this.inputElement.classList.add('print-input');
    this.inputElement.insertAdjacentElement('afterend', this.valueElement);
    this.control.valueChanges.subscribe(value => {
      this.valueElement.innerText = value;
    })
  }

}
