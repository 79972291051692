import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';

@Component({
  selector: 'masterplanner2-revenue-flat',
  templateUrl: './revenue-flat.component.html',
  styleUrls: ['./revenue-flat.component.css']
})
export class RevenueFlatComponent implements OnInit {

  @Input()
  revenueMap: {[year: string]: number};

  @Input()
  name: string;

  @Input()
  showIndex = false;
  @Input()
  showHeader = false;

  @Input()
  mode: 'default' | 'profitMargin' | 'estimatedSow' = 'default';

  @Input()
  yearSpan: number[];

  index: {[key: string]: number};

  currentYear: number = new Date().getFullYear();

  constructor() { }

  ngOnInit() {
    if(!this.revenueMap) {
      this.revenueMap = {};
    }
    console.log(this.revenueMap);
    this.index = this.calculateIndex(this.yearSpan, this.revenueMap);
  }


  calculateIndex(yearSpan: number[], revenueMap: {[year: string]: number}) {
    const indexEntries = (revenueMap ? Object.keys(revenueMap): []).map(year => {
      const previousYear = <any>year -1;
      return {
        year: year,
        index: (revenueMap[previousYear] ? Math.round(revenueMap[year] / revenueMap[previousYear] * 100) : 100)}
    });
    const indexMap = indexEntries.reduce((acc, newValue) => {
      newValue.index > 0 ? acc[newValue.year] = newValue.index : acc[newValue.year] = 0;
      return acc;
    }, {});
    return indexMap;
  }

}
