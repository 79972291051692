import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from "rxjs/internal/Observable";
import { of } from "rxjs/internal/observable/of";
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import * as pushid from "pushid";
import {filter, first, map, pluck, switchMap, tap} from "rxjs/operators";
import {Subscription} from "rxjs/index";
import {ActivatedRoute, Router} from "@angular/router";
import {CustomerService} from "../customer.service";
import {ToasterService} from "angular2-toaster";
import { KeyaccountService } from "@masterplanner2/users";

@Component({
  selector: 'masterplanner2-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerEditComponent implements OnInit, OnDestroy {
  keyaccounts$: Observable<any> = of([]);
  panelCollapseToggles = {
    'servicelines': new BehaviorSubject(true),
    'industries': new BehaviorSubject(true),
    'companytypes': new BehaviorSubject(true),
    'buyingbehaviors': new BehaviorSubject(true),
    'buyingcriteria': new BehaviorSubject(true),
    'brickwallCriteria': new BehaviorSubject(true),
  };
  domainCounts: {
    [key: string]: Observable<number>,
  };

  isEditing$ = new BehaviorSubject(false);
  private editCustomerSubscription: Subscription;


  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private toasterService: ToasterService,
    private router: Router,
    private keyaccountService: KeyaccountService,
  ) {
    this.form = formBuilder.group({
      'uid': [],
      'name': [, Validators.required],
      'contact': [, Validators.required],
      'address': [, Validators.required],
      'zipcode': [, Validators.required],
      'city': [, Validators.required],
      'country': [, Validators.required],
      'phonenumber': [],
      'logo': [],
      'featureToggles': [],
      'strategicFeatureToggles': [],
      'brickwallCriteria': [],
      'servicelines': formBuilder.array([]),
      'industries': formBuilder.array([]),
      'companytypes': formBuilder.array([]),
      'buyingbehaviors': formBuilder.array([]),
      'buyingcriteria': formBuilder.array([]),
    });

    this.domainCounts = {
      'servicelines': this.form.get('servicelines').valueChanges.pipe(map(domainArray => domainArray.length)),
      'industries': this.form.get('industries').valueChanges.pipe(map(domainArray => domainArray.length)),
      'companytypes': this.form.get('companytypes').valueChanges.pipe(map(domainArray => domainArray.length)),
      'buyingbehaviors': this.form.get('buyingbehaviors').valueChanges.pipe(map(domainArray => domainArray.length)),
      'buyingcriteria': this.form.get('buyingcriteria').valueChanges.pipe(map(domainArray => domainArray.length)),
      'brickwallCriteria': this.form.get('brickwallCriteria').valueChanges.pipe(map(domainArray => domainArray.length)),
    };

    const customer$ = activatedRoute.params.pipe(
      pluck('id'),
      filter(id=> !!id),
      tap(id => this.isEditing$.next(true)),
      switchMap(customerId => this.customerService.getCustomer(<string>customerId).pipe(first()))
    );

    this.editCustomerSubscription = customer$.pipe(
      tap(customer =>  {
        this.form.reset();
        const addControlsForDomain = (domainName: string) => {
          const length = customer[domainName].length;
          for(let i=0;i<length;i++) {
            this.getDomainFormArray(domainName).push(this.createDomainControls());
          }
        };
        addControlsForDomain('servicelines');
        addControlsForDomain('industries');
        addControlsForDomain('companytypes');
        addControlsForDomain('buyingbehaviors');
        addControlsForDomain('buyingcriteria');

        if(!customer.brickwallCriteria) {
          customer.brickwallCriteria = [];
        }
        this.form.patchValue(customer)
      })
    ).subscribe();

    this.keyaccounts$ = customer$.pipe(
      switchMap(customer => {
        return this.keyaccountService.getKeyaccountForGroupUid(customer.uid);
      })
    )

  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if(this.editCustomerSubscription) {
      this.editCustomerSubscription.unsubscribe();
    }
  }

  hasBrickwallEnabled(): boolean {
    const strategicControl = this.form.get('strategicFeatureToggles');
    const normalControl = this.form.get('featureToggles');
    return (strategicControl.value && strategicControl.value.brickwall) || (normalControl.value && normalControl.value.brickwall);
  }

  togglePanel(panelName: string): void {
    const subject = <BehaviorSubject<boolean>>this.panelCollapseToggles[panelName];
    subject.next(!subject.value);
  }

  isPanelCollapsed(panelName: string): Observable<boolean> {
    return <BehaviorSubject<boolean>>this.panelCollapseToggles[panelName];

  }

  addDomainValue(panelName: string) {
    const formArray = this.getDomainFormArray(panelName);
    const formGroup = this.createDomainControls();
    formGroup.patchValue({
      order: formArray.length + 1,
        uid: pushid(),
    });
    formArray.push(formGroup);
  }

  createDomainControls(): FormGroup {
    return this.formBuilder.group({
      'uid':[,Validators.required],
      'description':[,Validators.required],
      'order':[,Validators.required],
    })
  }

  removeDomainvalue(panelName: string, index) {
    const formArray =  this.form.get(panelName) as FormArray;
    formArray.removeAt(index);
  }

  getDomainFormArray(panelName: string): FormArray {
    return this.form.get(panelName) as FormArray;
  }

  getDomainControls(panelName: string): AbstractControl[] {
    const formArray =  this.form.get(panelName) as FormArray;
    return formArray.controls;
  }

  doSave() {
    const uid = this.form.get('uid').value;
    const customer = this.form.value;
    console.log(uid, customer);
    return (uid ? this.customerService.editCustomer(uid, customer) : this.customerService.createCustomer(customer).then(()=>{}))
      .then(() => {
        this.toasterService.pop('success', uid ? 'Edited customer successfull' : 'Created customer successfull');
        this.router.navigateByUrl('/settings/customers');
      })
      .catch(err => {
        console.error(err);
        this.toasterService.pop('error', 'Error creating customer: ' + err.message);
      })
      ;
  }
}
