import { StrategicActionsComponent } from "../../strategy/strategic-actions/strategic-actions.component";
import { FormGroup } from "@angular/forms";
import { sliderOptions } from "../../activity/activity-planning/slider-optionts";
export class ActivityLessonsComponent extends StrategicActionsComponent {
    constructor() {
        super(...arguments);
        this.Object = Object;
        this.sliderOptions = sliderOptions;
    }
    createItemControls() {
        const formGroup = super.createItemControls();
        // Disable controls that are read-only
        formGroup.get('startDate').disable();
        formGroup.get('endDate').disable();
        formGroup.get('action').disable();
        formGroup.get('responsible').disable();
        formGroup.get('result').disable();
        return formGroup;
    }
    removeItem(i) {
        if (!this.form.disabled) {
            const activityControl = this.getItemFormArray().at(i).value;
            this.getItemFormArray().at(i).patchValue(Object.assign({}, activityControl, { deleted: true }));
        }
        else {
            console.log('Cannot remove an item of a disabled control');
        }
    }
}
