<div class="btn-group">
  <a routerLink="/settings" id="settings" class="btn btn-primary">
    <i class="glyphicon glyphicon-cog"></i> {{ 'SETTINGS' | translate }}
  </a>
  <a id="btnManual" target="_blank"
     class="btn btn-primary" localeFile="assets/pdfs/manual_accountmanager.pdf">
    {{ 'MANUAL' | translate }}
    <i class="glyphicon glyphicon-new-window"></i>
  </a>
  <a id="btnMysettings" 
     class="btn btn-primary ellipsis mysettings" 
     routerLink="settings/my-settings"
     title="{{ username$ | async }} ({{ companyname$ | async }})"
  >
    <i class="glyphicon glyphicon-user"></i>
    <span>{{ username$ | async }}</span>&nbsp;
    (<span>{{ companyname$ | async }}</span>)
  </a>
  <button id="btnLogout" type="button" (click)="doLogout()" class="btn btn-primary">
    {{ 'LOGOUT' | translate }}&nbsp;<i class="glyphicon glyphicon-log-out"></i>
  </button>
</div>

