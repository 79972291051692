<div class="">
  <div class="panel panel-primary">
    <div class="sw-heading col-sm-12 panel-heading">
      <h2 >{{ 'settings' | translate }}</h2>
    </div>
    <div  >
      <div class="pull-left col-sm-2 text-center settings-item-outer">
        <div class="settings-item">
          <div><a routerLink="/settings/my-settings"><i class="glyphicon glyphicon-cog"></i></a></div>
          <div><a routerLink="/settings/my-settings">{{ 'MY_SETTINGS' | translate }}</a></div>
        </div>
      </div>
    </div>
    <div *ngIf="isAnyAdmin() | async">
      <div class="pull-left col-sm-2 text-center settings-item-outer">
        <div class="settings-item">
          <div><a routerLink="/settings/users"><i class="glyphicon glyphicon-user"></i></a></div>
          <div><a routerLink="/settings/users">{{ 'USERS' | translate }}</a></div>
        </div>
      </div>
    </div>
    <div *ngIf="(isAnyAdmin() | async) || (isAnyManager() | async)">
      <div class="pull-left col-sm-2 text-center settings-item-outer">
        <div class="settings-item">
          <div><a routerLink="/settings/keyaccounts"><i class="glyphicon glyphicon-tag"></i></a></div>
          <div><a routerLink="/settings/keyaccounts">{{ 'ACCOUNTS' | translate }}</a></div>
        </div>
      </div>
    </div>
    <div *ngIf="isAdmin() | async">
      <div class="pull-left col-sm-2 text-center settings-item-outer">
        <div class="settings-item">
          <div><a routerLink="/settings/customers"><i class="glyphicon glyphicon-briefcase"></i></a></div>
          <div><a routerLink="/settings/customers">{{ 'CUSTOMERS' | translate }}</a></div>
        </div>
      </div>
    </div>
    <div *ngIf="isAdmin() | async">
      <div class="pull-left col-sm-2 text-center settings-item-outer">
        <div class="settings-item">
          <div><a ng-href="#/usersexport" href="#/usersexport"><i class="glyphicon glyphicon-export"></i></a></div>
          <div><a ng-href="#/usersexport"  href="#/usersexport">{{ 'EXPORTUSERS' | translate }}</a></div>
        </div>
      </div>
    </div>
    <div *ngIf="isAnyAdmin() | async">
      <div class="pull-left col-sm-2 text-center settings-item-outer">
        <div class="settings-item">
          <div><a routerLink="/settings/import"><i class="glyphicon glyphicon-import"></i></a></div>
          <div><a routerLink="/settings/import">{{ 'IMPORT' | translate }}</a></div>
        </div>
      </div>
    </div>
    <div *ngIf="isAdmin() | async">
      <div class="pull-left col-sm-2 text-center settings-item-outer">
        <div class="settings-item">
          <div><a routerLink="/settings/translation"><i class="glyphicon glyphicon-transfer"></i></a></div>
          <div><a routerLink="/settings/translation">{{ 'TRANSLATIONS' | translate }}</a></div>
        </div>
      </div>
    </div>
    <div  *ngIf="!(isGroupManager() | async)">
      <div class="pull-left col-sm-2 text-center settings-item-outer">
        <div class="settings-item">
          <div><a routerLink="/settings/bundles"><i class="glyphicon glyphicon-compressed"></i></a></div>
          <div><a routerLink="/settings/bundles">{{ 'BUNDLES' | translate }}</a></div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
