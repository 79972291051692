import { OnInit } from '@angular/core';
import { AbstractFormArrayListComponent } from '../../profile/abstract-form-array-list/abstract-form-array-list.component';
import { FormGroup, Validators } from '@angular/forms';
import { delay, tap } from 'rxjs/operators';
export class SwRatingComponent extends AbstractFormArrayListComponent {
    constructor() {
        super(...arguments);
        this.disabled = false;
        this.distinctionOptions = [
            { value: 0, label: 'SW_NONE' },
            { value: 1, label: 'SW_LITTLE' },
            { value: 3, label: 'SW_AVERAGE' },
            { value: 5, label: 'SW_HIGH' },
        ];
        this.relevanceOptions = [
            { value: 0, label: 'SW_NONE' },
            { value: 1, label: 'SW_LITTLE' },
            { value: 3, label: 'SW_AVERAGE' },
            { value: 5, label: 'SW_HIGH' },
        ];
    }
    ngOnInit() {
        this.form.valueChanges.pipe(delay(0), tap(values => {
            let total = 0;
            let toMatrix = 0;
            for (let i = 0; i < values.length; i++) {
                const c = this.form.get('' + i);
                if (!c) {
                    continue;
                }
                const v = c.getRawValue();
                const score = v.relevance * v.distinction;
                if (score !== undefined && score !== v.score) {
                    c.get('score').setValue(score);
                    if (score && v.inMatrix === null) {
                        c.get('inMatrix').setValue(true);
                    }
                    else if (!score) {
                        c.get('inMatrix').setValue(null);
                    }
                }
                total += score;
                toMatrix += c.get('inMatrix').value ? 1 : 0;
            }
            this.totalScore = total;
            this.toMatrix = toMatrix;
        })).subscribe();
    }
    createItemControls() {
        return this.formBuilder.group({
            'uid': [, Validators.required],
            'name': [, Validators.required],
            'score': [{ value: 0, disabled: true }, Validators.required],
            'inMatrix': [],
            'distinction': [, Validators.required],
            'relevance': [, Validators.required],
        });
    }
    inMatrix(i) {
        return this.form.getRawValue()[i].inMatrix;
    }
    hasScore(i) {
        return this.form.getRawValue()[i].score;
    }
    toggleInMatrix(i) {
        if (this.form.disabled) {
            console.log('Cannot toggle value on disabled control');
            return;
        }
        const control = this.form.get('' + i).get('inMatrix');
        control.setValue(!control.value);
        this.onChangeCallback(this.form.value);
    }
}
