import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomainValue } from "../../../customer.model";
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BehaviorSubject, combineLatest, ConnectableObservable, Observable, Subscription } from "rxjs";
import { distinctUntilChanged, map, publishReplay, shareReplay, tap } from "rxjs/operators";
import { KeyaccountPlanService } from "../../keyaccount-plan.service";
import { BuyingPower, BuyingPowerScore } from "../../../keyaccount-plan.model";

@Component({
  selector   : 'masterplanner2-buyingpower',
  templateUrl: './buyingpower.component.html',
  styleUrls  : ['./buyingpower.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: BuyingpowerComponent, multi: true}
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuyingpowerComponent implements OnInit, OnDestroy, ControlValueAccessor {
  Math = Math;

  @Input()
  buyingcriteria: DomainValue[];

  @Input()
  customerName: string;

  form: FormGroup;

  sum$: ConnectableObservable<{ companyTotalWeightedScore: number, companyTotalRestScore: number, competitor1Score: number, competitor2Score: number }>;
  scores$: ConnectableObservable<{ [buyingcriterionUid: string]: BuyingPowerScore}>;

  /** Includes the extra manual buying behavior */
  public allBuyingcriteria$: BehaviorSubject<DomainValue[]> = new BehaviorSubject([]);

  private onChangeSubscription: Subscription;
  private onTouchedSubscription: Subscription;

  constructor(private formBuilder: FormBuilder, private keyaccountPlanService: KeyaccountPlanService) {
    this.form = formBuilder.group({
      competitor1:            [],
      competitor2:            [],
      swotType   :            [],
      swotReason :            [],
    });

    // Used for the progressbars for each buyingpower.
    this.scores$ = combineLatest(this.form.valueChanges, this.allBuyingcriteria$).pipe(
      map(([buyingpowers, allBuyingcriteria]) => {
        // Make sure every customer buyingcriterion + the extra has a buyingpowers object.
        const inflatedBuyingcriteria: BuyingPower[] = allBuyingcriteria.map(buyingcriteria => {
          return ({...buyingcriteria, ...buyingpowers[buyingcriteria.uid]});
        });

        return this.keyaccountPlanService.calculateBuyingpowerScores(inflatedBuyingcriteria)
          .filter(buyingCriteria => buyingCriteria.uid !== 'extra' || buyingCriteria.name)
          // Make the uid the index of a map with the scores
          .reduce((acc, criteriumScore) => { acc[criteriumScore.uid] = criteriumScore; return acc;}, {});
      }),
      publishReplay(1),
    ) as ConnectableObservable<{ [buyingcriterionUid: string]: BuyingPowerScore}>;

    this.sum$ = this.scores$.pipe(
      map((buyingcriteria) => this.keyaccountPlanService.calculateBuyingpowerTotals(buyingcriteria)),
      tap(buyingcriteriaSums => console.log('buyingcriteria sums', buyingcriteriaSums)),
      publishReplay(1),
    ) as ConnectableObservable<{ companyTotalWeightedScore: number, companyTotalRestScore: number, competitor1Score: number, competitor2Score: number, companyIndex: number, competitor1Index: number, competitor2Index: number }>;
  }


  ngOnInit() {
    const formGroup = this.form;
    this.allBuyingcriteria$.next([...this.buyingcriteria, {uid: 'extra', description:''}]);
    this.allBuyingcriteria$.getValue().forEach(criterion => {
      formGroup.addControl(criterion.uid, this.formBuilder.group(this.createBuyingpowerControls(criterion)));
    });
    // This is an hot observable, otherwise the initial patched value is not seen by the time we subscribe in the template
    this.sum$.connect();
    this.scores$.connect();
  }

  ngOnDestroy() {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe();
    }
    if (this.onTouchedSubscription) {
      this.onTouchedSubscription.unsubscribe();
    }
  }

  createBuyingpowerControls(buyingcriterion: DomainValue = null): { [key: string]: FormControl } {
    const controlMap = {};
    controlMap['name'] = this.formBuilder.control({value: buyingcriterion.description, disabled: buyingcriterion.uid !== 'extra'}, null);
    controlMap['importance'] = this.formBuilder.control(null, null);
    controlMap['score'] = this.formBuilder.control('', null);
    controlMap['competitor1'] = this.formBuilder.control('', null);
    controlMap['competitor2'] = this.formBuilder.control('', null);
    // controlMap['positionCompetition1'] = this.formBuilder.control('', null);
    // controlMap['positionCompetition2'] = this.formBuilder.control('', null);
    return controlMap;
  }

  registerOnChange(fn: any): void {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe()
    }
    this.onChangeSubscription = this.form.valueChanges.subscribe(value => {
      const rawValue = this.form.getRawValue();
      if(rawValue && rawValue.extra && !rawValue.extra.name) {
        delete rawValue.extra;
      }
      fn(rawValue)
    });
  }

  registerOnTouched(fn: any): void {
    if (this.onTouchedSubscription) {
      this.onTouchedSubscription.unsubscribe()
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.form[isDisabled ? 'disable' : 'enable']({emitEvent: false});
  }

  writeValue(items: any): void {
    if (!items) {
      items = {};
    }

    if(!items['extra']) {
      items['extra'] = {};
    }
    this.form.patchValue(items);
  }

}
