/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./revenue-print.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./revenue-print.component";
var styles_RevenuePrintComponent = [i0.styles];
var RenderType_RevenuePrintComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RevenuePrintComponent, data: {} });
export { RenderType_RevenuePrintComponent as RenderType_RevenuePrintComponent };
function View_RevenuePrintComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value[_v.parent.context.$implicit]; _ck(_v, 1, 0, currVal_0); }); }
function View_RevenuePrintComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(("SOW_" + _co.value[_v.parent.context.$implicit]))); _ck(_v, 1, 0, currVal_0); }); }
function View_RevenuePrintComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(("MAR_" + _co.value[_v.parent.context.$implicit]))); _ck(_v, 1, 0, currVal_0); }); }
function View_RevenuePrintComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "td", [["class", "text-center  border-top"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "target-cell": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenuePrintComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenuePrintComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenuePrintComponent_4)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "text-center  border-top"; var currVal_1 = _ck(_v, 2, 0, ((_v.context.$implicit === _co.currentYear) && _co.highlightCurrentYear)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_co.mode === "default"); _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.mode === "estimatedSow"); _ck(_v, 6, 0, currVal_3); var currVal_4 = (_co.mode === "profitMargin"); _ck(_v, 8, 0, currVal_4); }, null); }
function View_RevenuePrintComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "td", [["class", "text-center border-top"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "target-cell": 0, "target-cell-last": 1 }), (_l()(), i1.ɵted(3, null, [" ", " % "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "text-center border-top"; var currVal_1 = _ck(_v, 2, 0, ((_v.context.$implicit === _co.currentYear) && _co.highlightCurrentYear), ((_co.lastRowHighlight && (_co.currentYear === _v.context.$implicit)) && _co.highlightCurrentYear)); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.parent.context.ngIf[_v.context.$implicit]; _ck(_v, 3, 0, currVal_2); }); }
function View_RevenuePrintComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_RevenuePrintComponent_7)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.yearSpan$)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_RevenuePrintComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "th", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "row-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_RevenuePrintComponent_6)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.index$)); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(("INDEX_LASTYEAR_" + _co.name.toUpperCase()))); _ck(_v, 3, 0, currVal_0); }); }
export function View_RevenuePrintComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenuePrintComponent_1)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenuePrintComponent_5)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.yearSpan; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.showIndex; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.name.toUpperCase())); _ck(_v, 2, 0, currVal_0); }); }
export function View_RevenuePrintComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["masterplanner2-revenue-print", ""]], null, null, null, View_RevenuePrintComponent_0, RenderType_RevenuePrintComponent)), i1.ɵdid(1, 638976, null, 0, i4.RevenuePrintComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RevenuePrintComponentNgFactory = i1.ɵccf("[masterplanner2-revenue-print]", i4.RevenuePrintComponent, View_RevenuePrintComponent_Host_0, { name: "name", value: "value", yearSpan: "yearSpan", showIndex: "showIndex", highlightCurrentYear: "highlightCurrentYear", lastRowHighlight: "lastRowHighlight", mode: "mode" }, {}, []);
export { RevenuePrintComponentNgFactory as RevenuePrintComponentNgFactory };
