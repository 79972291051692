import { AngularFirestore } from '@angular/fire/firestore';
import { combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UserService } from '@masterplanner2/users';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "../../../../../libs/users/src/lib/user.service";
export class TranslationService {
    constructor(db, userService) {
        this.db = db;
        this.translationCollection = db.collection('translations');
        this.localesCollection = db.collection('locales');
        const user$ = userService.getCurrentUser();
        this.locales$ = combineLatest(this.localesCollection.valueChanges(), user$).pipe(map(([locales, user]) => {
            const isAdmin = user && user.roles.includes('ADMIN');
            // Admin users can see all the locales.
            // Return main locales, locales without a groupUid or locales that match the groupUid of the user
            return locales.filter(locale => isAdmin || locale.isMainLocale || !locale.groupUid || (user && user.groupUid === locale.groupUid));
        }), shareReplay(1));
    }
    getLocales() {
        return this.locales$;
    }
    getMainLocales() {
        return this.locales$.pipe(map(locales => locales.filter(locale => locale.code.split('_').length === 1)));
    }
    createLocale(code, name) {
        return this.localesCollection.doc(code).set({ code, name });
    }
    getTranslations(language) {
        return this.translationCollection.doc(language).valueChanges();
    }
    editTranslation(locale, translations) {
        return this.translationCollection.doc(locale).set(Object.assign({}, translations), { merge: true });
    }
}
TranslationService.ngInjectableDef = i0.defineInjectable({ factory: function TranslationService_Factory() { return new TranslationService(i0.inject(i1.AngularFirestore), i0.inject(i2.UserService)); }, token: TranslationService, providedIn: "root" });
