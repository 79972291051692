<div class="col-sm-12" [formGroup]="form">
  <div class="row">
    <div class="col-sm-12">
      <b>
        <span class="pull-left" [innerHTML]="'STAKEHOLDER_TABLE_HEADER' | translate" style="margin-top: 7px; margin-right: 7px;"    ></span>
      </b>
      <button type="button"
              class="btn ng-scope no-btn success pull-right"
              (click)="addItem()"
              title="{{ 'NEW' | translate }}"
      >
        <i class="glyphicon glyphicon-plus "></i>
      </button>
    </div>
  </div>
  <div class="row row-no-gutters">
    <div class="col-sm-5 header column left">
      <label for="firstname" class="control-label">
        {{ 'STAKEHOLDERS_NAME' | translate }}
      </label>
    </div>
    <div class="col-sm-2 header column">
      <label for="function" class="control-label">
        {{ 'STAKEHOLDERS_FUNCTION' | translate }}
      </label>
    </div>
    <div class="col-sm-2 header column">
      <label for="role" class="control-label">
        {{ 'STAKEHOLDERS_ROLE' | translate }}
      </label>
    </div>
    <div class="col-sm-3 header column right">
      <label for="externalorganisation" class="control-label">
        {{ 'STAKEHOLDERS_EXTERNALORGANISATION' | translate }}
      </label>
    </div>
  </div>
  <div class="row row-no-gutters" *ngFor="let row of getItemControls(); let i = index;" [formGroupName]="i">
    <div class="col-sm-2 column left">
      <input
        formControlName="firstname"
        name="firstname"
        type="text"
        class="form-control"
      >    
    </div>
    <div class="col-sm-1 column">
      <input
        formControlName="prefix"
        name="prefix"
        type="text"
        class="form-control"
      >    
    </div>
    <div class="col-sm-2 column">
      <input
        formControlName="surname"
        name="surname"
        type="text"
        class="form-control"
      >    
    </div>
    <div class="col-sm-2 column">
      <input
        formControlName="function"
        name="function"
        type="text"
        class="form-control"
      >    
    </div>
    <div class="col-sm-2 column">
      <input
        formControlName="role"
        name="role"
        type="text"
        class="form-control"
      >    
    </div>
    <div class="col-sm-3 column right">
      <div class="row">
        <div class="col-sm-10">
          <input formControlName="externalorganisation"
                 name="externalorganisation"
                 type="text"
                 class="form-control"
          >
        </div>
        <div class="col-sm-2" style="padding-top: 6px;">
          <button type="button"
            class="btn no-btn danger deletebutton "
            (click)="removeItem(i)"
            title="{{ 'DELETE' | translate }}"
          >
            <i class="glyphicon glyphicon-trash "></i>
          </button>  
        </div>
      </div>
    </div>
  </div>
</div>
