import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference, QueryFn } from '@angular/fire/firestore';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { CustomerService } from '../../../../apps/masterplanner-client/src/app/customer.service';
import { UserService } from './user.service';
import { flatten } from "@angular/compiler";
import { YearlyValues } from '../../../../apps/masterplanner-client/src/app/keyaccount-plan.model';


export class Keyaccount {
  uid?: string;
  name: string;
  logo: string;
  groupUid: string;
  teamMembers: TeamMember[];
  strategic: boolean;
  orderIntake: {[year:string]: number}; // YearlyValues;
  orderIntakeImportedAt?: Date;
  revenueImportedAt?: Date;
}

export class TeamMember {
  uid: string;
  firstname: string;
  prefix: string;
  surname: string;
  function: string;
  role: string;
  isPartOfTeam: boolean;
  external: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class KeyaccountService {
  private readonly collectionName = 'keyaccounts';

  private keyaccounts$: Observable<Keyaccount[]>;

  private keyaccountCollection: AngularFirestoreCollection<Keyaccount>;

  constructor(private db: AngularFirestore, private userService: UserService, private customerService: CustomerService) {
    this.keyaccountCollection = db.collection(this.collectionName);

    this.keyaccounts$ = userService.getCurrentUser().pipe(
      switchMap(user => {
        if(!user) {
          return of([]);
        }

        if (user.roles.includes('ADMIN')) {
          return this.getKeyaccountBy();
        } else if (user.roles.includes('GROUPADMIN')) {
          return this.getKeyaccountBy('groupUid', user.groupUid);
        } else if (user.roles.includes('ACCOUNTMANAGER') && user.roles.includes('ACCOUNTVIEWER')) {
          const keyaccounts = [...user.keyaccountsView, ...user.keyaccounts];
          return combineLatest(keyaccounts.map(keyaccount => this.getKeyaccountBy('uid', keyaccount))).pipe(map(flatten));
        } else if (user.roles.includes('ACCOUNTMANAGER')) {
          const keyaccounts = user.keyaccounts;
          return combineLatest(keyaccounts.map(keyaccount => this.getKeyaccountBy('uid', keyaccount))).pipe(map(flatten));
        } else if (user.roles.includes('ACCOUNTVIEWER')) {
          const keyaccounts = user.keyaccountsView;
          return combineLatest(keyaccounts.map(keyaccount => this.getKeyaccountBy('uid', keyaccount))).pipe(map(flatten));
        } else {
          // No role?
          console.error('No matching role found to filter keyaccountPlans', user);
          return of([]);
        }
      }),
      shareReplay(1),
      catchError(err => {
        console.error('Error retrieving keyaccounts', err);
        return of([]);
      }),
    );
  }


  /**
   *
   * @param field
   * @param value
   */
  private getKeyaccountBy(field?: string, value?: string) {
    const queryFn: QueryFn = field ? ref => ref.where(field, '==', value) : ref => ref;
    return this.db.collection<Keyaccount>(this.collectionName, queryFn).valueChanges().pipe(
      catchError(err => {
        console.error('Error retrieving keyaccountPlans:', err);
        return of([]);
      }),
    );
  }

  private getServiceLineUids() {
    return this.getCustomerForCurrentUser()
      .pipe(
        map(customer => {
          if(!customer || ! customer.servicelines) {
            return [];
          }
          return customer.servicelines.map(s => s.uid);
        })
      )
  }

  private getCustomerForCurrentUser() {
    return this.userService.getCurrentUser()
      .pipe(
        switchMap(user => {
          if(!user) {
            return of(null);
          }
          return this.customerService.getCustomer(user.groupUid);
        })
      )
  }


  getKeyaccounts() {
    return this.keyaccounts$;
  }

  getKeyaccount(keyaccountUid): Observable<Keyaccount | null> {
    return this.keyaccounts$
      .pipe(
        map(keyaccounts => keyaccounts.find(keyaccount => keyaccount.uid === keyaccountUid))
      );
  }

  getKeyaccountForGroupUid(groupUid): Observable<Keyaccount[]> {
    return this.keyaccounts$
      .pipe(
        map(keyaccounts => keyaccounts.filter(keyaccount => keyaccount.groupUid === groupUid))
      );
  }

  getKeyAccountsForCurrentUsersGroup() {
    return this.userService.getCurrentUser()
      .pipe(
        switchMap(user => {
          if(!user) {
            return of([]);
          }
          return this.getKeyaccountForGroupUid(user.groupUid);
        })
      );
  }

  editKeyaccount(uid, keyaccount: Partial<Keyaccount>) {
    return this.keyaccountCollection.doc(uid).update({ ...keyaccount, uid: uid });
  }

  setServicelineRevenues(uid, servicelines: { [uid: string]: YearlyValues }, importYearRange: { start: number, end: number }): Promise<any> {
    // servicelines contain all servicelines from the import
    // it might not contain a service line from an earlier import
    // existing service lines need to have their values reset to zero for the years that fall in the range of the import
    // the range can be extracted here as min / max from all given servicelines

    const initialRevenueValue: YearlyValues = {};
    for (let i = importYearRange.start; i <= importYearRange.end; i++) {
      initialRevenueValue[i] = 0;
    }

    // TODO: refactor: extract / isolate calls to firebase to support mocking in test
    return this.getServiceLineUids()
      .pipe(
        first(),
        map((serviceLineUids) => {
          // Set all existing revenues to the default value for the year range of the import
          return serviceLineUids.reduce((existingRevenueUpdates, serviceLineUid) => {
            existingRevenueUpdates[serviceLineUid] = {...initialRevenueValue};
            return existingRevenueUpdates;
          }, {})
        }),
        map(toUpdateRevenues => {
          // apply imported values over the default values for imported revenues, overwrite existing if needed
          Object.keys(servicelines).forEach(serviceLineUid => {
            toUpdateRevenues[serviceLineUid] = {...initialRevenueValue, ...servicelines[serviceLineUid]};
          });
          return toUpdateRevenues;
        }),
        map(toUpdateRevenues => {
          return Object.keys(toUpdateRevenues).map(serviceLineUid => {
            // update each revenue, merge with existing data to keep values for years outside of the imported range
            return this.keyaccountCollection.doc(uid)
              .collection('revenues')
              .doc(serviceLineUid)
              .set(toUpdateRevenues[serviceLineUid], {merge: true});
          });
        }),
        map(serviceLinePromises => {
          return this.keyaccountCollection.doc(uid)
            .update({revenueImportedAt: new Date()})
            .then(() => Promise.all(serviceLinePromises));
        })
      )
      .toPromise();
  }

  createKeyaccount(keyaccount: Keyaccount): Promise<DocumentReference> {
    return this.keyaccountCollection.add(keyaccount)
      .then(docRef => {
        return docRef.update({uid: docRef.id}).then(() => docRef);
      });
  }

  canEdit(keyaccountUid: string): Observable<boolean> {
    return combineLatest(this.userService.getCurrentUser(), this.getKeyaccount(keyaccountUid)).pipe(
      map(([user, keyaccount]) => {
        if(!user) {
          return false;
        } else if (user.roles.includes('ADMIN')) {
          return true;
        } else if(user.roles.includes('GROUPADMIN')) {
          return keyaccount.groupUid === user.groupUid;
        } else if(user.roles.includes('ACCOUNTMANAGER')) {
          return user.keyaccounts.includes(keyaccount.uid);
        } else {
          // No role or account viewer
          return false;
        }
      })
    )
  }

}
