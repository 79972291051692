<div name="editForm" id="editForm" class="form-horizontal col-sm-12" [formGroup]="form" (submit)="doSave()" role="form" autocomplete="off">
  <div class="panel panel-primary" *ngIf="(keyaccountPlan$ | async) as keyaccountPlan">
    <div class="sw-heading col-sm-12 panel-heading">
      <div class="pull-left col-sm-8">
        <h2>
          <masterplanner2-header-logos [groupUid]="keyaccountPlan.groupUid"
                                       [keyaccountUid]="keyaccountPlan.keyaccountUid"
          ></masterplanner2-header-logos>
          <div class="col-sm-10">{{ 'ACCOUNTPROFILE_PRO' | uppercase | translate }}</div>
        </h2>
        <div class="col-sm-12 version">{{ 'VERSION' | translate }}:{{ keyaccountPlan.version }}
          {{ 'ITARGETYEAR' | translate }}: {{ keyaccountPlan.targetYear }}
        </div>
      </div>
      <masterplanner2-nav-bar (save)="doSave()" [canSave]="canEdit$ | async"></masterplanner2-nav-bar>
    </div>
    <div class="form-group" *ngIf="(yearSpan$ | async) as yearSpan">
      <div class="separator col-sm-12">
        <span class="col-sm-4">{{ 'ACCOUNT_INFORMATION' | translate }}</span>
        <span class="col-sm-4  text-right" style="color:black;">{{ 'PROGNOSIS' | translate }}</span>
        <span class="info-text col-sm-4  text-right pull-right">{{ 'ALL_AMOUNTS_THOUSENDS' | translate }}</span>
      </div>
      <div class="col-sm-3"></div>
      <div class="col-sm-7">
          <div class="row">
              <div class="col-sm-2 text-center border-top" *ngFor="let year of yearSpan" [ngClass]="{ 'target-cell-first': year === currentYear }">&nbsp;</div>
          </div>
          <div class="row">
              <div class="col-sm-2 text-center border-top year-column" *ngFor="let year of yearSpan" [ngClass]="{ 'target-cell': year === currentYear }"><span>{{ year }}</span></div>
          </div>
      </div>
      <div class="col-sm-2 revenue-qualification" ><span>{{ 'QUALIFICATION' | translate }}</span></div>
      <masterplanner2-revenue *ngIf="keyaccountPlan.featureToggles.brancheRevenue"
                              formControlName="brancheRevenue"
                              [name]="'brancherevenue'"
                              [yearSpan]="yearSpan"
                              [highlightCurrentYear]="true"
                              [showIndex]="true"
      ></masterplanner2-revenue>
      <masterplanner2-revenue formControlName="keyaccountRevenue"
                              [name]="'keyaccountrevenue'"
                              [yearSpan]="yearSpan"
                              [highlightCurrentYear]="true"
                              [showIndex]="true"
                              [showSwotType]="true"
                              [swotMode]="'OT'"
                              [lastRowHighlight]="true"
                              label="{{'KEYACCOUNTREVENUE' | translate}} {{ keyaccountPlan.keyaccountName }}"
      ></masterplanner2-revenue>

      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-12">
            <label for="profileRemarks">{{ 'EXPLANATION_REMARKS' | translate }}</label>
            <textarea name="profileRemarks" class="form-control" formControlName="profileRemarks"></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-5 form-group">
                <label for="cIndustry" class="control-label">{{ 'CINDUSTRY' | translate }}</label>
                <ng-select [items]="(customer$ | async)?.industries"
                            id="cIndustry"
                            placeholder="Select industry"
                            bindValue="uid"
                            bindLabel="description"
                            clearAllText="Clear"
                            formControlName="industry"
                >
                </ng-select>
              </div>
              <div class="col-sm-5 form-group">
                <label for="cCompanytype" class="control-label">{{ 'CCOMPANYTYPE' | translate }}</label>
                <ng-select [items]="(customer$ | async)?.companytypes"
                            id="cCompanytype"
                            placeholder="Select company type"
                            bindValue="uid"
                            bindLabel="description"
                            clearAllText="Clear"
                            formControlName="companyType"
                >
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="form-group mission-strategy">
        <div class="separator col-sm-12"></div>

        <div class="col-sm-12">
          <label class="control-label">
            {{ keyaccountPlan.keyaccountName }} {{ 'EXPLANATION_MISSIONSTRATEGY_PRO' | translate }}
          </label>
        </div>

        <div class="col-sm-12 form-group">
          <label for="cMission" class="control-label">
              {{ 'EXPLANATION_MISSION' | translate }}
          </label>
          <textarea name="cMission"
                    class="form-control"
                    formControlName="mission"
                    kendoTextArea
                    [autoSize]="true"
          ></textarea>
        </div>

        <div class="col-sm-12 form-group">
          <label for="cStrategy" class="control-label">
            {{ 'EXPLANATION_STRATEGY' | translate }}
          </label>
          <textarea name="cStrategy"
                    class="form-control"
                    formControlName="strategy"
                    kendoTextArea
                    [autoSize]="true"
          ></textarea>
        </div>

        <div class="col-sm-12 form-group">
          <label for="cMaingoals" class="control-label">
            {{ keyaccountPlan.keyaccountName }} {{ 'CMAINGOALS_PRO' | translate }}
          </label>
          <textarea name="cMaingoals"
                    class="form-control"
                    formControlName="mainGoals"
                    autocomplete="off"
                    kendoTextArea
                    [autoSize]="true"
          ></textarea>
        </div>

        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-4 form-group">
              <label for="missionSwotType" class="control-label">
                {{ 'QUALIFICATION' | translate }}
              </label>
              <masterplanner2-swot-type formControlName="missionSwotType" name="missionSwotType"></masterplanner2-swot-type>
            </div>
            <div class="col-sm-6 form-group">
              <label for="missionSwotReason" class="control-label">{{ 'REASON' | translate }}</label>
              <input type="text" class="form-control" formControlName="missionSwotReason">
            </div>
          </div>
        </div>

        <div class="separator col-sm-12">{{ keyaccountPlan.keyaccountName }} {{ 'CMAINACTIVITIES_PRO' | translate }}</div>
        <masterplanner2-main-activities formControlName="mainActivities"></masterplanner2-main-activities>
      </div>

      <div *ngIf="keyaccountPlan.featureToggles.chracteristics" class="separator col-sm-12">{{ 'TITLE_CUSTOMER_CHARACTERISTICS' | translate }}</div>
      <div *ngIf="keyaccountPlan.featureToggles.chracteristics" class="form-group col-sm-12 chracteristics">
        <div class="row">
          <div class="col-sm-4">
            <label for="culture" class="control-label">{{ 'CCULTURE' | translate }}: </label>
            <select name="culture" formControlName="culture" class="form-control">
              <option *ngFor="let culture of cultures" [value]="culture.label">{{ culture.label | translate }}</option>
            </select>
          </div>
          <div class="col-sm-4">
            <label for="proposition" class="control-label">{{ 'CPROPOSITION' | translate }}: </label>
            <select name="proposition" formControlName="proposition" class="form-control">
                <option *ngFor="let proposition of propositions" [value]="proposition.label">{{ proposition.label | translate }}</option>
              </select>
          </div>
        </div>
      </div>


      <div *ngIf="keyaccountPlan.featureToggles.finances" class="separator col-sm-12">{{ 'KEY_ACCOUNT_FINANCES' | translate }}: {{ keyaccountPlan.keyaccountName }} </div>
      <masterplanner2-finances *ngIf="keyaccountPlan.featureToggles.finances" formControlName="finances" [keyaccountName]="keyaccountPlan.keyaccountName"></masterplanner2-finances>

      <div class="separator col-sm-12 margin-bottom">{{ 'CORGANOGRAM_PRO' | translate }} {{ keyaccountPlan.keyaccountName }}</div>
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-7">
            <masterplanner2-image-upload-preview *ngIf="form.get('uid').value" [path]="'keyaccountplan/' + form.get('uid').value + '/images/organogram'"></masterplanner2-image-upload-preview>
          </div>
          <div class="col-sm-5">
            <div class="row">
              <div class="col-sm-8">
                <label class="control-label iEmployees" [innerHTML]="'IEMPLOYEESTOTAL_PRO' | translate"></label>
                <kendo-numerictextbox [autoCorrect]="false"
                                      [spinners]="false"
                                      [decimals]="0"
                                      [format]="'n0'"
                                      formControlName="employeesTotalCompany"
                ></kendo-numerictextbox>
              </div>
              <div class="col-sm-1">
                <button class="btn no-btn showEmployeesdivision success" title="{{ 'ADD_DEPARTMENT' | translate }}" (click)="addDepartment()">
                  <i class="glyphicon glyphicon-plus"></i>
                </button>
              </div>
            </div>
            <div class="row" *ngIf="getDepartmentControls().length">
              <div class="col-sm-6">
                <label for="cDepartment" class="control-label">{{ 'DEPARTMENT_NAME' | translate }}</label>
              </div>
              <div class="col-sm-4">
                <label for="iNumemployees" class="control-label">{{ 'NUMBER' | translate }}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12" formArrayName="departments">
                <div class="departments row margin-bottom" *ngFor="let row of getDepartmentControls(); let i = index;" [formGroupName]="i">
                  <div class="col-sm-6">
                    <input type="text" class="form-control cDepartment" formControlName="name" name="cDepartment">
                  </div>
                  <div class="col-sm-4">
                    <kendo-numerictextbox [autoCorrect]="false"
                                          [spinners]="false"
                                          [decimals]="0"
                                          [format]="'n0'"
                                          formControlName="numEmployees"
                    ></kendo-numerictextbox>
                  </div>
                  <div class="col-sm-2">
                    <button type="button"
                            class="btn ng-scope no-btn danger deletebutton"
                            (click)="removeDepartment(i)"
                            title="{{ 'DELETE' | translate }}"
                            style="padding: 5px; "
                    >
                      <i class="glyphicon glyphicon-trash "></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="separator col-sm-12">
        <span>{{ keyaccountPlan.keyaccountName }}</span>
        <span>{{ 'MAINCOMPETITORS_PRO' | translate }}</span>
      </div>
      <masterplanner2-competitors formControlName="competitors"></masterplanner2-competitors>

      <div class="separator col-sm-12">
        <span>{{ 'LPTS' | translate }}</span>
        <span>{{ keyaccountPlan.keyaccountName }}</span>
      </div>
      <masterplanner2-opportunities formControlName="opportunities"></masterplanner2-opportunities>

      <div class="separator col-sm-12"> {{ keyaccountPlan.keyaccountName }} {{ 'EXTERNALDEVELOPMENTS_PRO' | translate }}
      </div>
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <b>
              <p class="info-text">{{ 'OPEN_FAQ' | translate }}</p>
            </b>
            <p class="info-text">
              <a target="_blank" localeFile="assets/pdfs/faq_externaldevelopmentscustomer.pdf">
                {{ 'FAQ_EXTERNALDEVELOPMENTSCUSTOMER' | translate }}
                <i class="glyphicon glyphicon-new-window"></i>
              </a>
            </p>
          </div>
          <div class="col-sm-5">
            <a localeFile="assets/images/tab3outsidein2.png" target="_blank">
              <img localeFile="assets/images/tab3outsidein2.png" class="img-responsive" />
            </a>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12 lpts">
        <masterplanner2-developments formControlName="developments" [templateValue]="{type: 'development'}" title="{{ keyaccountPlan.keyaccountName }} {{ 'PRODEVELOPMENTS' | translate }}"></masterplanner2-developments>
        <masterplanner2-developments formControlName="developmentOpportunities" [templateValue]="{type: 'opportunities'}" title="{{ keyaccountPlan.keyaccountName }} {{ 'PROOPPORTUNITIES' | translate }}"></masterplanner2-developments>
      </div>


      <div class="separator col-sm-12"> {{ keyaccountPlan.keyaccountName }} {{ 'STRENGTHWEAKNESSEN_PRO' | translate }}
      </div>
      <div class="form-group col-sm-12">
        <b>
          <p class="info-text"> {{ 'OPEN_FAQ' | translate }}</p>
        </b>
        <p class="info-text">
          <a target="_blank" localeFile="assets/pdfs/faq_customercharacteristic.pdf">
            {{ 'FAQ_CUSTOMERCHARACTERISTIC' | translate }}
            <i class="glyphicon glyphicon-new-window"></i>
          </a>
        </p>
      </div>
      <div class="form-group col-sm-12 proswstrats">
        <masterplanner2-developments type="sw" formControlName="strengthAndWeaknesses" title="{{ keyaccountPlan.keyaccountName }} {{ 'PROSTRENGTHWEAKNESS' | translate }}"></masterplanner2-developments>
        <masterplanner2-developments type="strategy" formControlName="strengthAndWeaknessDeployStrategy" title="{{ keyaccountPlan.keyaccountName }} {{ 'PROSTRENGTHWEAKNESSDEPLOY' | translate }}"></masterplanner2-developments>
      </div>

      <div class="separator col-sm-12"> {{ keyaccountPlan.keyaccountName }} {{ 'PURCHASEPROCESS_PRO' | translate }}
      </div>
      <div class="form-group col-sm-12">
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-6">
                <label class="control-label">
                  <span>{{ 'DESCRIBE' | translate }}</span>
                  <span> {{ keyaccountPlan.keyaccountName }}</span>
                  <span> {{ 'PURCHASEPROCESS_PRO' | translate }}</span>
                </label>
                <span class="info-text" [innerHTML]="'PURCHASEPROCESS_EXPLANATION' | translate "></span>
              </div>
              <div class="col-sm-5">
                <a target="_blank" localeFile="assets/images/tab5buyingprocess.png">
                  <img class="img-responsive" localeFile="assets/images/tab5buyingprocess.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <masterplanner2-purchase-process formControlName="purchaseProcess"></masterplanner2-purchase-process>
      </div>
      <div class="separator col-sm-12"></div>


      <div class="form-group">
        <label class="col-sm-12 control-label">
          <span> {{ 'DESCRIBE' | translate }}</span>
          <span> {{ 'BUYINGBEHAVIOR_PRO' | translate }}</span>
          <span> {{ keyaccountPlan.keyaccountName }}</span>
        </label>
        <div class="col-sm-12" style="margin-bottom:25px;">
          <span class="info-text" [innerHTML]="'BUYINGBEHAVIOR_PRO_EXPLANATION' | translate " style="margin-bottom:10px;"></span>
        </div>
        <div class="col-sm-12">
          <masterplanner2-buying-behavior formControlName="buyingBehavior"></masterplanner2-buying-behavior>
        </div>
      </div>

      <div class="separator col-sm-12">
        {{ 'TITLE_DMU_PRO' | translate }} {{ keyaccountPlan.keyaccountName }}
      </div>
      <div class="col-sm-12">
        <div class="form-group col-sm-8">
          <b><p class="info-text" [innerHTML]="'DMU_TABLE_EXPLANATION_PRO' | translate "></p></b>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <masterplanner2-dmus formControlName="dmus"></masterplanner2-dmus>
      </div>

      <!-- Stakeholders -->
      <masterplanner2-stakeholders *ngIf="keyaccountPlan.featureToggles.stakeholders" formControlName="stakeholders"></masterplanner2-stakeholders>
    </div>
  </div>


</div>
