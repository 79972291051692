/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./revenue-flat.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./revenue-flat.component";
var styles_RevenueFlatComponent = [i0.styles];
var RenderType_RevenueFlatComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RevenueFlatComponent, data: {} });
export { RenderType_RevenueFlatComponent as RenderType_RevenueFlatComponent };
function View_RevenueFlatComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_RevenueFlatComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "th", [["style", "text-align: right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenueFlatComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.yearSpan; _ck(_v, 3, 0, currVal_0); }, null); }
function View_RevenueFlatComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.revenueMap[_v.parent.context.$implicit]; _ck(_v, 1, 0, currVal_0); }); }
function View_RevenueFlatComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(("SOW_" + _co.revenueMap[_v.parent.context.$implicit]))); _ck(_v, 1, 0, currVal_0); }); }
function View_RevenueFlatComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(("MAR_" + _co.revenueMap[_v.parent.context.$implicit]))); _ck(_v, 1, 0, currVal_0); }); }
function View_RevenueFlatComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "td", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenueFlatComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenueFlatComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenueFlatComponent_6)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.mode === "default"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.mode === "estimatedSow"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.mode === "profitMargin"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_RevenueFlatComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "% "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.index[_v.context.$implicit]; _ck(_v, 1, 0, currVal_0); }); }
function View_RevenueFlatComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "th", [["style", "text-align: right"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenueFlatComponent_8)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.yearSpan; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(("INDEX_LASTYEAR_" + _co.name.toUpperCase()))); _ck(_v, 2, 0, currVal_0); }); }
export function View_RevenueFlatComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenueFlatComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "th", [["style", "text-align: right"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenueFlatComponent_3)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevenueFlatComponent_7)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showHeader; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.yearSpan; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.showIndex; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.name.toUpperCase())); _ck(_v, 6, 0, currVal_1); }); }
export function View_RevenueFlatComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "masterplanner2-revenue-flat", [], null, null, null, View_RevenueFlatComponent_0, RenderType_RevenueFlatComponent)), i1.ɵdid(1, 114688, null, 0, i4.RevenueFlatComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RevenueFlatComponentNgFactory = i1.ɵccf("masterplanner2-revenue-flat", i4.RevenueFlatComponent, View_RevenueFlatComponent_Host_0, { revenueMap: "revenueMap", name: "name", showIndex: "showIndex", showHeader: "showHeader", mode: "mode", yearSpan: "yearSpan" }, {}, []);
export { RevenueFlatComponentNgFactory as RevenueFlatComponentNgFactory };
