import { Component, OnInit } from '@angular/core';
import { KeyaccountService, Keyaccount, User, UserService } from '@masterplanner2/users';
import { KeyaccountPlanService } from '../keyaccountplans/keyaccount-plan.service';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap, pluck, filter, shareReplay, first, startWith } from 'rxjs/operators';
import { CustomerService } from '../customer.service'
import { Customer } from '../customer.model';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ToasterService } from "angular2-toaster";
import { Router, ActivatedRoute } from '@angular/router';
import { KeyaccountPlan } from '../keyaccount-plan.model';

@Component({
  selector: 'masterplanner2-keyaccount-plan-create',
  templateUrl: './keyaccount-plan-create.component.html',
  styleUrls: ['./keyaccount-plan-create.component.css']
})
export class KeyaccountPlanCreateComponent implements OnInit {

  customers$: Observable<Customer[]>;
  keyaccounts$: Observable<Keyaccount[]>;
  managers$: Observable<User[]>;
  keyaccountPlan$: Observable<KeyaccountPlan>;
  oldKeyaccountPlan: KeyaccountPlan = null;


  form: FormGroup;
  currentYear: number = (new Date()).getFullYear();
  targerYears: number[] = [this.currentYear, this.currentYear + 1];

  constructor(private keyaccountService: KeyaccountService,
    private customerService: CustomerService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toasterService: ToasterService,
    private keyaccountPlanService: KeyaccountPlanService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {

    this.form = formBuilder.group({
      'customer': [, Validators.required],
      'keyaccount': [, Validators.required],
      'keyaccountManager': [, Validators.required],
      'targetYear': [this.currentYear, Validators.required],
      'version': [, Validators.required],
    });

    const id$ = activatedRoute.params.pipe(
      first(),
      pluck('id'),
      tap(id => console.log('loading id:', id)),
    );

    this.keyaccountPlan$ = id$.pipe(
      switchMap(keyaccountPlanId => keyaccountPlanId ? this.keyaccountPlanService.getKeyaccountPlan(<string>keyaccountPlanId): of(null)),
      tap(keyaccountPlan => this.oldKeyaccountPlan = keyaccountPlan)
    );

    this.keyaccounts$ = this.getKeyaccountsByCusomter().pipe(tap(keyaccounts => console.log('keyaccounts', keyaccounts)));
    this.managers$ = this.getManagersByKeyaccount().pipe(tap(managers => console.log('managers', managers)));

    this.customers$ = this.keyaccountPlan$.pipe(
      switchMap(keyaccountPlan => {
        if (keyaccountPlan) {
          return this.customerService.getCustomer(keyaccountPlan.groupUid).pipe(
            tap(customer => {
              this.form.get('customer').patchValue(customer);
              this.form.get('customer').disable({emitEvent: false});
            }),
            map(customer => {
              return [customer];
            })
          )
        } else {
          return this.customerService.getCustomers().pipe(
            tap(customers => {
              if(customers.length === 1) {
                this.form.get('customer').patchValue(customers[0]);
              }
            })
          );
        }
      })
    );

  }

  ngOnInit() {
  }

  private getKeyaccountsByCusomter(): Observable<Keyaccount[]> {
    return this.keyaccountPlan$.pipe(
      switchMap(keyaccountPlan => {
        if (keyaccountPlan) {
          return this.keyaccountService.getKeyaccount(keyaccountPlan.keyaccountUid).pipe(
            tap(keyaccount => {
              this.form.get('keyaccount').patchValue(keyaccount);
              this.form.get('keyaccount').disable({emitEvent: false});
            }),
            map(keyaccount => {
              return [keyaccount];
            })
          )
        } else {
          return this.form.get('customer').valueChanges.pipe(
            startWith(this.form.get('customer').value),
            switchMap(customer => {
              return this.keyaccountService.getKeyaccounts().pipe(
                map(keyaccounts => {
                  return keyaccounts.filter(keyaccount => customer && (keyaccount.groupUid === customer.uid));
                }),
                tap((keyaccounts) => {
                  if(keyaccounts.length === 1) {
                    this.form.get('keyaccount').patchValue(keyaccounts[0]);
                  } else {
                    this.form.get('keyaccount').setValue(null);
                  }
                })
              )
            })
          )
        }
      })
    );
  }

  private getManagersByKeyaccount(): Observable<User[]> {
    return this.form.get('keyaccount').valueChanges.pipe(
      startWith(this.form.get('keyaccount').value),
      switchMap(keyaccount => {
        return this.userService.getUsers().pipe(
          map(users => {
            return keyaccount !== null ? users.filter(user => user.keyaccounts.includes(keyaccount.uid) && user.roles.includes('ACCOUNTMANAGER')) : null;
          }),
          tap(users => {
            if(users && users.length === 1) {
              this.form.get('keyaccountManager').patchValue(users[0]);
            } else {
              this.form.get('keyaccountManager').setValue(null);
            }
          })
        )
      })
    )
  }

  doSave() {
    const f = this.form.getRawValue();

    return this.keyaccountPlanService.createKeyaccountPlan(f.customer, f.keyaccount, f.keyaccountManager, f.targetYear, f.version, this.oldKeyaccountPlan)
    .then(success => {
      this.toasterService.pop('success', 'Created keyaccount-plan!');
      this.router.navigateByUrl('/keyaccountplan/' + success.id + '/profile');
    })
    .catch(err => {
      console.error(err);
      this.toasterService.pop('error', 'Error creating keyaccount-plan: ' + err.message);
    });


  }

}
