import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { FeatureToggleValues, KeyaccountPlan } from '../../../keyaccount-plan.model';
import { Observable } from 'rxjs';

class Sums {
  orderIntakeTarget: number[] = [];
  orderIntake: number[] = [];
  orderBacklog: number[] = [];
  targetRevenue: number[] = [];
  customerRevenue: number[] = [];
}

@Component({
  selector: 'masterplanner2-revenue-chart',
  templateUrl: './revenue-chart.component.html',
  styleUrls: ['./revenue-chart.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RevenueChartComponent implements OnInit {
  public Object = Object;
  public Math = Math;

  @Input()
  public keyaccountPlans$: Observable<KeyaccountPlan[]>;

  @Input()
  currentYear: number;

  @Input()
  yearSpan: number[];

  @Input()
  featureToggles: FeatureToggleValues;

  public sums$: Observable<Sums>;

  constructor() {
  }

  ngOnInit() {
    this.sums$ = this.keyaccountPlans$.pipe(
      map(keyaccountPlans => {
        const sums = new Sums();
        Object.keys(sums).forEach((property) => {
          sums[property] = this.yearSpan.map(year => {
            return keyaccountPlans.map(plan => this.getValueAsNumber(plan[property][year])).reduce((acc, v) => acc + v, 0);
          });
        });
        return sums;
      }),
    );
  }

  private getValueAsNumber(v: any): number {
    if (!v)
      return 0;
    if (Array.isArray(v))
      return 0;
    if ( typeof v === 'string')
      return parseInt(v, 10);
    return v;
  }
}
