<table class="table table-bordered purchase-process-table"  [formGroup]="form">
  <tr>
    <th width="25%"></th>
    <th width="12%"> {{ 'SPECIFY' | translate }}</th>
    <th width="12%"> {{ 'SELECT' | translate }}</th>
    <th width="12%"> {{ 'CONTRACT' | translate }}</th>
    <th width="12%"> {{ 'ORDER' | translate }}</th>
    <th width="12%"> {{ 'MONITOR' | translate }}</th>
    <th width="12%"> {{ 'EVALUATIONFOLLOWUP' | translate }}</th>
  </tr>
  <tr>
    <th width="25%"> {{ 'FUNCTION_DECISION' | translate }}</th>
    <td width="12%">
      <textarea formControlName="specifyDecision" class="table-cell" ></textarea>
    </td>
    <td width="12%">
      <textarea formControlName="selectDecision" class="table-cell" ></textarea>
    </td>
    <td width="12%">
      <textarea formControlName="contractDecision" class="table-cell" ></textarea>
    </td>
    <td width="12%">
      <textarea formControlName="orderDecision" class="table-cell" ></textarea>
    </td>
    <td width="12%">
      <textarea formControlName="monitorDecision" class="table-cell" ></textarea>
    </td>
    <td width="12%">
      <textarea formControlName="evaluateDecision" class="table-cell" ></textarea>
    </td>
  </tr>
  <tr>
    <th width="25%" >{{ 'FUNCTION_PROCESS' | translate }}</th>
    <td width="12%">
      <textarea formControlName="specifyInvolved" class="table-cell" ></textarea>
    </td>
    <td width="12%">
      <textarea formControlName="selectInvolved" class="table-cell" ></textarea>
    </td>
    <td width="12%">
      <textarea formControlName="contractInvolved" class="table-cell" ></textarea>
    </td>
    <td width="12%">
      <textarea formControlName="orderInvolved" class="table-cell" ></textarea>
    </td>
    <td width="12%">
      <textarea formControlName="monitorInvolved" class="table-cell" ></textarea>
    </td>
    <td width="12%">
      <textarea formControlName="evaluateInvolved" class="table-cell" ></textarea>
    </td>
  </tr>
</table>
<div class="row" [formGroup]="form">
  <div class="col-sm-12">
    <label for="purchaseExplanation" class="control-label">{{ 'GENERAL_REMARK_PURCHASING_PROCESS' | translate }}</label>
    <textarea name="purchaseExplanation" formControlName="explanation" class="form-control"></textarea>
  </div>
</div>
<div class="row" [formGroup]="form">
  <div class="col-sm-4">
    <label for="purchaseSwotType" class="control-label">{{ 'QUALIFICATION' | translate }}</label>
    <masterplanner2-swot-type formControlName="swotType" name="purchaseSwotType"></masterplanner2-swot-type>
  </div>
  <div class="col-sm-6">
    <label for="purchaseSwotReason" class="control-label">{{ 'REASON' | translate }}</label>
    <input class="form-control" name="purchaseSwotReason" formControlName="swotReason">
  </div>
</div>
