/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-upload-preview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./image-upload-preview.component";
import * as i5 from "@angular/fire/storage";
import * as i6 from "@angular/platform-browser";
var styles_ImageUploadPreviewComponent = [i0.styles];
var RenderType_ImageUploadPreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageUploadPreviewComponent, data: {} });
export { RenderType_ImageUploadPreviewComponent as RenderType_ImageUploadPreviewComponent };
function View_ImageUploadPreviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["data-horizontal", ""], ["data-style", "shrink"], ["type", "button"]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.uploadImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "progress"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "progress-inner"], ["style", "opacity: 1;"]], [[4, "width", null]], null, null, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("progress-button " + i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.uploadState$))); _ck(_v, 0, 0, currVal_0); var currVal_1 = (i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.uploadProgress$)) + "%"); _ck(_v, 5, 0, currVal_1); }); }
function View_ImageUploadPreviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["target", "_blank"], ["title", ""]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["style", "max-width: 400px; max-height: 300px"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.ngIf; _ck(_v, 1, 0, currVal_1); }); }
export function View_ImageUploadPreviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 0, "input", [["accept", "image/jpeg, image/png"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.setUploadFileChangeEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageUploadPreviewComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ImageUploadPreviewComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.uploadFileChangedEvent$.getValue(); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.getImagePreview() ? _co.getImagePreview() : i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.downloadUrl$))); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("IMAGE_UPLOAD_INSTRUCTIONS")); _ck(_v, 2, 0, currVal_0); }); }
export function View_ImageUploadPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "masterplanner2-image-upload-preview", [], null, null, null, View_ImageUploadPreviewComponent_0, RenderType_ImageUploadPreviewComponent)), i1.ɵdid(1, 114688, null, 0, i4.ImageUploadPreviewComponent, [i5.AngularFireStorage, i6.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageUploadPreviewComponentNgFactory = i1.ɵccf("masterplanner2-image-upload-preview", i4.ImageUploadPreviewComponent, View_ImageUploadPreviewComponent_Host_0, { path: "path" }, {}, []);
export { ImageUploadPreviewComponentNgFactory as ImageUploadPreviewComponentNgFactory };
