<div class="panel panel-primary" [formGroup]="form" role="form">
  <div class="sw-heading col-sm-12 panel-heading">
    <h2>{{ 'keyaccounts' | translate }}</h2>
  </div>
  <table cdk-table [dataSource]="filteredKeyaccounts$ | async" class="table table-bordered table-striped table-hover" [trackBy]="trackByUId">
    <!-- Name Column -->
    <ng-container cdkColumnDef="name">
      <th cdk-header-cell *cdkHeaderCellDef> Name
        <hr>
        <input type="text" class="form-control" formControlName="name">
      </th>
      <td cdk-cell *cdkCellDef="let element"> {{ element.name }}</td>
    </ng-container>

    <!-- Customer Column -->
    <ng-container cdkColumnDef="group">
      <th cdk-header-cell *cdkHeaderCellDef> Customer
        <hr>
        <input type="text" class="form-control" formControlName="groupName">
      </th>
      <td cdk-cell *cdkCellDef="let element"> {{ element.groupName}}</td>
    </ng-container>


    <!-- Symbol Column -->
    <ng-container cdkColumnDef="actionsColumn">
      <th cdk-header-cell *cdkHeaderCellDef>
        <a class="btn btn-success btn-xs" routerLink="/settings/new-keyaccount"
           title=" {{ 'NEW' | translate }}"
        >
          <i class="glyphicon glyphicon-plus "></i>
        </a>
        <hr>
        <button class="btn btn-xs reset-btn" (click)="resetFiltersForm()">
          <i class="glyphicon glyphicon-remove"></i>
        </button>

      </th>
      <td cdk-cell *cdkCellDef="let element">
        <a routerLink="/settings/keyaccount/{{element.uid}}" class="btn btn-default btn-xs btn-primary" title="{{ 'EDIT' | translate }}">
          <i class="glyphicon glyphicon-pencil"></i>
        </a>
      </td>
    </ng-container>

    <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
