import * as i0 from "@angular/core";
export class CanDeactivateTabGuard {
    constructor() { }
    canDeactivate(tabComponent, currentRoute, currentState, nextState) {
        if (!tabComponent.canDeactivate()) {
            return confirm("You have changes in the keyaccountplan. If you continue your changes will be lost");
        }
        return true;
    }
}
CanDeactivateTabGuard.ngInjectableDef = i0.defineInjectable({ factory: function CanDeactivateTabGuard_Factory() { return new CanDeactivateTabGuard(); }, token: CanDeactivateTabGuard, providedIn: "root" });
