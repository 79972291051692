import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { StrategicActionsComponent } from "../../strategy/strategic-actions/strategic-actions.component";
import { sliderOptions } from './slider-optionts';
import { Observable, Subscription } from "rxjs";
import { map, shareReplay, tap } from "rxjs/operators";
import { Options } from 'ng5-slider';

@Component({
  selector: 'masterplanner2-activity-planning',
  templateUrl: './activity-planning.component.html',
  styleUrls: ['./activity-planning.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: ActivityPlanningComponent, multi: true}
  ]
})
export class ActivityPlanningComponent extends StrategicActionsComponent implements OnInit, OnDestroy {
  progressCalculationSubscription: Subscription;
  progresses$: Observable<number[]>;
  Math = Math;
  Object = Object;

  @Input()
  dashboardView = false;

  @Input()
  showGotik = false;

  @Input()
  showActivityTurnover = false;

  @Input()
  showActivityType = false;

  @Input()
  showActivityComments = false;

  sliderOptions = sliderOptions;

  @Input()
  allowNew = false;

  expanded: {[key: string]: boolean} = {};

  ngOnInit() {
    this.progresses$ = this.form.valueChanges.pipe(
      map(formValue => {
        return formValue.map(row => {
          if(row.subActivities && row.subActivities.length) {
            const progress = row.subActivities.map(subActivity => subActivity.progress ? subActivity.progress : 0)
              .reduce((acc, cur) => acc + cur, 0) / row.subActivities.length;
            return progress;
          } else {
            return row.progress;
          }
        });
      }),
      tap(progresses => console.log(progresses)),
      shareReplay(1),

    );
    this.progressCalculationSubscription = this.progresses$.pipe(
      tap(progresses => {
        progresses.forEach((progress, index) => {
          const progressControl = (<FormArray>this.form).controls[index].get('progress');
          if(progressControl.value != progress) {
            progressControl.setValue(progress, {emitEvent: false});
          }
        })
      })
    ).subscribe();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if(this.progressCalculationSubscription) {
      this.progressCalculationSubscription.unsubscribe();
    }
  }

  // To lessons learned
  realised(index) {
    const realisedControl = (<FormArray>this.form).controls[index].get('realised');
    realisedControl.setValue(true);
  }

  updateGotik(index, char) {
    const gotikControl = (<FormArray>this.form).controls[index].get('gotik');
    const oldValue = gotikControl.value[char];
    let newValue = oldValue + 1;
    if(newValue === 4) {
      newValue = 0;
    }
    gotikControl.setValue({...gotikControl.value, [char]: newValue});
  }

  showGotikOnDashboard(gotik) {
    let showGotik = false;
    Object.keys(gotik).forEach(char => {
      if (gotik[char] > 0) {
        showGotik = true;
      }
    });
    return showGotik;
  }
}
