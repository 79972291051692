import { Component } from '@angular/core';
import { AbstractFormArrayListComponent } from "../../profile/abstract-form-array-list/abstract-form-array-list.component";
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";

@Component({
  selector   : 'masterplanner2-oppertunities-analysis',
  templateUrl: './opportunities.component.html',
  styleUrls  : ['./opportunities.component.css'],
  providers  : [
    {provide: NG_VALUE_ACCESSOR, useExisting: OpportunitiesComponent, multi: true}
  ]
})
export class OpportunitiesComponent extends AbstractFormArrayListComponent {

  constructor(formBuilder: FormBuilder) {
    super(formBuilder);
  }

  createItemControls(): FormGroup {
    return this.formBuilder.group({
      'uid'                 : [, Validators.required],
      'name'                : [{value: '', disabled: true}, Validators.required],
      'businessunit'        : [{value: '', disabled: true}, Validators.required],
      'expectedAwardDate'   : [{value: '', disabled: true}, Validators.required],
      'executionStartDate'  : [{value: '', disabled: true}, Validators.required],
      'totalDevelopmentCost': [{value: '', disabled: true}, Validators.required],
      'bokaValue'           : [, Validators.required],
      'goPercentage'        : [, Validators.required],
      'swotTypeOT'          : [],
      'swotTypeSW'          : [, Validators.required],
    });
  }

  writeValue(items: any): void {
    if(!items) { // possibly a null / undefined value
      items = [];
    }
    for (let i = this.getItemFormArray().length; i < items.length; i++) {
      const newItemControls = this.createItemControls();
      if (this.form.disabled) { 
        newItemControls.disable({ emitEvent: false }); 
      }
      this.getItemFormArray().push(newItemControls);
    }
    while(this.getItemFormArray().length > items.length) {
      this.getItemFormArray().removeAt(this.getItemFormArray().length - 1);
    }

    this.form.patchValue(items, );
  }
}
