import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, Validators } from "@angular/forms";
import * as pushid from "pushid";
import { AbstractTabComponent } from "./abstract-tab.component";
import { KeyaccountPlan } from "../../keyaccount-plan.model";
import { toNumbers, toNumber } from '../utils';
import { FinancesComponent } from './finances/finances.component';

@Component({
  selector       : 'masterplanner2-profile',
  templateUrl    : './profile.component.html',
  styleUrls      : ['./profile.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent extends AbstractTabComponent implements OnInit, OnDestroy {

  propositions = [
    {id: 1, label: 'PROPOSITION_PRODUCT_LEADERSHIP'},
    {id: 2, label: 'PROPOSITION_OPERATIONAL_EXCELLENCE'},
    {id: 3, label: 'PROPOSITION_CUSTOMER_INTIMACY'},
    {id: 4, label: 'PROPOSITION_NOT_KNOWN'}
  ];

  cultures = [
    {id: 1, label: 'CULTURE_QUALITY'},
    {id: 2, label: 'CULTURE_SERVICE'},
    {id: 3, label: 'CULTURE_WIN'},
    {id: 4, label: 'CULTURE_AUTHENTICITY'},
    {id: 5, label: 'CULTURE_OBSERVER'},
    {id: 6, label: 'CULTURE_LOYALTY'},
    {id: 7, label: 'CULTURE_IMPULSE'},
    {id: 8, label: 'CULTURE_POWER'},
    {id: 9, label: 'CULTURE_HARMONY'}
  ];

  patchForm(keyaccountPlan: KeyaccountPlan, canEdit: boolean) {
    if (keyaccountPlan.departments) {
      for(let i=0;i<keyaccountPlan.departments.length;i++) {
        this.getDepartmentFormArray().push(this.createDepartmentControls());
      }
    }
    super.patchForm(keyaccountPlan, canEdit);
  }


  buildForm() {
    return this.formBuilder.group({
      'uid'                              : [],
      'keyaccountRevenue'                : [],
      'brancheRevenue'                   : [],
      'profileRemarks'                   : [],
      'industry'                         : [, Validators.required],
      'companyType'                      : [, Validators.required],
      'mission'                          : [, Validators.required],
      'missionSwotType'                  : [],
      'missionSwotReason'                : [],
      'strategy'                         : [, Validators.required],
      'culture'                          : [],
      'proposition'                      : [],
      'mainGoals'                        : [, Validators.required],
      'mainActivities'                   : [],
      'orgChart'                         : [, Validators.required],
      'employeesTotalCompany'            : [, Validators.required],
      'departments'                      : this.formBuilder.array([]),
      'competitors'                      : [],
      'opportunities'                    : [],
      'developments'                     : [],
      'developmentOpportunities'         : [],
      'strengthAndWeaknesses'            : [],
      'strengthAndWeaknessDeployStrategy': [],
      'purchaseProcess'                  : [],
      'buyingBehavior'                   : [],
      'dmus'                             : [],
      'finances'                         : [],
      'stakeholders'                     : [],
      'networkDescription'               : [],
    });
  }

  removeDepartment(i: number) {
    this.getDepartmentFormArray().removeAt(i);
  }

  getDepartmentControls(i: number): AbstractControl[] {
    return this.getDepartmentFormArray().controls;
  }

  getDepartmentFormArray(): FormArray {
    return this.form.get('departments') as FormArray
  }

  addDepartment() {
    const formArray = this.getDepartmentFormArray();
    const formGroup = this.createDepartmentControls();
    formGroup.patchValue({
      order: formArray.length + 1,
      uid  : pushid(),
    });
    formArray.push(formGroup);
  }

  createDepartmentControls(): FormGroup {
    return this.formBuilder.group({
      'uid'         : [, Validators.required],
      'name'        : [, Validators.required],
      'numEmployees': [, Validators.required],
    })
  }

  mapFromDatabase(keyaccountPlan) {
    const finances = keyaccountPlan.finances ? {
      ...keyaccountPlan.finances,
      grossoperatingincome: keyaccountPlan.finances.grossoperatingincome ? toNumber(keyaccountPlan.finances.grossoperatingincome) : 0,
      netoperatingincome: keyaccountPlan.finances.netoperatingincome ? toNumber(keyaccountPlan.finances.netoperatingincome) : 0
    } : {grossoperatingincome: 0, netoperatingincome: 0};

    return {
      ...keyaccountPlan,
      developments                     : keyaccountPlan.developments ? keyaccountPlan.developments.filter(development => development.type === 'development') : [],
      developmentOpportunities         : keyaccountPlan.developments ? keyaccountPlan.developments.filter(development => development.type === 'opportunities') : [],
      strengthAndWeaknesses            : keyaccountPlan.strengthAndWeaknesses ? keyaccountPlan.strengthAndWeaknesses.filter(development => development.type === 'sw') : [],
      strengthAndWeaknessDeployStrategy: keyaccountPlan.strengthAndWeaknesses ? keyaccountPlan.strengthAndWeaknesses.filter(development => development.type === 'strategy') : [],
      purchaseProcess                  : keyaccountPlan.purchaseProcess ? keyaccountPlan.purchaseProcess : {},
      stakeholders                     : keyaccountPlan.stakeholders ? keyaccountPlan.stakeholders : [],
      finances                         : finances,
      opportunities                    : keyaccountPlan.opportunities ? keyaccountPlan.opportunities.map(opportunity => { return { ...opportunity, totalDevelopmentCost: opportunity.totalDevelopmentCost ? toNumber(opportunity.totalDevelopmentCost) : 0 } }) : [],
      keyaccountRevenue                : keyaccountPlan.keyaccountRevenue ? toNumbers(keyaccountPlan.keyaccountRevenue) : {},
      brancheRevenue                   : keyaccountPlan.brancheRevenue ? toNumbers(keyaccountPlan.brancheRevenue) : {},
      employeesTotalCompany            : keyaccountPlan.employeesTotalCompany ? toNumber(keyaccountPlan.employeesTotalCompany) : 0,
      departments                      : keyaccountPlan.departments ? keyaccountPlan.departments.map(department => { return { ...department, numEmployees: department.numEmployees ? toNumber(department.numEmployees) : 0 } }) : []
    }
  }

  mapToDatabase(keyaccountPlan) {

    const dbKeyaccountPlan = {
      ...keyaccountPlan,
      developments         : [...keyaccountPlan.developments, ...keyaccountPlan.developmentOpportunities],
      strengthAndWeaknesses: [
        ...keyaccountPlan.strengthAndWeaknesses.map(development => ({...development, type: 'sw'})),
        ...keyaccountPlan.strengthAndWeaknessDeployStrategy.map(development => ({...development, type: 'strategy'})),
      ]
    };
    delete dbKeyaccountPlan['developmentOpportunities'];
    return dbKeyaccountPlan;
  }

}
