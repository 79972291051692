import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { KeyaccountPlan } from '../../keyaccount-plan.model';
import { combineLatest, Observable, of } from 'rxjs';
import { Bundle, BundleService } from '../bundle.service';
import { KeyaccountPlanService } from '../../keyaccountplans/keyaccount-plan.service';
import { ActivatedRoute } from '@angular/router';
import { catchError, filter, map, pluck, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Customer } from '../../customer.model';
import { CustomerService } from '../../customer.service';

@Component({
  selector: 'masterplanner2-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {

  keyaccountPlans$: Observable<KeyaccountPlan[]>;
  bundle$: Observable<Bundle>;
  customer$: Observable<Customer>;
  currentYear = (new Date().getFullYear());
  yearSpan = Array.from({length: 4},(v,k)=> k + this.currentYear - 2);
  expandKeyaccountPlans = false;

  constructor(
    activatedRoute: ActivatedRoute,
    bundleService: BundleService,
    keyaccountPlanService: KeyaccountPlanService,
    customerService: CustomerService,
  ) {
    this.bundle$ = activatedRoute.params.pipe(
      pluck('id'),
      filter(id=> !!id),
      switchMap(bundleId => bundleService.getBundle(<string>bundleId)),
      tap(bundle => console.log('Bundle', bundle)),
      shareReplay(1),
    );

    this.customer$ = this.bundle$.pipe(
      map(keyaccountPlan => keyaccountPlan.groupUid),
      filter(id=> !!id),
      switchMap(groupUid => customerService.getCustomer(groupUid)),
      tap(customer => console.log('customer', customer)),
      shareReplay(1),
    );

    this.keyaccountPlans$ = this.bundle$.pipe(
      switchMap(bundle => {
        const plans$ = bundle.keyaccountPlans.map(planUid => keyaccountPlanService.getKeyaccountPlan(planUid)
          .pipe(catchError(e => of(null))));
        return combineLatest(plans$);
      }),
      tap(plans => console.log('plans', plans)),
      shareReplay(1),
    );

  }

  ngOnInit() {
  }

}
