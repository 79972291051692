import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DmuService } from "../../dmu.service";

@Component({
  selector: 'masterplanner2-critical-contacts-flat',
  templateUrl: './critical-contacts-flat.component.html',
  styleUrls: ['./critical-contacts-flat.component.css']
})
export class CriticalContactsFlatComponent implements OnInit, OnChanges {
  @Input()
  contacts: any[];
  score = 0;
  visits = 0;

  constructor(private dmuService: DmuService) {
  }

  ngOnInit() {

    if(this.contacts) {
      this.dmuService.calculateDmuScore(this.contacts);
      this.visits = this.dmuService.calculateDmuVisits(this.contacts);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.contacts) {
      this.score = this.dmuService.calculateDmuScore(this.contacts);
      this.visits = this.dmuService.calculateDmuVisits(this.contacts);
    }
  }

}
